import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FieldTimeOutlined,
  MenuOutlined,
  PlusOutlined,
  UserOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Popover,
  Row,
  Select,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";
import {
  actionCreateTask,
  actionGetAllTask,
  actionSortTasks,
  actionTaskSorting,
  actionUpdateTaskName,
  actionUpdateTaskStatus,
  actionUpdateTaskTime,
  actionUpdateTaskUser,
  deleteTask,
} from "../../store/actions/ticketAction";
import { actionGetUsersAllData } from "../../store/actions/userAction";

const { Paragraph } = Typography;

const Task2 = (props) => {
  const {
    actionGetAllTask,
    actionUpdateTaskUser,
    actionUpdateTaskTime,
    taskCount,
    actionTaskSorting,
    actionGetUsersAllData,
    allUsersData,
    actionSortTasks,
    getTaskData,
    updateTasksStatusLoader,
    deleteTask,
    actionCreateTask,
    actionUpdateTaskName,
    updateTimeLoading,
    createTaskLoading,
    updateUserTaskLoading,
    getTaskLoader,
    taskUserData,
    deletetaskLoading,
    actionUpdateTaskStatus,
  } = props;
  const [TaskValue, setTaskValue] = useState("");
  const [activeCardIndex, setActiveCardIndex] = useState(-1);
  const { ticketId } = useParams();
  const [popoverVisible, setPopoverVisible] = useState([]);
  const [timePopoverVisible, setTimePopoverVisible] = useState([]);
  const [open, setOpen] = useState(false);
  const [editableStr, setEditableStr] = useState("This is an editable text.");
  const [opened, setOpened] = useState(false);
  const [estimatedHours, setEstimatedHours] = useState([]);

  // Initialize selected values state for each card
  const [selectedValues, setSelectedValues] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [form] = Form.useForm();
  const [taskStatus, setTaskStatus] = useState("In Completed");

  const [items, setItems] = useState([]);

  useEffect(() => {
    actionGetAllTask(ticketId);
    actionGetUsersAllData(0, 10);
  }, []);

  useEffect(() => {
    setItems(getTaskData);
  }, [getTaskData]);

  useMemo(() => {}, [getTaskData]);

  const handleAddTask = (e) => {
    e.preventDefault();
    const request = {
      ticket: parseInt(ticketId),
      task_name: TaskValue,
    };
    actionCreateTask(request, form, setTaskValue);
  };

  const agentData = [];
  allUsersData?.map((data, index) => {
    if (data.roles === "AGENT") {
      agentData.push({
        key: index,
        // name: data.first_name + " " + data.last_name,
        // email: data.email,
        label: data.first_name + " " + data.last_name,
        value: data.id,
        // value: data.id,
      });
    }
  });

  const handleChange = (e) => {
    setTaskValue(e.target.value);
  };

  const onFinish = (values) => {
    const request = {
      ...values,
      ticket: parseInt(ticketId),
    };
    actionCreateTask(request, form, setTaskValue);
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Form submission failed:", errorInfo);
  };

  const handleUserIconClick = (index) => {
    const updatedPopoverVisibility = [...popoverVisible];
    updatedPopoverVisibility[index] = !updatedPopoverVisibility[index];
    setPopoverVisible(updatedPopoverVisibility);
  };

  const handleDropdownChange = (value, index, taskId) => {
    const selectedAgent = agentData.find((data) => data.value === value);

    // Update the selected value for the specific card
    const updatedSelectedValues = [...selectedValues];

    const cValue = selectedAgent && selectedAgent.label;
    const firstLetter =
      cValue &&
      cValue
        .split(" ")
        .map((word) => word.charAt(0))
        .join("");
    updatedSelectedValues[index] = firstLetter;
    setSelectedValues(updatedSelectedValues);
    actionUpdateTaskUser(value, taskId, setSelectedValues);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const confirm = (id, index) => {
    deleteTask(id);
  };

  const handleEstimatedHoursSubmit = (value, index, taskId) => {
    // Update the estimated hours state for the specific task
    const updatedEstimatedHours = [...estimatedHours];
    updatedEstimatedHours[index] = value;
    setEstimatedHours(updatedEstimatedHours);

    actionUpdateTaskTime(value.toString(), taskId, setEstimatedHours);
  };

  const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
  ));

  const handleChangeTaskStatus = (e, id) => {
    // setTaskStatus('complete');
    let taskStatus = e === "In Completed" ? "Completed" : "In Completed";
    actionUpdateTaskStatus(taskStatus, id);
  };

  const handleTImeShow = (index) => {
    // const updatedTimePopoverVisibility = timePopoverVisible.map(
    //   (value, i) => i === index
    // );
    // setTimePopoverVisible(updatedTimePopoverVisibility);
  };

  /* Task Cardd  */

  const TaskCard = ({
    task,
    i,

    handleEstimatedHoursSubmit,
    //handleUserIconClick,
    //selectedValues,
    handleDropdownChange,
    //popoverVisible,
    // agentData,
    allUsersData,
    deletetaskLoading,
    confirm,
    handleChangeTaskStatus,
    actionUpdateTaskName,
  }) => {
    const [estimatedHours, setEstimatedHours] = useState([]);
    const [popoverVisible, setPopoverVisible] = useState([]);

    const [timePopoverVisible, setTimePopoverVisible] = useState([]);

    const handleUserIconClick = (index) => {
      const updatedPopoverVisibility = [...popoverVisible];
      updatedPopoverVisibility[index] = !updatedPopoverVisibility[index];
      setPopoverVisible(updatedPopoverVisibility);
    };

    const agentData = [];
    allUsersData?.map((data, index) => {
      if (data.roles === "AGENT") {
        agentData.push({
          key: index,
          // name: data.first_name + " " + data.last_name,
          // email: data.email,
          label: data.first_name + " " + data.last_name,
          value: data.id,
          // value: data.id,
        });
      }
    });

    const handleChangeName = (e, id) => {
      setEditableStr(e);
      actionUpdateTaskName(e, id);
    };

    const handleTImeShow = (index) => {
      const updatedTimePopoverVisibility = timePopoverVisible.map(
        (value, i) => i === index
      );
      setTimePopoverVisible(updatedTimePopoverVisibility);
    };

    return (
      <Card
        className={`task-card`}
        style={{
          marginTop: 16,
        }}
        loading={false}
        key={i}
      >
        <Row align="middle">
          <Col span={1} className="lg:-mr-4 xl:-mr-4">
            {/* <HolderOutlined style={{ cursor: "all-scroll" }} /> */}
            <DragHandle />
          </Col>
          {/* <Col span={1} className="color-greeen-parent mx-2">
            <Tooltip title={task.status}>
              <CheckCircleOutlined
                style={{
                  color: task.status == "In Completed" ? "grey" : "green",
                }}
                className=" color-greeen text-current text-base"
                onClick={(e) => {
                  handleChangeTaskStatus(task.status, task.task_id);
                }}
              />
            </Tooltip>
          </Col> */}

          <Col span={1} className="color-greeen-parent mx-2">
            <Tooltip title={task.status}>
              {task.status === "In Completed" ? (
                <CheckCircleOutlined
                  style={{ color: "grey" }}
                  className="color-greeen text-current text-base"
                  onClick={(e) => {
                    handleChangeTaskStatus(task.status, task.task_id);
                  }}
                />
              ) : (
                <CheckCircleFilled
                  style={{ color: "green" }}
                  className="color-greeen text-current text-base"
                  onClick={(e) => {
                    handleChangeTaskStatus(task.status, task.task_id);
                  }}
                />
              )}
            </Tooltip>
          </Col>

          <Col
            span={17}
            style={{ marginLeft: 0 }}
            className="margin-type"
            align=""
          >
            {/* <Tooltip title={task.task_name} placement="top"> */}
            <Paragraph
              ellipsis
              //bordered={false}
              className="mb-0 bg-transparent"
              editable={{
                onChange: (e) => handleChangeName(e, task.task_id),
                icon: <EditOutlined />,
                triggerType: "icon",
              }}
            >
              {task.task_name}
            </Paragraph>
            {/* </Tooltip> */}
          </Col>
          <Col span={5} align="right">
            <div className="flex justify-end items-center">
              <div className="mx-2">
                <Popover
                  placement="bottom"
                  open={timePopoverVisible[i]}
                  content={
                    <span className="flex">
                      <InputNumber
                        style={{ width: "180px" }}
                        normalize={(value) => `${value.replace(/[^0-9]/g, "")}`}
                        min={0}
                        type="number"
                        prefix={<ClockCircleOutlined />}
                        suffix={"hrs"}
                        value={
                          (estimatedHours && estimatedHours[i]) ||
                          task.estimated_time ||
                          ""
                        }
                        // Set the value from the state
                        onChange={(value) => {
                          const updatedEstimatedHours = [...estimatedHours];
                          updatedEstimatedHours[i] = value;
                          setEstimatedHours(updatedEstimatedHours);
                        }}
                        onPressEnter={() =>
                          handleEstimatedHoursSubmit(
                            estimatedHours[i],
                            i,
                            task.task_id
                          )
                        }
                      />
                    </span>
                  }
                  trigger="click"
                >
                  {task && task.estimated_time ? (
                    <Typography.Text code onClick={() => handleTImeShow(i)}>
                      {`${task && task?.estimated_time} hrs`}
                    </Typography.Text>
                  ) : (
                    <div className="w-[30px] text-center">
                      <FieldTimeOutlined
                        className="cursor-pointer text-current text-base"
                        onClick={() => handleTImeShow(i)}
                      />
                    </div>
                  )}
                </Popover>
              </div>
              <div className=",mx-2 overflow-ellipsis bg-slate-300 ">
                <Popover
                  content={
                    <div className="custom-select-container">
                      <Select
                        defaultOpen={true}
                        optionFilterProp="children"
                        showSearch
                        allowClear
                        filterOption={filterOption}
                        placeholder="Select Agent"
                        value={selectedValues[i] || task?.agent?.id}
                        onChange={(value) =>
                          handleDropdownChange(value, i, task.task_id)
                        }
                        style={{ width: "100%", minWidth: 200 }}
                        options={agentData}
                      />
                    </div>
                  }
                  trigger="click"
                  open={selectedValues[i]} // Use the visibility state for this card
                  onOpenChange={(visible) =>
                    setPopoverVisible((prevState) => {
                      const updatedVisibility = [...prevState];
                      updatedVisibility[i] = visible;
                      return updatedVisibility;
                    })
                  }
                >
                  {task.agent &&
                  task.agent.first_name &&
                  task.agent.last_name ? (
                    <div
                      className="flex h-[30px] w-[30px] p-[5px] items-center justify-center rounded-full uppercase"
                      style={{ background: "rgb(234 179 8)" }}
                    >
                      {/* <Tooltip
                        title={
                          task.agent?.first_name + " " + task.agent?.last_name
                        }
                      > */}
                      {`${
                        task.agent &&
                        task.agent.first_name &&
                        task.agent.first_name[0]
                      }${
                        task.agent &&
                        task.agent.last_name &&
                        task.agent.last_name[0]
                      }`}
                      {/* </Tooltip> */}
                    </div>
                  ) : (
                    <div className="w-[30px] text-center">
                      <UserOutlined
                        className="cursor-pointer text-current text-base"
                        onClick={() => handleUserIconClick(i)} // Pass the card i
                      />
                    </div>
                  )}
                </Popover>
              </div>
              <div className="mx-2">
                <Popconfirm
                  title="Delete the task"
                  description="Are you sure to delete this task?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => confirm(task.task_id, i)}
                  okButtonProps={{
                    loading: deletetaskLoading,
                  }}
                >
                  <DeleteOutlined
                    style={{ color: "red" }}
                    className="cursor-pointer text-current text-base"
                  />
                </Popconfirm>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    );
  };
  /* Task Cardd  */

  const dispatch = useDispatch();

  useEffect(() => {
    // Initialize taskData with your existing task data
    setTaskData(getTaskData);
  }, [getTaskData]);

  function handleSortTasks(sortedData) {
    actionSortTasks(sortedData);
  }
  function onSortEnd({ oldIndex, newIndex }) {
    const reorderedTasks = arrayMove(getTaskData, oldIndex, newIndex);
    handleSortTasks(reorderedTasks); // Call the handleSortTasks function with the sorted data
    let taskSort = [];
    reorderedTasks.map((d) => {
      taskSort.push(d.task_id);
    });
    actionTaskSorting({ task_id: taskSort }, ticketId);
  }

  const SortableItem = SortableElement(({ i, value, ...props }) => {
    return (
      <div className="sortableItem">
        <div className="sortableItem-handle" />
        <TaskCard
          task={props}
          i={i}
          {...props}
          estimatedHours={estimatedHours}
          //   timePopoverVisible={timePopoverVisible}
          allUsersData={allUsersData}
          actionUpdateTaskName={actionUpdateTaskName}
          handleChangeTaskStatus={handleChangeTaskStatus}
          handleTImeShow={handleTImeShow}
          handleEstimatedHoursSubmit={handleEstimatedHoursSubmit}
          handleUserIconClick={handleUserIconClick}
          // selectedValues={selectedValues}
          handleDropdownChange={handleDropdownChange}
          confirm={confirm}
        />{" "}
        {/* Pass individual properties of the task object */}
      </div>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <ul>
        {items.map((item, index) => {
          return (
            <SortableItem
              key={`item-${index}`}
              i={index}
              index={index}
              value={item.task_name}
              {...item}
            />
          );
        })}
      </ul>
    );
  });

  return (
    <>
      <Spin
        spinning={
          getTaskLoader ||
          updateTasksStatusLoader ||
          updateUserTaskLoading ||
          updateTimeLoading
        }
      >
        <Row className="my-6" align="middle">
          <Col xxl={12} xl={15} lg={24} md={24} sm={24} xs={24}>
            <SortableList
              className="z-0 overflow-hidden"
              useDragHandle
              items={items}
              {...props}
              onSortEnd={onSortEnd}
            />
            <Card
              className={`task-card`}
              style={{
                marginTop: 16,
              }}
              loading={false}
            >
              <Row align="middle">
                <Col span={1} className="color-greeen-parent">
                  <CheckCircleOutlined
                    style={{ color: "grey" }}
                    className="color-greeen text-current text-base"
                  />
                </Col>
                <Col span={18} style={{ marginLeft: 0 }} align="middle">
                  <Form
                    form={form}
                    className="ant-form-item1"
                    name="taskForm"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >
                    <Form.Item
                      name="task_name"
                      rules={[
                        {
                          required: false,
                          message: "Please input your Task Name!",
                        },
                      ]}
                    >
                      <Input
                        onChange={handleChange}
                        // bordered={false}
                        // Remove the autofocus attribute
                        placeholder="Enter Task Name"
                        autoFocus
                        autoComplete="off"
                        className="bg-transparent task-input"
                      />
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Card>
            <Form.Item>
              <Button
                form="true"
                htmlType="button" // Ensure the type is "button" and not "submit"
                onClick={handleAddTask}
                disabled={!TaskValue}
                className="bg-transparent my-3 task-button"
                icon={<PlusOutlined />}
                loading={createTaskLoading}
              >
                Add Task
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getTaskData: state.ticket.getTaskData,
    getTaskLoader: state.ticket.getTaskLoader,
    taskCount: state.ticket.taskCount,
    allUsersData: state.users.allUsersData,
    createTaskLoading: state.ticket.createTaskLoading,
    deletetaskLoading: state.ticket.deletetaskLoading,
    taskUserData: state.ticket.taskUserData,
    updateTasksStatusData: state.ticket.updateTasksStatusData,
    updateTasksStatusLoader: state.ticket.updateTasksStatusLoader,
    updateTimeLoading: state.ticket.updateTimeLoading,
    updateUserTaskLoading: state.ticket.updateUserTaskLoading,
  };
};

export default connect(mapStateToProps, {
  actionGetAllTask,
  actionGetUsersAllData,
  actionCreateTask,
  actionUpdateTaskUser,
  deleteTask,
  actionTaskSorting,
  actionUpdateTaskName,
  actionUpdateTaskTime,
  actionSortTasks,
  actionUpdateTaskStatus,
})(Task2);
