export const LOGIN_LOADING = "LOGIN_LOADING";
export const USER_PROFILE_LOADING = "USER_PROFILE_LOADING";
export const USER_PROFILE_DATA = "USER_PROFILE_DATA";
export const LOGOUT = "LOGOUT";
export const UPDATE_PROFILE_LOADING = "UPDATE_PROFILE_LOADING";
export const UPDATE_USER_LOADING = "UPDATE_USER_LOADING";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPLOAD_PIC_LOADING = "UPLOAD_PIC_LOADING";
export const UPLOAD_PIC_DATA = "UPLOAD_PIC_DATA";
export const FORGOT_PASSWORD_LOADER = "FORGOT_PASSWORD_LOADER";
export const CHANGE_PASSWORD_LOADER = "CHANGE_PASSWORD_LOADER";
export const RESET_PASSWORD_LOADER = "RESET_PASSWORD_LOADER";
export const GET_ALL_BANNER_LOADING = "GET_ALL_BANNER_LOADING";
export const GET_ALL_BANNER_DATA = "GET_ALL_BANNER_DATA";
export const GET_PROJECTS_LOADING = "GET_PROJECTS_LOADING";
export const GET_PROJECTS_DATA = "GET_PROJECTS_DATA";
export const TOTAL_PROJECTS_COUNT = "TOTAL_PROJECTS_COUNT";
export const ADD_PROJECT_LOADING = "ADD_PROJECT_LOADING";
export const ADD_PROJECT_DATA = "ADD_PROJECT_DATA";
export const DELETE_PROJECT_ID = "DELETE_PROJECT_ID";
export const DELETE_PROJECT_LOADING = "DELETE_PROJECT_LOADING";
export const UPDATE_PROJECT_LOADING = "UPDATE_PROJECT_LOADING";
export const GET_PROJECT_DETAIL_LOADING = "GET_PROJECT_DETAIL_LOADING";
export const GET_PROJECT_DETAIL_DATA = "GET_PROJECT_DETAIL_DATA";
export const GET_USERS_DATA = "GET_USERS_DATA";
export const TOTAL_USERS_COUNT = "TOTAL_USERS_COUNT";
export const GET_USERS_LOADING = "GET_USERS_LOADING";
export const GET_ALL_USERS_LOADING = "GET_ALL_USERS_LOADING";
export const GET_ALL_USERS_DATA = "GET_ALL_USERS_DATA";
export const UPLOAD_DRAWS_PIC_LOADING = "UPLOAD_DRAWS_PIC_LOADING";
export const UPLOAD_DRAWS_PIC_DATA = "UPLOAD_DRAWS_PIC_DATA";
export const ADD_USER_LOADING = "ADD_USER_LOADING";
export const ADD_USER_DATA = "ADD_USER_DATA";
export const DELETE_USER_LOADING = "DELETE_USER_LOADING";
export const DELETE_USER_ID = "DELETE_USER_ID";
export const GET_USER_DETAIL_LOADING = "GET_USER_DETAIL_LOADING";
export const GET_USER_DETAIL_DATA = "GET_USER_DETAIL_DATA";
export const GET_ALL_TICKET_LOADING = "GET_ALL_TICKET_LOADING";
export const GET_ALL_TICKET_DATA = "GET_ALL_TICKET_DATA";
export const TOTAL_TICKETS_COUNT = "TOTAL_TICKETS_COUNT";
export const DELETE_TICKET_LOADING = "DELETE_TICKET_LOADING";
export const UPDATE_TICKET_STATUS_LOADING = "UPDATE_TICKET_STATUS_LOADING";
export const UPDATE_TICKET_STATUS_DATA = "UPDATE_TICKET_STATUS_DATA";
export const UPDATE_SEVERITY_LOADING = "UPDATE_SEVERITY_LOADING";
export const UPDATE_SEVERITY_DATA = "UPDATE_SEVERITY_DATA";
export const UPDATE_SEVERITY_DETAIL_DATA = "UPDATE_SEVERITY_DETAIL_DATA";
export const DELETE_TICKET_ID = "DELETE_TICKET_ID";
export const ADD_TICKET_LOADING = "ADD_TICKET_LOADING";
export const ADD_TICKET_DATA = "ADD_TICKET_DATA";
export const GET_ALL_TICKET_LOG_DATA = "GET_ALL_TICKET_LOG_DATA";
export const GET_ALL_TICKET_LOG_LOADING = "GET_ALL_TICKET_LOG_LOADING";
export const GET_TICKET_LOG_LOADING = "GET_TICKET_LOG_LOADING";
export const GET_TICKET_LOG_DATA = "GET_TICKET_LOG_DATA";
export const UPDATE_TICKET_LOADING = "UPDATE_TICKET_LOADING";
export const GET_TICKET_DETAIL_LOADING = "GET_TICKET_DETAIL_LOADING";
export const GET_TICKET_DETAIL_DATA = "GET_TICKET_DETAIL_DATA";
export const ADD_KNOWLEDGE_LOADING = "ADD_KNOWLEDGE_LOADING";
export const ADD_KNOWLEDGE_DATA = "ADD_KNOWLEDGE_DATA";
export const GET_ALL_AREA_CONCERN_LOADING = "GET_ALL_AREA_CONCERN_LOADING";
export const GET_ALL_AREA_CONCERN_DATA = "GET_ALL_AREA_CONCERN_DATA";
export const ADD_AREA_CONCERN_LOADING = "ADD_AREA_CONCERN_LOADING";
export const ADD_AREA_CONCERN_DATA = "ADD_AREA_CONCERN_DATA";
export const GET_CAMPAIGN_DATA = "GET_CAMPAIGN_DATA";
export const GET_CAMPAIGN_LOADING = "GET_CAMPAIGN_LOADING";
export const DELETE_CAMPAIGN_LOADING = "DELETE_CAMPAIGN_LOADING";
export const DELETE_CAMPAIGN_ID = "DELETE_CAMPAIGN_ID";
export const ADD_CAMPAIGN_LOADING = "ADD_CAMPAIGN_LOADING";
export const ADD_CAMPAIGN_DATA = "ADD_CAMPAIGN_DATA";
export const UPDATE_CAMPAIGN_LOADING = "UPDATE_CAMPAIGN_LOADING";
export const GET_CAMPAIGN_DETAIL_DATA = "GET_CAMPAIGN_DETAIL_DATA";
export const GET_CAMPAIGN_DETAIL_LOADING = "GET_CAMPAIGN_DETAIL_LOADING";
export const GET_CAMPAIGN_TICKET_LOADING = "GET_CAMPAIGN_TICKET_LOADING";
export const GET_CAMPAIGN_TICKET_DATA = "GET_CAMPAIGN_TICKET_DATA";
export const INCREASE_CAMPAIGN_TICKET_LOADING =
  "INCREASE_CAMPAIGN_TICKET_LOADING";
export const INCREASE_CAMPAIGN_TICKET_DATA = "INCREASE_CAMPAIGN_TICKET_DATA";
export const ALLOWED_REPETATIVE_USERS_LOADING =
  "ALLOWED_REPETATIVE_USERS_LOADING";
export const ALLOWED_REPETATIVE_USERS = "ALLOWED_REPETATIVE_USERS";
export const SEARCH_ANNOUNCE_WINNER_LOADING = "SEARCH_ANNOUNCE_WINNER_LOADING";
export const SEARCH_ANNOUNCE_WINNER_DATA = "SEARCH_ANNOUNCE_WINNER_DATA";
export const ANNOUNCE_WINNER_LOADING = "ANNOUNCE_WINNER_LOADING";
export const ANNOUNCE_WINNER_DATA = "ANNOUNCE_WINNER_DATA";
export const NOT_FOUND_CAMPAIGN_TICKET_DATA = "NOT_FOUND_CAMPAIGN_TICKET_DATA";
export const GET_ORDERS_LOADING = "GET_ORDERS_LOADING";
export const GET_ORDERS_DATA = "GET_ORDERS_DATA";
export const TOTAL_ORDERS_COUNT = "TOTAL_ORDERS_COUNT";
export const GET_ORDER_DETAIL_LOADING = "GET_ORDER_DETAIL_LOADING";
export const GET_ORDER_DETAIL_DATA = "GET_ORDER_DETAIL_DATA";
export const SEARCH_ANNOUNCE_DRAW_WINNER_LOADING =
  "SEARCH_ANNOUNCE_DRAW_WINNER_LOADING";
export const SEARCH_ANNOUNCE_DRAW_WINNER_DATA =
  "SEARCH_ANNOUNCE_DRAW_WINNER_DATA";
export const DRAWS_ANNOUNCE_WINNER_LOADING = "DRAWS_ANNOUNCE_WINNER_LOADING";
export const DRAWS_ANNOUNCE_WINNER_DATA = "DRAWS_ANNOUNCE_WINNER_DATA";
export const SEARCH_DRAWS_ANNOUNCE_WINNER_DATA =
  "SEARCH_DRAWS_ANNOUNCE_WINNER_DATA";
export const UPDATE_ORDER_STATUS_LOADING = "UPDATE_ORDER_STATUS_LOADING";
export const UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS";
export const SEND_NOTIFICATION_DATA = "SEND_NOTIFICATION_DATA";
export const SEND_NOTIFICATION_LOADER = "SEND_NOTIFICATION_LOADER";
export const GET_DASHBOARD_LOADING = "GET_DASHBOARD_LOADING";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_SERVICE_NUMBER = "GET_SERVICE_NUMBER";
export const GET_ALL_EXPORT_TICKET_LOADING = "GET_ALL_EXPORT_TICKET_LOADING";
export const GET_ALL_EXPORT_TICKET_DATA = "GET_ALL_EXPORT_TICKET_DATA";
export const UPDATE_TICKET_DETAIL_STATUS_DATA =
  "UPDATE_TICKET_DETAIL_STATUS_DATA";
export const UPDATE_PRIORITY_STATUS_DATA = "UPDATE_PRIORITY_STATUS_DATA";
export const UPDATE_PRIORITY_DETAIL_STATUS_DATA =
  "UPDATE_PRIORITY_DETAIL_STATUS_DATA";
export const UPDATE_PRIORITY_STATUS_LOADING = "UPDATE_PRIORITY_STATUS_LOADING";
export const UPDATE_TICKET_LEVEL_DATA = "UPDATE_TICKET_LEVEL_DATA";
export const UPDATE_TICKET_DETAIL_LEVEL_DATA =
  "UPDATE_TICKET_DETAIL_LEVEL_DATA";
export const UPDATE_TICKET_LEVEL_LOADING = "UPDATE_TICKET_LEVEL_LOADING";
export const UPDATE_LEVEL_STATUS_DATA = "UPDATE_LEVEL_STATUS_DATA";
export const UPDATE_LEVEL_DETAIL_STATUS_DATA =
  "UPDATE_LEVEL_DETAIL_STATUS_DATA";
export const UPDATE_LEVEL_STATUS_LOADING = "UPDATE_LEVEL_STATUS_LOADING";
export const UPDATE_TIME_DATA = "UPDATE_TIME_DATA";
export const UPDATE_DETAIL_TIME_DATA = "UPDATE_DETAIL_TIME_DATA";
export const ADD_RESOLVE_LOADING = "ADD_RESOLVE_LOADING";
export const ADD_RESOLVE_DATA = "ADD_RESOLVE_DATA";

export const SEND_MAIL_LOADING = "SEND_MAIL_LOADING";
export const SEND_EMAIL_DATA = "SEND_EMAIL_DATA";
export const GET_KNOWLEGDE_DATA = "GET_KNOWLEGDE_DATA";
export const TOTAL_KNOWLEDGE_COUNT = "TOTAL_KNOWLEDGE_COUNT";
export const GET_KNOWLEGDE_LOADING = "GET_KNOWLEGDE_LOADING";
export const GET_KNOWLEDGE_DETAIL_LOADING = "GET_KNOWLEDGE_DETAIL_LOADING";
export const GET_KNOWLEDGE_DETAIL_DATA = "GET_KNOWLEDGE_DETAIL_DATA";
export const UPDATE_KNOWLEDGE_LOADING = "UPDATE_KNOWLEDGE_LOADING";
export const DELETE_KNOWLEDGE_LOADING = "DELETE_KNOWLEDGE_LOADING";
export const DELETE_KNOWLEDGE_ID = "DELETE_KNOWLEDGE_ID";
export const STORE_CLICKED_DATA = "STORE_CLICKED_DATA";
// export const UPDATE_TICKET_LOADING = "UPDATE_TICKET_LOADING";
export const UPDATE_TICKET_DATA = "UPDATE_TICKET_DATA";
export const SET_PAGE = "SET_PAGE";

// Task Actions

export const CREATE_TASK_LOADING = "CREATE_TASK_LOADING";
export const CREATE_TASK_DATA = "CREATE_TASK_DATA";
export const DELETE_TASK_LOADING = "DELETE_TASK_LOADING";
export const DELETE_TASK_ID = "DELETE_TASK_ID";
export const GET_TASK_LOADING = "GET_TASK_LOADING";
export const GET_TASK_DATA = "GET_TASK_DATA";
export const UPDATE_TASK_LOADING = "UPDATE_TASK_LOADING";
export const UPDATE_TASK_DATA = "UPDATE_TASK_DATA";
export const TASK_COUNT = "TASK_COUNT";
export const UPDATE_TASK_TIME_LOADING = "UPDATE_TASK_TIME_LOADING";
export const UPDATE_TASK_USER_LOADING = "UPDATE_TASK_USER_LOADING";
export const UPDATE_TASK_NAME_LOADING = "UPDATE_TASK_NAME_LOADING";
export const TASK_USER = "TASK_USER";
export const TASK_NAME = "TASK_NAME";
export const TASK_TIME = "TASK_TIME";
export const SORT_TASKS = "SORT_TASKS";
export const CREATE_TIMING_LOADING = "CREATE_TIMING_LOADING";
export const CREATE_TIMING_DATA = "CREATE_TIMING_DATA";
export const GET_TIMING_LOADING = "GET_TIMING_LOADING";
export const GET_TIMING_DATA = "GET_TIMING_DATA";
export const UPDATE_TASKSTATUS_LOADING = "UPDATE_TASKSTATUS_LOADING";
export const UPDATE_TASK_STATUS = "UPDATE_TASK_STATUS";
export const TASK_SORTING_LOADING = "TASK_SORTING_LOADING";
export const TASK_SORTING_DATA = "TASK_SORTING_DATA";
export const TIME_COUNT = "TIME_COUNT";
export const REMOVE_TASK_STATUS_LOADING = "REMOVE_TASK_STATUS_LOADING";
export const REMOVE_TASK_STATUS_DATA = "REMOVE_TASK_STATUS_DATA";
export const REMOVE_DATA = "REMOVE_DATA";
export const REMOVE_TASK_STATUS = "REMOVE_TASK_STATUS";

export const TOTAL_TIME_COUNT = "TOTAL_TIME_COUNT";
export const GET_TIME_DATA = "GET_TIME_DATA";
export const GET_TIME_LOADING = "GET_TIME_LOADING";
export const ADD_TIME_LOADING = "ADD_TIME_LOADING";
export const ADD_TIME_DATA = "ADD_TIME_DATA";
export const UPDATE_TIME_LOADING = "UPDATE_TIME_LOADING";
export const DELETE_TIME_LOADING = "DELETE_TIME_LOADING";
export const DELETE_TIME_ID = "DELETE_TIME_ID";
export const GET_TIME_DETAIL_DATA = "GET_TIME_DETAIL_DATA";
export const GET_TIME_DETAIL_LOADING = "GET_TIME_DETAIL_LOADING";
export const UPDATE_TIMESINGLE_DATA = "UPDATE_TIMESINGLE_DATA";
export const TOTAL_TIMEHRS_COUNT = "TOTAL_TIMEHRS_COUNT";
export const GET_TICKETS_REPORT_LOADING = "GET_TICKETS_REPORT_LOADING";
export const GET_TICKETS_REPORT_DATA = "GET_TICKETS_REPORT_DATA";
export const TOTAL_USER_REPORT_COUNT = "TOTAL_USER_REPORT_COUNT";
export const GET_AGENT_YEARLY_REPORT_LOADING =
  "GET_AGENT_YEARLY_REPORT_LOADING";
export const GET_AGENT_YEARLY_REPORT_DATA = "GET_AGENT_YEARLY_REPORT_DATA";
export const TOTAL_AGENT_YEARLY_REPORT_COUNT =
  "TOTAL_AGENT_YEARLY_REPORT_COUNT";
export const GET_TIME_SPENT_REPORT_LOADING = "GET_TIME_SPENT_REPORT_LOADING";
export const TOTAL_TIME_SPENT_REPORT_COUNT = "TOTAL_TIME_SPENT_REPORT_COUNT";
export const GET_TIME_SPENT_REPORT_DATA = "GET_TIME_SPENT_REPORT_DATA";
export const GET_ACTUAL_HOURS_REPORT_LOADING =
  "GET_ACTUAL_HOURS_REPORT_LOADING";
export const TOTAL_ACTUAL_HOURS_REPORT_COUNT =
  "TOTAL_ACTUAL_HOURS_REPORT_COUNT";
export const GET_ACTUAL_HOURS_REPORT_DATA = "GET_ACTUAL_HOURS_REPORT_DATA";
export const GET_TICKETS_REPORT_ALL_DATA = "GET_TICKETS_REPORT_ALL_DATA";
export const GET_EXPORT_TICKETS_REPORT_LOADING =
  "GET_EXPORT_TICKETS_REPORT_LOADING";
export const GET_EXPORT_AGENT_YEARLY_REPORT_DATA =
  "GET_EXPORT_AGENT_YEARLY_REPORT_DATA";
export const GET_EXPORT_TIME_SPENT_REPORT_DATA =
  "GET_EXPORT_TIME_SPENT_REPORT_DATA";
export const GET_EXPORT_ACTUAL_HOURS_REPORT_DATA =
  "GET_EXPORT_ACTUAL_HOURS_REPORT_DATA";
export const GET_TICKET_AGENT_YEARLY_REPORT_LOADING =
  "GET_TICKET_AGENT_YEARLY_REPORT_LOADING";
export const GET_TICKET_AGENT_YEARLY_REPORT_DATA =
  "GET_TICKET_AGENT_YEARLY_REPORT_DATA";
export const TOTAL_TICKET_AGENT_YEARLY_REPORT_COUNT =
  "TOTAL_TICKET_AGENT_YEARLY_REPORT_COUNT";
export const GET_EXPORT_TICKET_AGENT_YEARLY_REPORT_DATA =
  "GET_EXPORT_TICKET_AGENT_YEARLY_REPORT_DATA";
export const GET_TICKET_TIME_SPENT_REPORT_LOADING =
  "GET_TICKET_TIME_SPENT_REPORT_LOADING";
export const TOTAL_TICKET_TIME_SPENT_REPORT_COUNT =
  "TOTAL_TICKET_TIME_SPENT_REPORT_COUNT";
export const GET_TICKET_TIME_SPENT_REPORT_DATA =
  "GET_TICKET_TIME_SPENT_REPORT_DATA";
export const GET_EXPORT_TICKET_TIME_SPENT_REPORT_DATA =
  "GET_EXPORT_TICKET_TIME_SPENT_REPORT_DATA";
export const GET_TASK_HOURS_DASHBOARD_DATA = "GET_TASK_HOURS_DASHBOARD_DATA";
export const GET_TASK_HOURS_DASHBOARD_LOADING =
  "GET_TASK_HOURS_DASHBOARD_LOADING";
export const CHANGE_TOTAL_TIME_SPEND_GET_ALL_TICKTES =
  "CHANGE_TOTAL_TIME_SPEND_GET_ALL_TICKTES";
export const UPDATE_PROJECT_TOTAL_HOURS_DATA =
  "UPDATE_PROJECT_TOTAL_HOURS_DATA";
export const UPDATE_PROJECT_TOTAL_HOURS_LOADING =
  "UPDATE_PROJECT_TOTAL_HOURS_LOADING";
export const GET_PROJECT_HRS_LOG_LOADING = "GET_PROJECT_HRS_LOG_LOADING";
export const GET_PROJECT_HRS_LOG_DATA = "GET_PROJECT_HRS_LOG_DATA";
export const HIDE_REPORTS = "HIDE_REPORTS";
export const HIDE_REPORTS_LOADER = "HIDE_REPORTS_LOADER";
