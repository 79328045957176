import { MailOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Row,
  Typography
} from "antd";
import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/comman/logo.png";
import { actionForgotPassword } from "../../store/actions/authAction";
const { Title } = Typography;

const ForgotPassword = (props) => {
  const Navigate = useNavigate();

  const { actionForgotPassword, forgotPasswordLoader } = props;
  const onFinish = (values) => {
    actionForgotPassword(values, Navigate);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div>
      <Card
        className="login-card"
        style={{
          width: 450,
          marginBottom: "100px",
        }}
      >
        {/* <Title level={3} style={{ textAlign: "center" }}>
          Forgot Password
        </Title> */}
        <div className="logo text-center">
          <Image src={logo} preview={false} alt="logo" />
        </div>
        <h2 className="text-2xl	pb-10 pt-3 text-center font-bold">
          Forgot Password
        </h2>
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size="large"
        >
          <Row align="center" className="row-card">
            <Col span="24">
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                  suffix={<MailOutlined className="site-form-item-icon" />}
                />
              </Form.Item>
            </Col>

            <Col span={24} align="left">
              Provide your account email address to receive an email to reset
              your password.
            </Col>
            <Col span={24} style={{ marginBottom: 20 }} />

            <Col span={24} align="middle">
              <Form.Item>
                <Button block loading={forgotPasswordLoader} htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    forgotPasswordLoader: state.auth.forgotPasswordLoader,
  };
};

export default connect(mapStateToProps, { actionForgotPassword })(
  ForgotPassword
);
