import * as actionTypes from "../action";

const initialState = {
  addKnowledgeLoader: false,
  addKnowledgeData: [],
  knowledgeData: [],
  knowledgeLoader: false,
  deleteKnowledgeLoading: false,
  knowledgeUpdateLoader: false,
  knowledgeDetailLoader: false,
  knowledgeDetailData: {},
  knowledgeCount: {},
};

const ticketsReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.GET_KNOWLEGDE_LOADING:
      return { ...state, knowledgeLoader: action.payload };

    case actionTypes.GET_KNOWLEGDE_DATA:
      return { ...state, knowledgeData: action.payload };

    case actionTypes.ADD_KNOWLEDGE_LOADING:
      return { ...state, addKnowledgeLoader: action.payload };

    case actionTypes.ADD_KNOWLEDGE_DATA:
      return { ...state, addKnowledgeData: action.payload };

    case actionTypes.TOTAL_KNOWLEDGE_COUNT:
      return { ...state, knowledgeCount: action.payload };

    case actionTypes.DELETE_KNOWLEDGE_LOADING:
      return { ...state, deleteKnowledgeLoading: action.payload };

    case actionTypes.DELETE_KNOWLEDGE_ID:
      return {
        ...state,
        knowledgeData: [
          ...state.knowledgeData.filter(
            (data) => data.kb_id !== action.payload
          ),
        ],
      };

    case actionTypes.UPDATE_KNOWLEDGE_LOADING:
      return { ...state, knowledgeUpdateLoader: action.payload };

    case actionTypes.GET_KNOWLEDGE_DETAIL_LOADING:
      return { ...state, knowledgeDetailLoader: action.payload };

    case actionTypes.GET_KNOWLEDGE_DETAIL_DATA:
      return {
        ...state,
        knowledgeDetailData: action.payload,
      };


    default:
      return state;
  }
};

export default ticketsReducer;
