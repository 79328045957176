import axios from "axios";
import { BASE_URL } from "../../components/config/web-config";
import * as actionTypes from "../action";
import { message } from "antd";

export const actionGetProjectAll =
  (offset, limit, keyword, status) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_PROJECTS_LOADING, payload: true });
    try {
      let result = await axios.get(
        `${BASE_URL}/projects?limit=${limit}&offset=${offset}${
          keyword ? "&keyword=" + keyword : ""
        }`
      );
      dispatch({ type: actionTypes.GET_PROJECTS_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.GET_PROJECTS_DATA,
          payload: result.data.data,
        });
        dispatch({
          type: actionTypes.TOTAL_PROJECTS_COUNT,
          payload: result.data.total_records,
        });
      } else if (parseInt(result.data.status) === 404) {
        dispatch({
          type: actionTypes.GET_PROJECTS_DATA,
          payload: [],
        });
        dispatch({
          type: actionTypes.TOTAL_PROJECTS_COUNT,
          payload: 0,
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.GET_PROJECTS_LOADING, payload: false });
    }
  };

export const actionAddProject =
  (userData, navigate) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.ADD_PROJECT_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/projects`, userData);
      dispatch({ type: actionTypes.ADD_PROJECT_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.ADD_PROJECT_DATA,
          payload: result.data.data,
        });
        message.success(result.data.message, 5);
        navigate && setTimeout(() => navigate(`/projects`), 1000);
      } else {
        message.error(result.data.message, 5);
      }
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.ADD_PROJECT_LOADING, payload: false });
    }
  };

export const actionDeleteProject = (id) => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.DELETE_PROJECT_LOADING,
    payload: true,
  });
  try {
    let result = await axios.delete(`${BASE_URL}/projects/${id}`);
    dispatch({
      type: actionTypes.DELETE_PROJECT_LOADING,
      payload: false,
    });
    if (parseInt(result.data.status) === 200) {
      dispatch({ type: actionTypes.DELETE_PROJECT_ID, payload: id });
      message.success(result.data.message, 5);
    } else message.error(result.data.message, 5);
  } catch (error) {
    message.error(error.message, 5);
    dispatch({
      type: actionTypes.DELETE_PROJECT_LOADING,
      payload: false,
    });
  }
};

// export const actionDeleteProject = (id) => (dispatch, getState) => {
//   dispatch({ type: actionTypes.DELETE_PROJECT_ID, payload: id });
//   message.success("Project deleted successfully.", 5);
// };

export const actionUpdateProject =
  (userData, navigate, id) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_PROJECT_LOADING, payload: true });
    try {
      let result = await axios.patch(`${BASE_URL}/projects/${id}`, userData);
      dispatch({ type: actionTypes.UPDATE_PROJECT_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        message.success(result.data.message, 5);
        navigate && setTimeout(() => navigate(`/projects`), 1000);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.UPDATE_PROJECT_LOADING, payload: false });
    }
  };

export const actionGetProjectDetail = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_PROJECT_DETAIL_LOADING, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/projects/${id}`);
    dispatch({ type: actionTypes.GET_PROJECT_DETAIL_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_PROJECT_DETAIL_DATA,
        payload: result.data.data,
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    dispatch({ type: actionTypes.GET_PROJECT_DETAIL_LOADING, payload: false });
  }
};

// upadte project total hours
export const actionUpdateProjectTotalHours =
  (req) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.UPDATE_PROJECT_TOTAL_HOURS_LOADING,
      payload: true,
    });
    try {
      let result = await axios.post(`${BASE_URL}/projects/add-extra-hours`, req);
      dispatch({
        type: actionTypes.UPDATE_PROJECT_TOTAL_HOURS_LOADING,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.UPDATE_PROJECT_TOTAL_HOURS_DATA,
          payload: req,
        });
        message.success(result.data.message, 5);
      } else {
        message.error(result.data.message, 5);
      }
    } catch (error) {
      message.error(error.message, 5);
      dispatch({
        type: actionTypes.UPDATE_PROJECT_TOTAL_HOURS_LOADING,
        payload: false,
      });
    }
  };


// get project hours log
export const actionGetProjectHRSLog = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_PROJECT_HRS_LOG_LOADING, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/projects/extra-hours?${id ? "project_id=" + id : ""}`);
    dispatch({ type: actionTypes.GET_PROJECT_HRS_LOG_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_PROJECT_HRS_LOG_DATA,
        payload: result.data.data,
      });
    } 
    // else message.error(result.data.message, 5);
  } catch (error) {
    dispatch({ type: actionTypes.GET_PROJECT_HRS_LOG_LOADING, payload: false });
  }
};