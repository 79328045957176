import * as actionTypes from "../action";

const initialState = {
    notificationLoader: false,
    notificationData: [],
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SEND_NOTIFICATION_LOADER:
            return { ...state, notificationLoader: action.payload };

        case actionTypes.SEND_NOTIFICATION_DATA:
            return { ...state, notificationData: action.payload };

        default:
            return state;
    }
};
export default notificationReducer;
