import * as actionTypes from "../action";

const initialState = {
    ticketsReportData: [],
    reportCount: {},
    ticketsReportLoader: false,
    agentYearlyReportLoader: false,
    agentYearlyReportData: [],
    agentYearlyReportCount: {},
    timeSpentReportLoader: false,
    timeSpentReportCount: {},
    timeSpentReportData: [],
    actualHoursReportLoader: false,
    actualHoursReportCount: {},
    actualHoursReportData: [],
    userexportrReportData: [],
    agentExportYearlyReportData: [],
    exportTimeSpentReportData: [],
    exportActualHoursReportData: [],
    agentTicketYearlyReportLoader: false,
    agentTicketYearlyReportData: [],
    agentTicketYearlyReportCount: {},
    agentTicketExportYearlyReportData: [],
    ticketTimeSpentReportLoader: false,
    ticketTimeSpentReportCount: {},
    ticketTimeSpentReportData: [],
    exportTicketTimeSpentReportData: [],
    taskDashboardDataLoader:false,
    taskDashboardData:[]


};

const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_TICKETS_REPORT_LOADING:
            return { ...state, ticketsReportLoader: action.payload };

        case actionTypes.TOTAL_USER_REPORT_COUNT:
            return { ...state, reportCount: action.payload };

        case actionTypes.GET_EXPORT_TICKETS_REPORT_LOADING:
            return { ...state, userexportrReportLoader: action.payload };

        case actionTypes.GET_TICKETS_REPORT_ALL_DATA:
            return { ...state, userexportrReportData: action.payload };

        case actionTypes.GET_TICKETS_REPORT_DATA:
            return { ...state, ticketsReportData: action.payload };

        case actionTypes.GET_AGENT_YEARLY_REPORT_LOADING:
            return { ...state, agentYearlyReportLoader: action.payload };

        case actionTypes.GET_AGENT_YEARLY_REPORT_DATA:
            return { ...state, agentYearlyReportData: action.payload };

        case actionTypes.TOTAL_AGENT_YEARLY_REPORT_COUNT:
            return { ...state, agentYearlyReportCount: action.payload };

        case actionTypes.GET_EXPORT_AGENT_YEARLY_REPORT_DATA:
            return { ...state, agentExportYearlyReportData: action.payload };


        case actionTypes.GET_TIME_SPENT_REPORT_LOADING:
            return { ...state, timeSpentReportLoader: action.payload };

        case actionTypes.TOTAL_TIME_SPENT_REPORT_COUNT:
            return { ...state, timeSpentReportCount: action.payload };

        case actionTypes.GET_TIME_SPENT_REPORT_DATA:
            return { ...state, timeSpentReportData: action.payload };

        case actionTypes.GET_EXPORT_TIME_SPENT_REPORT_DATA:
            return { ...state, exportTimeSpentReportData: action.payload };

        case actionTypes.GET_ACTUAL_HOURS_REPORT_LOADING:
            return { ...state, actualHoursReportLoader: action.payload };

        case actionTypes.TOTAL_ACTUAL_HOURS_REPORT_COUNT:
            return { ...state, actualHoursReportCount: action.payload };

        case actionTypes.GET_ACTUAL_HOURS_REPORT_DATA:
            return { ...state, actualHoursReportData: action.payload };

        case actionTypes.GET_EXPORT_ACTUAL_HOURS_REPORT_DATA:
            return { ...state, exportActualHoursReportData: action.payload };

        case actionTypes.GET_TICKET_AGENT_YEARLY_REPORT_LOADING:
            return { ...state, agentTicketYearlyReportLoader: action.payload };

        case actionTypes.GET_TICKET_AGENT_YEARLY_REPORT_DATA:
            return { ...state, agentTicketYearlyReportData: action.payload };

        case actionTypes.TOTAL_TICKET_AGENT_YEARLY_REPORT_COUNT:
            return { ...state, agentTicketYearlyReportCount: action.payload };

        case actionTypes.GET_EXPORT_TICKET_AGENT_YEARLY_REPORT_DATA:
            return { ...state, agentTicketExportYearlyReportData: action.payload };


        case actionTypes.GET_TICKET_TIME_SPENT_REPORT_LOADING:
            return { ...state, ticketTimeSpentReportLoader: action.payload };

        case actionTypes.TOTAL_TICKET_TIME_SPENT_REPORT_COUNT:
            return { ...state, ticketTimeSpentReportCount: action.payload };

        case actionTypes.GET_TICKET_TIME_SPENT_REPORT_DATA:
            return { ...state, ticketTimeSpentReportData: action.payload };

        case actionTypes.GET_EXPORT_TICKET_TIME_SPENT_REPORT_DATA:
            return { ...state, exportTicketTimeSpentReportData: action.payload };

        case actionTypes.GET_TASK_HOURS_DASHBOARD_LOADING:
            return { ...state, taskDashboardDataLoader: action.payload };

        case actionTypes.GET_TASK_HOURS_DASHBOARD_DATA:
            return { ...state, taskDashboardData: action.payload };

        default:
            return state;
    }
};
export default reportReducer;
