import React, { useEffect } from "react";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import {
  Table,
  Typography,
  Form,
  DatePicker,
  Col,
  Row,
  Select,
  Input,
  Button,
  Breadcrumb,
  Tooltip,
} from "antd";
import {
  CalendarOutlined,
  ArrowLeftOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { actionGetUsersAllData } from "../../store/actions/userAction";
import { useState } from "react";
import {
  actionGetTicketTimeSpentReportAll,
  clearReportData,
} from "../../store/actions/reportAction";
import { useNavigate, Link } from "react-router-dom";
import moment from "moment";

const TicketTimeSpentReport = (props) => {
  const {
    allUsersData,
    clearReportData,
    actionGetUsersAllData,
    allUsersLoader,
    ticketTimeSpentReportLoader,
    ticketTimeSpentReportCount,
    ticketTimeSpentReportData,
    actionGetTicketTimeSpentReportAll,
    exportTicketTimeSpentReportData,
  } = props;

  const [form] = Form.useForm();
  const [user, setUser] = useState("");
  const [ticketId, setTicketId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [current, setCurrent] = useState(1);
  const { RangePicker } = DatePicker;

  const Navigate = useNavigate();

  const columns = [
    {
      title: "Ticket No.",
      dataIndex: "ticket_number",
      key: "ticket_number",
      fixed: "left",
      width: 150,
    },
    {
      title: (
        <div>
          <CalendarOutlined className="mr-1" />
          DOS
        </div>
      ),
      dataIndex: "dos",
      key: "dos",
      width: 150,
    },
    {
      title: "Ticket Title",
      dataIndex: "ticket_title",
      key: "ticket_title",
      width: 150,
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
      width: 150,
    },
    {
      title: "Area Of Concern",
      dataIndex: "aoc",
      key: "aoc",
      width: 150,
    },
    {
      title: "Technical Hours",
      dataIndex: "technical_hours",
      key: "technical_hours",
      width: 150,
    },
    {
      title: "Functional Hours",
      dataIndex: "functional_hours",
      key: "functional_hours",
      width: 150,
    },
    {
      title: "CSR Hours",
      dataIndex: "csr_hours",
      key: "csr_hours",
      width: 150,
    },
    {
      title: "Total Hours",
      dataIndex: "hours",
      key: "hours",
      fixed: "right",
      width: 150,
    },
  ];

  const data =
    ticketTimeSpentReportData &&
    ticketTimeSpentReportData.data &&
    ticketTimeSpentReportData.data.length > 0 &&
    ticketTimeSpentReportData.data.map((item, index) => ({
      key: index,
      ticket_number: (
        <Link to={`/view-ticket/${item.ticket_id}`} className="link">
          {item.ticket_no || "-"}
        </Link>
      ),
      dos: moment(item.date_of_submission).format("DD-MM-YYYY") || "-",
      ticket_title: (
        <div style={{ width: 100, margin: "auto" }}>
          <Tooltip title={item.ticket_title}>
            {item.ticket_title ? (
              item.ticket_title.length > 30 ? (
                <>{item.ticket_title.slice(0, 30)}...</>
              ) : (
                item.ticket_title
              )
            ) : (
              "-"
            )}
          </Tooltip>
        </div>
      ),
      // date: moment(item.date).format('DD-MM-YYYY') || '-' ,
      agent: item.agent || "-",
      aoc: item.area_of_concern || "-",
      hours: item.total_hours || "-",
      isagentdeleted: item.isagentdeleted,
      technical_hours: item.technical_hours || "-",
      functional_hours: item.functional_hours || "-",
      csr_hours: item.csr_hours || "-",
    }));

  const onChange = (e) => {
    setUser(e);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    actionGetUsersAllData(0, 10);
    actionGetTicketTimeSpentReportAll(0, 10);
  }, []);

  const onClickPaginationNumber = (e, t, sorter) => {
    actionGetTicketTimeSpentReportAll(
      e.current - 1,
      e.pageSize,
      ticketId,
      startDate,
      endDate,
      user
    );
    setCurrent(e.current);
  };

  const agentData = [];
  allUsersData?.map((data, index) => {
    if (data.roles === "AGENT") {
      agentData.push({
        key: index,
        label: data.first_name + " " + data.last_name,
        // value: data.first_name + " " + data.last_name,
        value: data.id,
      });
    }
  });

  const handleFinish = (value) => {
    setCurrent(1);
    actionGetTicketTimeSpentReportAll(
      0,
      10,
      value.ticket_id || "",
      startDate,
      endDate,
      user
    );
  };
  const breadcrumbItems = [
    {
      key: "back",
      title: (
        <>
          <ArrowLeftOutlined style={{ cursor: "pointer" }} />
          <span
            style={{
              cursor: "pointer",
              color: "rgba(0, 0, 0, 0.88)",
              fontWeight: "600",
              fontSize: 18,
            }}
          >
            Go Back
          </span>
        </>
      ),
      onClick: () => Navigate(-1),
    },
  ];

  useEffect(() => {
    if (
      exportTicketTimeSpentReportData &&
      exportTicketTimeSpentReportData.data &&
      exportTicketTimeSpentReportData.data.length > 0
    ) {
      const data =
        exportTicketTimeSpentReportData.data &&
        exportTicketTimeSpentReportData.data.map((data) => ({
          "Ticket No.": data.ticket_no || "-",
          "Date of Submission":
            moment(data.date_of_submission).format("DD-MM-YYYY") || "-",
          "Ticket Title": data.ticket_title || "-",
          "Agent	": data.agent || "-",
          "Area Of Concern": data.area_of_concern || "-",
          "Technical Hours": data.technical_hours || "-",
          "Functional Hours": data.functional_hours || "-",
          "CSR Hours": data.csr_hours || "-",
          "Total Hours": data.total_hours || "-",
        }));
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet2");
      // Generate the Excel file and initiate the download
      XLSX.writeFile(workbook, "SAP_TicketTimeTracking_Report.xlsx");

      clearReportData();
    }
  }, [exportTicketTimeSpentReportData]);

  const exportLogToExcel = () => {
    try {
      actionGetTicketTimeSpentReportAll(
        0,
        10,
        ticketId,
        startDate,
        endDate,
        user,
        "YES"
      );
    } catch (err) {
      console.log(err);
    }
  };

  const rangePresets = [
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 14 Days",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "Last 90 Days",
      value: [dayjs().add(-90, "d"), dayjs()],
    },
    {
      label: "Last Year",
      value: [dayjs().add(-1, "y"), dayjs()],
    },
    // {
    //     label: "Last Year",
    //     value: [dayjs().subtract(1, "y").startOf("year"), dayjs().subtract(1, "y").endOf("year")],
    // },
  ];

  return (
    <>
      <Breadcrumb className="mb-3" items={breadcrumbItems} />
      <div className="flex justify-between">
        <Typography.Title level={3} className="mb-0">
          Ticket Time Tracking Report
        </Typography.Title>
        <div className="border border-1 rounded-lg border-[#89baff] flex py-2 px-3">
          <div className=" flex items-center border-r-2 border-[#C5CBD1] pr-3">
            <p className="text-lg font-semibold">Total Hours</p>
            <p className="text-lg ml-2 font-bold">
              {/* {ticketTimeSpentReportData && ticketTimeSpentReportData.dashboard && ticketTimeSpentReportData.dashboard.total_hours ? ticketTimeSpentReportData.dashboard.total_hours : "0"} */}
              {ticketTimeSpentReportData &&
              ticketTimeSpentReportData.dashboard &&
              ticketTimeSpentReportData.dashboard.total_hours
                ? parseFloat(
                    ticketTimeSpentReportData.dashboard.total_hours
                  ).toFixed(2)
                : "0"}
            </p>
          </div>
          <div className=" flex items-center border-r-2 border-[#C5CBD1] px-3">
            <p className="text-lg font-semibold ">Technical Hours</p>
            <p className="text-lg ml-2 font-bold">
              {/* {ticketTimeSpentReportData && ticketTimeSpentReportData.dashboard && ticketTimeSpentReportData.dashboard.technical_hours ? ticketTimeSpentReportData.dashboard.technical_hours : "0"} */}
              {ticketTimeSpentReportData &&
              ticketTimeSpentReportData.dashboard &&
              ticketTimeSpentReportData.dashboard.technical_hours
                ? parseFloat(
                    ticketTimeSpentReportData.dashboard.technical_hours
                  ).toFixed(2)
                : "0"}
            </p>
          </div>
          <div className=" flex items-center  border-r-2 border-[#C5CBD1] px-3">
            <p className="text-lg font-semibold">Functional Hours</p>
            <p className="text-lg ml-2 font-bold">
              {/* {ticketTimeSpentReportData && ticketTimeSpentReportData.dashboard && ticketTimeSpentReportData.dashboard.functional_hours ? ticketTimeSpentReportData.dashboard.functional_hours : "0"} */}
              {ticketTimeSpentReportData &&
              ticketTimeSpentReportData.dashboard &&
              ticketTimeSpentReportData.dashboard.functional_hours
                ? parseFloat(
                    ticketTimeSpentReportData.dashboard.functional_hours
                  ).toFixed(2)
                : "0"}
            </p>
          </div>
          <div className=" flex items-center pl-3">
            <p className="text-lg font-semibold">CSR Hours</p>
            <p className="text-lg ml-2 font-bold">
              {/* {ticketTimeSpentReportData && ticketTimeSpentReportData.dashboard && ticketTimeSpentReportData.dashboard.csr_hours ? ticketTimeSpentReportData.dashboard.csr_hours : "0"} */}
              {ticketTimeSpentReportData &&
              ticketTimeSpentReportData.dashboard &&
              ticketTimeSpentReportData.dashboard.csr_hours
                ? parseFloat(
                    ticketTimeSpentReportData.dashboard.csr_hours
                  ).toFixed(2)
                : "0"}
            </p>
          </div>
        </div>
      </div>
      <Form onFinish={handleFinish} className="mt-8" form={form}>
        <Row gutter={[16, 16]}>
          <Col span={5}>
            <Form.Item className="mb-4" name="user">
              <Select
                onClear={() => setUser("")}
                style={{ padding: "6px 0" }}
                className="width rounded py-[7px] h-full  border border-true"
                showSearch
                allowClear
                placeholder="Select Agent"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={filterOption}
                options={agentData}
                loading={allUsersLoader}
                disabled={allUsersLoader}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item className="mb-4" name="ticket_id">
              <Input
                autoComplete="off"
                onChange={(e) => setTicketId(e.target.value)}
                className="mb-4 border border-true"
                placeholder="Enter Ticket Number"
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name="Date">
              {/* <DatePicker.RangePicker
                                onChange={(e, value) => {
                                    setStartDate(value[0]);
                                    setEndDate(value[1]);
                                }}
                                className='w-full mb-4 border border-true'
                            /> */}
              <RangePicker
                onChange={(e, value) => {
                  setStartDate(value[0]);
                  setEndDate(value[1]);
                }}
                showTime={false}
                className="w-full mb-4 border border-true"
                presets={[...rangePresets]}
                format="YYYY-MM-DD"
              />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item>
              <Button
                className="min-h-[42px] outline-none focus:shadow-none focus-visible:outline-0 focus-visible:outline-offset-0	 "
                type="primary"
                htmlType="submit"
              >
                Search
              </Button>
            </Form.Item>
          </Col>
          <div className="ml-auto mr-3">
            <Button
              className="min-h-[42px] outline-none focus:shadow-none focus-visible:outline-0 focus-visible:outline-offset-0 flex items-center justify-center"
              onClick={exportLogToExcel}
            >
              <ExportOutlined />
              Export
            </Button>
          </div>
        </Row>
      </Form>

      <Table
        bordered
        // className="user-table"
        sticky={true}
        size="small"
        pagination={{
          total: ticketTimeSpentReportCount || 0,
          current: current,
          hideOnSinglePage: true,
        }}
        columns={columns}
        dataSource={data}
        loading={ticketTimeSpentReportLoader}
        onChange={(e) => onClickPaginationNumber(e)}
        scroll={{
          x: "max-content",
          // y: window.innerWidth < 1500 ? "55vh" : "60vh",
        }}
        rowClassName={(record) => {
          const isDeleted = record.isagentdeleted;
          return isDeleted === 1 ? "delete-red" : "text-black";
        }}
        onRow={(record) => ({
          onMouseEnter: (event) => {
            if (record.isagentdeleted === 1) {
              // Show tooltip or message when user is deleted
              const tooltip = document.createElement("div");
              tooltip.innerHTML = "This User Was Deleted";
              tooltip.style.position = "absolute";
              tooltip.style.backgroundColor = "#000"; // Customize the background color
              tooltip.style.color = "#fff"; // Customize the text color
              tooltip.style.padding = "4px 8px";
              tooltip.style.borderRadius = "4px";
              tooltip.style.zIndex = "9999";
              tooltip.style.left = `${event.pageX}px`;
              tooltip.style.top = `${event.pageY}px`;
              tooltip.style.fontSize = "13px";
              document.body.appendChild(tooltip);

              record._tooltip = tooltip; // Store the tooltip in the record for removal later
            }
          },
          onMouseLeave: () => {
            if (record.isagentdeleted === 1 && record._tooltip) {
              // Remove the tooltip when leaving the row
              document.body.removeChild(record._tooltip);
              delete record._tooltip;
            }
          },
        })}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allUsersData: state.users.allUsersData,
    allUsersLoader: state.users.allUsersLoader,
    ticketTimeSpentReportLoader: state.report.ticketTimeSpentReportLoader,
    ticketTimeSpentReportCount: state.report.ticketTimeSpentReportCount,
    ticketTimeSpentReportData: state.report.ticketTimeSpentReportData,
    exportTicketTimeSpentReportData:
      state.report.exportTicketTimeSpentReportData,
  };
};

export default connect(mapStateToProps, {
  actionGetUsersAllData,
  clearReportData,
  actionGetTicketTimeSpentReportAll,
})(TicketTimeSpentReport);
