import React, { useMemo } from "react";

import { connect } from "react-redux";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { actionGetUserProfile } from "../src/store/actions/authAction";
import "./assets/scss/index.scss";
import Dashboard from "./components/Dashboard/Dashboard";
import CreateUser from "./components/Users/CreateUser";
import UpdateUser from "./components/Users/UpdateUser";
import User from "./components/Users/User";
import UserDetail from "./components/Users/UserDetail";
import ChangePassword from "./components/auth/ChangePassword";
import EditProfile from "./components/auth/EditProfile";
import ForgotPassword from "./components/auth/ForgotPassword";
import Login from "./components/auth/Login";
import ResetPassword from "./components/auth/ResetPassword";
import MainLayout from "./components/comman/MainLayout";
import setAuthToken from "./components/config/setAuthToken";

import CreateKnowledge from "./components/Knowlegde/CreateKnowledge";
import Knowledge from "./components/Knowlegde/Knowledge";
import CreateProject from "./components/Projects/CreateProject";
import Project from "./components/Projects/Project";
import UpdateProject from "./components/Projects/UpdateProject";
import ViewProject from "./components/Projects/ViewProject";
import Ticket from "./components/tickets/Ticket";
import ViewTicket from "./components/tickets/ViewTicket";
//import Log from "./components/Log/Log";
import UpdateKnowledge from "./components/Knowlegde/UpdateKnowledge";
import ViewKnowledge from "./components/Knowlegde/ViewKnowledge";
import CreateAgent from "./components/Users/CreateAgent";
import CreateTicket from "./components/tickets/CreateTicket";
import EditResolve from "./components/tickets/EditResolve";
import EditTicket from "./components/tickets/EditTicket";
import TicketTable from "./components/tickets/TicketTable";
import Accordion from "./components/tickets/Accordion";
import Task from "./components/Task/Task";
import Report from "./components/Report/Report";
import UserProductReport from "./components/Report/UserProductReport";
import TimeSpentReport from "./components/Report/TimeSpentReport";
import AgentYearlyReport from "./components/Report/AgentYearlyReport";
import ActualHoursReport from "./components/Report/ActualHoursReport";
import BusinessReport from "./components/businessReport/BusinessReport";
import TicketAgentYearlyReport from "./components/businessReport/TicketAgentYearlyReport";
import TicketTimeSpentReport from "./components/businessReport/TicketTimeSpentReport";
import NotFound from "./components/NotFound";
import DashboardTest from "./components/Dashboard/DashboardTest";
import { ConfigProvider } from "antd";
import Tabs from "./components/Projects/Tabs";
import ProjectTabs from "./components/Projects/Tabs";

const App = (props) => {
  const { actionGetUserProfile, userProfileData, showReports } = props;

  useMemo(() => {
    if (localStorage.rushirBackendJwtToken) {
      setAuthToken(localStorage.rushirBackendJwtToken);
      actionGetUserProfile();
    }
  }, [localStorage.rushirBackendJwtToken]);

  const RequireAuth = ({ children }) => {
    let location = useLocation();

    const loggedIn =
      localStorage.rushirBackendJwtToken &&
      userProfileData &&
      (userProfileData.roles === "AGENT" ||
        userProfileData.roles === "ADMIN" ||
        userProfileData.roles === "BUSINESS USER");

    if (loggedIn) {
      if (location.pathname === "/tickets") {
        return (
          <Navigate
            to={"/ticket-view"}
            state={{ from: location }}
            replace={true}
          />
        );
      }
    }

    if (
      localStorage.rushirBackendJwtToken &&
      userProfileData &&
      userProfileData.roles === "BUSINESS USER"
    ) {
      const notAllowedPaths = [
        "/knowledge",
        "/view-knowledge/",
        "/create-knowledge/",
        "/update-knowlegde/",
        "/create-knowledge",
        "/users",
        "/edit-resolve",
        "/view-user/",
        "/create-user",
        "/create-agent",
        "/update-user/",
        "/report",
        "/user-productivity-report",
        "/time-spent-report",
        "/actual-hours-report",
        "/agent-yearly-report",

        ...(userProfileData.is_reports_visible === "YES"
          ? []
          : [
              "/ticket-report",
              "/ticket-time-spent-report",
              "/ticket-agent-yearly-report",
            ]),
      ];

      if (notAllowedPaths.some((path) => location.pathname.startsWith(path))) {
        window.location.href = "/";
      }
    }

    if (
      localStorage.rushirBackendJwtToken &&
      userProfileData &&
      userProfileData.roles === "AGENT"
    ) {
      const notAllowedPaths = [
        "/users",
        "/view-user/",
        "/create-user",
        "/create-agent",
        "/update-user/",
      ];

      if (notAllowedPaths.some((path) => location.pathname.startsWith(path))) {
        window.location.href = "/";
      }
    }

    if (!localStorage.rushirBackendJwtToken) {
      return React.createElement(Navigate, {
        to: "/login",
        state: { from: location },
        replace: true,
      });
    }

    return children;
  };

  const withLayoutAuth = (WrappedComponent) => () =>
    (
      <MainLayout>
        <WrappedComponent />
      </MainLayout>
    );

  const withLayoutAndAuth = (WrappedComponent) => () =>
    (
      <MainLayout>
        <RequireAuth>
          <WrappedComponent />
        </RequireAuth>
      </MainLayout>
    );

  const LoginAuth = withLayoutAuth(Login);
  // const DashboardWithLayout = withLayoutAndAuth(Dashboard);
  const DashboardWithLayout = withLayoutAndAuth(DashboardTest);
  const CreateUserWithLayout = withLayoutAndAuth(CreateUser);
  const UpdateUserWithLayout = withLayoutAndAuth(UpdateUser);
  const UserWithLayout = withLayoutAndAuth(User);
  const UserDetailWithLayout = withLayoutAndAuth(UserDetail);
  const ChangePasswordWithLayout = withLayoutAndAuth(ChangePassword);
  const EditProfileWithLayout = withLayoutAndAuth(EditProfile);
  const ForgotPasswordWithLayout = withLayoutAuth(ForgotPassword);
  const ResetPasswordWithLayout = withLayoutAndAuth(ResetPassword);

  const CreateKnowledgeWithLayout = withLayoutAndAuth(CreateKnowledge);
  const KnowledgeWithLayout = withLayoutAndAuth(Knowledge);
  const CreateProjectWithLayout = withLayoutAndAuth(CreateProject);
  const ProjectWithLayout = withLayoutAndAuth(Project);
  const UpdateProjectWithLayout = withLayoutAndAuth(UpdateProject);
  // const ViewProjectWithLayout = withLayoutAndAuth(ViewProject);
  const ViewProjectWithLayout = withLayoutAndAuth(ProjectTabs);
  const TicketWithLayout = withLayoutAndAuth(Ticket);
  const ViewTicketWithLayout = withLayoutAndAuth(ViewTicket);
  const UpdateKnowledgeWithLayout = withLayoutAndAuth(UpdateKnowledge);
  const ViewKnowledgeWithLayout = withLayoutAndAuth(ViewKnowledge);
  const CreateAgentWithLayout = withLayoutAndAuth(CreateAgent);
  const CreateTicketWithLayout = withLayoutAndAuth(CreateTicket);
  const EditResolveWithLayout = withLayoutAndAuth(EditResolve);
  const EditTicketWithLayout = withLayoutAndAuth(EditTicket);
  const TicketTableWithLayout = withLayoutAndAuth(TicketTable);
  const AccordionWithLayout = withLayoutAndAuth(Accordion);
  const TaskWithLayout = withLayoutAndAuth(Task);
  const ReportWithLayout = withLayoutAndAuth(Report);
  const UserProductReportWithLayout = withLayoutAndAuth(UserProductReport);
  const TimeSpentReportWithLayout = withLayoutAndAuth(TimeSpentReport);
  const AgentYearlyReportWithLayout = withLayoutAndAuth(AgentYearlyReport);
  const ActualHoursReportWithLayout = withLayoutAndAuth(ActualHoursReport);
  const BusinessReportWithLayout = withLayoutAndAuth(BusinessReport);
  const TicketAgentYearlyReportWithLayout = withLayoutAndAuth(
    TicketAgentYearlyReport
  );
  const TicketTimeSpentReportWithLayout = withLayoutAndAuth(
    TicketTimeSpentReport
  );

  return (
    <ConfigProvider
      Component={{
        Card: {
          actionsBg: "red",
        },
      }}
    >
      <BrowserRouter>
        {/* <MainLayout activeKey="dashboard"> */}
        <Routes>
          <Route path="/login" element={<LoginAuth />} />
          <Route path="/" element={<DashboardWithLayout />} />
          <Route
            path="/forgot-password"
            element={<ForgotPasswordWithLayout />}
          />
          <Route path="/reset-password" element={<ResetPasswordWithLayout />} />
          <Route
            path="/change-password/:id"
            element={<ChangePasswordWithLayout />}
          />
          <Route path="/edit-profile" element={<EditProfileWithLayout />} />
          <Route path="/projects" element={<ProjectWithLayout />} />
          <Route path="/edit-profile" element={<EditProfileWithLayout />} />
          <Route path="/projects" element={<ProjectWithLayout />} />
          <Route path="/knowledge" element={<KnowledgeWithLayout />} />

          <Route path="/create-project" element={<CreateProjectWithLayout />} />
          <Route
            path="/create-knowledge/:kbId"
            element={<CreateKnowledgeWithLayout />}
          />
          <Route
            path="/create-knowledge"
            element={<CreateKnowledgeWithLayout />}
          />
          <Route
            path="/update-knowlegde/:kbId"
            element={<UpdateKnowledgeWithLayout />}
          />
          <Route
            path="/view-knowlegde/:kbId"
            element={<ViewKnowledgeWithLayout />}
          />
          <Route
            path="/update-project/:projectId"
            element={<UpdateProjectWithLayout />}
          />
          <Route path="/tickets" element={<TicketWithLayout />} />

          <Route path="/ticket-view" element={<TicketTableWithLayout />}>
            <Route
              path=":ticketAgeing"
              element={
                <RequireAuth>
                  <TicketTableWithLayout />
                </RequireAuth>
              }
            />
          </Route>

          <Route
            path="/edit-resolve/:ticketId"
            element={<EditResolveWithLayout />}
          />
          <Route path="/create-ticket" element={<CreateTicketWithLayout />} />
          <Route
            path="/edit-ticket/:ticketId"
            element={<EditTicketWithLayout />}
          />
          <Route
            path="/view-ticket/:ticketId"
            element={
              userProfileData && userProfileData.roles === "BUSINESS USER" ? (
                <ViewTicketWithLayout />
              ) : (
                <AccordionWithLayout />
              )
            }
          />
          <Route path="/project" element={<ProjectWithLayout />} />
          <Route
            path="/view-project/:projectId"
            element={<ViewProjectWithLayout />}
          />
          <Route path="/users" element={<UserWithLayout />} />
          <Route path="/view-user/:userId" element={<UserDetailWithLayout />} />
          <Route path="/create-user" element={<CreateUserWithLayout />} />
          <Route path="/create-agent" element={<CreateAgentWithLayout />} />
          <Route
            path="/update-user/:userId"
            element={<UpdateUserWithLayout />}
          />
          <Route path="/report" element={<ReportWithLayout />} />
          <Route
            path="/user-productivity-report"
            element={<UserProductReportWithLayout />}
          />
          <Route
            path="/time-spent-report"
            element={<TimeSpentReportWithLayout />}
          />
          <Route
            path="/actual-hours-report"
            element={<ActualHoursReportWithLayout />}
          />
          <Route
            path="/agent-yearly-report"
            element={<AgentYearlyReportWithLayout />}
          />
          <Route path="/ticket-report" element={<BusinessReportWithLayout />} />
          <Route
            path="/ticket-time-spent-report"
            element={<TicketTimeSpentReportWithLayout />}
          />
          <Route
            path="/ticket-agent-yearly-report"
            element={<TicketAgentYearlyReportWithLayout />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
        {/* </MainLayout> */}
      </BrowserRouter>
    </ConfigProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    userProfileData: state.auth.userProfileData,
    showReports: state.report.showReports,
  };
};
export default connect(mapStateToProps, { actionGetUserProfile })(App);
