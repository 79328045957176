import * as XLSX from "xlsx";
import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { actionGetActualHoursReportAll, clearReportData } from '../../store/actions/reportAction';
import {
    Table,
    Typography,
    Form,
    DatePicker,
    Col,
    Row,
    Select,
    Button,
    Input,
    Breadcrumb,
    Tooltip
} from "antd";

import {
    CalendarOutlined,
    ArrowLeftOutlined,
    ExportOutlined
} from "@ant-design/icons";
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";


const ActualHoursReport = ({ actionGetActualHoursReportAll,

    actualHoursReportLoader,
    actualHoursReportCount,
    actualHoursReportData,
    exportActualHoursReportData,
    clearReportData
 }) => {
    const [form] = Form.useForm();
    const [ticketId, setTicketId] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [current, setCurrent] = useState(1)
    const { RangePicker } = DatePicker;

    const Navigate = useNavigate();

    useEffect(() => {
        actionGetActualHoursReportAll(0, 10)
    }, [])

    const columns = [
        {
            title: "Ticket No.",
            dataIndex: "ticket_number",
            key: "ticket_number",
            width: 100,
        },
        {
            title:
                (<div>
                    <CalendarOutlined className='mr-1' />DOS
                </div>),
            dataIndex: "dos",
            key: "dos",
            width: 100,
        },
        {
            title: "Ticket Title",
            dataIndex: "ticket_title",
            key: "ticket_title",
            width: 100,
        },

        {
            title: "Total Hours",
            dataIndex: "hours",
            key: "hours",
            width: 100,
        },

    ];

    const data = actualHoursReportData && actualHoursReportData.length > 0 && actualHoursReportData.map((item, index) => ({
        key: index, // Assuming you want to assign a unique key to each row
        ticket_number: item.ticket_no || '-',
         // ticket_number: (
        //     <Link to={`/view-ticket/${item.ticket_no}`} className="link">
        //       {item.ticket_no}
        //     </Link>
        //   ) || "-",
        dos: moment(item.date_of_submission).format("DD-MM-YYYY") || '-',
        ticket_title: <div style={{ width: 100, margin: 'auto' }} >
            <Tooltip title={item.ticket_title}>
                {item.ticket_title
                    ? item.ticket_title.length > 30
                        ? item.ticket_title.slice(0, 30) + "..."
                        : item.ticket_title
                    : "-"}
            </Tooltip>
        </div>,
        hours: item.hours || '-',
    }));

    const handleFinish = (values) => {
        setCurrent(1);
        actionGetActualHoursReportAll(0, 10,
            ticketId,
            startDate,
            endDate,
        )
    }

    const onClickPaginationNumber = (e) => {
        actionGetActualHoursReportAll(e.current - 1, e.pageSize,
            ticketId,
            startDate,
            endDate,
        )
        setCurrent(e.current);

    };

    const breadcrumbItems = [
        {
            key: 'back',
            title: (
                <>
                    <ArrowLeftOutlined style={{ cursor: 'pointer' }} />
                    <span style={{ cursor: 'pointer', color: 'rgba(0, 0, 0, 0.88)', fontWeight: '600', fontSize: 18 }}>Go Back</span>
                </>
            ),
            onClick: () => Navigate(-1),
        },
    ];

    useEffect(() => {
        if (exportActualHoursReportData && exportActualHoursReportData.length > 0 ) {
            const data =
            exportActualHoursReportData &&
            exportActualHoursReportData.map((data) => ({
                "Ticket No":
                    data.ticket_no ? data.ticket_no
                        : "-",
                "Ticket_title":
                    data.ticket_title ? data.ticket_title
                        : "-",
                "Submission Date": moment(data.date_of_submission).format("DD/MM/YYYY"),
                "Hours": data.hours ? data.hours : "-",
            }));
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet2");
        // Generate the Excel file and initiate the download
        XLSX.writeFile(workbook, "SAP_EffortsHours_Report.xlsx");
        clearReportData()
    }
    },[exportActualHoursReportData])

    const exportLogToExcel = () => {
        try {
            actionGetActualHoursReportAll(0, 10, ticketId, startDate, endDate, "YES");

        } catch (err) {
            console.log(err)
        }
    };


    const rangePresets = [
        {
          label: "Last 7 Days",
          value: [dayjs().add(-7, "d"), dayjs()],
        },
        {
          label: "Last 14 Days",
          value: [dayjs().add(-14, "d"), dayjs()],
        },
        {
          label: "Last 30 Days",
          value: [dayjs().add(-30, "d"), dayjs()],
        },
        {
          label: "Last 90 Days",
          value: [dayjs().add(-90, "d"), dayjs()],
        },
        {
          label: "Last Year",
          value: [dayjs().add(-1, "y"), dayjs()],
        },
        // {
        //     label: "Last Year",
        //     value: [dayjs().subtract(1, "y").startOf("year"), dayjs().subtract(1, "y").endOf("year")],
        // },
      ];

    return (
        <>
            <Breadcrumb items={breadcrumbItems} className='mb-3' />
            <Typography.Title level={3} >
            Efforts Hours Report
            </Typography.Title>
            <Form
                className='mt-8'
                form={form}
                onFinish={handleFinish}
            >

                <Row gutter={[16, 16]}>
                    <Col span={5}>
                        <Form.Item
                            name={'ticket_id'}>
                            <Input autoComplete="off" onChange={(e) => setTicketId(e.target.value)} className="mb-4 border border-true" placeholder="Enter Ticket Number" />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item
                            name={'start_date'}
                        >
                            {/* <DatePicker.RangePicker
                                onChange={(e, value) => {
                                    setStartDate(value[0]);
                                    setEndDate(value[1]);
                                }}
                                className='mb-4 w-full border border-true'
                            /> */}
                            <RangePicker
                                 onChange={(e, value) => {
                                    setStartDate(value[0]);
                                    setEndDate(value[1]);
                                }}
                                showTime={false}
                                className="w-full mb-4 border border-true"
                                presets={[...rangePresets]}
                                format="YYYY-MM-DD"

                            />

                        </Form.Item>
                    </Col>

                    {/* <Col span={5}>
                        <Form.Item
                            name={'end_date'}
                        >
                            <DatePicker
                                disabledDate={(current) => startDate && current && current < moment(startDate).endOf('day')}
                                onChange={(e, value) => {
                                    setEndDate(value)
                                }}
                                placeholder="Date To"
                                className='w-full mb-4 border border-true' />

                        </Form.Item>
                    </Col> */}
                    <Col span={5}>

                        <Form.Item>
                            <Button className='min-h-[42px] outline-none focus:shadow-none focus-visible:outline-0 focus-visible:outline-offset-0	 ' type="primary" htmlType="submit">
                                Search
                            </Button>
                        </Form.Item>

                    </Col>
                    <div className="ml-auto mr-3">
                        <Button className='min-h-[42px] outline-none focus:shadow-none focus-visible:outline-0 focus-visible:outline-offset-0 flex items-center justify-center'
                            onClick={exportLogToExcel}><ExportOutlined />Export</Button>
                    </div>
                </Row>

            </Form>



            <Table
                bordered
                // className="user-table"
                sticky={true}
                size="small"
                pagination={{ total: actualHoursReportCount, current: current, hideOnSinglePage: true }}
                columns={columns}
                dataSource={data}
                loading={actualHoursReportLoader}
                onChange={(e) => onClickPaginationNumber(e)}
                scroll={{
                    x: "max-content",
                    // y: window.innerWidth < 1500 ? "55vh" : "60vh",
                }}

            />
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        userProfileData: state.auth.userProfileData,
        actualHoursReportLoader: state.report.actualHoursReportLoader,
        actualHoursReportCount: state.report.actualHoursReportCount,
        actualHoursReportData: state.report.actualHoursReportData,
        exportActualHoursReportData: state.report.exportActualHoursReportData,
    };
};

export default connect(mapStateToProps, {
    actionGetActualHoursReportAll,
    clearReportData
})(ActualHoursReport);