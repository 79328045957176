import React from "react";
import {
  Card,
  Image,
  Typography,
  Button,
  Checkbox,
  Form,
  Input,
  Row,
  Col,
} from "antd";
import logo from "../../assets/img/comman/logo.png";
import { connect } from "react-redux";
import { actionResetPassword } from "../../store/actions/authAction";
import { useNavigate, useLocation } from "react-router-dom";
const { Title } = Typography;

const ResetPassword = (props) => {
  const Navigate = useNavigate();
  const { actionResetPassword, resetPasswordLoader } = props;

  const token = useLocation();
  const tokenSubmit = token.search.split("=")[1];
  const [form] = Form.useForm();

  const onFinish = (values) => {
    actionResetPassword(
      { password: values.password, token: tokenSubmit ? tokenSubmit : "" },
      form,
      Navigate
    );
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <Card
        className="login-card"
        style={{
          width: 450,
          marginBottom: "100px",
        }}
      >
        <div className=" text-center">
          <Image src={logo} width={80} preview={false} alt="logo" />
          <h2 className="text-2xl	pb-10 pt-3 font-bold break-words">
            Reset Password
          </h2>
        </div>
        <Form
          form={form}
          name="basic"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size="large"
        >
          <Row align="center" className="row-card" style={{ paddingBottom: 0 }}>
            <Col span="24">
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                  {
                    min: 8,
                    message:
                      "passwords must be longer than or equal to 8 characters !",
                  },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
            </Col>
            {/* <Link to='/forgot-password'>ForgotPassword </Link> */}
            <Col span={24}>
              <Form.Item
                name="cpassword"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please Confirm Your Password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm Password" />
              </Form.Item>
            </Col>

            <Col span={12} align="right" className="mt-4">
              <Form.Item>
                <Button block loading={resetPasswordLoader} htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Col>
            <Col span={24} />
            {/* <Col span={24} align="middle">
              <Form.Item>
                <Link
                  style={{ color: "#265BA6" }}
                  className="link-color"
                  to="/forgot-password"
                >
                  Forgot password?
                </Link>
              </Form.Item>
            </Col> */}
          </Row>
        </Form>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    resetPasswordLoader: state.auth.resetPasswordLoader,
  };
};

export default connect(mapStateToProps, { actionResetPassword })(ResetPassword);
