import { CheckCircleOutlined, DragOutlined } from "@ant-design/icons";
import { Column, Pie } from "@ant-design/plots";
import {
  Card,
  Col,
  DatePicker,
  Empty,
  Form,
  List,
  Row,
  Spin,
  Tag,
  Typography,
  Divider,
} from "antd";

import dayjs from "dayjs";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";

import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { Link, NavLink } from "react-router-dom";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
  sortableElement,
} from "react-sortable-hoc";
import { actionSendNotification } from "../../store/actions/notificationAction";
import {
  actionGetDashboardData,
  actionUpdateTaskStatus,
  storeClickedData,
} from "../../store/actions/ticketAction";
import { actionGetUsersAll } from "../../store/actions/userAction";
import SortableRowComponent from "./Test";

const { RangePicker } = DatePicker;

const count = 3;

const DragHandle = SortableHandle(() => (
  <DragOutlined style={{ fontSize: 20 }} className="cursor-pointer" />
));

const SortableCol = SortableElement(({ children }) => (
  <Col xxl={12} lg={12} md={12} xl={12} sm={24}>
    {children}
  </Col>
));

const SortableRow = SortableContainer(({ children }) => (
  <Row gutter={[16, 16]} style={{ marginTop: 25 }}>
    {children}
  </Row>
));

const DashboardTest = React.memo((props) => {
  const {
    actionGetDashboardData,
    storeClickedData,
    dashboardData,
    actionUpdateTaskStatus,
    dashboardLoader,
    datta,
    userProfileData,
  } = props;

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [year, setYear] = useState("");
  const [bValue, setBValue] = useState(false);

  const dashboardRef = useRef(null);
  const navigate = useNavigate();
  const [characters, setCharacters] = useState();
  // Retrieve the initial card order from localStorage or set a default order

  useEffect(() => {
    actionGetDashboardData();
  }, []);

  useMemo(() => {}, [dashboardData, datta]);

  useEffect(() => {
    // Ensure dashboardData is not null before processing it
    if (
      dashboardData &&
      dashboardData.header_part &&
      dashboardData.header_part.length > 0
    ) {
      const d = dashboardData.header_part.map((data, index) => ({
        key: index,
        name: data.name,
        value: data.value,
      }));
      setCharacters(d); // Set characters state once after processing
      // Retrieve card order from localStorage
      const ticket_order = JSON.parse(localStorage.getItem("ticket_orders"));
      if (ticket_order) {
        const sortedCharacters = ticket_order.map((key) =>
          d.find((item) => item.key === key)
        );

        setCharacters(sortedCharacters.filter(Boolean));
      }
    }
  }, [dashboardData]);

  const data = [];
  dashboardData.ticket_aging &&
    dashboardData.ticket_aging.length > 0 &&
    dashboardData.ticket_aging.map((d, i) => {
      data.push({
        key: i,
        name: d.name,
        tickets: d.value,
      });
    });

  const levelData = [];

  dashboardData.total_tickets_groupby_level &&
    dashboardData.total_tickets_groupby_level.length > 0 &&
    dashboardData.total_tickets_groupby_level.map((d, i) => {
      levelData.push({
        key: i,
        name: d.name,
        levelValue: parseInt(d.value),
      });
    });

  const handleChangeStatus = (e, id) => {
    let taskStatus = e === "In Completed" ? "Completed" : "In Completed";
    actionUpdateTaskStatus(taskStatus, id);
  };

  // Top 6 Cards in Row
  const SortableItem = sortableElement(({ index, name, value }) => (
    <Card
      className="w-[20%] mx-2 relative"
      key={index}
      align="center"
      hoverable
      bordered={false}
    >
      <Typography.Text strong className="text-md">
        {name}
      </Typography.Text>
      <br />
      <Typography.Text className="text-lg" strong>
        {value}
      </Typography.Text>
    </Card>
  ));

  const SortableContainers = SortableContainer(({ children }) => {
    return (
      <div className="flex overflow-x-auto justify-between items-center w-[99%] m-auto">
        {children}
      </div>
    );
  });

  const taskData =
    dashboardData &&
    dashboardData.agent_task &&
    dashboardData.agent_task.length > 0 &&
    dashboardData.agent_task.map((data) => data);

  const config = {
    data: data,
    color: ["#3163AB", "#E62C31", "#6395FA", "#A4CEEF"],
    isGroup: true,
    xField: "name",
    yField: "tickets",
    seriesField: "name",
    xAxis: {
      label: {
        autoRotate: false,
      },
    },
    onReady: (plot) => {
      plot.on("element:click", (...args) => {
        // storeClickedData(args[0].data.data.name, Navigate);
        const aging = encodeURIComponent(args[0].data.data.name);
        navigate(`/ticket-view/${aging}`);
      });
    },
    legend: {
      position: "top-left",
    },
    layout: [
      {
        type: "interval-adjust-position",
      },
      {
        type: "interval-hide-overlap",
      },
      {
        type: "adjust-color",
      },
      {
        interactions: [{ type: "element-active" }],
        state: {
          // 设置 active 激活状态的样式
          active: {
            animate: { duration: 100, easing: "easeLinear" },
            style: {
              lineWidth: 2,
              stroke: "#000",
            },
          },
        },
      },
    ],
    animation: false,
    maxColumnWidth: 40,
    minColumnWidth: 40,
    style: {
      cursor: "pointer",
    }, // Apply the cursor pointer style here
  };

  // get Data Second Chart
  const handleElementClick = (event, chart) => {
    console.log("Element clicked:", event.data.data.name);
  };

  const dataPie = [];
  dashboardData.total_tickets &&
    dashboardData.total_tickets.length &&
    dashboardData.total_tickets.map((d, i) => {
      dataPie.push({
        key: i,
        name: d.name,
        value: d.value,
      });
    });

  const configPie = {
    appendPadding: 10,
    data: dataPie,
    color: ["#265BA6", "#E42126"],
    angleField: "value",
    colorField: "name",
    radius: 0.8,
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    events: {
      onElementClick: (event, chart) => {
        handleElementClick(event, chart);
      },
    },
    interactions: [
      {
        type: "pie-legend-active",
      },
      {
        type: "element-active",
      },
    ],
    animation: false,
    legend: {
      position: "right",
      itemName: {
        formatter: (text, item) => {
          // Display the count of each slice in the legend
          const dataItem = dataPie && dataPie.find((d) => d.name === text);
          return `${text} (${dataItem.value})`;
        },
      },
    },
  };

  const statusData = (dashboardData.header_part || [])
    .filter((d) => !["total tickets", "raised"].includes(d.name.toLowerCase()))
    .map((d, i) => ({
      key: i,
      name: d.name.charAt(0).toUpperCase() + d.name.slice(1).toLowerCase(),
      value: d.value,
    }));

  const statusPie = {
    appendPadding: 10,
    data: statusData,
    color: ["#3163AB", "#E62C31", "#6395FA", "#A4CEEF", "#0a2351"],

    angleField: "value",
    colorField: "name",
    radius: 0.8,
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    animation: false,
    legend: {
      position: "right",
      itemName: {
        formatter: (text, item) => {
          // Display the count of each slice in the legend
          const dataItem =
            statusData && statusData.find((d) => d.name === text);
          return `${text} (${dataItem.value})`;
        },
      },
    },
    events: {
      onElementClick: (event, chart) => {
        handleElementClick(event, chart);
      },
    },
    interactions: [
      {
        type: "pie-legend-active",
      },
      {
        type: "element-active",
      },
    ],
  };

  // Get Data HoursPic
  const hoursPieData = [];
  dashboardData &&
    dashboardData.ticket_comparison &&
    dashboardData.ticket_comparison.length > 0 &&
    dashboardData.ticket_comparison.map((d, i) => {
      hoursPieData.push({
        key: i,
        name: d.name,
        value: d.value,
      });
    });

  const hoursPie = {
    appendPadding: 10,
    data: hoursPieData,
    color: ["#265BA6", "#E42126", "#9fcbee"],

    angleField: "value",
    colorField: "name",
    radius: 0.8,
    legend: {
      position: "right",
      itemName: {
        formatter: (text, item) => {
          // Display the count of each slice in the legend
          const dataItem =
            hoursPieData && hoursPieData.find((d) => d.name === text);
          return `${text} (${dataItem.value})`;
        },
      },
    },
    animation: false,
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "pie-legend-active",
      },
      {
        type: "element-active",
      },
    ],
  };

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setStartDate(dateStrings[0]);
      setEndDate(dateStrings[1]);
      actionGetDashboardData(dateStrings[0], dateStrings[1]);
      setBValue(true);
    } else {
      setStartDate("");
      setEndDate("");
      setBValue(false);
    }
    dashboardRef.current.blur();
  };

  const onYearChange = (dates, year) => {
    setYear(year);

    if (year) {
      const sDate = `${year}-01-01`;
      const eDate = `${year}-12-31`;
      setStartDate(sDate);
      setEndDate(eDate);
      actionGetDashboardData(sDate, eDate);
    } else {
      // Handle the case when year is not provided
      actionGetDashboardData();
    }
  };

  const rangePresets = [
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 14 Days",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "Last 90 Days",
      value: [dayjs().add(-90, "d"), dayjs()],
    },
    {
      label: "Last Year",
      value: [dayjs().add(-1, "y"), dayjs()],
    },
    // {
    //   label: "Last Year",
    //   value: [dayjs().subtract(1, "y").startOf("year"), dayjs().subtract(1, "y").endOf("year")],
    // },
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updatedCharacters = arrayMove(characters, oldIndex, newIndex);
    setCharacters(updatedCharacters);
    // Store the updated order in localStorage
    localStorage.setItem(
      "ticket_orders",
      JSON.stringify(updatedCharacters.map((item) => item.key))
    );
  };

  const spendHoursColor = (() => {
    const spendHours = parseFloat(
      dashboardData.project_hours && dashboardData.project_hours.spend_hours
    );
    const totalHours = parseFloat(
      dashboardData.project_hours && dashboardData.project_hours.total_hours
    );

    if (spendHours < 0.5 * totalHours) {
      return "green";
    } else if (spendHours > 0.8 * totalHours) {
      return "red";
    } else {
      return "orange";
    }
  })();

  const onClearRangePicker = (dates) => {
    if (!dates || !dates.length) {
      console.log("Clear button clicked. Performing API call.");
      setBValue(false);
      setStartDate("");
      setEndDate("");
      actionGetDashboardData();
    }
  };

  const handleChartClick = () => {
    console.log("Chart Click");
  };

  const TaskCard = ({ taskData }) => (
    <Card
      title={
        <div className="flex w-full justify-between items-center">
          <p className="text-center mx-auto">Task</p>
          <DragHandle />
        </div>
      }
      id="dash-task-card"
      style={{ height: 500, width: "100%" }}
    >
      {taskData?.length > 0 ? (
        <List
          className="webkit-dash"
          style={{ maxHeight: 400, height: "auto", overflow: "auto" }}
          itemLayout="vertical"
          size="large"
          dataSource={taskData}
          renderItem={(item) => {
            return <TaskItem item={item} />;
          }}
        />
      ) : (
        <Empty description="No tasks available" />
      )}
    </Card>
  );

  const TaskItem = ({ item }) => (
    <>
      <div className="px-3 " style={{ borderBottom: "1px solid #e9e9e9" }}>
        {item.tasks?.length > 0 && (
          <>
            <div className="flex justify-between">
              <a href={`/view-ticket/${item.ticket.ticket_id}?key=2`}>
                <p className="my-3 text-base font-medium">
                  {`Ticket  :  ${
                    item.ticket &&
                    item.ticket.title &&
                    item.ticket.title.length > 50
                      ? item.ticket?.title?.slice(0, 50) + "..."
                      : item.ticket?.title
                  }`}
                </p>
              </a>
            </div>
            <List.Item className="mbb-0">
              {item.tasks.map((d, index) => (
                <TaskCardItem key={index} d={d} />
              ))}
            </List.Item>
          </>
        )}
      </div>
    </>
  );

  const TaskCardItem = ({ d }) => (
    <Card
      className={`task-card dashboard-task`}
      style={{ marginTop: 16 }}
      loading={false}
    >
      <List.Item.Meta
        description={
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-between">
              <CheckCircleOutlined
                style={{
                  color: d.status === "In Completed" ? "grey" : "green",
                }}
                onClick={() => handleChangeStatus(d.status, d.task_id)}
                className="mr-2 text-current text-base"
              />
              <Typography.Paragraph className="mb-00" href={d.href}>
                {d.task_name?.length > 71
                  ? d.task_name.slice(0, 70) + "..."
                  : d.task_name}
              </Typography.Paragraph>
            </div>
            {d.agent.first_name && d.agent.last_name && (
              <Tag color="default">
                {d.agent.first_name} {d.agent.last_name}
              </Tag>
            )}
          </div>
        }
      />
    </Card>
  );

  const configLevel = {
    data: levelData,
    color: ["#3163AB", "#E62C31", "#6395FA", "#A4CEEF", "#0a2351"],
    isGroup: true,
    xField: "name",
    yField: "levelValue",
    seriesField: "name",
    xAxis: {
      label: {
        autoRotate: false,
      },
    },
    onReady: (plot) => {
      plot.on("element:click", (...args) => {
        // storeClickedData(args[0].data.data.name, Navigate);
        const level = encodeURIComponent(args[0].data.data.name);
        navigate(`/ticket-view?level=${level}`);
        // navigate(`/ticket-view?level=${level}`);
      });
    },
    animation: false,
    legend: {
      position: "top-left",
    },
    layout: [
      {
        type: "interval-adjust-position",
      },
      {
        type: "interval-hide-overlap",
      },
      {
        type: "adjust-color",
      },
    ],
    maxColumnWidth: 40,
    minColumnWidth: 40,
    style: {
      cursor: "pointer",
    }, // Apply the cursor pointer style here
  };

  const cardComponents = [];
  if (!(userProfileData && userProfileData.roles === "BUSINESS USER")) {
    cardComponents.push(<TaskCard taskData={taskData} />);
  }

  cardComponents.push(
    <Card
      key="ticketsAging"
      title={
        <div className="flex w-full justify-between items-center">
          <p className="text-center mx-auto">Tickets Aging</p>
          <DragHandle />
        </div>
      }
      bordered={false}
      style={{ height: 500 }}
      className="chart-container"
    >
      <Column {...config} renderer={"svg"} onPlotClick={handleChartClick} />
    </Card>,
    <Card
      key="totalVsResolved"
      title={
        <div className="flex w-full justify-between items-center">
          <p className="text-center mx-auto">
            Total Tickets V/S Resolved Tickets
          </p>
          <DragHandle />
        </div>
      }
      bordered={false}
    >
      <Pie {...configPie} />
    </Card>,
    <Card
      key="loggedVsPendingVsClosed"
      title={
        <div className="flex w-full justify-between items-center">
          <p className="text-center mx-auto">
            Logged tickets V/S Pending tickets V/S Closed tickets
          </p>
          <DragHandle />
        </div>
      }
      bordered={false}
    >
      <Pie {...hoursPie} />
    </Card>,
    <Card
      key="status"
      title={
        <div className="flex w-full justify-between items-center">
          <p className="text-center mx-auto">Status</p>
          <DragHandle />
        </div>
      }
      bordered={false}
    >
      <Pie {...statusPie} />
    </Card>,
    <Card
      title={
        <div className="flex w-full justify-between items-center">
          <p className="text-center mx-auto">Level</p>
          <DragHandle />
        </div>
      }
      bordered={false}
      style={{ height: 500 }}
      className="chart-container" // Add a class to the chart container
    >
      <Column
        {...configLevel}
        // onPlotClick={handleChartClick} // Attach the click event handler
      />
    </Card>
  );

  // State for sorting chart
  const initialCardOrder =
    JSON.parse(localStorage.getItem("cardOrder")) ||
    Array.from({ length: cardComponents.length }, (_, index) => index);
  const [cardOrder, setCardOrder] = useState(initialCardOrder);

  // Update the order of cards
  const handleChangeCardPosition = ({ oldIndex, newIndex }) => {
    const newCardOrder = [...cardOrder];
    const [removed] = newCardOrder.splice(oldIndex, 1);
    newCardOrder.splice(newIndex, 0, removed);
    setCardOrder(newCardOrder);
    localStorage.setItem("cardOrder", JSON.stringify(newCardOrder));
  };

  const gridStyle = {
    width: "20%",
    textAlign: "center",
    cursor: "default",
  };

  console.log("Called");

  return (
    <Fragment>
      <Spin spinning={dashboardLoader}>
        <Row gutter={[16, 16]} className="top-card">
          <Col span={24}>
            <Row gutter={[16, 16]} className="center-card">
              <Col span={19} className="flex items-center">
                {dashboardData && dashboardData.project_hours && (
                  /* userProfileData &&
                  userProfileData.roles !== "BUSINESS USER"  &&*/ <Card className="dashboard-card w-full">
                    <div className="rounded-0 px-3 flex-1 justify-center flex border-r border-r-[#C5CBD1] flex-col bg-white items-center ">
                      <p className="text-lg font-bold text-[#265ba6]">
                        {dashboardData.project_hours.total_hours}
                      </p>
                      <Typography.Text
                        ellipsis={true}
                        className="2xl:text-[17px]  xl:text-[16px] text-[15px] font-semibold text-[#000] whitespace-nowrap"
                      >
                        Total Hours
                      </Typography.Text>
                    </div>
                    <div className="rounded-0 px-3 flex-1 justify-center flex border-r border-r-[#C5CBD1] flex-col bg-white items-center">
                      <p
                        className="text-lg font-bold"
                        style={{
                          color: spendHoursColor,
                        }}
                      >
                        {/* {dashboardData.project_hours.effort_hours}
                         */}
                        {parseFloat(
                          dashboardData.project_hours.effort_hours
                        ).toFixed(2)}
                      </p>
                      <Typography.Text
                        ellipsis={true}
                        className="2xl:text-[17px] xl:text-[16px] text-[15px] font-semibold text-[#000] whitespace-nowrap"
                      >
                        Effort Hours
                      </Typography.Text>
                    </div>

                    <div className="rounded-0 px-3 flex-1 justify-center flex border-r border-r-[#C5CBD1] flex-col bg-white items-center">
                      <p
                        className="text-lg font-bold"
                        style={{
                          color: spendHoursColor,
                        }}
                      >
                        {parseFloat(
                          dashboardData.project_hours.remaining_hours
                        ).toFixed(2)}
                        {/* {dashboardData.project_hours.remaining_hours} */}
                      </p>
                      <Typography.Text
                        ellipsis={true}
                        className="2xl:text-[17px] xl:text-[16px] text-[15px] font-semibold whitespace-nowrap text-[#000]"
                      >
                        Remaining Hours
                      </Typography.Text>
                    </div>

                    {userProfileData && userProfileData.roles === "ADMIN" && (
                      <div className="rounded-0 px-3 flex-1 justify-center flex border-r border-r-[#C5CBD1] flex-col bg-white items-center ">
                        <p
                          className="text-lg font-bold"
                          style={{
                            color: spendHoursColor,
                          }}
                        >
                          {/* {parseFloat(dashboardData.project_hours.spend_hours).toFixed(2)} */}
                          {dashboardData.project_hours.spend_hours}
                        </p>
                        <Typography.Text
                          ellipsis={true}
                          className="2xl:text-[17px] xl:text-[16px] text-[15px] font-semibold text-[#000] whitespace-nowrap"
                        >
                          Actual Hours
                        </Typography.Text>
                      </div>
                    )}
                    {userProfileData && userProfileData.roles === "ADMIN" && (
                      <div className="rounded-0 px-3 flex-1 justify-center flex border-r border-r-[#C5CBD1] flex-col bg-white items-center ">
                        <p className="text-lg font-bold text-[#265ba6]">
                          {dashboardData.project_hours.spend_cr_hours}
                        </p>
                        <Typography.Text
                          ellipsis={true}
                          className="2xl:text-[17px] xl:text-[16px] text-[15px] font-semibold text-[#000] whitespace-nowrap"
                        >
                          Actual CR Hours
                        </Typography.Text>
                      </div>
                    )}
                    <div className="rounded-0 px-3 flex-1 justify-center flex flex-col bg-white items-center ">
                      <p className="text-lg font-bold text-[#265ba6]">
                        {dashboardData.project_hours.effort_cr_hours}
                      </p>
                      <Typography.Text
                        ellipsis={true}
                        className="2xl:text-[17px] xl:text-[16px] text-[15px] font-semibold text-[#000] whitespace-nowrap"
                      >
                        Effort CR Hours
                      </Typography.Text>
                    </div>
                  </Card>
                )}
              </Col>
              <Col span={5}>
                <div className="custom-slect flex items-end ">
                  <RangePicker
                    ref={dashboardRef}
                    onCalendarChange={onClearRangePicker}
                    showTime={false}
                    className="w-[90%] dashboard-range ml-auto"
                    presets={[...rangePresets]}
                    format="YYYY-MM-DD"
                    onChange={onRangeChange}
                    disabled={year}
                  />
                </div>
                <div className="custom-slect flex items-end">
                  <DatePicker
                    onChange={onYearChange}
                    disabled={bValue}
                    className="dashboard-range w-[90%] mt-2 ml-auto"
                    picker="year"
                  />
                </div>
              </Col>

              <Col span={24}>
                <div className="flex gap-2 items-center m-auto ">
                  {dashboardData &&
                    dashboardData.header_part &&
                    dashboardData.header_part.length > 0 &&
                    dashboardData.header_part.map((data, index) => {
                      return (
                        <NavLink
                          className="flex-1"
                          key={index}
                          to={
                            data.name === "TOTAL TICKETS"
                              ? `/ticket-view?${
                                  startDate ? "&startDate=" + startDate : ""
                                }${endDate ? "&endDate=" + endDate : ""}`
                              : data.name === "RAISED"
                              ? `/ticket-view?statusList=${"REGISTERED"}${
                                  startDate ? "&startDate=" + startDate : ""
                                }${endDate ? "&endDate=" + endDate : ""}`
                              : data.name === "IN PROCESS"
                              ? startDate && endDate
                                ? `/ticket-view?statusList=${"IN_PROCESS"}${
                                    startDate ? "&startDate=" + startDate : ""
                                  }${endDate ? "&endDate=" + endDate : ""}`
                                : `/ticket-view?statusList=${"IN_PROCESS"}`
                              : data.name === "AWAITING BUS"
                              ? startDate && endDate
                                ? `/ticket-view?statusList=${"AWAITING_BUSINESS_CONFIRMATION"}${
                                    startDate ? "&startDate=" + startDate : ""
                                  }${endDate ? "&endDate=" + endDate : ""}`
                                : `/ticket-view?statusList=${"AWAITING_BUSINESS_CONFIRMATION"}`
                              : data.name === "ON HOLD BY BUS"
                              ? startDate && endDate
                                ? `/ticket-view?statusList=${"ON_HOLD_BY_BUSINESS"}${
                                    startDate ? "&startDate=" + startDate : ""
                                  }${endDate ? "&endDate=" + endDate : ""}`
                                : `/ticket-view?statusList=${"ON_HOLD_BY_BUSINESS"}`
                              : data.name === "ON HOLD BY DEV TEAM"
                              ? startDate && endDate
                                ? `/ticket-view?statusList=${"ON_HOLD_BY_ADMIN"}${
                                    startDate ? "&startDate=" + startDate : ""
                                  }${endDate ? "&endDate=" + endDate : ""}`
                                : `/ticket-view?statusList=${"ON_HOLD_BY_ADMIN"}`
                              : startDate && endDate
                              ? `/ticket-view?statusList=${"CLOSED"}${
                                  startDate ? "&startDate=" + startDate : ""
                                }${endDate ? "&endDate=" + endDate : ""}`
                              : `/ticket-view?statusList=${"CLOSED"}`
                          }
                          style={gridStyle}
                        >
                          <Card
                            key={index}
                            align="center"
                            hoverable
                            className=""
                            bordered={false}
                          >
                            <Typography.Text
                              ellipsis={true}
                              strong
                              className="text-md truncate	w-full"
                            >
                              {data.name}
                            </Typography.Text>
                            <br />
                            <Typography.Text className="text-lg" strong>
                              {data.value}
                            </Typography.Text>
                          </Card>
                        </NavLink>
                      );
                    })}
                </div>
              </Col>
            </Row>

            <SortableRow
              axis="xy"
              helperClass="hello"
              transitionDuration={300}
              lockAxis="xy"
              onSortEnd={handleChangeCardPosition}
              useDragHandle
              lockToContainerEdges
            >
              {cardOrder.map((index, position) => (
                <SortableCol key={position} index={position}>
                  {cardComponents[index]}
                </SortableCol>
              ))}
            </SortableRow>
          </Col>
        </Row>
      </Spin>
    </Fragment>
  );
});

const mapStateToProps = (state) => {
  return {
    usersLoader: state.users.usersLoader,
    usersCount: state.users.usersCount,
    usersData: state.users.usersData,
    notificationLoader: state.notification.notificationLoader,
    notificationData: state.notification.notificationData,
    dashboardLoader: state.ticket.dashboardLoader,
    dashboardData: state.ticket.dashboardData,
    userProfileData: state.auth.userProfileData,
    datta: state.ticket.datta,
  };
};
export default connect(mapStateToProps, {
  actionGetUsersAll,
  actionGetDashboardData,
  actionGetDashboardData,
  storeClickedData,
  actionUpdateTaskStatus,
  actionSendNotification,
})(DashboardTest);
