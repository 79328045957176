import { CalendarOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Popconfirm,
  Row,
  Spin,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  actionDeleteKnowledge,
  actionGetKnowledgeDetail,
} from "../../store/actions/knowlegdeAction";
const { Title, Text } = Typography;
const ViewKnowledge = (props) => {
  const Navigate = useNavigate();
  const {
    knowledgeDetailData,
    deleteKnowledgeLoading,
    actionGetKnowledgeDetail,
    actionDeleteKnowledge,
    knowledgeDetailLoader,
  } = props;
  const { kbId } = useParams();

  useEffect(() => {
    actionGetKnowledgeDetail(kbId);
  }, []);

  const projectdata = [
    {
      title: "Title",
      description: (
        <>
          {knowledgeDetailData && knowledgeDetailData.title
            ? knowledgeDetailData.title
            : "-"}
        </>
      ),
    },

    {
      title: "Description",
      description: (
        <>
          <div
            className=""
            dangerouslySetInnerHTML={{
              __html: knowledgeDetailData.description
                ? knowledgeDetailData.description
                : "-",
            }}
          />
        </>
      ),
    },
    {
      title: "Module",
      description: (
        <>
          {knowledgeDetailData && knowledgeDetailData.areaofconcernName
            ? knowledgeDetailData.areaofconcernName
            : "-"}
        </>
      ),
    },

    {
      title: "Submission Date",
      description: (
        <Text>
          {knowledgeDetailData.created_at ? (
            <>
              <div className="flex justify-start items-center">
                <Text strong className="ml-1">
                  {dayjs(knowledgeDetailData.created_at).format(
                    "DD/MM/YYYY hh:mm:ss A"
                  )}
                </Text>
              </div>
            </>
          ) : (
            "-"
          )}{" "}
        </Text>
      ),
    },
  ];

  const confirm = (id) => {
    actionDeleteKnowledge(id, Navigate);
  };

  return (
    <>
      <Spin spinning={knowledgeDetailLoader}>
        <div className="user-detail">
          <Row gutter={16}>
            <Col xl={17} md={24} sm={24} xxl={17}>
              <Card
                className="big-card"
                title={
                  <>
                    <div className="flex items-center justify-between">
                      <div>
                        <Typography.Title level={4}>
                          <div>
                            View Knowledge Base
                            <span className="text-line-new "></span>
                          </div>
                        </Typography.Title>
                      </div>
                      <div>
                        <Link
                          to={`/update-knowlegde/${knowledgeDetailData.kb_id}`}
                        >
                          <Button
                            htmlType="button"
                            size="large"
                            style={{ marginRight: 10 }}
                          >
                            Update
                          </Button>
                        </Link>
                        <Popconfirm
                          title="Are you sure you want to delete this Knowledge Base?"
                          onConfirm={() => confirm(knowledgeDetailData.kb_id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            htmlType="button"
                            size="large"
                            loading={deleteKnowledgeLoading}
                          >
                            Delete
                          </Button>
                        </Popconfirm>
                      </div>
                    </div>
                  </>
                }
                bordered={false}
              >
                {/* <List
                  itemLayout="horizontal"
                  dataSource={data}
                  renderItem={(item, index) => (
                    <List.Item
                      style={{
                        backgroundColor: index % 2 === 0 ? "#fff" : "#F1F5F9",
                      }}
                    >
                      <List.Item.Meta
                        title={item.title}
                        description={item.description}
                      />
                    </List.Item>
                  )}
                /> */}
                <div className="user-detail">
                  <Row gutter={16}>
                    <Col span={24}>
                      <Descriptions
                        title=""
                        layout="horizontal"
                        colon={true}
                        bordered
                        column={1}
                      >
                        {projectdata &&
                          projectdata.length > 0 &&
                          projectdata.map((item, index) => (
                            <Descriptions.Item
                              key={index}
                              label={item.title}
                              style={{
                                background:
                                  index % 2 !== 0 ? "#FFFFFF" : "#E2EEFF",
                              }} // Set color dynamically or default to black
                            >
                              {item.description ? item.description : "-"}
                            </Descriptions.Item>
                          ))}
                      </Descriptions>
                    </Col>
                  </Row>
                </div>
                {/* <br /> */}
                {/* <Link to={`/update-knowlegde/${knowledgeDetailData.kb_id}`}>
                  <Button
                    htmlType="button"
                    size="large"
                    style={{ marginRight: 10 }}
                  >
                    Update
                  </Button>
                </Link>
                <Popconfirm
                  title="Are you sure you want to delete this Knowledge Base?"
                  onConfirm={() => confirm(knowledgeDetailData.kb_id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button htmlType="button" size="large" loading={deleteKnowledgeLoading}>
                    Delete
                  </Button>
                </Popconfirm> */}
              </Card>
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    knowledgeDetailLoader: state.knowledge.knowledgeDetailLoader,
    knowledgeDetailData: state.knowledge.knowledgeDetailData,
    deleteKnowledgeLoading: state.knowledge.deleteKnowledgeLoading,
  };
};

export default connect(mapStateToProps, {
  actionGetKnowledgeDetail,
  actionDeleteKnowledge,
})(ViewKnowledge);
