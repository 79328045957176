import { Card, Col, Row, Table, Typography, Image } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { actionGetTickets } from "../../store/actions/ticketAction";
import userProductivity from "../../assets/img/icon/userProductivity.png";
import agentYearly from "../../assets/img/icon/agentYearly.png";
import timeSpent from "../../assets/img/icon/timeSpent.png";
import actualHours from "../../assets/img/icon/actualHours.png";

const Report = (props) => {
  const [current, setCurrent] = useState(1);
  const [current1, setCurrent1] = useState(1);

  const {
    userProfileData,
    actionGetTickets,
    ticketsData,
    ticketsCount,
    actionGetTicketsReportAll,

    ticketsReportData,
    ticketsLoader,
  } = props;

  useEffect(() => {
    if (userProfileData.roles === "BUSINESS USER") {
      actionGetTickets(0, 10);
    }
  }, []);

  const onClickPaginationNumber = (e, t, sorter) => {
    actionGetTickets(e.current - 1, e.pageSize);
    setCurrent(e.current);
    console.log(e.current, "e.current");
  };

  const columns = [
    {
      title: "Task Name",
      dataIndex: "task_name",
      key: "task_name",
      width: 100,
    },
    {
      title: "Task Owner",
      dataIndex: "task_owner",
      key: "task_owner",
      width: 100,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
    },
    {
      title: "Estimated Hours",
      dataIndex: "estimated_hours",
      key: "estimated_hours",
      width: 100,
    },
    {
      title: "Actual Hours",
      dataIndex: "actual_hours",
      key: "actual_hours",
      width: 100,
    },
  ];
  const data = [
    {
      key: "1",
      task_name: "Task 1",
      task_owner: "User 1",
      status: "Copleted",
      estimated_hours: "234",
      actual_hours: "123",
    },
    {
      key: "2",
      task_name: "Task 2",
      task_owner: "User 2",
      status: "Copleted",
      estimated_hours: "234",
      actual_hours: "123",
    },
    {
      key: "3",
      task_name: "Task 3",
      task_owner: "User 3",
      status: "Copleted",
      estimated_hours: "234",
      actual_hours: "123",
    },
    {
      key: "4",
      task_name: "Task 4",
      task_owner: "User 4",
      status: "Copleted",
      estimated_hours: "234",
      actual_hours: "123",
    },
  ];

  const columns1 = [
    {
      title: "User",
      dataIndex: "user_name",
      key: "user_name",
      width: 100,
    },
    {
      title: "Task Completed",
      dataIndex: "task_compeleted",
      key: "task_compeleted",
      width: 100,
    },
    {
      title: "Efforts Hours",
      dataIndex: "efforts_hours",
      key: "efforts_hours",
      width: 100,
    },
    {
      title: "Actual Working Hours",
      dataIndex: "worked_hours",
      key: "worked_hours",
      width: 100,
    },
  ];
  const data1 = [];
  ticketsReportData &&
    ticketsReportData.length > 0 &&
    ticketsReportData.map((e, index) => {
      data1.push({
        key: index,
        task_compeleted: e.task_compeleted ? e.task_compeleted : "-",
        worked_hours: e.worked_hours ? e.worked_hours : "-",
        user_name: e.user.first_name + " " + e.user.last_name,
        efforts_hours: e.effort_hours ? e.effort_hours : "-",
      });
    });

  const columnsBusiness = [
    {
      title: "Ticket Number",
      dataIndex: "service_request_number",
      key: "service_request_number",
      width: 100,
    },
    {
      title: "Ticket Title",
      dataIndex: "title",
      key: "title",
      width: 100,
    },
    {
      title: "Ticket Timeline ( Hours )",
      dataIndex: "total_estimated_hours",
      key: "total_estimated_hours",
      width: 100,
    },
  ];
  const dataBusiness = [];
  ticketsData &&
    ticketsData.length > 0 &&
    ticketsData.map((e, index) => {
      dataBusiness.push({
        key: index,
        service_request_number: (
          <a href={`/view-ticket/${e.ticket_id}`} className="link">
            {e.service_request_number}
          </a>
        ),
        title: (
          <div style={{ width: 150, margin: "auto" }}>
            {" "}
            {e.title
              ? e.title.length > 50
                ? e.title.slice(0, 50) + "..."
                : e.title
              : "-"}
          </div>
        ),
        total_estimated_hours: e.total_estimated_hours
          ? e.total_estimated_hours
          : "-",
      });
    });

  return (
    <Fragment>
      {(userProfileData && userProfileData.roles === "ADMIN") ||
      (userProfileData && userProfileData.roles === "AGENT") ? (
        <>
          <Typography.Title level={3} className="ml-3">
            Reports
          </Typography.Title>

          <Row className="mt-10" gutter={[8, 32]}>
            <Col className="gutter-row" lg={12} md={12} sm={24} xxl={6}>
              <Link to="/user-productivity-report">
                <Card
                  align="center"
                  hoverable
                  className="mx-3 estimating-card cursor-pointer"
                  bordered={false}
                >
                  <Image
                    preview={false}
                    width={50}
                    height={50}
                    src={userProductivity}
                    alt="User Productivity"
                  />
                  <p className="text-[#265ba6] font-bold text-[20px] tracking-wide">
                    <span className="mr-2">User Productivity Report</span>
                  </p>
                  <Typography.Text className="text-[1.145rem] text-[#808080]">
                    User productivity report for performance insights and
                    optimisation
                  </Typography.Text>
                </Card>
              </Link>
            </Col>
            <Col className="gutter-row" lg={12} md={12} sm={24} xxl={6}>
              <Link to="/agent-yearly-report">
                <Card
                  align="center"
                  hoverable
                  className="mx-3 estimating-card cursor-pointer"
                  bordered={false}
                >
                  <Image
                    preview={false}
                    width={50}
                    height={50}
                    src={agentYearly}
                    alt="User Productivity"
                  />
                  <p className="text-[#265ba6] font-bold text-[20px] tracking-wide">
                    <span className="mr-2">
                      Monthly Agent Productivity Overview
                    </span>
                  </p>
                  <Typography.Text className="text-[1.145rem] text-[#808080]">
                    Optimize Agent Hours for Efficiency with Monthly Ticket
                    Analysis
                  </Typography.Text>
                </Card>
              </Link>
            </Col>
            <Col className="gutter-row" lg={12} md={12} sm={24} xxl={6}>
              <Link to="/time-spent-report">
                <Card
                  align="center"
                  hoverable
                  className="mx-3 estimating-card cursor-pointer"
                  bordered={false}
                >
                  <Image
                    preview={false}
                    width={50}
                    height={50}
                    src={timeSpent}
                    alt="User Productivity"
                  />
                  <p className="text-[#265ba6] font-bold text-[20px] tracking-wide">
                    <span className="mr-2">Ticket Time Tracking Report</span>
                  </p>
                  <Typography.Text className="text-[1.145rem] text-[#808080]">
                    Track time on specific tickets and assigned agents for
                    improved project management and accountability
                  </Typography.Text>
                </Card>
              </Link>
            </Col>
            <Col className="gutter-row" lg={12} md={12} sm={24} xxl={6}>
              <Link to="/actual-hours-report">
                <Card
                  align="center"
                  hoverable
                  className="mx-3 estimating-card cursor-pointer"
                  bordered={false}
                >
                  <Image
                    preview={false}
                    width={50}
                    height={50}
                    src={actualHours}
                    alt="User Productivity"
                  />
                  <p className="text-[#265ba6] font-bold text-[20px] tracking-wide">
                    <span className="mr-2">Efforts Hours Report</span>
                  </p>
                  <Typography.Text className="text-[1.145rem] text-[#808080]">
                    Gain insights into productive hours for enhanced efficiency
                    and evaluation.
                  </Typography.Text>
                </Card>
              </Link>
            </Col>
          </Row>
        </>
      ) : (
        ""
      )}

      {userProfileData && userProfileData.roles === "BUSINESS USER" ? (
        <>
          <Typography.Title level={3} className="ml-3">
            Reports
          </Typography.Title>

          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Card
                className="main-card no-padding report-card"
                title={"Solution Efforts Report"}
              >
                <Table
                  bordered
                  className="user-table"
                  sticky={true}
                  size="small"
                  pagination={{
                    total: ticketsCount,
                    current: current,
                    hideOnSinglePage: true,
                  }}
                  columns={columnsBusiness}
                  dataSource={dataBusiness}
                  loading={ticketsLoader}
                  onChange={(e) => onClickPaginationNumber(e)}
                  scroll={{
                    x: "max-content",
                    // y: window.innerWidth < 1500 ? "55vh" : "60vh",
                  }}
                />
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        ""
      )}
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    userProfileData: state.auth.userProfileData,
    ticketsData: state.ticket.ticketsData,
    ticketsCount: state.ticket.ticketsCount,
    ticketsReportData: state.report.ticketsReportData,
    ticketsReportLoader: state.report.ticketsReportLoader,
    ticketsLoader: state.ticket.ticketsLoader,
    reportCount: state.report.reportCount,
  };
};
export default connect(mapStateToProps, {
  actionGetTickets,
})(Report);
