import { Table, Breadcrumb, Button, Select, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  actionGetTicketsReportAll,
  clearReportData,
} from "../../store/actions/reportAction";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, ExportOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import { actionGetUsersAllData } from "../../store/actions/userAction";

const UserProductReport = ({
  actionGetTicketsReportAll,
  ticketsReportLoader,
  ticketsReportData,
  actionGetUsersAllData,
  allUsersLoader,
  allUsersData,
  userexportrReportData,
  reportCount,
  clearReportData,
}) => {
  const [current1, setCurrent1] = useState(1);
  const [user, setUser] = useState("");
  const [All, setAll] = useState(false);

  useEffect(() => {
    actionGetUsersAllData(0, 10);
    actionGetTicketsReportAll(0, 10);
  }, []);
  const Navigate = useNavigate();

  const columns1 = [
    {
      title: "User",
      dataIndex: "user_name",
      key: "user_name",
      width: 100,
    },
    {
      title: "Task Completed",
      dataIndex: "task_compeleted",
      key: "task_compeleted",
      width: 100,
    },
    {
      title: "Efforts Hours",
      dataIndex: "efforts_hours",
      key: "efforts_hours",
      width: 100,
    },
    {
      title: "Actual Working Hours",
      dataIndex: "worked_hours",
      key: "worked_hours",
      width: 100,
    },
  ];

  const data1 = [];
  ticketsReportData &&
    ticketsReportData.length > 0 &&
    ticketsReportData.map((e, index) => {
      data1.push({
        key: index,
        task_compeleted: e.task_compeleted ? e.task_compeleted : "-",
        worked_hours: e.worked_hours ? e.worked_hours : "-",
        user_name: e.user.first_name + " " + e.user.last_name,
        efforts_hours: e.effort_hours ? e.effort_hours : "-",
      });
    });

  const onClickPaginationNumberForAdmin = (e, t, sorter) => {
    actionGetTicketsReportAll(e.current - 1, e.pageSize, user);
    setCurrent1(e.current);
  };
  const breadcrumbItems = [
    {
      key: "back",
      title: (
        <>
          <ArrowLeftOutlined style={{ cursor: "pointer" }} />
          <span
            style={{
              cursor: "pointer",
              color: "rgba(0, 0, 0, 0.88)",
              fontWeight: "600",
              fontSize: 18,
            }}
          >
            Go Back
          </span>
        </>
      ),
      onClick: () => Navigate(-1),
    },
  ];
  const exportLogToExcel = async () => {
    try {
      await actionGetTicketsReportAll(0, 10, user, "YES");
      /*console.log({ userexportrReportData });
            const data = userexportrReportData.map((data) => ({
                "User": data && data.user ? `${data.user.first_name} ${data.user.last_name}` : "-",
                "Task Completed": data.task_compeleted || "-",
                "Effort Hours": data.effort_hours || "-",
                "Actual Working Hours": data.worked_hours || "-",
            }));

            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet2");

            // Generate the Excel file and initiate the download
            XLSX.writeFile(workbook, "SAP_UserProductivity_Report.xlsx");*/
    } catch (error) {
      console.error("Error fetching data from the API:", error);
      // Handle error appropriately, e.g., show a message to the user
    }
  };

  /* Generate Excel */
  useEffect(() => {
    if (userexportrReportData && userexportrReportData.length > 0) {
      console.log({ userexportrReportData });
      const data = userexportrReportData.map((data) => ({
        User:
          data && data.user
            ? `${data.user.first_name} ${data.user.last_name}`
            : "-",
        "Task Completed": data.task_compeleted || "-",
        "Effort Hours": data.effort_hours || "-",
        "Actual Working Hours": data.worked_hours || "-",
      }));

      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet2");

      // Generate the Excel file and initiate the download
      XLSX.writeFile(workbook, "SAP_UserProductivity_Report.xlsx");
      clearReportData();
    }
  }, [userexportrReportData]);
  /* Generate Excel Ends */

  const onChange = (val) => {
    setUser(val);
    setCurrent1(1);
    actionGetTicketsReportAll(0, 10, val);
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const agentData = [];
  allUsersData?.map((data, index) => {
    if (data.roles === "AGENT") {
      agentData.push({
        key: index,
        label: data.first_name + " " + data.last_name,
        value: data.first_name + " " + data.last_name,
        // value: data.id,
      });
    }
  });

  const onSearch = (value) => {
    console.log("search:", value);
  };

  return (
    <>
      <Breadcrumb items={breadcrumbItems} className="mb-3" />

      <div className="flex">
        <p level={3} className=" text-[24px] font-semibold">
          User Productivity Report
        </p>
      </div>
      <div className="grid grid-cols-2 items-center">
        <div>
          <Row gutter={[16, 16]}>
            <Col span={10}>
              <Select
                onClear={() => setUser("")}
                style={{ padding: "6px 0" }}
                className="width rounded py-[7px] min-h-[42px] border border-true w-full mt-4"
                allowClear
                placeholder="Select Agent"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={filterOption}
                options={agentData}
                loading={allUsersLoader}
                disabled={allUsersLoader}
              />
            </Col>
          </Row>
        </div>
        <div className="ml-auto mr-3">
          <Button
            className="min-h-[42px] outline-none focus:shadow-none focus-visible:outline-0 focus-visible:outline-offset-0 flex items-center justify-center"
            onClick={exportLogToExcel}
          >
            <ExportOutlined />
            Export
          </Button>
        </div>
      </div>

      <br />
      <Table
        bordered
        className="user-table"
        sticky={true}
        size="small"
        pagination={{ total: reportCount, hideOnSinglePage: true }}
        columns={columns1}
        dataSource={data1}
        loading={ticketsReportLoader}
        onChange={(e) => onClickPaginationNumberForAdmin(e)}
        scroll={{
          x: "max-content",
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userProfileData: state.auth.userProfileData,
    ticketsReportData: state.report.ticketsReportData,
    ticketsReportLoader: state.report.ticketsReportLoader,
    userexportrReportData: state.report.userexportrReportData,
    reportCount: state.report.reportCount,
    allUsersData: state.users.allUsersData,
    allUsersLoader: state.users.allUsersLoader,
  };
};

export default connect(mapStateToProps, {
  actionGetTicketsReportAll,
  actionGetUsersAllData,
  clearReportData,
})(UserProductReport);
