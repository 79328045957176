import {
  CheckCircleOutlined,
  CheckOutlined,
  DeleteOutlined,
  FieldTimeOutlined,
  PlusOutlined,
  UserOutlined,
  MenuOutlined,
  HolderOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Popover,
  Row,
  Select,
  Spin,
  Typography,
  Avatar,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  actionCreateTask,
  actionGetAllTask,
  actionUpdateTaskName,
  actionUpdateTaskTime,
  actionUpdateTaskUser,
  deleteTask,
  actionUpdateTaskStatus,
} from "../../store/actions/ticketAction";
import { actionGetUsersAllData } from "../../store/actions/userAction";
import { SortableHandle } from "react-sortable-hoc";

const { Paragraph } = Typography;

const Task1 = (props) => {
  const {
    actionGetAllTask,
    actionUpdateTaskUser,
    actionUpdateTaskTime,
    taskCount,
    actionGetUsersAllData,
    allUsersData,
    getTaskData,
    deleteTask,
    actionCreateTask,
    actionUpdateTaskName,
    createTaskLoading,
    getTaskLoader,
    taskUserData,
    deletetaskLoading,
    actionUpdateTaskStatus,
  } = props;
  const [TaskValue, setTaskValue] = useState("");
  const [activeCardIndex, setActiveCardIndex] = useState(-1);
  const { ticketId } = useParams();
  const [popoverVisible, setPopoverVisible] = useState([]);
  const [timePopoverVisible, setTimePopoverVisible] = useState([]);
  const [open, setOpen] = useState(false);
  const [editableStr, setEditableStr] = useState("This is an editable text.");
  const [opened, setOpened] = useState(false);
  const [estimatedHours, setEstimatedHours] = useState([]);

  // Initialize selected values state for each card
  const [selectedValues, setSelectedValues] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [form] = Form.useForm();
  const [taskStatus, setTaskStatus] = useState("In Completed");
  const [isEditable, setIsEditable] = useState(false);
  // useEffect(() => {
  //   actionGetUsersAllData(0, 10);
  // }, []);

  // const cardStyle = {
  //   marginTop:'16px',
  //   paddingTop: isEditable ? "0px !important" : "", // Set padding to 0px when editable is active
  //   paddingBottom: isEditable ? "0px !important" : "",

  // };
  useEffect(() => {
    actionGetAllTask(ticketId);
    actionGetUsersAllData(0, 10);
  }, []);

  useMemo(() => {}, [getTaskData]);

  const handleAddTask = (e) => {
    e.preventDefault();
    const request = {
      ticket: parseInt(ticketId),
      task_name: TaskValue,
    };
    actionCreateTask(request, form, setTaskValue);
  };

  const agentData = [];
  allUsersData?.map((data, index) => {
    if (data.roles === "AGENT") {
      agentData.push({
        key: index,
        // name: data.first_name + " " + data.last_name,
        // email: data.email,
        label: data.first_name + " " + data.last_name,
        value: data.id,
        // value: data.id,
      });
    }
  });

  const handleChange = (e) => {
    setTaskValue(e.target.value);
  };

  const onFinish = (values) => {
    const request = {
      ...values,
      ticket: parseInt(ticketId),
    };
    actionCreateTask(request, form, setTaskValue);
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Form submission failed:", errorInfo);
  };

  const handleUserIconClick = (index) => {
    const updatedPopoverVisibility = [...popoverVisible];
    updatedPopoverVisibility[index] = !updatedPopoverVisibility[index];
    setPopoverVisible(updatedPopoverVisibility);
  };

  const handleTImeShow = (index) => {
    const updatedTimePopoverVisibility = timePopoverVisible.map(
      (value, i) => i === index
    );
    setTimePopoverVisible(updatedTimePopoverVisibility);
  };

  const handleDropdownChange = (value, index, taskId) => {
    const selectedAgent = agentData.find((data) => data.value === value);

    // Update the selected value for the specific card
    const updatedSelectedValues = [...selectedValues];

    const cValue = selectedAgent && selectedAgent.label;
    const firstLetter =
      cValue &&
      cValue
        .split(" ")
        .map((word) => word.charAt(0))
        .join("");

    updatedSelectedValues[index] = firstLetter;
    setSelectedValues(updatedSelectedValues);
    actionUpdateTaskUser(value, taskId, setSelectedValues);

    // Close the popover for the clicked card
    const updatedPopoverVisibility = [...popoverVisible];
    updatedPopoverVisibility[index] = false;
    setPopoverVisible(updatedPopoverVisibility);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const confirm = (id, index) => {
    deleteTask(id);
    // Clear the selected value for the specific task
    const updatedSelectedValues = [...selectedValues];
    updatedSelectedValues[index] = "";
    setSelectedValues(updatedSelectedValues);

    // Close the popover for the clicked card
    const updatedPopoverVisibility = [...popoverVisible];
    updatedPopoverVisibility[index] = false;
    setPopoverVisible(updatedPopoverVisibility);
  };

  const handleEstimatedHoursSubmit = (value, index, taskId) => {
    // Update the estimated hours state for the specific task
    const updatedEstimatedHours = [...estimatedHours];
    updatedEstimatedHours[index] = value;
    setEstimatedHours(updatedEstimatedHours);

    // Close the popover for the clicked card
    const updatedTimePopoverVisibility = [...timePopoverVisible];
    updatedTimePopoverVisibility[index] = false;
    setTimePopoverVisible(updatedTimePopoverVisibility);

    // Send the estimated hours to the API or perform any necessary actions
    actionUpdateTaskTime(value.toString(), taskId, setEstimatedHours);
  };

  const handleChangeName = (e, id) => {
    setEditableStr(e);
    actionUpdateTaskName(e, id);
  };

  const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
  ));

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return; // Dragged outside of the droppable area
    }

    const reorderedTasks = Array.from(taskData);
    const [movedTask] = reorderedTasks.splice(result.source.index, 1); // Remove the dragged item
    reorderedTasks.splice(result.destination.index, 0, movedTask); // Insert it at the new position

    setTaskData(reorderedTasks); // Update the state to reflect the new order
  };

  const handleChangeTaskStatus = (e, id) => {
    // setTaskStatus('complete');
    let taskStatus = e === "In Completed" ? "Completed" : "In Completed";
    actionUpdateTaskStatus(taskStatus, id);
  };

  useEffect(() => {
    // Initialize taskData with your existing task data
    setTaskData(getTaskData);
  }, [getTaskData]);

  const onSortEnd = () => {
    console.log("Object");
  };

  return (
    <>
      <Spin spinning={getTaskLoader}>
        <Row className="my-6" align="middle">
          <Col xxl={18} xl={18} lg={24} md={24} sm={24} xs={24}>
            {getTaskData?.map((task, index) => (
              <Card
                className={`task-card`}
                style={{
                  marginTop: 16,
                }}
                loading={false}
                key={index}
              >
                <Row
                  align="middle"
                  // style={cardStyle}
                >
                  <Col span={1} className="-mr-5">
                    <HolderOutlined style={{ cursor: "all-scroll" }} />
                  </Col>
                  <Col span={1} className="color-greeen-parent">
                    <CheckCircleOutlined
                      style={{
                        color: task.status == "In Completed" ? "grey" : "green",
                      }}
                      className=" color-greeen text-current text-base"
                      onClick={(e) => {
                        handleChangeTaskStatus(task.status, task.task_id);
                      }}
                    />
                  </Col>
                  <Col
                    span={17}
                    style={{ marginLeft: 0 }}
                    className="margin-type"
                    align=""
                    // onClick= {() => setIsEditable(true)}
                  >
                    <Paragraph
                      bordered={false}
                      className="mb-0 bg-transparent"
                      editable={{
                        onChange: (e) => handleChangeName(task, task.task_id),
                        enterIcon: <CheckOutlined className="color-grey" />,
                        triggerType: "text",
                      }}
                    >
                      {task.task_name}
                    </Paragraph>
                  </Col>

                  <Col span={5} align="right">
                    <div className="flex justify-end items-center">
                      <div className="mx-2">
                        <Popover
                          placement="bottom"
                          open={timePopoverVisible[index]}
                          content={
                            <span className="flex">
                              <InputNumber
                                style={{ width: "180px" }}
                                normalize={(value) =>
                                  `${value.replace(/[^0-9]/g, "")}`
                                }
                                min={0}
                                type="number"
                                prefix={<ClockCircleOutlined />}
                                suffix={"hrs"}
                                value={estimatedHours[index] || ""} // Set the value from the state
                                onChange={(value) => {
                                  const updatedEstimatedHours = [
                                    ...estimatedHours,
                                  ];
                                  updatedEstimatedHours[index] = value;
                                  setEstimatedHours(updatedEstimatedHours);
                                }}
                                onPressEnter={() =>
                                  handleEstimatedHoursSubmit(
                                    estimatedHours[index],
                                    index,
                                    task.task_id
                                  )
                                }
                              />
                            </span>
                          }
                          trigger="click"
                        >
                          {task && task.estimated_time ? (
                            <Typography.Text
                              code
                              onClick={() => handleTImeShow(index)}
                            >
                              {`${task.agent && task.estimated_time} hrs`}
                            </Typography.Text>
                          ) : (
                            <div className="w-[30px] text-center">
                              <FieldTimeOutlined
                                className="cursor-pointer text-current text-base"
                                onClick={() => handleTImeShow(index)}
                              />
                            </div>
                          )}
                        </Popover>
                      </div>
                      <div className=",mx-2 overflow-ellipsis bg-slate-300 ">
                        <Popover
                          content={
                            <Select
                              optionFilterProp="children"
                              showSearch
                              allowClear
                              filterOption={filterOption}
                              placeholder="Select Agent"
                              value={selectedValues[index]} // Use the selected value for this card
                              onChange={(value) =>
                                handleDropdownChange(value, index, task.task_id)
                              }
                              style={{ width: "100%", minWidth: 200 }}
                              options={agentData}
                            />
                          }
                          trigger="click"
                          open={popoverVisible[index]} // Use the visibility state for this card
                          onOpenChange={(visible) =>
                            setPopoverVisible((prevState) => {
                              const updatedVisibility = [...prevState];
                              updatedVisibility[index] = visible;
                              return updatedVisibility;
                            })
                          }
                        >
                          {task.agent &&
                          task.agent.first_name &&
                          task.agent.last_name ? (
                            // <div
                            //   className="flex h-[30px] w-[30px] p-[5px] items-center justify-center rounded-full uppercase"
                            //   style={{ background: "rgb(234 179 8)" }}
                            // >
                            //   {`${task.agent &&
                            //     task.agent.first_name &&
                            //     task.agent.first_name[0]
                            //     }${task.agent &&
                            //     task.agent.last_name &&
                            //     task.agent.last_name[0]
                            //     }`}
                            // </div>
                            <Avatar
                              style={{
                                backgroundColor: "rgb(234 179 8)",
                                color: "#000",
                                textTransform: "uppercase",
                              }}
                            >
                              {`${
                                task.agent &&
                                task.agent.first_name &&
                                task.agent.first_name[0]
                              }${
                                task.agent &&
                                task.agent.last_name &&
                                task.agent.last_name[0]
                              }`}
                            </Avatar>
                          ) : (
                            <div className="w-[30px] text-center">
                              <UserOutlined
                                className="cursor-pointer text-current text-base"
                                onClick={() => handleUserIconClick(index)} // Pass the card index
                              />
                            </div>
                          )}
                        </Popover>
                      </div>
                      <div className="mx-2">
                        <Popconfirm
                          title="Delete the task"
                          description="Are you sure to delete this task?"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => confirm(task.task_id)}
                          okButtonProps={{
                            loading: deletetaskLoading,
                          }}
                        >
                          <DeleteOutlined
                            style={{ color: "red" }}
                            className="cursor-pointer text-current text-base"
                          />
                        </Popconfirm>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            ))}

            {/* <SortableTaskList tasks={taskData} onSortEnd={onSortEnd} /> */}

            <Card
              className={`task-card`}
              style={{
                marginTop: 16,
              }}
              loading={false}
            >
              <Row align="middle">
                <Col span={1} className="color-greeen-parent">
                  <CheckCircleOutlined
                    style={{ color: "grey" }}
                    className="color-greeen text-current text-base"
                  />
                </Col>
                <Col span={18} style={{ marginLeft: 0 }} align="middle">
                  <Form
                    form={form}
                    className="ant-form-item1"
                    name="taskForm"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >
                    <Form.Item
                      name="task_name"
                      rules={[
                        {
                          required: false,
                          message: "Please input your Task Name!",
                        },
                      ]}
                    >
                      <Input
                        onChange={handleChange}
                        bordered={false}
                        // Remove the autofocus attribute
                        placeholder="Enter Task Name"
                        autoFocus
                        className="bg-transparent task-input"
                      />
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Card>

            <Button
              type="button" // Ensure the type is "button" and not "submit"
              form
              onClick={handleAddTask}
              disabled={!TaskValue}
              className="bg-transparent my-3 task-button"
              icon={<PlusOutlined />}
              loading={createTaskLoading}
            >
              Add Task
            </Button>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getTaskData: state.ticket.getTaskData,
    getTaskLoader: state.ticket.getTaskLoader,
    taskCount: state.ticket.taskCount,
    allUsersData: state.users.allUsersData,
    createTaskLoading: state.ticket.createTaskLoading,
    deletetaskLoading: state.ticket.deletetaskLoading,
    taskUserData: state.ticket.taskUserData,
    updateTasksStatusData: state.ticket.updateTasksStatusData,
    updateTasksStatusLoader: state.ticket.updateTasksStatusLoader,
  };
};

export default connect(mapStateToProps, {
  actionGetAllTask,
  actionGetUsersAllData,
  actionCreateTask,
  actionUpdateTaskUser,
  deleteTask,
  actionUpdateTaskName,
  actionUpdateTaskTime,
  actionUpdateTaskStatus,
})(Task1);
