import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

// Define the higher-order component for sorting
const SortableCard = SortableElement(({ children }) => <div>{children}</div>);
const SortableRow = SortableContainer(({ children }) => <div>{children}</div>);

const SortableRowComponent = ({ onSortEnd, children }) => (
  <SortableRow axis="xy" onSortEnd={onSortEnd}>
    {React.Children.map(children, (child, index) => (
      <SortableCard key={index} index={index}>
        {child}
      </SortableCard>
    ))}
  </SortableRow>
);

export default SortableRowComponent;
