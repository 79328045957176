import * as actionTypes from "../action";

const initialState = {
  projectsLoader: false,
  projectsData: [],
  addProjectsLoader: false,
  addProjectsData: [],
  deleteProjectLoading: false,
  projectUpdateLoader: false,
  projectDetailLoader: false,
  projectTotalHoursUpdateLoader: false,
  projectDetailData: {},
  projectsCount: {},
  projectHRSlogLoader: false,
  projectHRSlogData: {},
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PROJECTS_LOADING:
      return { ...state, projectsLoader: action.payload };

    case actionTypes.GET_PROJECTS_DATA:
      return { ...state, projectsData: action.payload };

    case actionTypes.TOTAL_PROJECTS_COUNT:
      return { ...state, projectsCount: action.payload };

    case actionTypes.ADD_PROJECT_LOADING:
      return { ...state, addProjectsLoader: action.payload };

    case actionTypes.ADD_PROJECT_DATA:
      return { ...state, addProjectsData: action.payload };

    case actionTypes.SEARCH_ANNOUNCE_DRAW_WINNER_DATA:
      return {
        ...state,
        announceWinnerDrawDetailData: action.payload,
      };

    case actionTypes.DELETE_PROJECT_LOADING:
      return { ...state, deleteProjectLoading: action.payload };

    case actionTypes.DELETE_PROJECT_ID:
      return {
        ...state,
        projectsData: [
          ...state.projectsData.filter(
            (data) => data.project_id !== action.payload
          ),
        ],
      };

    case actionTypes.UPDATE_PROJECT_LOADING:
      return { ...state, projectUpdateLoader: action.payload };

    case actionTypes.GET_PROJECT_DETAIL_LOADING:
      return { ...state, projectDetailLoader: action.payload };

    case actionTypes.GET_PROJECT_DETAIL_DATA:
      return {
        ...state,
        projectDetailData: action.payload,
      };

    case actionTypes.UPDATE_PROJECT_TOTAL_HOURS_LOADING:
      return { ...state, projectTotalHoursUpdateLoader: action.payload };

    case actionTypes.UPDATE_PROJECT_TOTAL_HOURS_DATA:
      return {
        ...state,
        projectsData:
          state.projectsData &&
          state.projectsData.map((data) =>
            data.project_id == action.payload.project_id
              ? {
                  ...data,
                  hours: +data.hours + +action.payload.hours,
                }
              : data
          ),
      };

      case actionTypes.GET_PROJECT_HRS_LOG_LOADING:
        return { ...state, projectHRSlogLoader: action.payload };
  
      case actionTypes.GET_PROJECT_HRS_LOG_DATA:
        return {
          ...state,
          projectHRSlogData: action.payload,
        };

    default:
      return state;
  }
};

export default projectReducer;
