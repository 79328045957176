import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionAddAreaOfConcern,
  actionGetAreaOfConcern,
} from "../../store/actions/ticketAction";
import { actionAddUser } from "../../store/actions/userAction";

const { Text } = Typography;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const CreateAgent = (props) => {
  const {
    addUserLoading,
    actionAddUser,
    actionGetAreaOfConcern,
    actionAddAreaOfConcern,
    areaConcernData,
    areaConcernLoader,
    addAreaConcernLoader,
  } = props;

  const [disable, setDisable] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [areaConcernValue, setAreaConcernValue] = useState("");
  const [roles, setRoles] = useState();
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const inputRef = useRef(null);
  const Navigate = useNavigate();

  const [form] = Form.useForm();

  useEffect(() => {
    actionGetAreaOfConcern();
  }, []);

  const handleChange = (value, KeyValuePair) => {
    setSelectedOption(KeyValuePair);
    setAreaConcernValue(KeyValuePair);
  };

  useEffect(() => {
    const areaConcern = areaConcernData.map((data, index) => ({
      key: index,
      label: data.name,
      value: data.id,
    }));
    setData(areaConcern);
  }, [areaConcernData]);

  const onNameChange = (event, index) => {
    setName(event.target.value);
  };
  const addItem = () => {
    actionAddAreaOfConcern({ name: name });
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const onFinish = (values) => {
    const AgentSingleObject = [
      {
        name: selectedOption && selectedOption.label,
        area_of_concern_id: selectedOption && selectedOption.value,
      },
    ];
    const updatedValues = {
      ...values,
      roles: "AGENT",
      area_of_concern: AgentSingleObject,
    };
    actionAddUser(updatedValues, Navigate);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Fragment>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Card
            className="main-card "
            title={
              <>
                <Row align="middle" justify="">
                  <Col span={2}>
                    <h2 className="text-white text-base">Create Agent</h2>
                  </Col>
                </Row>
              </>
            }
            style={{
              width: "100%",
              minHeight: "60vh",
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form
                  autoComplete="off"
                  requiredMark={true}
                  form={form}
                  layout="vertical"
                  size="large"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Row gutter={[32, 0]}>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label="First Name"
                        name="first_name"
                        normalize={(value) =>
                          value.replace(/[^a-zA-Z]/g, "").trim()
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please input your first name!",
                          },
                          {
                            validator: (_, value) =>
                              value && value.length <= 15
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "Please enter maximum 15 characters for first Name"
                                    )
                                  ),
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="first name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Last Name"
                        colon={false}
                        name="last_name"
                        normalize={(value) =>
                          value.replace(/[^a-zA-Z]/g, "").trim()
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please enter last name!",
                          },
                          {
                            validator: (_, value) =>
                              value && value.length <= 15
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "Please enter maximum 15 characters for last Name"
                                    )
                                  ),
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="last name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Email"
                        normalize={(value) => value.trim()}
                        colon={false}
                        name="email"
                        rules={[
                          {
                            type: "email",
                            required: true,
                            // message: "Please enter email!",
                            message: "The input is not valid E-mail!",
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Email"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        label="Mobile"
                        normalize={(value) => value.trim()}
                        colon={false}
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: "Please enter mobile!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (value && value.length == 10) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("Invalid Mobile Number!")
                              );
                            },
                          }),
                        ]}
                      >
                        <Input
                          maxLength={2}
                          autoFocus="none"
                          type={"number"}
                          placeholder="Mobile"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        normalize={(value) => value.trim()}
                        label="Password"
                        colon={false}
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please enter password!",
                          },
                          {
                            validator: (_, value) =>
                              value && value.length >= 8
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "Please enter minimum 8 characters for Password"
                                    )
                                  ),
                          },
                        ]}
                      >
                        <Input.Password placeholder="Password" />
                      </Form.Item>
                    </Col>
                    {/* <Col span={12}>
                      <Form.Item
                        label="Roles"
                        colon={false}
                        name="roles"
                        rules={[
                          {
                            required: true,
                            message: "Please select Roles.!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          allowClear
                          onChange={handleRoleChange}
                          placeholder="Select Role"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={[
                            {
                              value: "ADMIN",
                              label: "Admin",
                            },
                            // {
                            //   value: "AGENT",
                            //   label: "Agent",
                            // },
                            {
                              value: "USER",
                              label: "User",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col> */}
                    {/* <Col span={12}>
                      <Form.Item
                        label="Area Of Concern"
                        colon={false}
                        name="area_of_concern"
                        rules={[
                          {
                            required: false,
                            message: "Please select Area Of Concern!",
                          },
                        ]}
                      >
                        {roles === "USER" ? (
                          <Select
                            mode="multiple"
                            showArrow
                            loading={areaConcernLoader}
                            value={
                              areaConcernValue ? areaConcernValue : undefined
                            }
                            disabled={disable ? true : false}
                            onChange={handleChange}
                            style={{
                              width: "100%",
                            }}
                            options={areaConcern}
                          />
                        ) : (
                          <Select
                            showArrow
                            loading={areaConcernLoader}
                            value={
                              areaConcernValue ? areaConcernValue : undefined
                            }
                            disabled={disable ? true : false}
                            onChange={handleChange}
                            style={{
                              width: "100%",
                            }}
                            options={areaConcern}
                          />
                        )}

                        <Text type="secondary">
                          Please Select Role as User Either Agent
                        </Text>
                      </Form.Item>
                    </Col> */}
                    <Col span={12}>
                      <Form.Item
                        label="Area Of Concern"
                        colon={false}
                        name="area_of_concern"
                        rules={[
                          {
                            required: true,
                            message: "Please select Area Of Concern!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Area of Concern"
                          allowClear
                          showArrow
                          dropdownRender={(menu) => (
                            <>
                              {menu}
                              <Divider
                                style={{
                                  margin: "8px 0",
                                }}
                              />
                              <Space
                                style={{
                                  padding: "0 8px 4px",
                                }}
                              >
                                <Input
                                  placeholder="Please enter item"
                                  ref={inputRef}
                                  value={name}
                                  onChange={onNameChange}
                                />
                                <Button
                                  type="text"
                                  icon={<PlusOutlined />}
                                  className="not-white flex  items-center"
                                  disabled={!name}
                                  onClick={addItem}
                                >
                                  Add item
                                </Button>
                              </Space>
                            </>
                          )}
                          loading={areaConcernLoader}
                          value={
                            areaConcernValue ? areaConcernValue : undefined
                          }
                          // disabled={disable ? true : false}
                          onChange={handleChange}
                          style={{
                            width: "100%",
                          }}
                          options={data}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Agent Type"
                        colon={false}
                        name="agent_type"
                        rules={[
                          {
                            required: true,
                            message: "Please select agent type!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          allowClear
                          // onChange={handleTypeChange}
                          placeholder="Select Agent Type"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={[
                            {
                              value: "FUNCTIONAL",
                              label: "Functional",
                            },
                            {
                              value: "TECHNICAL",
                              label: "Technical",
                            },
                            {
                              value: "CSR",
                              label: "CSR",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item normalize={(value) => value.trim()}>
                        <Button
                          loading={addUserLoading}
                          align="center"
                          htmlType="submit"
                          className="primary"
                          size="large"
                          style={{ width: 200, marginTop: "30px" }}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    addUserLoading: state.users.addUserLoading,
    addUserData: state.users.addUserData,
    areaConcernLoader: state.ticket.areaConcernLoader,
    areaConcernData: state.ticket.areaConcernData,
    addAreaConcernLoader: state.ticket.addAreaConcernLoader,
    addAreaConcernData: state.ticket.addAreaConcernData,
  };
};

export default connect(mapStateToProps, {
  actionAddUser,
  actionGetAreaOfConcern,
  actionAddAreaOfConcern,
})(CreateAgent);
