import axios from "axios";
import { BASE_URL } from "../../components/config/web-config";
import * as actionTypes from "../action";
import { message } from "antd";

export const actionGetUsersAll =
  (offset, limit, keyword, roleList, nationality) =>
  async (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_USERS_LOADING, payload: true });
    try {
      let result = await axios.get(
        `${BASE_URL}/auth/all-user?limit=${limit}&offset=${offset}${
          keyword ? "&keyword=" + keyword : ""
        }${roleList ? "&roleList=" + roleList : ""}`
      );
      dispatch({ type: actionTypes.GET_USERS_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.GET_USERS_DATA,
          payload: result.data.data,
        });
        dispatch({
          type: actionTypes.TOTAL_USERS_COUNT,
          payload: result.data.total_records,
        });
      } else if (parseInt(result.data.status) === 404) {
        dispatch({
          type: actionTypes.GET_USERS_DATA,
          payload: [],
        });
        dispatch({
          type: actionTypes.TOTAL_USERS_COUNT,
          payload: 0,
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.GET_USERS_LOADING, payload: false });
    }
  };

export const actionGetUsersAllData =
  (offset, limit) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_ALL_USERS_LOADING, payload: true });
    try {
      let result = await axios.get(
        `${BASE_URL}/auth/all-user?limit=${limit}&offset=${offset}&getall=${"YES"}`
      );
      dispatch({ type: actionTypes.GET_ALL_USERS_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.GET_ALL_USERS_DATA,
          payload: result.data.data,
        });
        dispatch({
          type: actionTypes.TOTAL_USERS_COUNT,
          payload: result.data.total_records,
        });
      } else if (parseInt(result.data.status) === 404) {
        dispatch({
          type: actionTypes.GET_ALL_USERS_DATA,
          payload: [],
        });
        dispatch({
          type: actionTypes.TOTAL_USERS_COUNT,
          payload: 0,
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.GET_ALL_USERS_LOADING, payload: false });
    }
  };

export const actionAddUser =
  (userData, navigate) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.ADD_USER_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/auth/create-user`, userData);
      dispatch({ type: actionTypes.ADD_USER_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.ADD_USER_DATA,
          payload: result.data.data,
        });
        message.success("User Added Successfully", 5);
        navigate && setTimeout(() => navigate(`/users`), 1000);
      } else {
        message.error(result.data.message, 5);
      }
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.ADD_USER_LOADING, payload: false });
    }
  };

export const actionDeleteUser =
  (id, navigate) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.DELETE_USER_LOADING,
      payload: true,
    });
    try {
      let result = await axios.delete(`${BASE_URL}/auth/${id}`);
      dispatch({
        type: actionTypes.DELETE_USER_LOADING,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        dispatch({ type: actionTypes.DELETE_USER_ID, payload: id });
        message.success(result.data.message, 5);

        navigate && setTimeout(() => navigate(`/users`), 1000);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({
        type: actionTypes.DELETE_USER_LOADING,
        payload: false,
      });
    }
  };

export const actionUpdateUser =
  (userData, navigate, id) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_USER_LOADING, payload: true });
    try {
      let result = await axios.put(`${BASE_URL}/auth/update/user/${id}`, {
        ...userData,
      });
      dispatch({ type: actionTypes.UPDATE_USER_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        message.success(result.data.message, 5);
        navigate && setTimeout(() => navigate(`/users`), 1000);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.UPDATE_USER_LOADING, payload: false });
    }
  };

export const actionGetUserDetail = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_USER_DETAIL_LOADING, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/auth/findOne/${id}`);
    dispatch({ type: actionTypes.GET_USER_DETAIL_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_USER_DETAIL_DATA,
        payload: result.data.data,
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    dispatch({ type: actionTypes.GET_USER_DETAIL_LOADING, payload: false });
  }
};

export const hideReports = (id, is_reports_visible) => async (dispatch) => {
  dispatch({
    type: actionTypes.HIDE_REPORTS_LOADER,
    payload: { isLoading: true, id },
  });
  try {
    let result = await axios.patch(
      `${BASE_URL}/auth/reports/visibility/${id}`,
      { is_reports_visible: is_reports_visible }
    );
    dispatch({
      type: actionTypes.HIDE_REPORTS_LOADER,
      payload: { isLoading: false, id },
    });

    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.HIDE_REPORTS,
        payload: { id, is_reports_visible },
      });
      message.success(result.data.message, 5);
    } else {
      message.error(result.data.message, 5);
      dispatch({
        type: actionTypes.HIDE_REPORTS_LOADER,
        payload: { isLoading: false, id },
      });
    }
  } catch (error) {
    message.error(error.message, 5);
    dispatch({
      type: actionTypes.HIDE_REPORTS_LOADER,
      payload: { isLoading: false, id },
    });
  }
};
