import * as actionTypes from "../action";

const initialState = {
  ordersLoader: false,
  ordersData: [],
  orderDetailLoader: false,
  orderDetailData: {},
  ordersCount: {},
  changeOrderStatusLoader: false,

  removeTaskDashboard: false,
  datta: {},
};

const drawReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOTAL_ORDERS_COUNT:
      return { ...state, ordersCount: action.payload };

    case actionTypes.GET_ORDERS_LOADING:
      return { ...state, ordersLoader: action.payload };

    case actionTypes.GET_ORDERS_DATA:
      return { ...state, ordersData: action.payload };

    case actionTypes.UPDATE_ORDER_STATUS_LOADING:
      return { ...state, changeOrderStatusLoader: action.payload };

    case actionTypes.UPDATE_ORDER_STATUS:
      return {
        ...state,
        ordersData: state.ordersData.map((data) =>
          data._id === action.payload.id
            ? {
                ...data,
                order_status: action.payload.order_status,
              }
            : data
        ),
      };

    
    //return { ...state, dashboardData: action.payload };

    // case actionTypes.REMOVE_DATA:
    //   // Assuming state.dashboardData is an object with an agent_task property
    //   const updatedAgentTask = state.dashboardData.agent_task.map((d) => ({
    //     ...d,
    //     tasks: d.tasks.filter((i) => i.task_id !== action.payload.task_id),
    //   }));

    //   return {
    //     ...state,
    //     dashboardData: {
    //       ...state.dashboardData,
    //       agent_task: updatedAgentTask,
    //     },
    //   };

    // case actionTypes.REMOVE_DATA:
    //   return {
    //     ...state,
    //     datta: action.payload,
    //   };

    case actionTypes.GET_ORDER_DETAIL_LOADING:
      return { ...state, orderDetailLoader: action.payload };

    case actionTypes.GET_ORDER_DETAIL_DATA:
      return {
        ...state,
        orderDetailData: action.payload,
      };

    default:
      return state;
  }
};

export default drawReducer;
