import { Editor } from "@tinymce/tinymce-react";
import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { actionAddKnowledge } from "../../store/actions/knowlegdeAction";
import {
  actionAddAreaOfConcern,
  actionGetAreaOfConcern,
  actionGetTicketDetail,
} from "../../store/actions/ticketAction";
import { actionGetUsersAll } from "../../store/actions/userAction";
const { TextArea } = Input;

const CreateKnowledge = (props) => {
  const {
    addKnowledgeLoader,
    actionGetTicketDetail,
    actionAddKnowledge,
    ticketsDetailLoader,
    ticketsDetailData,
    actionGetAreaOfConcern,
    areaConcernData,
  } = props;

  const Navigate = useNavigate();
  const [form] = Form.useForm();

  const [content, setContent] = useState();

  const [boolean, setboolean] = useState();
  const [consID, setConsID] = useState();
  const [descriptionError, setDescriptionError] = useState(
    ticketsDetailData.resolve_note ? false : false
  );

  const editorRef = useRef(null);
  const { kbId } = useParams();

  useEffect(() => {
    kbId && actionGetTicketDetail(kbId);
    ticketsDetailData.resolve_note && setDescriptionError(false);
  }, [kbId]);

  useEffect(() => {
    actionGetAreaOfConcern();
  }, []);

  useMemo(() => {}, [ticketsDetailData]);

  useEffect(() => {
    kbId &&
      form.setFieldsValue({
        title: ticketsDetailData.title,
        areaofconcern_id: ticketsDetailData.areaofconcernName,
      });
    kbId && setContent(ticketsDetailData.resolve_note);
  }, [ticketsDetailData]);

  const handleChange = (e, data) => {
    setboolean(true);
    setConsID(data.value);
  };

  const onFinish = (values) => {
    if (!content || content.trim() === "") {
      setDescriptionError(true);
      return; // Stop form submission
    } else {
      setDescriptionError(false);
    }

    const selectedAreaConcern = areaConcernData.find(
      (data) => data.name === values.areaofconcern_id
    );
    const areaofconcern_id = selectedAreaConcern
      ? selectedAreaConcern.id
      : null;

    const request = {
      ...values,
      description: content,
      ticket_id: kbId ? parseInt(kbId) : 0,
      areaofconcern_id: boolean ? consID : parseInt(areaofconcern_id),
    };
    actionAddKnowledge(request, Navigate);
    setContent("");
    setDescriptionError("");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleEditorChange = (newContent, editor) => {
    // Check if the new content is of type string
    if (typeof newContent === "string") {
      setContent(newContent);
      // setDescriptionError("");
    } else {
      setContent("");
    }
  };
  const areaConcern = [];
  areaConcernData &&
    areaConcernData.length > 0 &&
    areaConcernData.map((data, index) => {
      areaConcern.push({
        key: index,
        label: data.name,
        value: data.id,
      });
    });

  return (
    <Fragment>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Card
            className="main-card "
            title={
              <>
                <Row align="middle" justify="">
                  <Col span={2}>
                    <h2 className="text-white text-base">
                      Create Knowledge Base
                    </h2>
                  </Col>
                </Row>
              </>
            }
            style={{
              width: "100%",
              minHeight: "60vh",
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form
                  autoComplete="off"
                  requiredMark={true}
                  form={form}
                  layout="vertical"
                  size="large"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Row gutter={[32, 0]}>
                    <Col span={24}>
                      <Form.Item
                        colon={false}
                        label="Title"
                        name="title"
                        rules={[
                          {
                            required: true,
                            message: "Please input your  Title!",
                          },
                          {
                            validator: (_, value) =>
                              value.length <= 100
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "Please enter maximum 100 characters for Title"
                                    )
                                  ),
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Title"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        className="text-black text-base font-medium"
                        name="description"
                        label="Description"
                      >
                        <Editor
                          initialValue={kbId && ticketsDetailData.resolve_note}
                          apiKey="hglsma9tuhg1k0s3lopkfjuqpv6uhaa4qiv8sbgnbepck75f" // Replace 'your-api-key' with your TinyMCE API key
                          onEditorChange={handleEditorChange}
                          init={{
                            height: 350,
                            branding: false,
                            plugins:
                              "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                            imagetools_cors_hosts: ["picsum.photos"],
                            menubar:
                              "file edit view insert format tools table help",
                            toolbar:
                              "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
                            toolbar_sticky: true,
                            autosave_interval: "30s",
                            autosave_ask_before_unload: true,
                          }}
                          wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor"
                          ref={editorRef} // Set a reference to the editor instance
                        />
                        {descriptionError && (
                          <div
                            className=" mt-1"
                            style={{ color: "#ff4d4f", fontWeight: "400" }}
                          >
                            Please Enter Description!
                          </div>
                        )}
                        <div className="hidden">
                          <h2>Editor Content:</h2>
                          {content ? (
                            <div
                              dangerouslySetInnerHTML={{ __html: content }}
                            />
                          ) : null}
                        </div>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="areaofconcern_id"
                        label="Module"
                        // className="text-black text-base font-medium"
                        rules={[
                          {
                            required: true,
                            message: "Please select Module",
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          // className="rounded-md border border-[#162038] border-opacity-50 border-0.5 bg-[#EDEDED] py-1 text-black"
                          placeholder="Choose Module"
                          onChange={handleChange}
                          options={areaConcern}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item normalize={(value) => value.trim()}>
                        <Button
                          loading={addKnowledgeLoader}
                          align="center"
                          htmlType="submit"
                          className="primary"
                          size="large"
                          style={{ width: 200, marginTop: "30px" }}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    addKnowledgeLoader: state.project.addKnowledgeLoader,
    addProjectsData: state.project.addProjectsData,
    usersData: state.users.usersData,
    ticketsDetailLoader: state.ticket.ticketsDetailLoader,
    ticketsDetailData: state.ticket.ticketsDetailData,
    usersLoader: state.users.usersLoader,
    areaConcernLoader: state.ticket.areaConcernLoader,
    areaConcernData: state.ticket.areaConcernData,
    addAreaConcernLoader: state.ticket.addAreaConcernLoader,
    addAreaConcernData: state.ticket.addAreaConcernData,
  };
};

export default connect(mapStateToProps, {
  actionAddKnowledge,
  actionGetUsersAll,
  actionGetTicketDetail,
  actionGetAreaOfConcern,
  actionAddAreaOfConcern,
})(CreateKnowledge);
