import * as actionTypes from "../action";

const initialState = {
  campaignPrintTicketLoader: false,
  campaignPrintTicketData: [],
  campaignLoader: false,
  campaignData: [],
  addCampaignLoader: false,
  addCampaignData: [],
  campaignUpdateLoader: false,
  campaignDetailLoader: false,
  campaignDetailData: {},
  campaignIncreaseTicketLoader: false,
  allowedRepetativeUserLoader: false,
  allowedRepetativeUserData: {},
  announceUserDetailLoader: false,
  announceUserDetailData: {},
  winnerDetailLoader: false,
  winnerDetailData: {},
  productNotFound: "",
};

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CAMPAIGN_DATA:
      return { ...state, campaignData: action.payload };

    case actionTypes.GET_CAMPAIGN_LOADING:
      return { ...state, campaignLoader: action.payload };

    case actionTypes.ADD_CAMPAIGN_LOADING:
      return { ...state, addCampaignLoader: action.payload };

    case actionTypes.ADD_CAMPAIGN_DATA:
      return { ...state, addCampaignData: action.payload }; 

    case actionTypes.DELETE_CAMPAIGN_LOADING:
      return { ...state, deleteCampaignLoading: action.payload };

    case actionTypes.DELETE_CAMPAIGN_ID:
      return {
        ...state,
        campaignData: [
          ...state.campaignData.filter((data) => data._id !== action.payload),
        ],
      };

    case actionTypes.UPDATE_CAMPAIGN_LOADING:
      return { ...state, campaignUpdateLoader: action.payload };

    case actionTypes.GET_CAMPAIGN_DETAIL_LOADING:
      return { ...state, campaignDetailLoader: action.payload };

    case actionTypes.GET_CAMPAIGN_DETAIL_DATA:
      return {
        ...state,
        campaignDetailData: action.payload,
      };
    case actionTypes.SEARCH_ANNOUNCE_WINNER_LOADING:
      return { ...state, announceUserDetailLoader: action.payload };

    case actionTypes.SEARCH_ANNOUNCE_WINNER_DATA:
      return {
        ...state,
        announceUserDetailData: action.payload,
      };
    case actionTypes.GET_CAMPAIGN_TICKET_LOADING:
      return { ...state, campaignPrintTicketLoader: action.payload };

    case actionTypes.GET_CAMPAIGN_TICKET_DATA:
      return {
        ...state,
        campaignPrintTicketData: action.payload,
      };
    case actionTypes.NOT_FOUND_CAMPAIGN_TICKET_DATA:
      return {
        ...state,
        productNotFound: action.payload,
      };
    case actionTypes.INCREASE_CAMPAIGN_TICKET_LOADING:
      return { ...state, campaignIncreaseTicketLoader: action.payload };

    case actionTypes.INCREASE_CAMPAIGN_TICKET_DATA:
      return {
        ...state,
        campaignData: state.campaignData.map((data) =>
          data._id === action.payload._id
            ? {
                ...data,
                no_of_tickets: 
                  data.no_of_tickets +
                  action.payload.no_of_tickets.no_of_tickets,
              }
            : data
        ),
      };

    case actionTypes.ALLOWED_REPETATIVE_USERS_LOADING:
      return { ...state, allowedRepetativeUserLoader: action.payload };

    case actionTypes.ALLOWED_REPETATIVE_USERS:
      return {
        ...state,
        campaignData: state.campaignData.map((data) =>
          data._id === action.payload._id
            ? {
                ...data,
                allowed_reapeted_users:
                  action.payload.userData.allowed_reapeted_users,
              }
            : data
        ),
      };

    case actionTypes.ANNOUNCE_WINNER_LOADING:
      return { ...state, winnerDetailLoader: action.payload };

    case actionTypes.ANNOUNCE_WINNER_DATA:
      return {
        ...state,
        campaignData: state.campaignData.map((data) =>
          data._id === action.payload._id
            ? {
                ...data,
                winner_name: action.payload.winner_name,
              }
            : data
        ),
      };
    default: {
      return state;
    }
  }
};

export default campaignReducer;
