import axios from "axios";
import { BASE_URL } from "../../components/config/web-config";
import * as actionTypes from "../action";
import { message } from "antd";

export const actionGetTicketsReportAll =
  (offset, limit, user, getall) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_TICKETS_REPORT_LOADING, payload: true });
    try {
      let result = await axios.get(
        // `${BASE_URL}/tickets/report/activity?limit=${limit}&offset=${offset}`
        `${BASE_URL}/tickets/report/activity?limit=${limit}&offset=${offset}${
          user ? "&keyword=" + user : ""
        }${getall ? "&getall=" + getall : ""}`
      );
      dispatch({
        type: actionTypes.GET_TICKETS_REPORT_LOADING,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        if (getall == "YES") {
          dispatch({
            type: actionTypes.GET_TICKETS_REPORT_ALL_DATA,
            payload: result.data.data,
          });
        } else {
          dispatch({
            type: actionTypes.GET_TICKETS_REPORT_DATA,
            payload: result.data.data,
          });
        }

        dispatch({
          type: actionTypes.TOTAL_USER_REPORT_COUNT,
          payload: result.data.total_records,
        });
      } else if (parseInt(result.data.status) === 404) {
        dispatch({
          type: actionTypes.GET_TICKETS_REPORT_DATA,
          payload: [],
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({
        type: actionTypes.GET_TICKETS_REPORT_LOADING,
        payload: false,
      });
    }
  };

export const actionGetAgentYearlyReportAll =
  (offset, limit, year, user, getall) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.GET_AGENT_YEARLY_REPORT_LOADING,
      payload: true,
    });
    try {
      let result = await axios.get(
        `${BASE_URL}/tickets/report/utilization-yearly-agent-report?limit=${limit}&offset=${offset}${
          year ? "&year=" + year : ""
        }${user ? "&search=" + user : ""}${getall ? "&getall=" + getall : ""}`
      );
      dispatch({
        type: actionTypes.GET_AGENT_YEARLY_REPORT_LOADING,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        if (getall == "YES") {
          dispatch({
            type: actionTypes.GET_EXPORT_AGENT_YEARLY_REPORT_DATA,
            payload: result.data.data,
          });
        } else {
          dispatch({
            type: actionTypes.GET_AGENT_YEARLY_REPORT_DATA,
            payload: result.data.data,
          });
        }
        dispatch({
          type: actionTypes.TOTAL_AGENT_YEARLY_REPORT_COUNT,
          payload: result.data.total_records,
        });
      } else if (parseInt(result.data.status) === 404) {
        dispatch({
          type: actionTypes.GET_AGENT_YEARLY_REPORT_DATA,
          payload: [],
        });
        dispatch({
          type: actionTypes.TOTAL_AGENT_YEARLY_REPORT_COUNT,
          payload: 0,
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({
        type: actionTypes.GET_AGENT_YEARLY_REPORT_LOADING,
        payload: false,
      });
    }
  };

export const actionGetTimeSpentReportAll =
  (offset, limit, ticket, start_date, end_date, agent_id, getall) =>
  async (dispatch, getState) => {
    dispatch({
      type: actionTypes.GET_TIME_SPENT_REPORT_LOADING,
      payload: true,
    });
    try {
      let result = await axios.get(
        `${BASE_URL}/tickets/report/utilization-agent-timespent-report?limit=${limit}&offset=${offset}${
          ticket ? "&ticket_service_number=" + ticket : ""
        }${start_date ? "&start_date=" + start_date : ""}${
          end_date ? "&end_date=" + end_date : ""
        }${agent_id ? "&agent_id=" + agent_id : ""}${
          getall ? "&getall=" + getall : ""
        }`
      );
      dispatch({
        type: actionTypes.GET_TIME_SPENT_REPORT_LOADING,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        if (getall == "YES") {
          dispatch({
            type: actionTypes.GET_EXPORT_TIME_SPENT_REPORT_DATA,
            payload: result.data.data,
          });
        } else {
          dispatch({
            type: actionTypes.GET_TIME_SPENT_REPORT_DATA,
            payload: result.data.data,
          });
        }

        dispatch({
          type: actionTypes.TOTAL_TIME_SPENT_REPORT_COUNT,
          payload: result.data.total_records,
        });
      } else if (parseInt(result.data.status) === 404) {
        dispatch({
          type: actionTypes.GET_TIME_SPENT_REPORT_DATA,
          payload: [],
        });
        dispatch({
          type: actionTypes.TOTAL_TIME_SPENT_REPORT_COUNT,
          payload: 0,
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({
        type: actionTypes.GET_TIME_SPENT_REPORT_LOADING,
        payload: false,
      });
    }
  };

export const actionGetActualHoursReportAll =
  (offset, limit, ticket, start_date, end_date, getall) =>
  async (dispatch, getState) => {
    dispatch({
      type: actionTypes.GET_ACTUAL_HOURS_REPORT_LOADING,
      payload: true,
    });
    try {
      let result = await axios.get(
        `${BASE_URL}/tickets/report/utilization-tickets-actualhours-report?limit=${limit}&offset=${offset}${
          ticket ? "&ticket_id=" + ticket : ""
        }${start_date ? "&start_date=" + start_date : ""}${
          end_date ? "&end_date=" + end_date : ""
        }${getall ? "&getall=" + getall : ""}`
      );
      dispatch({
        type: actionTypes.GET_ACTUAL_HOURS_REPORT_LOADING,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        if (getall == "YES") {
          dispatch({
            type: actionTypes.GET_EXPORT_ACTUAL_HOURS_REPORT_DATA,
            payload: result.data.data,
          });
        } else {
          dispatch({
            type: actionTypes.GET_ACTUAL_HOURS_REPORT_DATA,
            payload: result.data.data,
          });
        }
        dispatch({
          type: actionTypes.TOTAL_ACTUAL_HOURS_REPORT_COUNT,
          payload: result.data.total_records,
        });
      } else if (parseInt(result.data.status) === 404) {
        dispatch({
          type: actionTypes.GET_ACTUAL_HOURS_REPORT_DATA,
          payload: [],
        });
        dispatch({
          type: actionTypes.TOTAL_ACTUAL_HOURS_REPORT_COUNT,
          payload: 0,
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({
        type: actionTypes.GET_ACTUAL_HOURS_REPORT_LOADING,
        payload: false,
      });
    }
  };

export const actionGetTicketAgentYearlyReportAll =
  (offset, limit, year, user, level, status, getall) =>
  async (dispatch, getState) => {
    dispatch({
      type: actionTypes.GET_TICKET_AGENT_YEARLY_REPORT_LOADING,
      payload: true,
    });
    try {
      let result = await axios.get(
        `${BASE_URL}/tickets/report/agent-yearly-task-report?limit=${limit}&offset=${offset}${
          year ? "&year=" + year : ""
        }${user ? "&search=" + user : ""}${getall ? "&getall=" + getall : ""}${
          level ? "&level=" + level : ""
        }${status ? "&status=" + status : ""}`
      );
      dispatch({
        type: actionTypes.GET_TICKET_AGENT_YEARLY_REPORT_LOADING,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        if (getall == "YES") {
          dispatch({
            type: actionTypes.GET_EXPORT_TICKET_AGENT_YEARLY_REPORT_DATA,
            payload: result.data.data,
          });
        } else {
          dispatch({
            type: actionTypes.GET_TICKET_AGENT_YEARLY_REPORT_DATA,
            payload: result.data.data,
          });
        }
        dispatch({
          type: actionTypes.TOTAL_TICKET_AGENT_YEARLY_REPORT_COUNT,
          payload: result.data.total_records,
        });
      } else if (parseInt(result.data.status) === 404) {
        dispatch({
          type: actionTypes.GET_TICKET_AGENT_YEARLY_REPORT_DATA,
          payload: [],
        });
        dispatch({
          type: actionTypes.TOTAL_TICKET_AGENT_YEARLY_REPORT_COUNT,
          payload: 0,
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({
        type: actionTypes.GET_TICKET_AGENT_YEARLY_REPORT_LOADING,
        payload: false,
      });
    }
  };

export const actionGetTicketTimeSpentReportAll =
  (offset, limit, ticket, start_date, end_date, agent_id, getall) =>
  async (dispatch, getState) => {
    dispatch({
      type: actionTypes.GET_TICKET_TIME_SPENT_REPORT_LOADING,
      payload: true,
    });
    try {
      let result = await axios.get(
        `${BASE_URL}/tickets/report/agent-timespent-task-report?limit=${limit}&offset=${offset}${
          ticket ? "&ticket_service_number=" + ticket : ""
        }${start_date ? "&start_date=" + start_date : ""}${
          end_date ? "&end_date=" + end_date : ""
        }${agent_id ? "&agent_id=" + agent_id : ""}${
          getall ? "&getall=" + getall : ""
        }`
      );
      dispatch({
        type: actionTypes.GET_TICKET_TIME_SPENT_REPORT_LOADING,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        if (getall == "YES") {
          dispatch({
            type: actionTypes.GET_EXPORT_TICKET_TIME_SPENT_REPORT_DATA,
            payload: result.data.data,
          });
        } else {
          dispatch({
            type: actionTypes.GET_TICKET_TIME_SPENT_REPORT_DATA,
            payload: result.data.data,
          });
        }

        dispatch({
          type: actionTypes.TOTAL_TICKET_TIME_SPENT_REPORT_COUNT,
          payload: result.data.total_records,
        });
      } else if (parseInt(result.data.status) === 404) {
        dispatch({
          type: actionTypes.GET_TICKET_TIME_SPENT_REPORT_DATA,
          payload: [],
        });
        dispatch({
          type: actionTypes.TOTAL_TICKET_TIME_SPENT_REPORT_COUNT,
          payload: 0,
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({
        type: actionTypes.GET_TICKET_TIME_SPENT_REPORT_LOADING,
        payload: false,
      });
    }
  };

export const clearReportData = () => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.GET_EXPORT_TICKET_TIME_SPENT_REPORT_DATA,
    payload: [],
  });
  dispatch({ type: actionTypes.GET_TICKETS_REPORT_ALL_DATA, payload: [] });
  dispatch({
    type: actionTypes.GET_EXPORT_AGENT_YEARLY_REPORT_DATA,
    payload: [],
  });
  dispatch({
    type: actionTypes.GET_EXPORT_TIME_SPENT_REPORT_DATA,
    payload: [],
  });
  dispatch({
    type: actionTypes.GET_EXPORT_ACTUAL_HOURS_REPORT_DATA,
    payload: [],
  });
  dispatch({
    type: actionTypes.GET_EXPORT_TICKET_AGENT_YEARLY_REPORT_DATA,
    payload: [],
  });
};
