import { PlusOutlined } from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Upload,
  message,
} from "antd";
import { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionAddAreaOfConcern,
  actionAddTicket,
  actionGetAreaOfConcern,
} from "../../store/actions/ticketAction";
import { actionGetUsersAll } from "../../store/actions/userAction";
import { BASE_URL_UPLOAD } from "../config/web-config";

const { TextArea } = Input;

const getBase64 = (img) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result));
    reader.addEventListener("error", (error) => reject(error));
    reader.readAsDataURL(img);
  });
};

const CreateTicket = (props) => {
  const {
    addTicketsLoader,
    actionAddTicket,
    actionGetAreaOfConcern,
    actionAddAreaOfConcern,
    areaConcernData,
    usersLoader,
    areaConcernLoader,
    actionGetUsersAll,
    usersData,

    addAreaConcernLoader,
  } = props;

  const [form] = Form.useForm();
  const [imageFileList, setImageFileList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [screenshot, setScreenshot] = useState(false);

  const Navigate = useNavigate();

  const [content, setContent] = useState("");
  const editorRef = useRef(null);

  useEffect(() => {
    actionGetAreaOfConcern();
  }, []);

  useEffect(() => {
    actionGetUsersAll(0, 500);
  }, []);

  const areaConcern = [];
  areaConcernData &&
    areaConcernData.length > 0 &&
    areaConcernData.map((data, index) => {
      areaConcern.push({
        key: index,
        label: data.name,
        value: data.id,
      });
    });

  const users = [];
  usersData.map((user) => {
    if (user.roles === "USER") {
      users.push({
        label: user.first_name + " " + user.last_name,
        value: user.id,
        email: user.email,
      });
    }
  });
  const handleChange = (value) => {
    const selectedUser = users.find((user) => user.value === value);
   
    if (selectedUser) {
      form.setFieldsValue({ email: selectedUser.email }); // Assuming the email is a property of the user object
    }
  };

  const handleEditorChange = (newContent, editor) => {
    // Check if the new content is of type string
    if (typeof newContent === "string") {
      setContent(newContent);
      // setDescriptionError("");
    } else {
      setContent("");
    }
  };

  const handleClear = () => {
    form.setFieldsValue({ email: undefined });
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      try {
        const preview = await getBase64(file.originFileObj);
        file.preview = preview;
      } catch (error) {
        console.error("Error while getting the preview image:", error);
        return;
      }
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleCancel = () => setPreviewOpen(false);

  const handleChangeImage = ({ fileList: newFileList }) => {
    // let updatedFileList = [...newFileList];
    const maxSize = 100;

    const imageFormat = /\.(jpe?g|png)$/i;
    const allFormat = /\.(pdf|csv|xls[x]?|doc[x]?)$/i;

    const updatedFileList = newFileList.filter((file) => {
      const fileSize = file.size / 1024 / 1024;

      if (
        (file.type.startsWith("image/") && imageFormat.test(file.name)) ||
        allFormat.test(file.name)
      ) {
        if (fileSize <= maxSize) {
          return true;
        } else {
          message.error("Maximum image size allowed is 10MB!", 2);
          return false;
        }
      } else {
        message.error(
          "Please upload file/image with (jpg/png/doc/pdf/csv/xlsx) extensions only.",
          2
        );
        return false;
      }
    });

    setImageFileList(updatedFileList);
  };

  const uploadButton = (
    <div className="flex justify-center items-center ">
      <PlusOutlined />
      <div
        style={{
          marginLeft: 5,
          letterSpacing: 0.5,
        }}
      >
        Upload
      </div>
    </div>
  );

  const onReset = () => {
    form.resetFields();
    setImageFileList([]);
  };

  const onFinish = (values) => {
    const imagesArr = [];

    if (!content || content.trim() === "") {
      // Set the description error if the description field is empty
      setDescriptionError(true);
      return; // Stop form submission
    } else {
      setDescriptionError(false);
    }

    // Image set to property image

    imageFileList &&
      imageFileList.length > 0 &&
      imageFileList.map((d) => {
        if (d.response) {
          return imagesArr.push(d.response.file.filename);
        } else if (d.is_cropper_image) {
          return imagesArr.push(d.is_cropper_image);
        } else return imagesArr.push(d.filename);
      });

    const request = {
      ...values,
      description: content,
      attachemnets: imagesArr,
    };
    actionAddTicket(request, Navigate);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Fragment>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Card
            className="main-card "
            title={
              <>
                <Row align="middle" justify="">
                  <Col span={2}>
                    <h2 className="text-white text-base">Create Ticket</h2>
                  </Col>
                </Row>
              </>
            }
            style={{
              width: "100%",
              minHeight: "60vh",
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form
                  initialValues={{ level: "Level 1" }}
                  autoComplete="off"
                  requiredMark={true}
                  form={form}
                  layout="vertical"
                  size="large"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Row gutter={[32, 0]}>
                    <Col span={12}>
                      <Form.Item
                        label="Users"
                        colon={false}
                        name="projectuser"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Users!",
                          },
                        ]}
                      >
                        <Select
                          className="project-select"
                          showSearch
                          allowClear
                          style={{
                            width: "100%",
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          placeholder="Users"
                          onChange={handleChange}
                          options={users}
                          onClear={handleClear} // Call the function to reset the email field
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        //   className=" text-black text-base font-medium"

                        name="email"
                        label="Email"
                        rules={[
                          {
                            type: "email",
                            required: !form.getFieldValue("email"),
                            message: "Please input your Email!",
                          },
                        ]}
                      >
                        <Input
                          autoComplete="off"
                          placeholder="Enter Your Email"
                          disabled
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        //   className=" text-black text-base font-medium"
                        name="title"
                        label="Title"
                        rules={[
                          {
                            // type: "text",
                            required: true,
                            message: "Please input Title!",
                          },
                        ]}
                      >
                        <Input autoComplete="off" placeholder="Enter Title" />
                      </Form.Item>
                    </Col>
                    {/* <Col span={12}>
                      <Form.Item
                        //   className=" text-black text-base font-medium"
                        name="on_behalf_of_name"
                        label="On Behalf Name"
                        rules={[
                          {
                            // type: "text",
                            required: false,
                            // message: "Please input Title!",
                          },
                        ]}
                      >
                        <Input autoComplete="off" placeholder="Enter On Behalf Name" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        //   className=" text-black text-base font-medium"
                        name="on_behalf_of_email"
                        label="On Behalf Email"
                        rules={[
                          {
                            type: "email",
                            required: false,
                            message: "Please input valid email!",
                          },
                        ]}
                      >
                        <Input autoComplete="off" placeholder="Enter On Behalf Email" />
                      </Form.Item>
                    </Col> */}
                    <Col span={12}>
                      <Form.Item
                        name="on_behalf_of_name"
                        label="On Behalf Name"
                        rules={[
                          {
                            required: form.getFieldValue("on_behalf_of_email"),
                            // message: "Please input On Behalf Name!",
                            validator: (_, value) => {
                              if (
                                !value &&
                                form.getFieldValue("on_behalf_of_email")
                              ) {
                                return Promise.reject(
                                  "Please input On Behalf Name!"
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input
                          autoComplete="off"
                          placeholder="Enter On Behalf Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="on_behalf_of_email"
                        label="On Behalf Email"
                        rules={[
                          {
                            type: "email",
                            message: "Please input valid email",
                          },
                          {
                            required: form.getFieldValue("on_behalf_of_name"),
                            validator: (_, value) => {
                              if (
                                !value &&
                                form.getFieldValue("on_behalf_of_name")
                              ) {
                                return Promise.reject(
                                  "Please input On Behalf Email!"
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input
                          autoComplete="off"
                          placeholder="Enter On Behalf Email"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="primary_troubleshooting"
                        label="Has the Primary Troubleshooting Done?"
                        //   className=" text-black text-base font-medium"
                        rules={[
                          {
                            required: true,
                            message: "Please select Primary Troubleshooting",
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          // className="rounded-md border border-[#162038] border-opacity-50 border-0.5 bg-[#EDEDED] py-1 text-black"
                          placeholder="Choose"
                          //   onChange={handleChange}
                          options={[
                            {
                              value: "YES",
                              label: "YES",
                            },
                            {
                              value: "NO",
                              label: "NO",
                            },
                            {
                              value: "Not Sure how to perform",
                              label: "Not Sure, How To Perform",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        // className=" text-black text-base font-medium notRequired"
                        name="primary_troubleshooting_description"
                        label="Please mention the Steps performed in Primary Troubleshooting"
                        rules={[
                          {
                            required: true,
                            message: "Please input Steps Performed!",
                          },
                        ]}
                      >
                        <Input
                          autoComplete="off"
                          placeholder="Your answer"
                          // className="rounded-md border border-[#162038] border-opacity-50 border-0.5 bg-[#EDEDED] py-2 text-black"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        name="rate_issuue"
                        label="Please Rate the Issue according to Priority"
                        // className=" text-black text-base font-medium"
                        rules={[
                          {
                            required: true,
                            message: "Please select Priority",
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          // className="rounded-md border border-[#162038] border-opacity-50 border-0.5 bg-[#EDEDED] py-1 text-black"
                          placeholder="Choose"
                          //   onChange={handleChange}
                          options={[
                            {
                              value: "Normal",
                              label: "Normal",
                            },
                            {
                              value: "Medium",
                              label: "Medium",
                            },
                            {
                              value: "High",
                              label: "High",
                            },
                            {
                              value: "Very High",
                              label: "Very High",
                            },
                            {
                              value: "Urgent",
                              label: "Urgent",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="areaofconcernId"
                        label="Area of Concern"
                        // className="text-black text-base font-medium"
                        rules={[
                          {
                            required: true,
                            message: "Please select Area of Concern",
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          // className="rounded-md border border-[#162038] border-opacity-50 border-0.5 bg-[#EDEDED] py-1 text-black"
                          placeholder="Choose"
                          //   onChange={handleChange}
                          options={areaConcern}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="typesofissue"
                        label="Type of Issue"
                        // className=" text-black text-base font-medium"
                        rules={[
                          {
                            required: true,
                            message: "Please select Type Of issue",
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          // className="rounded-md border border-[#162038] border-opacity-50 border-0.5 bg-[#EDEDED] py-1 text-black"
                          placeholder="Choose"
                          //   onChange={handleChange}
                          options={[
                            {
                              value: "INCIDENT",
                              label: "Incident",
                            },
                            {
                              value: "PROBLEM",
                              label: "Problem",
                            },
                            {
                              value: "TROUBLESHOOTING",
                              label: "Troubleshooting",
                            },
                            {
                              value: "CUSTOM INTEGRATION",
                              label: "Custom Integration",
                            },
                            {
                              value: "NEW REQUIREMENT",
                              label: "New Requirement",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="level"
                        label="Level of Ticket"
                        // className=" text-black text-base font-medium"
                        rules={[
                          {
                            required: false,
                            message: "Please select Level of Ticket",
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          // className="rounded-md border border-[#162038] border-opacity-50 border-0.5 bg-[#EDEDED] py-1 text-black"
                          placeholder="Choose"
                          //   onChange={handleChange}
                          //   defaultValue="Level 1"
                          options={[
                            {
                              value: "Level 1",
                              label: "Level 1",
                            },
                            {
                              value: "Level 2",
                              label: "Level 2",
                            },
                            {
                              value: "Level 3",
                              label: "Level 3",
                            },
                            {
                              value: "Level 4",
                              label: "Level 4",
                            },
                            {
                              value: "CR",
                              label: "CR",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            validator: (_, value) => {
                              if (!content || content.trim() == "") {
                                return Promise.reject(
                                  new Error("Please Describe Issue!")
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                        getValueFromEvent={(event, editorValue) => editorValue} // Pass the editor value to the form
                        className="text-black text-base font-medium"
                        name="description"
                        label="Describe the Issue?"
                      >
                        <Editor
                          apiKey="hglsma9tuhg1k0s3lopkfjuqpv6uhaa4qiv8sbgnbepck75f" // Replace 'your-api-key' with your TinyMCE API key
                          onEditorChange={handleEditorChange}
                          init={{
                            height: 350,
                            branding: false,
                            plugins:
                              "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                            imagetools_cors_hosts: ["picsum.photos"],
                            menubar:
                              "file edit view insert format tools table help",
                            toolbar:
                              "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
                            toolbar_sticky: true,
                            autosave_interval: "30s",
                            autosave_ask_before_unload: true,
                          }}
                          wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor"
                          ref={editorRef} // Set a reference to the editor instance
                        />
                        {descriptionError && (
                          <div className="text-red-500 mt-1">
                            Please Describe Issue!
                          </div>
                        )}
                        <div className="hidden">
                          <h2>Editor Content:</h2>
                          {content ? (
                            <div
                              dangerouslySetInnerHTML={{ __html: content }}
                            />
                          ) : null}
                        </div>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        className="attatchment-input"
                        label="Kindly attach file/image with (jpg/png/doc/pdf/csv/xlsx):"
                        name="attachemnets"
                        rules={[
                          {
                            required: imageFileList ? false : true,
                            message: "Screenshot is required!",
                          },
                        ]}
                        // rules={[
                        //   {
                        //     required: imageFileList.length === 0,
                        //     message: "Screenshot is required!",
                        //   },
                        // ]}
                      >
                        <div>
                          {/* <ImgCrop rotationSlider> */}
                          <Upload
                            name="file"
                            action={BASE_URL_UPLOAD}
                            listType="picture-card"
                            fileList={imageFileList}
                            onChange={handleChangeImage}
                            accept=".png,.jpg,.jpeg,.pdf,.csv,.xlsx,.xls,.doc"
                            onPreview={handlePreview}
                          >
                            {uploadButton}
                          </Upload>
                          {/* </ImgCrop> */}
                          <Modal
                            open={previewOpen}
                            title={previewTitle}
                            footer={null}
                            onCancel={handleCancel}
                          >
                            <img
                              alt="example"
                              style={{
                                width: "100%",
                              }}
                              src={previewImage}
                            />
                          </Modal>
                        </div>
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item normalize={(value) => value.trim()}>
                        <Button
                          loading={addTicketsLoader}
                          align="center"
                          htmlType="submit"
                          className="primary"
                          size="large"
                          style={{ width: 200, marginTop: "30px" }}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    addTicketsLoader: state.ticket.addTicketsLoader,
    addTicketData: state.ticket.addTicketData,
    areaConcernLoader: state.ticket.areaConcernLoader,
    areaConcernData: state.ticket.areaConcernData,
    addAreaConcernLoader: state.ticket.addAreaConcernLoader,
    addAreaConcernData: state.ticket.addAreaConcernData,
    usersData: state.users.usersData,
    usersLoader: state.users.usersLoader,
  };
};

export default connect(mapStateToProps, {
  actionAddTicket,
  actionGetAreaOfConcern,
  actionAddAreaOfConcern,
  actionGetUsersAll,
})(CreateTicket);
