import {
  Card,
  Col,
  Avatar,
  Image,
  List,
  Row,
  Typography,
  Button,
  Popconfirm,
  Spin,
  Descriptions,
} from "antd";
import React, { useEffect, useMemo } from "react";
import { CheckCircleOutlined, CalendarOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  actionDeleteUser,
  actionGetUserDetail,
} from "../../store/actions/userAction";
const { Title, Text } = Typography;
const UserDetail = (props) => {
  const Navigate = useNavigate();
  const {
    actionGetUserDetail,
    userDetailLoader,
    userDetailData,
    actionDeleteUser,
  } = props;
  const { userId } = useParams();

  useEffect(() => {
    actionGetUserDetail(userId);
  }, []);

  const userdata = [
    {
      title: "Username",
      description: <>{userDetailData.username}</>,
    },
    {
      title: "Name",
      description: (
        <>{userDetailData.first_name + " " + userDetailData.last_name}</>
      ),
    },
    {
      title: "Email",
      description: <>{userDetailData && userDetailData.email}</>,
    },
    {
      title: "Mobile Number",
      description: <>{userDetailData && userDetailData.phone}</>,
    },

    {
      title: "Role",
      description: (
        <>
          <p style={{ textTransform: "lowercase" }}>
            {userDetailData && userDetailData.roles}
          </p>
        </>
      ),
    },
    {
      title: "Area Of Concern",
      description: (
        <>
          <p style={{ textTransform: "lowercase" }}>
            {userDetailData.user_area_of_concern &&
            userDetailData.user_area_of_concern.length > 0
              ? userDetailData.user_area_of_concern.map((data, index) => {
                  return (
                    <Typography.Text
                      key={index}
                      className="text-dark uppercase"
                    >
                      {data.name +
                        " " +
                        (index < userDetailData.user_area_of_concern.length - 1
                          ? ", "
                          : "")}
                    </Typography.Text>
                  );
                })
              : "-"}
          </p>
        </>
      ),
    },
  ];

  const confirm = (id) => {
    actionDeleteUser(id, Navigate);
  };

  return (
    <>
      <Spin spinning={userDetailLoader}>
        <div className="user-detail">
          <Row gutter={16}>
            <Col xl={17} md={24} sm={24} xxl={17}>
              <Card
                className="big-card"
                title={
                  <>
                    <Typography.Title level={4}>
                      <div>
                        View User<span className="text-line"></span>
                      </div>
                    </Typography.Title>
                  </>
                }
                bordered={false}
              >
                <div className="user-detail">
                  <Row gutter={16}>
                    <Col span={24}>
                      <Descriptions
                        title=""
                        layout="horizontal"
                        colon={true}
                        bordered
                        column={1}
                      >
                        {userdata &&
                          userdata.length > 0 &&
                          userdata.map((item, index) => (
                            <Descriptions.Item
                              key={index}
                              label={item.title}
                              style={{
                                background:
                                  index % 2 !== 0 ? "#FFFFFF" : "#E2EEFF",
                              }} // Set color dynamically or default to black
                            >
                              {item.description ? item.description : "-"}
                            </Descriptions.Item>
                          ))}
                      </Descriptions>
                    </Col>
                  </Row>
                </div>
                <br />
                {/* <Col span={10}> */}
                {/* <Button  htmlType="button" size="large" onClick={() => deleteUser(userDetailData._id)}> */}
                <Link to={`/update-user/${userDetailData.id}`}>
                  <Button
                    htmlType="button"
                    size="large"
                    style={{ marginRight: 10 }}
                  >
                    Update
                  </Button>
                </Link>
                <Popconfirm
                  title="Are you sure you want to delete this User?"
                  onConfirm={() => confirm(userDetailData.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button htmlType="button" size="large">
                    Delete
                  </Button>
                </Popconfirm>
                {/* </Col> */}
              </Card>
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetailLoader: state.users.userDetailLoader,
    userDetailData: state.users.userDetailData,
  };
};

export default connect(mapStateToProps, {
  actionGetUserDetail,
  actionDeleteUser,
})(UserDetail);
