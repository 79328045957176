import {
  CalendarOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  FieldTimeOutlined,
  FileAddOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Popover,
  Row,
  Select,
  Space,
  Spin,
  Steps,
  Table,
  Tag,
  TimePicker,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import moment from "moment/moment";

import React, { Fragment, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import status1 from "../../assets/img/icon/status1.svg";
import {
  actionCreateTimingResolution,
  actionDeleteTicket,
  actionGetAllTicketLog,
  actionGetAllTickets,
  actionGetAllTimingResolution,
  actionGetTicketDetail,
  actionGetTicketLog,
  actionGetTickets,
  actionPage,
  actionUpdateLevelStatus,
  actionUpdatePriorityStatus,
  actionUpdateTicketSeverity,
  actionUpdateTicketStatus,
} from "../../store/actions/ticketAction";
import {
  actionAddTime,
  actionDeleteTime,
  actionGetTimeAll,
  actionGetTimeDetail,
  actionUpdateTime,
} from "../../store/actions/timeAction";
import { actionGetUsersAllData } from "../../store/actions/userAction";
import { mode } from "../config/web-config";

dayjs.locale("en"); // Set the locale to English
const { Option } = Select;
const { RangePicker } = DatePicker;

// const { Text, Link } = Typography;

const TicketTable = (props) => {
  const navigate = useNavigate();
  const {
    actionGetTickets,
    clickedData,
    getTimedLoader,
    ticketsLoader,
    actionGetAllTimingResolution,
    actionGetUsersAllData,
    actionCreateTimingResolution,
    allUsersData,
    ticketsData,
    userProfileData,
    pages,
    actionPage,
    ticketsCount,
    getAllticketsData,
    createTimingLoading,
    ticketLevelUpdateLoader,
    severityUpdateLoader,
    actionUpdateTicketSeverity,
    actionUpdateLevelStatus,
    getAllticketsLoader,
    getTimedData,
    actionUpdatePriorityStatus,
    getAllTicketLogLoader,
    getAllTicketLogData,
    priorityStatusUpdateLoader,
    actionGetAllTickets,
    ticketLogLoader,
    actionUpdateTicketStatus,
    ticketStatusUpdateLoader,
    actionGetTicketLog,
    actionGetAllTicketLog,
    ticketLogData,
    actionGetTimeAll,
    actionAddTime,
    addTimeLoader,
    timeData,
    actionDeleteTime,
    deleteTimeLoading,
    actionUpdateTime,
    actionGetTimeDetail,
    timeDetailData,
    timeCount,
  } = props;
  const { ticketAge } = useParams();
  // const mode = "PROD";
  // const mode = "DEV";

  const [form] = Form.useForm();
  // const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const { ticketAgeing } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const paramValue = urlParams.get("statusList");
  const sDate = urlParams.get("startDate");
  const eDate = urlParams.get("endDate");
  const levelParams = urlParams.get("level");
  const [search, setSearch] = useState();
  const [status, setStatus] = useState();
  const [sortedInfo, setSortedInfo] = useState({});
  const [rateIssue, setRateIssue] = useState();
  const [srSeverity, setSrseverity] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [currentPage, setCurrentPage] = useState(pages);
  const [dataList, setDataList] = useState();
  const [modal2Open, setModal2Open] = useState(false);
  const [ticketData, setTicketData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [TimeStartDate, setTimeStartDate] = useState("");
  const [TimeEndDate, setTimeEndDate] = useState("");
  const [Date, setDate] = useState([]);
  const [TicketId, setTicketId] = useState("");
  const [Minute, setMinute] = useState("");
  const [Hours, setHours] = useState("");
  const [lastNames, setLastNames] = useState([""]); // Initialize with one empty last name

  const [UserState, setUserState] = useState({
    uId: "",
    label: "",
  });

  const [popoverVisible, setPopoverVisible] = useState([]);

  const [open, setOpen] = useState([]);
  const [totalhrs, setTotalhrs] = useState("");
  const [timeDetailUpdate, setTimeDetailUpdate] = useState(false);
  const [timeDetailDataId, setTimeDetailDataId] = useState("");
  const [currentModalTicketData, setCurrentModalTicketData] = useState({});
  const [level, setLevel] = useState();

  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const showModal = (id) => {
    actionGetUsersAllData(0, 10);
    setIsModalOpen(true);
    setTicketId(id);
    actionGetTimeAll(id);
    console.log({ userProfileData });
    if (userProfileData && userProfileData.roles === "AGENT") {
      form2.setFieldsValue({
        agent: userProfileData.id,
      });
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form2.resetFields();
  };

  const d = {
    items: getTimedData?.map((item) => {
      return {
        agent: item?.agent?.id,
        date: dayjs(item.date),

        dates:
          (item.times || []).map((time) => {
            const startTime = dayjs(time.start_time, "HH:mm");
            const endTime = dayjs(time.end_time, "HH:mm");

            // Calculate the time difference
            const duration = endTime.diff(startTime, "minutes"); // 'minutes' can be replaced with other units like 'hours'

            // Calculate hours and minutes from the duration
            const hours = Math.floor(duration / 60);
            const minutes = duration % 60;

            return {
              times: [
                time.start_time ? dayjs(time.start_time, "HH:mm") : "",
                time.end_time ? dayjs(time.end_time, "HH:mm") : "",
              ],
              first: time.diff,
            };
          }) || [],
      };
    }),
  };

  const onFinishSearch = (value) => {
    setCurrentPage(1);
    setSearch(value.keyword ? value.keyword : "");
    // setSearch(value.searchdes ? value.searchdes : "");
    setStatus(value.status ? value.status : "");
    setRateIssue(value.rate_issue ? value.rate_issue : "");
    setSrseverity(value.sr_severity ? value.sr_severity : "");
    setLevel(value.level ? value.level : "");
    actionGetTickets(
      0,
      10,
      value.keyword ? value.keyword : "",
      // value.status ? value.status : "",
      paramValue ? paramValue : value.status ? value.status : "",
      value.rate_issue ? value.rate_issue : "",
      value.sr_severity ? value.sr_severity : "",
      ticketAgeing ? ticketAgeing : "",
      // clickedData ? clickedData : "",
      startDate ? startDate : "",
      endDate ? endDate : "",
      levelParams ? levelParams : value.level ? value.level : ""
    );
    actionGetAllTicketLog(
      value.keyword ? value.keyword : "",
      // value.status ? value.status : "",
      paramValue ? paramValue : value.status ? value.status : "",
      value.rate_issue ? value.rate_issue : "",
      value.sr_severity ? value.sr_severity : "",
      startDate ? startDate : "",
      endDate ? endDate : "",
      levelParams ? levelParams : value.level ? value.level : ""
    );

    actionGetAllTickets(
      0,
      10,
      value.keyword ? value.keyword : "",
      // value.status ? value.status : "",
      paramValue ? paramValue : value.status ? value.status : "",
      value.rate_issue ? value.rate_issue : "",
      value.sr_severity ? value.sr_severity : "",
      startDate ? startDate : "",
      endDate ? endDate : "",
      levelParams ? levelParams : value.level ? value.level : ""
    );
  };

  const handleReset = () => {
    form.resetFields();
    setSearch("");
    setStatus("");
    setRateIssue("");
    setSrseverity("");
    setStartDate("");
    setEndDate("");
    setLevel("");

    actionGetTickets(0, 10);
  };

  useEffect(() => {
    actionGetTickets(
      currentPage - 1,
      10,
      search ? search : "",
      // status ? status : "",
      paramValue ? paramValue : status ? status : "",
      rateIssue ? rateIssue : "",
      srSeverity ? srSeverity : "",
      ticketAgeing ? ticketAgeing : "",
      // clickedData ? clickedData : "",
      sDate ? sDate : startDate ? startDate : "",
      eDate ? eDate : endDate ? endDate : "",
      levelParams ? levelParams : level ? level : ""
    );
    actionGetAllTicketLog(
      search ? search : "",
      paramValue ? paramValue : status ? status : "",
      rateIssue ? rateIssue : "",
      srSeverity ? srSeverity : "",
      sDate ? sDate : startDate ? startDate : "",
      eDate ? eDate : endDate ? endDate : "",
      levelParams ? levelParams : level ? level : ""
    );
    actionGetAllTickets(
      0,
      10,
      search ? search : "",
      // status ? status : "",
      paramValue ? paramValue : status ? status : "",
      rateIssue ? rateIssue : "",
      srSeverity ? srSeverity : "",
      sDate ? sDate : startDate ? startDate : "",
      eDate ? eDate : endDate ? endDate : "",
      levelParams ? levelParams : level ? level : "",
      "YES"
    );
  }, []);

  const modalOpen = (id) => {
    setModal2Open(true);
    actionGetTicketLog(id);
  };

  const onRangeChange = (data, dateString) => {
    if (data) {
      setStartDate(dateString[0]);
      setEndDate(dateString[1]);
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  const logData = [];
  ticketLogData &&
    ticketLogData.length > 0 &&
    ticketLogData.map((data, index) => {
      logData.push({
        key: index,
        title:
          data.created_at && dayjs(data.created_at).format("YYYY/MM/DD h:mm A"),
        description: data.log,
      });
    });

  const onChange = (id, data) => {
    actionUpdateTicketStatus(id, data);
  };

  const handlePriorityChange = (id, data) => {
    actionUpdatePriorityStatus(id, data);
  };

  const handleLevelChange = (id, data) => {
    actionUpdateLevelStatus(id, data);
  };

  const data = [];
  const items = [
    {
      key: "1",
      label: "Normal",
      value: "Normal",
    },
    {
      key: "2",
      label: "Medium",
      value: "Medium",
    },
    {
      key: "3",
      label: "High",
      value: "High",
    },
    {
      key: "4",
      label: "Very High",
      value: "Very High",
    },
    {
      key: "6",
      label: "Urgent",
      value: "Urgent",
    },
    {
      key: "7",
      label: "Registered",
      value: "REGISTERED",
    },

    {
      key: "8",
      label: "In Process",
      value: "IN_PROCESS",
    },

    {
      key: "9",
      label: "Await Business Confirmation",
      value: "AWAITING_BUSINESS_CONFIRMATION",
    },
    {
      key: "10",
      label: "Closed",
      value: "CLOSED",
    },
    {
      key: "11",
      label: "On Hold By Business",
      value: "ON_HOLD_BY_BUSINESS",
    },
    {
      key: "12",
      label: "On Hold By Dev Team",
      value: "ON_HOLD_BY_ADMIN",
    },
    {
      key: "13",
      label: "Normal",
      value: "Normal",
    },
    {
      key: "14",
      label: "Medium",
      value: "Medium",
    },
    {
      key: "15",
      label: "Complex",
      value: "Complex",
    },
  ];

  const [selectedDates, setSelectedDates] = useState([]);

  const onClick = (key, id) => {
    const selected = items.find((item) => item.key === key.key);
    actionUpdatePriorityStatus(id, selected.value);
  };

  // const handleClickStatus = (key, id) => {
  //   const selected = items.find((item) => item.key === key.key);
  //   actionUpdateTicketStatus(id, selected.value);
  // };
  const handleClickStatus = (key, id) => {
    const selected = items.find((item) => item.key === key.key);
    const currentModalTicket = ticketsData.find(
      (item) => item.ticket_id === id
    );
    setCurrentModalTicketData(currentModalTicket);

    if (selected.value === "CLOSED" && currentModalTicket.status !== "CLOSED") {
      // Open the modal
      showTicketClosedModal(true);
    } else {
      actionUpdateTicketStatus(id, selected.value);
    }
  };

  const truncateHtml = (html, maxLength) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    const text = doc.body.textContent || "";
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  // console.log(items.slice(-3));

  const handleConfirm = (id) => {
    console.log({ id });
    // actionUpdateTicketStatus(id, "CLOSED");
  };

  const handleClickSeverity = (key, id) => {
    const selected = items.find((item) => item.key === key.key);
    actionUpdateTicketSeverity(id, selected.value);
  };

  // useMemo(() => {
  ticketsData &&
    ticketsData.length > 0 &&
    ticketsData.map((e, index) =>
      data.push({
        key: index,
        timestamp: dayjs(e.created_at).format("MM/DD/YYYY h:mm:ss A"),
        id: e.ticket_id,
        title: (
          <div style={{ width: 150, margin: "auto" }}>
            {" "}
            {e.title
              ? e.title.length > 50
                ? e.title.slice(0, 50) + "..."
                : e.title
              : "-"}
          </div>
        ),
        service_request_number: (
          <Link to={`/view-ticket/${e.ticket_id}`} className="link">
            {e.service_request_number}
          </Link>
        ),
        requester_name: e.user.first_name
          ? e.user.first_name + " " + e.user.last_name
          : "-",

        description: <div>{truncateHtml(e.description, 60)}</div>,

        email: e.email && e.email.toLowerCase(),
        rate_issuue: (
          <>
            <div id="dropw">
              {userProfileData && userProfileData.roles == "BUSINESS USER" ? (
                <Tag
                  color={
                    e.rate_issuue == "Normal"
                      ? "blue"
                      : e.rate_issuue == "Medium"
                      ? "warning"
                      : e.rate_issuue == "High"
                      ? "orange"
                      : e.rate_issuue == "Very High"
                      ? "error"
                      : "purple"
                  }
                >
                  {e.rate_issuue}
                </Tag>
              ) : (
                <Dropdown
                  menu={{
                    items: items.slice(0, 5),
                    onClick: (key) => onClick(key, e.ticket_id),
                  }}
                  placement="bottom"
                  arrow={{
                    pointAtCenter: true,
                  }}
                >
                  {/* <Tag>bottom</Tag> */}
                  <a onClick={(e) => e.preventDefault()}>
                    <Tag
                      className="cursor-pointer"
                      color={
                        e.rate_issuue == "Normal"
                          ? "blue"
                          : e.rate_issuue == "Medium"
                          ? "warning"
                          : e.rate_issuue == "High"
                          ? "orange"
                          : e.rate_issuue == "Very High"
                          ? "error"
                          : "purple"
                      }
                    >
                      {e.rate_issuue}
                    </Tag>
                  </a>
                </Dropdown>
              )}
            </div>
          </>
        ),
        level: (
          <>
            <div id="dropw">
              {userProfileData && userProfileData.roles !== "BUSINESS USER" ? (
                <Select
                  loading={ticketLevelUpdateLoader}
                  disabled={ticketLevelUpdateLoader}
                  size="small"
                  value={e.level}
                  name="level"
                  onChange={(data) => handleLevelChange(e.ticket_id, data)}
                  placeholder="Level Of Ticket"
                  className="custom-select"
                >
                  <Option value="Level 1">Level 1</Option>
                  <Option value="Level 2">Level 2</Option>
                  <Option value="Level 3">Level 3</Option>
                  <Option value="Level 4">Level 4</Option>
                  <Option value="CR">CR</Option>
                </Select>
              ) : (
                e.level
              )}
            </div>{" "}
          </>
        ),
        areaofconcernName: e.areaofconcernName,
        typesofissue: e.typesofissue,

        resolve_note: e.resolve_note,
        knowledgeBase: e.knowledgeBase,
        primary_troubleshooting_description:
          e.primary_troubleshooting_description,
        submission_date: dayjs(e.created_at).format("DD/MM/YYYY h:mm:ss A"),
        // submission_time: dayjs(e.created_at).format("h:mm:ss A"),
        updated_at: dayjs(e.updated_at).format("DD/MM/YYYY h:mm:ss A"),
        status: (
          <>
            {userProfileData && userProfileData.roles == "BUSINESS USER" ? (
              <Tag
                color={
                  e.status == "REGISTERED"
                    ? "blue"
                    : e.status == "IN_PROCESS"
                    ? "orange"
                    : e.status == "AWAITING_BUSINESS_CONFIRMATION"
                    ? "warning"
                    : e.status == "ON_HOLD_BY_BUSINESS"
                    ? "purple"
                    : e.status == "ON_HOLD_BY_ADMIN"
                    ? "magenta"
                    : "green"
                }
              >
                {e.status.length > 10
                  ? e.status.slice(0, 15) + "..."
                  : e.status}
              </Tag>
            ) : (
              <Dropdown
                menu={{
                  items: items.slice(5, 11),
                  onClick: (key) => handleClickStatus(key, e.ticket_id),
                }}
                placement="bottom"
                arrow={{
                  pointAtCenter: true,
                }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Tag
                    className="cursor-pointer"
                    color={
                      e.status == "REGISTERED"
                        ? "blue"
                        : e.status == "IN_PROCESS"
                        ? "orange"
                        : e.status == "AWAITING_BUSINESS_CONFIRMATION"
                        ? "warning"
                        : e.status == "ON_HOLD_BY_BUSINESS"
                        ? "purple"
                        : e.status == "ON_HOLD_BY_ADMIN"
                        ? "magenta"
                        : "green"
                    }
                  >
                    {e.status.length > 10
                      ? e.status.slice(0, 15) + "..."
                      : e.status}
                  </Tag>
                </a>
              </Dropdown>
              // <Tooltip title={e.status}>

              // </Tooltip>
            )}
          </>
        ),
        sr_severity: (
          <>
            {userProfileData && userProfileData.roles == "BUSINESS USER" ? (
              <Tag
                color={
                  e.sr_severity === "Normal"
                    ? "green"
                    : e.sr_severity === "Medium"
                    ? "orange"
                    : "red"
                }
              >
                {e.sr_severity.length > 10
                  ? e.sr_severity.slice(0, 15) + "..."
                  : e.sr_severity}
              </Tag>
            ) : (
              <Dropdown
                menu={{
                  items: items.slice(-3),
                  onClick: (key) => handleClickSeverity(key, e.ticket_id),
                }}
                placement="bottom"
                arrow={{
                  pointAtCenter: true,
                }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Tag
                    className="cursor-pointer"
                    color={
                      e.sr_severity === "Normal"
                        ? "green"
                        : e.sr_severity === "Medium"
                        ? "orange"
                        : "red"
                    }
                  >
                    {e.sr_severity.length > 10
                      ? e.sr_severity.slice(0, 15) + "..."
                      : e.sr_severity}
                  </Tag>
                </a>
              </Dropdown>
            )}
          </>
        ),
        primary_troubleshooting:
          e.primary_troubleshooting === "YES" ? (
            <div className="flex justify-center items-center m-auto">
              <p>{e.primary_troubleshooting}</p>
              <p className="mb-1.5 ml-1">
                <Popover
                  className="popover-ticket"
                  content={<div>{e.primary_troubleshooting_description}</div>}
                  trigger="click"
                >
                  <InfoCircleOutlined />
                </Popover>
              </p>
            </div>
          ) : (
            e.primary_troubleshooting
          ),

        attachemnets:
          e.attachemnets && e.attachemnets.length > 0 ? (
            <div>
              {/* Map PDF attachments */}
              {e.attachemnets
                .filter((item) => item.toLowerCase().endsWith(".pdf"))
                .map((item, index) => (
                  <div className="mr-5 mt-2" key={index}>
                    <a
                      style={{ fontSize: 13, whiteSpace: "nowrap" }}
                      href={item}
                      target="_blank"
                      className="link-tag hover:underline text-sm hover:text-black-600 flex items-center"
                      rel="noopener noreferrer"
                      download
                    >
                      <DownloadOutlined style={{ marginRight: "5px" }} />
                      {item.split("/").pop()}
                    </a>
                  </div>
                ))}

              {/* Map Excel, Word, and other attachments */}
              {e.attachemnets
                .filter((item) => {
                  const lowercaseItem = item.toLowerCase();
                  const fileExtension = lowercaseItem.split(".").pop();
                  return ["xlsx", "xls", "csv", "doc", "docx"].includes(
                    fileExtension
                  );
                })
                .map((item, index) => (
                  <div className="mr-5 mt-2" key={index}>
                    <a
                      style={{ fontSize: 13, whiteSpace: "nowrap" }}
                      href={item}
                      target="_blank"
                      className="link-tag hover:underline text-sm hover:text-black-600 flex items-center"
                      rel="noopener noreferrer"
                    >
                      <DownloadOutlined style={{ marginRight: "5px" }} />

                      {item.split("/").pop()}
                    </a>
                  </div>
                ))}

              {e.attachemnets.filter((item) => {
                const lowercaseItem = item.toLowerCase();
                const fileExtension = lowercaseItem.split(".").pop();
                return ["jpg", "jpeg", "png"].includes(fileExtension);
              }).length > 0 && (
                <div className="mr-5 mt-2">
                  <Image.PreviewGroup
                    items={
                      e.attachemnets &&
                      e.attachemnets.filter((item) => {
                        const lowercaseItem = item.toLowerCase();
                        const fileExtension = lowercaseItem.split(".").pop();
                        return ["jpg", "jpeg", "png"].includes(fileExtension);
                      })
                    }
                  >
                    <Badge
                      style={{ cursor: "pointer" }}
                      offset={[-0, 5]}
                      count={
                        e.attachemnets &&
                        e.attachemnets.filter((item) => {
                          const lowercaseItem = item.toLowerCase();
                          const fileExtension = lowercaseItem.split(".").pop();
                          return ["jpg", "jpeg", "png"].includes(fileExtension);
                        }).length
                      }
                      color="grey"
                      size="medium"
                      title="Images"
                    >
                      <Image
                        preview={{ mask: <EyeOutlined /> }}
                        width={70}
                        height={70}
                        style={{
                          objectFit: "contain",
                          maxWidth: "100%",
                          maxHeight: "100%",
                        }}
                        src={
                          e.attachemnets.filter((item) => {
                            const lowercaseItem = item.toLowerCase();
                            const fileExtension = lowercaseItem
                              .split(".")
                              .pop();
                            return ["jpg", "jpeg", "png"].includes(
                              fileExtension
                            );
                          })[0]
                        }
                      />
                    </Badge>
                  </Image.PreviewGroup>
                </div>
              )}
            </div>
          ) : (
            // Display "No attachments available" when no attachments are available
            <p>No attachments available</p>
          ),
      })
    );

  useMemo(() => setDataList(data), [ticketsData]);

  const onClickPaginationNumber = (e, filter, sorter) => {
    setSortedInfo(sorter);
    actionGetTickets(
      e.current - 1,
      e.pageSize,
      search,
      // status,
      // status ? status : "",
      paramValue ? paramValue : status ? status : "",
      rateIssue,
      srSeverity,
      ticketAgeing ? ticketAgeing : "",
      // clickedData ? clickedData : "",
      sDate ? sDate : startDate ? startDate : "",
      eDate ? eDate : endDate ? endDate : "",
      level ? level : ""
    );
    setCurrentPage(e.current);
    actionPage(e.current);
  };

  const columns = [
    {
      title: "Ticket Number",
      dataIndex: "service_request_number",
      key: "service_request_number",
      width: 150,
    },
    {
      title: (
        <div className="flex justify-center items-center m-auto">
          <CalendarOutlined />
          <div className=" ml-1 flex justify-center items-center ">
            <Tooltip
              className="popover-ticket flex justify-center items-center "
              title={<div className="text-white">Date of Submission</div>}
              trigger="hover"
            >
              <button>DOS</button>
            </Tooltip>
          </div>
        </div>
      ),
      dataIndex: "submission_date",
      key: "submission_date",
      width: 180,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 200,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 200,
    },

    {
      title: "Priority",
      dataIndex: "rate_issuue",
      key: "rate_issuue",
      width: 120,
    },
    {
      title: "Category",
      dataIndex: "areaofconcernName",
      key: "areaofconcernName",
      width: 120,
    },
    {
      title: "Requester Name",
      dataIndex: "requester_name",
      key: "requester_name",
      width: 150,
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      width: 120,
    },

    {
      title: "SR Severity",
      dataIndex: "sr_severity",
      key: "sr_severity",
      width: 120,
    },
    {
      title: "Requester Email",
      dataIndex: "email",
      key: "email",
      width: 200,
    },

    {
      title: "Troubleshooting",
      dataIndex: "primary_troubleshooting",
      key: "primary_troubleshooting",
      width: 150,
    },
    {
      title: "Type of Issue",
      dataIndex: "typesofissue",
      key: "typesofissue",
      width: 150,
    },

    {
      title: (
        <div className="flex justify-center items-center m-auto">
          <p className="mb-1.5 mr-1">
            <CalendarOutlined />
          </p>
          <p>Updated Date</p>
        </div>
      ),
      dataIndex: "updated_at",
      key: "updated_at",
      width: 180,
    },
    {
      title: "Attachments",
      dataIndex: "attachemnets",
      key: "attachemnets",
      width: 150,
    },
    {
      title: "Status",
      fixed: "right",
      dataIndex: "status",
      key: "status",
      width: 150,
    },

    {
      title: "Action",
      fixed: "right",
      align: "center",
      width: "80px",
      key: "action",
      render: (item) => (
        <>
          <Space size="middle" style={{ display: "flex" }}>
            {ticketAgeing || levelParams ? (
              <a
                href={`/view-ticket/${item.id}`}
                // onClick={(id) => showModal(item.id)}
                style={{ color: "black" }}
              >
                <Tooltip placement="top" title={"View Ticket"}>
                  <EyeOutlined
                    style={{
                      color: "#265BA6",
                      fontSize: "18px",
                      // marginRight: 5,
                    }}
                  />
                </Tooltip>
              </a>
            ) : (
              <Link
                to={`/view-ticket/${item.id}`}
                // onClick={(id) => showModal(item.id)}
                style={{ color: "black" }}
              >
                <Tooltip placement="top" title={"View Ticket"}>
                  <EyeOutlined
                    style={{
                      color: "#265BA6",
                      fontSize: "18px",
                      // marginRight: 5,
                    }}
                  />
                </Tooltip>
              </Link>
            )}

            <a
              // to={`/view-ticket/${item.id}`}
              onClick={(id) => modalOpen(item.id)}
              style={{ color: "black" }}
            >
              <Tooltip placement="top" title={"View Logs"}>
                <SolutionOutlined
                  style={{
                    color: "#265BA6",
                    fontSize: "18px",
                    // marginRight: 5,
                  }}
                />
              </Tooltip>
            </a>
            {userProfileData && userProfileData.roles !== "BUSINESS USER" && (
              <Link to={`/edit-ticket/${item.id}`} style={{ color: "black" }}>
                <Tooltip placement="top" title={"Edit Ticket"}>
                  <EditOutlined
                    style={{
                      color: "#265BA6",
                      fontSize: "18px",
                      // marginRight: 5,
                    }}
                  />
                </Tooltip>
              </Link>
            )}

            {userProfileData && userProfileData.roles !== "BUSINESS USER" && (
              <Tooltip placement="top" title={"Resolution Time"}>
                <FieldTimeOutlined
                  onClick={() => showModal(item.id)}
                  className="cursor-pointer"
                  style={{
                    color: "#265BA6",
                    fontSize: "18px",
                  }}
                />
              </Tooltip>
            )}

            {userProfileData &&
              userProfileData.roles !== "BUSINESS USER" &&
              item.resolve_note && (
                <a
                  href={
                    item.resolve_note && item.knowledgeBase.title
                      ? `/update-knowlegde/${item.knowledgeBase.kb_id}`
                      : `/create-knowledge/${item.id}`
                  }
                  // onClick={(id) => showModal(item.id)}
                  style={{ color: "black" }}
                >
                  <Tooltip
                    placement="top"
                    title={
                      item.resolve_note && item.knowledgeBase.title
                        ? "Edit KB"
                        : "Add KB"
                    }
                  >
                    {item.resolve_note && item.knowledgeBase.title ? (
                      <FileTextOutlined
                        style={{
                          color: "#265BA6",
                          fontSize: "18px",
                          // marginRight: 5,
                        }}
                      />
                    ) : (
                      <FileAddOutlined
                        style={{
                          color: "#265BA6",
                          fontSize: "18px",
                          // marginRight: 5,
                        }}
                      />
                    )}
                  </Tooltip>
                </a>
              )}
          </Space>
        </>
      ),
    },
  ];

  const htmlToPlainText = (html) => {
    // Create a temporary element to parse the HTML
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;

    // Get the plain text content from the temporary element
    let plainText = tempElement.textContent || tempElement.innerText;

    // Clean up special HTML entities
    const entities = {
      "&lt;": "<",
      "&gt;": ">",
      "&amp;": "&",
      "&quot;": '"',
      "&apos;": "'",
      "&nbsp;": " ",
      // Add more entities as needed
    };

    for (const entity in entities) {
      plainText = plainText.replace(new RegExp(entity, "g"), entities[entity]);
    }

    // Remove any extra leading/trailing white spaces
    plainText = plainText.trim();

    return plainText;
  };

  const handleResoltion = (value, option, index) => {
    // Close the Popover after a value is selected
    setPopoverVisible((prevState) => {
      const updatedVisibility = [...prevState];
      updatedVisibility[index] = false;
      return updatedVisibility;
    });
  };

  const exportToExcel = () => {
    const modifiedData =
      getAllticketsData &&
      getAllticketsData.length > 0 &&
      getAllticketsData.map((item) => ({
        "Ticket No": item.service_request_number,
        Email: item.email,
        title: item && item.title ? item.title : "-",
        "On Behalf Name": item.on_behalf_of_name ? item.on_behalf_of_name : "-",
        "On Behalf Email": item.on_behalf_of_email
          ? item.on_behalf_of_email
          : "-",
        "Submission Date": dayjs(item.created_at).format("DD/MM/YYYY"),
        "Submission Time": dayjs(item.created_at).format("hh:mm:ss A"),
        Description: htmlToPlainText(item.description),
        "Updated At": dayjs(item.updated_at).format("DD/MM/YYYY hh:mm:ss A"),
        "Requester Name":
          item.user && item.user.first_name
            ? item.user.first_name + " " + item.user.last_name
            : "-",
        "Primary Troubleshooting": item.primary_troubleshooting,
        "Primary Troubleshooting Description":
          item.primary_troubleshooting_description,
        "Area of concern Name": item.areaofconcernName,
        Status: item.status,
        "Level Of Ticket": item.level,
        "Rate Issuue": item.rate_issuue,
        "Type Of Issue": item.typesofissue,
        "SR Severity": item.sr_severity ? item.sr_severity : "-",
        "Resolution URL": item.knowledgeBase.kb_id
          ? {
              v:
                mode === "DEV"
                  ? `https://tickets.vytech.co/knowledge-base/${item.knowledgeBase.kb_id}`
                  : `http://rdltickets.vytechenterprise.com/knowledge-base/${item.knowledgeBase.kb_id}`, // Displayed text for the hyperlink
              l: {
                Target:
                  mode === "DEV"
                    ? `https://tickets.vytech.co/knowledge-base/${item.knowledgeBase.kb_id}`
                    : `http://rdltickets.vytechenterprise.com/knowledge-base/${item.knowledgeBase.kb_id}`,
                Tooltip: "Click to open link",
              },
              s: {
                font: { color: { rgb: "0000FF" } }, // Blue color
              },
            }
          : "-",
      }));
    const worksheet = XLSX.utils.json_to_sheet(modifiedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "SAP_Ticket_list.xlsx");
  };

  const exportToExcelNew = () => {
    const modifiedData =
      getAllticketsData &&
      getAllticketsData.length > 0 &&
      getAllticketsData
        .filter(
          (item) =>
            item.total_estimated_hours === null &&
            item.total_working_time === "00:00"
        )
        .map((item) => ({
          "Ticket No": item.service_request_number,
          Email: item.email,
          title: item && item.title ? item.title : "-",
          "On Behalf Name": item.on_behalf_of_name
            ? item.on_behalf_of_name
            : "-",
          "On Behalf Email": item.on_behalf_of_email
            ? item.on_behalf_of_email
            : "-",
          "Submission Date": dayjs(item.created_at).format("DD/MM/YYYY"),
          "Submission Time": dayjs(item.created_at).format("hh:mm:ss A"),
          Description: htmlToPlainText(item.description),
          "Updated At": dayjs(item.updated_at).format("DD/MM/YYYY hh:mm:ss A"),
          "Requester Name":
            item.user && item.user.first_name
              ? item.user.first_name + " " + item.user.last_name
              : "-",
          "Primary Troubleshooting": item.primary_troubleshooting,
          "Primary Troubleshooting Description":
            item.primary_troubleshooting_description,
          "Area of concern Name": item.areaofconcernName,
          Status: item.status,
          "Level Of Ticket": item.level,
          "Rate Issuue": item.rate_issuue,
          "Type Of Issue": item.typesofissue,
          "SR Severity": item.sr_severity ? item.sr_severity : "-",
          "Resolution URL": item.knowledgeBase.kb_id
            ? {
                v:
                  mode === "DEV"
                    ? `https://tickets.vytech.co/knowledge-base/${item.knowledgeBase.kb_id}`
                    : `http://rdltickets.vytechenterprise.com/knowledge-base/${item.knowledgeBase.kb_id}`, // Displayed text for the hyperlink
                l: {
                  Target:
                    mode === "DEV"
                      ? `https://tickets.vytech.co/knowledge-base/${item.knowledgeBase.kb_id}`
                      : `http://rdltickets.vytechenterprise.com/knowledge-base/${item.knowledgeBase.kb_id}`,
                  Tooltip: "Click to open link",
                },
                s: {
                  font: { color: { rgb: "0000FF" } }, // Blue color
                },
              }
            : "-",
        }));

    console.log(modifiedData);
    const worksheet = XLSX.utils.json_to_sheet(modifiedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "SAP_Ticket_list.xlsx");
  };

  //const dataSourceLog = useMemo(() => getDataLogSource(logData), [logData]);

  // Function to export data to

  const exportLogToExcel = () => {
    const data =
      getAllTicketLogData &&
      getAllTicketLogData.map((data) => ({
        "Ticket No":
          data.ticket && data.ticket.service_request_number
            ? data.ticket.service_request_number
            : "-",

        Email:
          data.ticket && data.ticket.email
            ? data.ticket && data.ticket.email
            : "-",
        // "On Behalf Name": data.ticket.on_behalf_of_name ? data.ticket.on_behalf_of_name : "-",
        // "On Behalf Email": data.ticket.on_behalf_of_email ? data.ticket.on_behalf_of_email : "-",
        "Submission Date": dayjs(data.created_at).format("DD/MM/YYYY"),
        "Submission Time": dayjs(data.created_at).format("hh:mm:ss A"),
        Log: data.log ? data.log : "-",
        "Project Name": data.project ? data.project : "-",
        // "Requester Name": data.log ? data.log : "-",
        Category:
          data && data.areaofconcernName ? data && data.areaofconcernName : "-",
      }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet2");

    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "SAP_Ticket_log.xlsx");
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const agentData = [];
  allUsersData?.map((data, index) => {
    if (data.roles === "AGENT") {
      agentData.push({
        key: index,
        // name: data.first_name + " " + data.last_name,
        // email: data.email,
        label: data.first_name + " " + data.last_name,
        value: data.id,
        // value: data.id,
      });
    }
  });
  const [timeDifferences, setTimeDifferences] = useState([]);

  const totalHours = (value, e) => {
    var startTime = moment(e[0], "HH:mm");
    var endTime = moment(e[1], "HH:mm");
    var duration = moment.duration(endTime.diff(startTime));
    var hours = parseInt(duration.asHours().toString());
    var minutes = parseInt(duration.asMinutes().toString()) % 60;
    // Update state with the calculated values as an object
    // setHours({ hours, minutes });
    setHours(`${hours} hrs ${minutes} min`);
  };
  // const handleRangeChange = (subKey, Key, value, x, index) => {
  //   //console.log({ subKey, index });
  //   const startTime = x && x[0];
  //   const endTime = x && x[1];

  //   // Calculate time difference as you did in your code
  //   let calculatedTimeDiff = "0 hrs 0 min";
  //   if (startTime && endTime) {
  //     var startMoment = moment(startTime, "HH:mm");
  //     var endMoment = moment(endTime, "HH:mm");

  //     var duration = moment.duration(endMoment.diff(startMoment));
  //     var hours = parseInt(duration.asHours().toString());
  //     var minutes = parseInt(duration.asMinutes().toString()) % 60;

  //     calculatedTimeDiff = `${hours} hrs ${minutes} min`;
  //   }

  //   const items = form1.getFieldValue().items;

  //   // Update the "first" field value in each subitem
  //   const updatedItems = items.map((item, itemIndex) => {
  //     if (itemIndex === Key) {
  //       item.dates = item.dates.map((subItem, subItemIndex) => {
  //         /* console.log({ subItem, subItemIndex, subKey });
  //         console.log({ calculatedTimeDiff, itemIndex, Key }); */
  //         if (subItemIndex === index) {
  //           return {
  //             ...subItem,
  //             first: calculatedTimeDiff,
  //           };
  //         }
  //         return subItem;
  //       });
  //     }
  //     return item;
  //   });

  //   // Set the updated values back to the form
  //   form1.setFieldsValue({
  //     items: updatedItems,
  //   });

  //   // Update the time difference for the current item
  //   // setTimeDifferences((prevDifferences) => {
  //   //   const updatedDifferences = [...prevDifferences];
  //   //   updatedDifferences[index] = calculatedTimeDiff;
  //   //   return updatedDifferences;
  //   // });
  // };

  const handleFormSubmit = async () => {
    try {
      // Validate and submit the form data here
      await form2.validateFields();
      form2.submit();
    } catch (error) {
      console.error("Form validation failed:", error);
    }
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  // Function to calculate time difference

  const onFinishTiming = (values) => {
    console.log({ values });
    const request =
      values &&
      values.items.map((item) => {
        const agent = agentData.filter((d) => d.value === item.agent);

        return {
          // ticket: TicketId,
          // agent: item.agent,
          agent: item.agent,
          date: dayjs(item.date).format("YYYY-MM-DD"),
          times: item?.dates?.map((data) => ({
            start_time: dayjs(data.times[0].$d).format("HH:mm"),
            end_time: dayjs(data.times[1].$d).format("HH:mm"),
          })),
        };
      });

    const d = {
      ticket: TicketId,
      data: request,
    };

    actionCreateTimingResolution(d, setIsModalOpen, setTicketId);
  };

  const handleChange = (time, timeString, c) => {
    setTimeStartDate(timeString[0]);
    setTimeEndDate(timeString[1]);
  };

  const confirm = (timeData, id) => {
    actionDeleteTime(timeData, id);
  };

  const handleSetTimeData = (id) => {
    //actionGetTimeDetail(id);
    const timeDetailDataSingle = timeData.filter((time) => time.id === id)[0];
    setHours(timeDetailDataSingle.diff);
    setTimeDetailDataId(id);
    setTimeDetailUpdate(true);
    form2.setFieldsValue({
      date:
        (timeDetailDataSingle &&
          dayjs(timeDetailDataSingle.date, "YYYY-MM-DD")) ||
        "",
      agent: timeDetailDataSingle?.agent ? timeDetailDataSingle.agent.id : "",
      start_end_time: timeDetailDataSingle && [
        dayjs(timeDetailDataSingle?.start_time, "HH:mm"),
        dayjs(timeDetailDataSingle?.end_time, "HH:mm"),
      ],
    });
  };
  const handleCancelUpdateTime = () => {
    form2.resetFields();
    setTimeDetailUpdate(false);
    setHours("");
  };
  const handleUpdateTimeData = (e) => {
    const updateTimeRequest = {
      ticket: TicketId,
      agent: e.agent,
      date: dayjs(e.date).format("YYYY-MM-DD"),
      start_time: dayjs(e.start_end_time[0]).format("HH:mm"),
      end_time: dayjs(e.start_end_time[1]).format("HH:mm"),
    };
    actionUpdateTime(updateTimeRequest, timeDetailDataId);
    form2.resetFields();
    setHours("");
    setTimeDetailUpdate(false);
  };

  const columns1 = [
    {
      title: "Date",
      width: 90,
      dataIndex: "date",
      key: "date",
      render: (item) => <p>{dayjs(item).format("DD/MM/YYYY")}</p>,
      // fixed: 'left',
    },
    {
      title: "Agent",
      width: 100,
      dataIndex: "agent",
      key: "agent",
      render: (item) => <p>{`${item.first_name} ${item.last_name}`}</p>,
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      key: "1",
      width: 80,
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      key: "2",
      width: 80,
    },
    {
      title: "Total Time",
      width: 100,
      dataIndex: "diff",
      key: "totalTime",
      // fixed: 'left',
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 60,
      render: (item) => (
        <>
          <Space size="middle">
            {/* <Link to={`/edit-ticket/${item.id}`} style={{ color: "black" }}> */}
            <Tooltip placement="top" title={"Update"}>
              <EditOutlined
                style={{
                  color: "#265BA6",
                  fontSize: "18px",
                  // marginRight: 5,
                }}
                onClick={() => handleSetTimeData(item.id)}
              />
            </Tooltip>
            {/* </Link> */}
            <Tooltip placement="top" title={"Delete"}>
              <Popconfirm
                okButtonProps={
                  {
                    // loading: deleteTimeLoading,
                  }
                }
                placement="top"
                title="Are you sure you want to delete?"
                onConfirm={() => confirm({ ticket: TicketId }, item.id)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined
                  style={{
                    color: "#265BA6",
                    fontSize: "18px",
                    marginRight: 10,
                  }}
                />
              </Popconfirm>
            </Tooltip>
          </Space>
        </>
      ),
    },
  ];

  const handleAddtime = (e) => {
    const addTimeRequest = {
      ticket: TicketId,
      agent: e.agent,
      date: dayjs(e.date).format("YYYY-MM-DD"),
      start_time: dayjs(e.start_end_time[0]).format("HH:mm"),
      end_time: dayjs(e.start_end_time[1]).format("HH:mm"),
    };
    actionAddTime(addTimeRequest);
    form2.resetFields();
    setHours("");
  };

  const [cldOpen, setCldOpen] = useState(false);
  const showTicketClosedModal = () => {
    setCldOpen(true);
  };
  const handleTicketClosedOk = (key) => {
    actionUpdateTicketStatus(currentModalTicketData.ticket_id, "CLOSED");
    setTimeout(() => {
      setCldOpen(false);
    }, 1000);
  };
  const handleTicketClosedCancel = () => {
    setCldOpen(false);
  };

  return (
    <Fragment>
      <Typography.Title level={3} className="ml-3">
        Tickets
      </Typography.Title>
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
        items={[
          {
            title: (
              <Link
                to="/"
                className="bg-transparent breadcumb-link	 hover:bg-transparent	text-black-700 "
              >
                Home
              </Link>
            ),
          },
          {
            title: <p className="breadcumb-link">Tickets</p>,
          },
        ]}
      ></Breadcrumb>

      {ticketAgeing && (
        <Typography.Title className="	" level={5}>
          Filtered By : {ticketAgeing}
        </Typography.Title>
      )}
      {levelParams && (
        <Typography.Title className="	" level={5}>
          Filtered By : {levelParams}
        </Typography.Title>
      )}
      {paramValue && (
        <Typography.Title className="	" level={5}>
          Filtered By :{" "}
          {paramValue &&
            paramValue
              .replace(/_/g, " ")
              .toLowerCase()
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
        </Typography.Title>
      )}

      <Card
        className="main-card no-padding"
        title={
          !sDate && !paramValue && !eDate && !ticketAgeing && !levelParams ? (
            <>
              <Row align="middle" justify="">
                <Col xs={14} sm={14} md={14} lg={18} xl={20} xxl={17} align="">
                  {" "}
                  <Form
                    className="custom-form md:pl-3 sm:pl-8"
                    name="basic"
                    form={form}
                    layout="inline"
                    onFinish={onFinishSearch}
                    autoComplete="off"
                  >
                    <Form.Item name="keyword" className="custom-slect">
                      <Input
                        style={{ width: "100%" }}
                        autoFocus="none"
                        type={"text"}
                        allowClear
                        prefix={
                          <SearchOutlined className="site-form-item-icon text-white" />
                        }
                        placeholder="Search Here"
                      />
                    </Form.Item>

                    <Form.Item
                      name="status"
                      className="custom-slect   md:pt-1 lg:pt-0"
                    >
                      <Select
                        showSearch
                        className="user rate-select"
                        placeholder="Select Status"
                        optionFilterProp="children"
                        allowClear
                        onClear={() => setStatus("")}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={[
                          {
                            value: "REGISTERED",
                            label: "Registered",
                          },
                          {
                            value: "IN_PROCESS",
                            label: "In Process",
                          },
                          {
                            value: "AWAITING_BUSINESS_CONFIRMATION",
                            label: "Await Business Confirmation",
                          },
                          {
                            value: "CLOSED",
                            label: "Closed",
                          },
                          {
                            label: "On Hold By Business",
                            value: "ON_HOLD_BY_BUSINESS",
                          },
                          {
                            value: "ON_HOLD_BY_ADMIN",
                            label: "On Hold By Dev Team",
                          },
                        ]}
                      />
                    </Form.Item>

                    <Form.Item
                      name="level"
                      className="custom-slect   md:pt-1 lg:pt-0"
                    >
                      <Select
                        showSearch
                        className="user rate-select"
                        placeholder="Select Level"
                        optionFilterProp="children"
                        allowClear
                        onClear={() => setLevel("")}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={[
                          {
                            value: "Level 1",
                            label: "Level 1",
                          },
                          {
                            value: "Level 2",
                            label: "Level 2",
                          },
                          {
                            value: "Level 3",
                            label: "Level 3",
                          },
                          {
                            value: "Level 4",
                            label: "Level 4",
                          },
                          {
                            value: "CR",
                            label: "CR",
                          },
                        ]}
                      />
                    </Form.Item>

                    <Form.Item
                      name="rate_issue"
                      className="custom-slect  md:pt-1 lg:pt-0"
                    >
                      <Select
                        showSearch
                        className="user rate-select"
                        placeholder="Select Priority"
                        optionFilterProp="children"
                        allowClear
                        onClear={() => setRateIssue("")}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={[
                          {
                            value: "Normal",
                            label: "Normal",
                          },
                          {
                            value: "Medium",
                            label: "Medium",
                          },
                          {
                            value: "High",
                            label: "High",
                          },
                          {
                            value: "Very High",
                            label: "Very High",
                          },
                          {
                            value: "Urgent",
                            label: "Urgent",
                          },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item
                      name="sr_severity"
                      className="custom-slect severity  md:pt-1 lg:pt-0"
                    >
                      <Select
                        showSearch
                        className="user rate-select"
                        placeholder="Select SR severity"
                        optionFilterProp="children"
                        allowClear
                        onClear={() => setSrseverity("")}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={[
                          {
                            value: "Normal",
                            label: "Normal",
                          },
                          {
                            value: "Medium",
                            label: "Medium",
                          },
                          {
                            value: "Complex",
                            label: "Complex",
                          },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item name="date" className="custom-slect">
                      <RangePicker
                        defaultValue={
                          sDate && eDate ? [dayjs(sDate), dayjs(eDate)] : ""
                        }
                        onChange={onRangeChange}
                        className="range-date"
                      />
                    </Form.Item>

                    <Form.Item className="md:pt-1 lg:pt-0">
                      <Button
                        className="bg-white search-btn user"
                        type="primary"
                        htmlType="submit"
                      >
                        Search
                      </Button>
                    </Form.Item>
                    <Form.Item className="md:pt-1 lg:pt-0">
                      <Button
                        className="bg-white search-btn user"
                        type="primary"
                        // htmlType="submit"
                        onClick={handleReset}
                      >
                        Reset
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
                <Col
                  xs={10}
                  sm={10}
                  md={10}
                  lg={5}
                  xl={4}
                  xxl={7}
                  align="right"
                  className="pr-3 custom-dropdown-render"
                >
                  {userProfileData &&
                    userProfileData.roles !== "BUSINESS USER" && (
                      <Tooltip placement="top" title={"Create Ticket"}>
                        <Link to="/create-ticket">
                          <Button
                            // onClick={"/create-ticket"}
                            type="primary"
                            className="bg-white-btn plus-btn inline-flex items-center justify-center"
                            size={"large"}
                          >
                            <PlusOutlined />
                          </Button>
                        </Link>
                      </Tooltip>
                    )}

                  {/* <Tooltip placement="top" title={"Export"}> */}
                  <Dropdown.Button
                    className="bg-white-drop inline-flex items-center "
                    menu={{
                      items: [
                        {
                          key: 1,
                          label: (
                            <Typography.Text
                              onClick={exportToExcel}
                              type="primary"
                              className=" ml-2"
                              size={"large"}
                              style={{
                                borderRadius: 5,
                                margin: 0,
                                fontWeight: 700,
                                color: "black",
                              }}
                            >
                              Export tickets
                            </Typography.Text>
                          ),
                        },
                        {
                          key: 2,
                          label: (
                            <Typography.Text
                              //onClick={exportToExcel}
                              type="primary"
                              // loading={getAllTicketLogLoader}
                              disabled={getAllTicketLogLoader}
                              className=" ml-2"
                              size={"large"}
                              style={{
                                borderRadius: 5,
                                margin: 0,
                                fontWeight: 700,
                                color: "black",
                              }}
                              onClick={
                                !getAllTicketLogLoader && exportLogToExcel
                              }
                            >
                              Export Logs
                            </Typography.Text>
                          ),
                        },
                        {
                          key: 3,
                          label: (
                            <Typography.Text
                              ellipsis
                              //onClick={exportToExcel}
                              type="primary"
                              // loading={getAllTicketLogLoader}
                              // disabled={getAllTicketLogLoader}
                              className=" ml-2"
                              size={"large"}
                              style={{
                                borderRadius: 5,
                                margin: 0,
                                fontWeight: 700,
                                color: "black",
                              }}
                              onClick={
                                !getAllTicketLogLoader && exportToExcelNew
                              }
                            >
                              Export Non-Effort Hours Tickets
                            </Typography.Text>
                          ),
                        },
                      ],
                    }}
                  >
                    Actions
                  </Dropdown.Button>
                </Col>
              </Row>
            </>
          ) : (
            false
          )
        }
        bordered={false}
        style={{
          width: "100%",
          marginBottom: 40,
        }}
      >
        <Row>
          <Col span={24}>
            <Table
              bordered
              className="ticket-table"
              sticky={true}
              size="small"
              pagination={{
                total: ticketsCount,
                hideOnSinglePage: true,
                defaultCurrent: currentPage,
                current: currentPage,
              }}
              columns={columns}
              dataSource={dataList}
              loading={
                ticketsLoader ||
                ticketStatusUpdateLoader ||
                priorityStatusUpdateLoader ||
                ticketStatusUpdateLoader ||
                severityUpdateLoader
              }
              onChange={(e, filters, sorter) =>
                onClickPaginationNumber(e, filters, sorter)
              }
              scroll={{
                x: "max-content",
                //y: window.innerWidth < 1500 ? "55vh" : "60vh",
              }}
              rowClassName={(record) => {
                const status =
                  record?.status?.props?.children?.props?.children?.props
                    ?.children?.props?.children;
                const busiNessStatus =
                  record?.status?.props?.children?.props.children;

                const getStatusColor = () => {
                  const statusToCheck =
                    userProfileData && userProfileData.roles === "BUSINESS USER"
                      ? busiNessStatus
                      : status;

                  switch (statusToCheck) {
                    case "REGISTERED":
                      return "status-blue";
                    case "IN_PROCESS":
                      return "status-orange";
                    case "AWAITING_BUSINE...":
                      return "status-warning";
                    case "ON_HOLD_BY_BUSI...":
                      return "status-purple";
                    case "ON_HOLD_BY_ADMI...":
                      return "status-magenta";
                    default:
                      return "status-green";
                  }
                };

                return getStatusColor();
              }}
            />
          </Col>
        </Row>
        <Modal
          className="log-modal"
          title={
            // <Space align="middle">
            <Row align="middle">
              <Col span={12} className="flex items-center">
                <Image preview={false} src={status1} className="mr-3" />
                <Typography.Title level={4} className="pl-3">
                  Status
                </Typography.Title>
              </Col>
            </Row>
          }
          footer={null}
          centered
          open={modal2Open}
          onOk={() => setModal2Open(false)}
          onCancel={() => setModal2Open(false)}
        >
          <Spin spinning={ticketLogLoader}>
            <Steps
              progressDot
              current={ticketLogData.length}
              direction="vertical"
              items={logData}
            />
          </Spin>
        </Modal>
      </Card>

      <Modal
        width={630}
        className="time-modal"
        cancelButtonProps={false}
        open={isModalOpen}
        style={{ width: 600 }}
        okText={"Submit"}
        onOk={handleFormSubmit} // Handle form submission when "OK" is clicke
        footer={null}
        onCancel={handleCancel}
      >
        <Spin spinning={getTimedLoader}>
          <Card className="border-bottom-0 p-4">
            <Form
              layout="vertical"
              form={form2}
              onFinish={(e) => {
                timeDetailUpdate ? handleUpdateTimeData(e) : handleAddtime(e);
              }}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="agent"
                    // label="Agent:"
                    className="mb-2 "
                    rules={[
                      {
                        required: true,
                        message: "Please Select Agent",
                      },
                    ]}
                    // name={[field.name, "agent"]}
                  >
                    <Select
                      className="text-left-select"
                      optionFilterProp="children"
                      showSearch
                      style={{ Width: 250 }}
                      allowClear
                      filterOption={filterOption}
                      placeholder="Select Agent"
                      options={agentData}
                      disabled={
                        userProfileData && userProfileData.roles !== "ADMIN"
                      }
                      defaultValue={
                        userProfileData && userProfileData.roles === "AGENT"
                          ? userProfileData.id
                          : null
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    // label='Date:'
                    name="date"
                    className=" mb-2"
                    rules={[
                      {
                        required: true,
                        message: "Please Select date!",
                      },
                    ]}
                    // name={[field.name, "date"]}
                  >
                    <DatePicker
                      className="w-full"
                      // disabledDate={(current) => current && current < moment().startOf('year')}
                      // format={"YYYY-MM-DD"}
                      bordered={false}
                    />
                  </Form.Item>
                </Col>
                <Col span={18} className="px-0 ">
                  <Form.Item
                    // label='Start Date and End Date:'
                    name="start_end_time"
                    className="helloRange"
                    rules={[
                      {
                        required: true,
                        message: "Please Select time!",
                      },
                    ]}
                    // noStyle
                    // name={[subField.name, "times"]}
                  >
                    <TimePicker.RangePicker
                      showTime={{ format: "HH:mm" }}
                      bordered={false}
                      style={{ width: "100%" }}
                      showNow={true}
                      use12Hours={false}
                      format="HH:mm"
                      // onChange={(value, x) => {
                      //   // console.log({ index });
                      //   handleRangeChange(
                      //     // subField.key,
                      //     // field.key,
                      //     // value,
                      //     // x,
                      //     // index
                      //   );
                      // }}
                      onChange={totalHours}
                    />
                  </Form.Item>
                </Col>

                <Col span={6} align="middle">
                  <Form.Item>
                    <Input
                      disabled
                      style={{
                        background: "white",
                        color: "black",
                        cursor: "auto",
                        padding: "0 0px",
                        width: "90px",
                        paddingLeft: 0,
                        fontSize: "12px",
                        textAlign: "center",
                        border: "0.6px solid rgb(231 218 218)",
                        marginTop: "8px",
                      }}
                      placeholder="0 hrs 0 min"
                      bordered={false}
                      value={Hours}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} className="h-7 text-end">
                  <Form.Item>
                    {timeDetailUpdate ? (
                      <Button
                        className="w-[110px] ml-auto mr-2"
                        onClick={handleCancelUpdateTime}
                      >
                        Cancel
                      </Button>
                    ) : (
                      ""
                    )}
                    <Button
                      htmlType="submit"
                      className="w-[110px] ml-auto"
                      loading={addTimeLoader}
                      // disabled={true}
                    >
                      {timeDetailUpdate ? "Update" : "Save"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>

          <div className="mt-4 text-lg font-semibold text-[#265ba6] leading-7 tracking-wide text-end">
            Total:{" "}
            {timeCount
              ? timeCount.toString().split(":")[0] +
                "hrs " +
                timeCount.toString().split(":")[1] +
                "min"
              : "0hrs 0min"}
          </div>

          <Table
            className="mt-2 empty-data"
            pagination={false}
            columns={columns1}
            dataSource={timeData}
            scroll={{
              x: "max-content",
              y: 300,
            }}
          />
        </Spin>
      </Modal>
      <Modal
        title={`Total Time Spend -  ${
          currentModalTicketData
            ? currentModalTicketData.total_working_time
            : "00:00"
        }`}
        open={cldOpen}
        okText="Yes"
        onOk={(key) => handleTicketClosedOk(key)}
        onCancel={handleTicketClosedCancel}
      >
        <p>Are you sure you want to closed this ticket?</p>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    ticketsLoader: state.ticket.ticketsLoader,
    ticketsData: state.ticket.ticketsData,
    ticketLogLoader: state.ticket.ticketLogLoader,
    ticketLogData: state.ticket.ticketLogData,
    ticketsDetailLoader: state.ticket.ticketsDetailLoader,
    ticketsDetailData: state.ticket.ticketsDetailData,
    ticketStatusUpdateLoader: state.ticket.ticketStatusUpdateLoader,
    ticketStatusUpdateData: state.ticket.ticketStatusUpdateData,
    ticketsCount: state.ticket.ticketsCount,
    deleteticketsLoading: state.ticket.deleteticketsLoading,
    serviceNumber: state.ticket.serviceNumber,
    getAllticketsData: state.ticket.getAllticketsData,
    getAllticketsLoader: state.ticket.getAllticketsLoader,
    getAllTicketLogLoader: state.ticket.getAllTicketLogLoader,
    getAllTicketLogData: state.ticket.getAllTicketLogData,
    priorityStatusUpdateLoader: state.ticket.priorityStatusUpdateLoader,
    ticketLevelUpdateLoader: state.ticket.ticketLevelUpdateLoader,
    allUsersData: state.users.allUsersData,
    clickedData: state.ticket.clickedData,
    severityUpdateLoader: state.ticket.severityUpdateLoader,
    userProfileData: state.auth.userProfileData,
    pages: state.ticket.pages,
    getTimedData: state.ticket.getTimedData,
    getTimedLoader: state.ticket.getTimedLoader,
    createTimingLoading: state.ticket.createTimingLoading,
    timeLoader: state.time.timeLoader,
    timeData: state.time.timeData,
    timeCount: state.time.timeCount,
    addTimeLoader: state.time.addTimeLoader,
    addTimeData: state.time.addTimeData,
    deleteTimeLoading: state.time.deleteTimeLoading,
    timeUpdateLoader: state.time.timeUpdateLoader,
    timeDetailLoader: state.time.timeDetailLoader,
    timeDetailData: state.time.timeDetailData,
  };
};

export default connect(mapStateToProps, {
  actionGetTicketLog,
  actionGetTickets,
  actionGetTicketDetail,
  actionUpdateTicketStatus,
  actionUpdateLevelStatus,
  actionGetAllTicketLog,
  actionGetUsersAllData,
  actionDeleteTicket,
  actionUpdateTicketSeverity,
  actionGetAllTickets,
  actionUpdatePriorityStatus,
  actionPage,
  actionGetAllTimingResolution,
  actionCreateTimingResolution,
  actionGetTimeAll,
  actionAddTime,
  actionDeleteTime,
  actionUpdateTime,
  actionGetTimeDetail,
})(TicketTable);
