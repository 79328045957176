import * as actionTypes from "../action";

const initialState = {
  ticketsLoader: false,
  deleteticketsLoading: false,
  ticketsData: [],
  addTicketsLoader: false,
  addTicketsData: [],
  ticketsUpdateLoader: false,
  ticketsDetailLoader: false,
  ticketsDetailData: {},
  ticketsCount: {},
  ticketLogLoader: false,
  ticketStatusUpdateLoader: false,
  ticketLogData: [],
  getAllTicketLogLoader: false,
  getAllTicketLogData: [],
  areaConcernLoader: false,
  areaConcernData: [],
  addAreaConcernLoader: false,
  addAreaConcernData: [],
  serviceNumber: {},
  getAllticketsLoader: false,
  getAllticketsData: [],
  priorityStatusUpdateLoader: false,
  ticketLevelUpdateLoader: false,
  severityUpdateLoader: false,
  clickedData: "",
  pages: 1,
  dashboardLoader: false,
  dashboardData: {},
  // Knowledge stars Here
  addKnowledgeLoader: false,
  addKnowledgeData: [],
  addResolveLoader: false,
  addResolveData: [],
  updateTicketsLoader: false,
  getTaskData: [],
  getTaskLoader: false,
  createTaskLoading: false,
  deletetaskLoading: false,
  updateTasksLoader: false,
  updateUserTaskLoading: false,
  createTaskData: {},
  taskUserData: {},
  taskCount: "",
  sortedTaskData: [],
  createTimingLoading: false,
  getTimedData: [],
  timingCount: {},
  getTimedLoader: false,
  updateTasksStatusData: [],
  updateTasksStatusLoader: false,
  updateTimeLoading: false,
};

const ticketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOTAL_TICKETS_COUNT:
      return { ...state, ticketsCount: action.payload };

    case actionTypes.GET_DASHBOARD_LOADING:
      return { ...state, dashboardLoader: action.payload };

    case actionTypes.GET_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: action.payload,
      };

    case actionTypes.TASK_COUNT:
      return { ...state, taskCount: action.payload };

    case actionTypes.TIME_COUNT:
      return { ...state, timingCount: action.payload };

    case actionTypes.GET_SERVICE_NUMBER:
      return { ...state, serviceNumber: action.payload };

    case actionTypes.GET_ALL_TICKET_LOADING:
      return { ...state, ticketsLoader: action.payload };

    case actionTypes.GET_ALL_TICKET_DATA:
      return { ...state, ticketsData: action.payload };

    case actionTypes.GET_ALL_EXPORT_TICKET_LOADING:
      return { ...state, getAllticketsLoader: action.payload };

    case actionTypes.GET_ALL_EXPORT_TICKET_DATA:
      return { ...state, getAllticketsData: action.payload };

    case actionTypes.ADD_TICKET_LOADING:
      return { ...state, addTicketsLoader: action.payload };

    case actionTypes.ADD_TICKET_DATA:
      return { ...state, addTicketsData: action.payload };

    case actionTypes.UPDATE_TICKET_LOADING:
      return { ...state, updateTicketsLoader: action.payload };

    case actionTypes.DELETE_TICKET_LOADING:
      return { ...state, deleteticketsLoading: action.payload };

    case actionTypes.DELETE_TICKET_ID:
      return {
        ...state,
        ticketsData: [
          ...state.ticketsData.filter((data) => data.id !== action.payload),
        ],
      };
    case actionTypes.UPDATE_TICKET_STATUS_LOADING:
      return { ...state, ticketStatusUpdateLoader: action.payload };

    case actionTypes.UPDATE_TICKET_STATUS_DATA:
      return {
        ...state,
        ticketsData: state.ticketsData.map((data) =>
          data.ticket_id === action.payload.ticket_id
            ? {
                ...data,
                status: action.payload.status,
              }
            : data
        ),
      };

    case actionTypes.UPDATE_PRIORITY_STATUS_LOADING:
      return { ...state, priorityStatusUpdateLoader: action.payload };

    case actionTypes.UPDATE_TASK_TIME_LOADING:
      return { ...state, updateTimeLoading: action.payload };

    case actionTypes.UPDATE_PRIORITY_STATUS_DATA:
      return {
        ...state,
        ticketsData: state.ticketsData.map((data) =>
          data.ticket_id === action.payload.ticket_id
            ? {
                ...data,
                rate_issuue: action.payload.rate_issuue,
              }
            : data
        ),
      };

    case actionTypes.UPDATE_SEVERITY_LOADING:
      return { ...state, severityUpdateLoader: action.payload };

    case actionTypes.UPDATE_SEVERITY_DATA:
      return {
        ...state,
        ticketsData: state.ticketsData.map((data) =>
          data.ticket_id === action.payload.ticket_id
            ? {
                ...data,
                sr_severity: action.payload.sr_severity,
              }
            : data
        ),
      };

    case actionTypes.UPDATE_SEVERITY_DETAIL_DATA:
      return {
        ...state,
        ticketsDetailData:
          state.ticketsDetailData &&
          state.ticketsDetailData.ticket_id === action.payload.ticket_id
            ? {
                ...state.ticketsDetailData,
                sr_severity: action.payload.sr_severity,
              }
            : state.ticketsDetailData,
      };

    case actionTypes.UPDATE_PRIORITY_DETAIL_STATUS_DATA:
      return {
        ...state,
        ticketsDetailData:
          state.ticketsDetailData &&
          state.ticketsDetailData.ticket_id === action.payload.ticket_id
            ? {
                ...state.ticketsDetailData,
                rate_issuue: action.payload.rate_issuue,
              }
            : state.ticketsDetailData,
      };

    case actionTypes.UPDATE_TICKET_LEVEL_LOADING:
      return { ...state, ticketLevelUpdateLoader: action.payload };

    case actionTypes.UPDATE_TICKET_LEVEL_DATA:
      return {
        ...state,
        ticketsData:
          state.ticketsData &&
          state.ticketsData.map((data) =>
            data.ticket_id === action.payload.ticket_id
              ? {
                  ...data,
                  level: action.payload.level,
                }
              : data
          ),
      };

    case actionTypes.UPDATE_TICKET_DETAIL_LEVEL_DATA:
      return {
        ...state,
        ticketsDetailData:
          state.ticketsDetailData &&
          state.ticketsDetailData.ticket_id === action.payload.ticket_id
            ? {
                ...state.ticketsDetailData,
                level: action.payload.level,
              }
            : state.ticketsDetailData,
      };

    case actionTypes.UPDATE_TIME_DATA:
      return {
        ...state,
        ticketsData:
          state.ticketsData &&
          state.ticketsData.map((data) =>
            data.ticket_id === action.payload.ticket_id
              ? {
                  ...data,
                  updated_at: action.payload.updated_at,
                }
              : data
          ),
      };

    case actionTypes.UPDATE_DETAIL_TIME_DATA:
      return {
        ...state,
        ticketsDetailData:
          state.ticketsDetailData &&
          state.ticketsDetailData.ticket_id === action.payload.ticket_id
            ? {
                ...state.ticketsDetailData,
                updated_at: action.payload.updated_at,
              }
            : state.ticketsDetailData,
      };

    case actionTypes.GET_TICKET_DETAIL_LOADING:
      return { ...state, ticketsDetailLoader: action.payload };

    case actionTypes.GET_TICKET_DETAIL_DATA:
      return {
        ...state,
        ticketsDetailData: action.payload,
      };

    case actionTypes.UPDATE_TICKET_DETAIL_STATUS_DATA:
      return {
        ...state,
        ticketsDetailData:
          state.ticketsDetailData.ticket_id === action.payload.ticket_id
            ? {
                ...state.ticketsDetailData,
                status: action.payload.status,
              }
            : state.ticketsDetailData,
      };

    case actionTypes.GET_ALL_TICKET_LOG_LOADING:
      return { ...state, getAllTicketLogLoader: action.payload };

    case actionTypes.GET_ALL_TICKET_LOG_DATA:
      return { ...state, getAllTicketLogData: action.payload };

    case actionTypes.GET_TICKET_LOG_LOADING:
      return { ...state, ticketLogLoader: action.payload };

    case actionTypes.GET_TICKET_LOG_DATA:
      return { ...state, ticketLogData: action.payload };

    // area of concern

    case actionTypes.GET_ALL_AREA_CONCERN_LOADING:
      return { ...state, areaConcernLoader: action.payload };

    case actionTypes.GET_ALL_AREA_CONCERN_DATA:
      return { ...state, areaConcernData: action.payload };

    case actionTypes.ADD_AREA_CONCERN_LOADING:
      return { ...state, addAreaConcernLoader: action.payload };

    case actionTypes.ADD_AREA_CONCERN_DATA:
      return {
        ...state,
        areaConcernData: [action.payload, ...state.areaConcernData],
      };

    // Knowlegde starts
    case actionTypes.ADD_KNOWLEDGE_LOADING:
      return { ...state, addKnowledgeLoader: action.payload };

    case actionTypes.ADD_KNOWLEDGE_DATA:
      return { ...state, addKnowledgeData: action.payload };
    // Knowlegde ends

    case actionTypes.ADD_RESOLVE_LOADING:
      return { ...state, addResolveLoader: action.payload };

    case actionTypes.ADD_RESOLVE_DATA:
      return { ...state, addResolveData: action.payload };

    case actionTypes.SEND_MAIL_LOADING:
      return { ...state, sendEmailLoader: action.payload };

    case actionTypes.SEND_EMAIL_DATA:
      return { ...state, sendEmailData: action.payload };

    case actionTypes.STORE_CLICKED_DATA:
      return { ...state, clickedData: action.payload };

    case actionTypes.SET_PAGE:
      return { ...state, pages: action.payload };

    // task Reducers

    case actionTypes.GET_TASK_LOADING:
      return { ...state, getTaskLoader: action.payload };

    case actionTypes.GET_TASK_DATA:
      return { ...state, getTaskData: action.payload };

    case actionTypes.CREATE_TASK_LOADING:
      return {
        ...state,
        createTaskLoading: action.payload,
      };

    case actionTypes.CREATE_TASK_DATA:
      return {
        ...state,
        getTaskData: [...state.getTaskData, action.payload], // Append the new task data
      };

    case actionTypes.DELETE_TASK_LOADING:
      return { ...state, deletetaskLoading: action.payload };

    case actionTypes.DELETE_TASK_ID:
      return {
        ...state,
        getTaskData: [
          ...state.getTaskData.filter(
            (data) => data.task_id !== action.payload
          ),
        ],
      };

    case actionTypes.UPDATE_TASK_USER_LOADING:
      return {
        ...state,
        updateUserTaskLoading: action.payload,
      };

    case actionTypes.TASK_NAME:
      return {
        ...state,
        getTaskData: state.getTaskData.map((data) =>
          data.task_id === action.payload.task_id
            ? {
                ...data,
                task_name: action.payload.task_name,
              }
            : data
        ),
      };
    case actionTypes.TASK_USER:
      return {
        ...state,
        getTaskData: state.getTaskData.map((data) =>
          data.task_id === action.payload.task_id
            ? {
                ...data,
                agent: action.payload.agent,
              }
            : data
        ),
      };
    case actionTypes.TASK_TIME:
      return {
        ...state,
        getTaskData: state.getTaskData.map((data) =>
          data.task_id === action.payload.task_id
            ? {
                ...data,
                estimated_time: action.payload.estimated_time,
              }
            : data
        ),
      };

    // case actionTypes.TASK_USER:
    //   return { ...state, taskUserData: action.payload };

    case actionTypes.UPDATE_TASK_LOADING:
      return { ...state, updateTasksLoader: action.payload };

    // for task
    case actionTypes.SORT_TASKS:
      return {
        ...state,
        getTaskData: action.payload, // Update sorted task data with the payload
      };
    case actionTypes.CREATE_TIMING_LOADING:
      return {
        ...state,
        createTimingLoading: action.payload,
      };

    case actionTypes.CREATE_TIMING_DATA:
      return {
        ...state,
        getTimedData: [...state.getTimedData, action.payload], // Append the new task data
      };

    case actionTypes.GET_TIMING_LOADING:
      return { ...state, getTimedLoader: action.payload };

    case actionTypes.GET_TIMING_DATA:
      return { ...state, getTimedData: action.payload };

    case actionTypes.UPDATE_TASKSTATUS_LOADING:
      return { ...state, updateTasksStatusLoader: action.payload };

    case actionTypes.UPDATE_TASK_STATUS:
      return {
        ...state,
        getTaskData: state.getTaskData.map((data) =>
          data.task_id === action.payload.task_id
            ? {
                ...data,
                status: action.payload.status,
              }
            : data
        ),
      };
    case actionTypes.CHANGE_TOTAL_TIME_SPEND_GET_ALL_TICKTES:
      return {
        ...state,
        ticketsData: state.ticketsData.map((record) => {
          if (record.ticket_id === action.payload.ticket_id) {
            return {
              ...record,
              total_working_time: action.payload.data,
            };
          }
          return record; // No need for extra object wrapping if record doesn't match
        }),
      };

    case actionTypes.REMOVE_TASK_STATUS:
      // Find the task that needs to be updated
      const updatedTaskId = action.payload;

      const updatedAgentTask = state.dashboardData.agent_task?.map((data) => {
        const updatedTasks = data.tasks?.filter(
          (task) => task.task_id !== updatedTaskId
        );
        // Update the tasks array in the data object
        return {
          ...data,
          tasks: updatedTasks,
        };
      });
      if (
        updatedAgentTask?.every(
          (data) => !data.tasks || data.tasks.length === 0
        )
      ) {
        // If no tasks left, remove the ticket as well
        return {
          ...state,
          dashboardData: {
            ...state.dashboardData,
            agent_task: null,
          },
        };
      } else {
        // If tasks are still present, update the dashboardData property with updatedAgentTask
        return {
          ...state,
          dashboardData: {
            ...state.dashboardData,
            agent_task: updatedAgentTask,
          },
        };
      }

    case actionTypes.TASK_SORTING_LOADING:
      return { ...state };

    case actionTypes.TASK_SORTING_DATA:
      return { ...state };

    default:
      return state;
  }
};

export default ticketsReducer;
