import { EyeOutlined } from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Image,
  Row,
  Spin,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  actionAddResolution,
  actionGetTicketDetail,
} from "../../store/actions/ticketAction";

import { useRef } from "react";
import { BASE_URL } from "../config/web-config";

const EditResolve = (props) => {
  const Navigate = useNavigate();
  const {
    actionGetTicketDetail,
    ticketsDetailLoader,
    ticketsDetailData,
    actionAddResolution,
    addResolveLoader,
    addResolveData,
  } = props;
  const { ticketId } = useParams();
  const editorRef = useRef(null);

  const [content, setContent] = useState(ticketsDetailData.resolve_note);

  useEffect(() => {
    actionGetTicketDetail(ticketId);
  }, []);

  const handleEditorChange = (newContent, editor) => {
    // Check if the new content is of type string
    if (typeof newContent === "string") {
      setContent(newContent);
      // setDescriptionError("");
    } else {
      setContent("");
    }
  };

  const ticketdata = [
    {
      title: "Ticket Number",
      description: (
        <>{ticketsDetailData && ticketsDetailData.service_request_number}</>
      ),
    },
    {
      title: "Description",
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html: ticketsDetailData && ticketsDetailData.description,
          }}
        ></div>
      ),
    },
    {
      title: "Status",
      // description: ticketsDetailData && ticketsDetailData.status,
      description: (
        <>
          {ticketsDetailData && (
            <Tag
              color={
                ticketsDetailData.status == "REGISTERED"
                  ? "blue"
                  : ticketsDetailData.status == "IN_PROCESS"
                  ? "orange"
                  : ticketsDetailData.status == "AWAITING_BUSINESS_CONFIRMATION"
                  ? "warning"
                  : "green"
              }
            >
              {ticketsDetailData.status}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Screenshots",
      description:
        ticketsDetailData &&
        ticketsDetailData.attachemnets &&
        ticketsDetailData.attachemnets.length > 0 ? (
          ticketsDetailData.attachemnets.map((item, index) => {
            const lowercaseItem = item.toLowerCase();
            const fileName = lowercaseItem.split("/").pop(); // Extract the file name from the URL
            const fileExtension = fileName.split(".").pop(); // Extract the file extension

            return (
              <div className="mr-5 mt-2" key={index}>
                {/* Display the attachment item */}
                {fileExtension === "pdf" ? (
                  <a
                    href={item}
                    target="_blank"
                    className="link-tag hover:underline text-sm hover:text-black-600"
                    rel="noopener noreferrer"
                  >
                    {fileName}
                  </a>
                ) : fileExtension === "jpg" ||
                  fileExtension === "jpeg" ||
                  fileExtension === "png" ? (
                  <Image
                    preview={{ mask: <EyeOutlined /> }}
                    width={100}
                    src={item}
                  />
                ) : fileExtension === "xlsx" ||
                  fileExtension === "xls" ||
                  fileExtension === "csv" ||
                  fileExtension === "doc" ||
                  fileExtension === "docx" ? (
                  <a
                    href={item}
                    target="_blank"
                    className="link-tag  hover:underline text-sm hover:text-black-600"
                    rel="noopener noreferrer"
                  >
                    {fileName}
                  </a>
                ) : (
                  <p>No attachments available</p>
                )}
              </div>
            );
          })
        ) : (
          // Display "No attachments available" when no attachments are available
          <p>No attachments available</p>
        ),
    },

    {
      title: "Resolution",
      description: (
        <>
          <Editor
            apiKey="hglsma9tuhg1k0s3lopkfjuqpv6uhaa4qiv8sbgnbepck75f" // Replace 'your-api-key' with your TinyMCE API key
            onEditorChange={handleEditorChange}
            initialValue={ticketsDetailData.resolve_note}
            init={{
              image_advtab: true,
              imagetools_cors_hosts: [BASE_URL],

              height: 350,
              images_file_types: "jpg,png,jpeg",
              block_unsupported_drop: true,
              branding: false,

              plugins:
                "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars",
              menubar: "file edit view insert format tools table help",
              toolbar:
                "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist |  removeformat | pagebreak | charmap | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
              toolbar_sticky: true,
              autosave_interval: "30s",
              autosave_ask_before_unload: true,
            }}
            wrapperClassName="demo-wrapper"
            //scriptLoading={{ async: true, delay: 500 }}
            editorClassName="demo-editor"
            ref={editorRef} // Set a reference to the editor instance
          />
        </>
      ),
    },
  ];

  const handleAddResolve = () => {
    actionAddResolution(content, Navigate, ticketId);
    setContent("");
  };

  return (
    <>
      <Spin spinning={ticketsDetailLoader}>
        <div className="user-detail">
          <Row gutter={16}>
            <Col xl={17} md={24} sm={24} xxl={17}>
              <Card
                className="big-card"
                title={
                  <>
                    <Typography.Title level={4}>
                      <div className="flex justify-between items-center">
                        {ticketsDetailData.resolve_note === null
                          ? "Add"
                          : "Edit"}{" "}
                        Resolution
                        <span className="text-line"></span>
                      </div>
                    </Typography.Title>
                  </>
                }
                bordered={false}
              >
                <div className="user-detail">
                  <Row gutter={16}>
                    <Col span={24}>
                      <Descriptions
                        className="hello"
                        title=""
                        layout="horizontal"
                        colon={true}
                        // bordered
                        column={1}
                      >
                        {ticketdata &&
                          ticketdata.length > 0 &&
                          ticketdata.map((item, index) => (
                            <Descriptions.Item key={index} label={item.title}>
                              {item.description ? item.description : "-"}
                            </Descriptions.Item>
                          ))}
                      </Descriptions>

                      <Button
                        onClick={handleAddResolve}
                        className="custom-disabled"
                        disabled={!content}
                        loading={addResolveLoader}
                      >
                        Resolve
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ticketsDetailLoader: state.ticket.ticketsDetailLoader,
    ticketsDetailData: state.ticket.ticketsDetailData,
    addResolveLoader: state.ticket.addResolveLoader,
    addResolveData: state.ticket.addResolveData,
  };
};

export default connect(mapStateToProps, {
  actionGetTicketDetail,
  actionAddResolution,
})(EditResolve);
