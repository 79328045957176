import axios from "axios";
import { BASE_URL } from "../../components/config/web-config";
import * as actionTypes from "../action";
import { message, Slider } from "antd";

// Knowlege Action Start Here

export const actionAddKnowledge =
  (userData, navigate) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.ADD_KNOWLEDGE_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/knowledge-base`, userData);
      dispatch({ type: actionTypes.ADD_KNOWLEDGE_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.ADD_KNOWLEDGE_DATA,
          payload: result.data.data,
        });
        message.success("Knowledge Base Added Successfully", 5);
        navigate && setTimeout(() => navigate(`/knowledge`), 1000);
      } else {
        message.error(result.data.message, 5);
      }
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.ADD_KNOWLEDGE_LOADING, payload: false });
    }
  };



export const actionGetKnowledgeAll =
  (offset,
    limit,
    keyword,
    areasOfConcernId,
    start_date,
    end_date) => async (dispatch, getState) => {
      dispatch({ type: actionTypes.GET_KNOWLEGDE_LOADING, payload: true });
      try {
        let result = await axios.get(
          `${BASE_URL}/knowledge-base?limit=${limit}&offset=${offset}${keyword ? "&keyword=" + keyword : ""}${areasOfConcernId ? "&areasOfConcernId=" + areasOfConcernId : ""
          }${start_date ? "&start_date=" + start_date : ""
          }${end_date ? "&end_date=" + end_date : ""}`
        );
        dispatch({ type: actionTypes.GET_KNOWLEGDE_LOADING, payload: false });
        if (parseInt(result.data.status) === 200) {
          dispatch({
            type: actionTypes.GET_KNOWLEGDE_DATA,
            payload: result.data.data,
          });
          dispatch({
            type: actionTypes.TOTAL_KNOWLEDGE_COUNT,
            payload: result.data.total_records,
          });
        } else if (parseInt(result.data.status) === 404) {
          dispatch({
            type: actionTypes.GET_KNOWLEGDE_DATA,
            payload: [],
          });
          dispatch({
            type: actionTypes.TOTAL_KNOWLEDGE_COUNT,
            payload: 0,
          });
        } else message.error(result.data.message, 5);
      } catch (error) {
        message.error(error.message, 5);
        dispatch({ type: actionTypes.GET_KNOWLEGDE_LOADING, payload: false });
      }
    };



export const actionDeleteKnowledge = (id, navigate) => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.DELETE_KNOWLEDGE_LOADING,
    payload: true,
  });
  try {
    let result = await axios.delete(`${BASE_URL}/knowledge-base/${id}`);
    dispatch({
      type: actionTypes.DELETE_KNOWLEDGE_LOADING,
      payload: false,
    });
    if (parseInt(result.data.status) === 200) {
      dispatch({ type: actionTypes.DELETE_KNOWLEDGE_ID, payload: id });
      message.success("Deleted Successfully", 5);
      navigate && setTimeout(() => navigate(`/knowledge`), 1000);

    } else message.error(result.data.message, 5);
  } catch (error) {
    message.error(error.message, 5);
    dispatch({
      type: actionTypes.DELETE_KNOWLEDGE_LOADING,
      payload: false,
    });
  }
};



export const actionUpdateKnowledge =
  (userData, navigate, id) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_KNOWLEDGE_LOADING, payload: true });
    try {
      let result = await axios.patch(`${BASE_URL}/knowledge-base/${id}`, userData);
      dispatch({ type: actionTypes.UPDATE_KNOWLEDGE_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        message.success(result.data.message, 5);
        navigate && setTimeout(() => navigate(`/knowledge`), 1000);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.UPDATE_KNOWLEDGE_LOADING, payload: false });
    }
  };

export const actionGetKnowledgeDetail = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_KNOWLEDGE_DETAIL_LOADING, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/knowledge-base/${id}`);
    dispatch({ type: actionTypes.GET_KNOWLEDGE_DETAIL_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_KNOWLEDGE_DETAIL_DATA,
        payload: result.data.data,
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    dispatch({ type: actionTypes.GET_KNOWLEDGE_DETAIL_LOADING, payload: false });
  }
};





// Knowlege Action Start Here
