import { message } from "antd";
import axios from "axios";
import { BASE_URL } from "../../components/config/web-config";
import * as actionTypes from "../action";

export const actionSendNotification = (userData) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SEND_NOTIFICATION_LOADER, payload: true });
    try {
        let result = await axios.post(`${BASE_URL}/notification`,      userData);
        dispatch({ type: actionTypes.SEND_NOTIFICATION_LOADER, payload: false });
        if (parseInt(result.data.status) === 200) {
            dispatch({
                type: actionTypes.SEND_NOTIFICATION_DATA,
                payload: result.data.data,
            });
            message.success(result.data.message, 5);
        } else message.error(result.data.message, 5);
    } catch (error) {
        message.error(error.message, 5);
    }
};