import React, { useEffect, useMemo, useState } from "react";
import {
  CalendarOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  DeleteOutlined,
  FieldTimeOutlined,
  PlusOutlined,
  UserOutlined,
  HolderOutlined,
  MenuOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Popover,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  actionCreateTask,
  actionGetAllTask,
  actionUpdateTaskName,
  actionUpdateTaskTime,
  actionUpdateTaskUser,
  deleteTask,
  actionTaskSorting,
  actionUpdateTaskStatus,
} from "../../store/actions/ticketAction";
import { actionGetUsersAllData } from "../../store/actions/userAction";
import {
  SortableContainer,
  arrayMove,
  SortableHandle,
  sortableElement,
} from "react-sortable-hoc";
import { actionSortTasks } from "../../store/actions/ticketAction"; // Import your new action
import { useDispatch } from "react-redux";

// import arrayMove from 'array-move';

const { Paragraph } = Typography;

const Task = (props) => {
  const {
    actionGetAllTask,
    actionUpdateTaskUser,
    actionUpdateTaskTime,
    taskCount,
    actionGetUsersAllData,
    allUsersData,
    getTaskData,
    deleteTask,
    actionCreateTask,
    actionUpdateTaskName,
    createTaskLoading,
    getTaskLoader,
    taskUserData,
    deletetaskLoading,
    actionTaskSorting,
    actionUpdateTaskStatus,
  } = props;
  const [TaskValue, setTaskValue] = useState("");
  const [activeCardIndex, setActiveCardIndex] = useState(-1);
  const { ticketId } = useParams();
  const [popoverVisible, setPopoverVisible] = useState([]);
  const [timePopoverVisible, setTimePopoverVisible] = useState([]);
  const [open, setOpen] = useState(false);
  const [editableStr, setEditableStr] = useState("This is an editable text.");
  const [opened, setOpened] = useState(false);
  const [estimatedHours, setEstimatedHours] = useState([]);

  const [selectedValues, setSelectedValues] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [form] = Form.useForm();
  const [taskStatus, setTaskStatus] = useState("In Completed");

  useEffect(() => {
    actionGetAllTask(ticketId);
    actionGetUsersAllData(0, 10);
  }, []);

  useMemo(() => {}, [getTaskData]);

  const handleAddTask = (e) => {
    e.preventDefault();
    const request = {
      ticket: parseInt(ticketId),
      task_name: TaskValue,
    };
    actionCreateTask(request, form, setTaskValue);
  };

  const agentData = [];
  allUsersData?.map((data, index) => {
    if (data.roles === "AGENT") {
      agentData.push({
        key: index,
        label: data.first_name + " " + data.last_name,
        value: data.id,
      });
    }
  });

  const handleChange = (e) => {
    setTaskValue(e.target.value);
  };

  const onFinish = (values) => {
    const request = {
      ...values,
      ticket: parseInt(ticketId),
    };
    actionCreateTask(request, form, setTaskValue);
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Form submission failed:", errorInfo);
  };

  const handleUserIconClick = (index) => {
    const updatedPopoverVisibility = [...popoverVisible];
    updatedPopoverVisibility[index] = !updatedPopoverVisibility[index];
    setPopoverVisible(updatedPopoverVisibility);
  };

  const handleTImeShow = (index) => {
    const updatedTimePopoverVisibility = timePopoverVisible.map(
      (value, i) => i === index
    );
    setTimePopoverVisible(updatedTimePopoverVisibility);
  };

  const handleDropdownChange = (value, index, taskId) => {
    const selectedAgent = agentData.find((data) => data.value === value);

    const updatedSelectedValues = [...selectedValues];
    const cValue = selectedAgent && selectedAgent.label;
    const firstLetter =
      cValue &&
      cValue
        .split(" ")
        .map((word) => word.charAt(0))
        .join("");

    updatedSelectedValues[index] = firstLetter;
    setSelectedValues(updatedSelectedValues);

    actionUpdateTaskUser(value, taskId, setSelectedValues);

    const updatedPopoverVisibility = [...popoverVisible];
    updatedPopoverVisibility[index] = false;
    setPopoverVisible(updatedPopoverVisibility);
  };

  const confirm = (id, index) => {
    deleteTask(id);

    const updatedSelectedValues = [...selectedValues];
    updatedSelectedValues[index] = "";
    setSelectedValues(updatedSelectedValues);

    const updatedPopoverVisibility = [...popoverVisible];
    updatedPopoverVisibility[index] = false;
    setPopoverVisible(updatedPopoverVisibility);
  };

  const handleEstimatedHoursSubmit = (value, index, taskId) => {
    const updatedEstimatedHours = [...estimatedHours];
    updatedEstimatedHours[index] = value;
    setEstimatedHours(updatedEstimatedHours);

    const updatedTimePopoverVisibility = [...timePopoverVisible];
    updatedTimePopoverVisibility[index] = false;
    setTimePopoverVisible(updatedTimePopoverVisibility);

    actionUpdateTaskTime(value.toString(), taskId, setEstimatedHours);
  };
  const dispatch = useDispatch();

  function handleSortTasks(sortedData) {
    dispatch(actionSortTasks(sortedData));
  }

  const handleChangeTaskStatus = (e, id) => {
    // setTaskStatus('complete');
    let taskStatus = e === "In Completed" ? "Completed" : "In Completed";

    actionUpdateTaskStatus(taskStatus, id);
  };

  const handleChangeName = (e, id) => {
    setEditableStr(e);
    actionUpdateTaskName(e, id);
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  function onSortEnd({ oldIndex, newIndex }) {
    const reorderedTasks = arrayMove(getTaskData, oldIndex, newIndex);
    handleSortTasks(reorderedTasks); // Call the handleSortTasks function with the sorted data
    let taskSort = [];
    reorderedTasks.map((d) => {
      taskSort.push(d.task_id);
    });
    actionTaskSorting({ task_id: taskSort }, ticketId);
  }

  // Call the sorting function when the component mounts
  useEffect(() => {
    handleSortTasks(getTaskData); // Initially sort the tasks when the component mounts
  }, []); // The empty dependency array ensures this effect runs only once on component mount

  const SortableCard = sortableElement(({ task, index }) => (
    <>
      <Card
        className={`task-card`}
        style={{
          marginTop: 16,
        }}
        loading={false}
        key={index}
      >
        <Row align="middle">
          <Col span={1} className="lg:-mr-4 xl:-mr-4">
            <HolderOutlined
              style={{ cursor: "all-scroll" }}
              //  className="grabbable"
            />
          </Col>
          <Col span={1} className="color-greeen-parent">
            <CheckCircleOutlined
              style={{
                color: task.status == "In Completed" ? "grey" : "green",
              }}
              className=" color-greeen text-current text-base"
              onClick={(e) => {
                handleChangeTaskStatus(task.status, task.task_id);
              }}
            />
          </Col>
          <Col
            span={17}
            style={{ marginLeft: 0 }}
            className="margin-type"
            align=""
          >
            {/* <Typography.Text>{task.task_name}</Typography.Text> */}
            <Paragraph
              bordered={false}
              className="mb-0 bg-transparent"
              // onChange={handleChangeName}
              editable={{
                // icon: "",
                onChange: (e) => handleChangeName(task, task.task_id),
                enterIcon: <CheckOutlined className="color-grey" />,
                triggerType: "text",
              }}
            >
              {task.task_name}
            </Paragraph>
          </Col>
          <Col span={5} align="right">
            <div className="flex justify-end items-center">
              <div className="mx-2">
                <Popover
                  placement="bottom"
                  open={timePopoverVisible && timePopoverVisible[index]}
                  content={
                    <span className="flex">
                      <InputNumber
                        style={{ width: "180px" }}
                        // normalize={(value) =>
                        //   value.replace(/[^a-zA-Z]/g, "").trim()
                        // }
                        normalize={(value) => `${value.replace(/[^0-9]/g, "")}`}
                        min={0}
                        // placeholder="Enter Estimated Hours"
                        type="number"
                        prefix={<ClockCircleOutlined />}
                        suffix={"hrs"}
                        value={(estimatedHours && estimatedHours[index]) || ""} // Set the value from the state
                        onChange={(value) => {
                          const updatedEstimatedHours = [...estimatedHours];
                          updatedEstimatedHours[index] = value;
                          setEstimatedHours(updatedEstimatedHours);
                        }}
                        onPressEnter={() =>
                          handleEstimatedHoursSubmit(
                            estimatedHours[index],
                            index,
                            task.task_id
                          )
                        }
                      />
                    </span>
                  }
                  trigger="click"
                >
                  {task && task.estimated_time ? (
                    // <div className="flex h-[30px] w-[30px] p-[5px] items-center justify-center rounded-full uppercase">
                    <Typography.Text code onClick={() => handleTImeShow(index)}>
                      {`${task.agent && task.estimated_time} hrs`}
                    </Typography.Text>
                  ) : (
                    // </div>

                    <div className="w-[30px] text-center">
                      <FieldTimeOutlined
                        className="cursor-pointer text-current text-base"
                        onClick={() => handleTImeShow(index)} // Pass the card index
                      />
                    </div>
                  )}
                </Popover>
              </div>
              <div className=",mx-2 overflow-ellipsis bg-slate-300 ">
                <Popover
                  content={
                    <Select
                      optionFilterProp="children"
                      showSearch
                      allowClear
                      filterOption={filterOption}
                      placeholder="Select Agent"
                      value={selectedValues && selectedValues[index]} // Use the selected value for this card
                      onChange={(value) =>
                        handleDropdownChange(value, index, task.task_id)
                      }
                      style={{ width: "100%", minWidth: 200 }}
                      options={agentData}
                    />
                  }
                  trigger="click"
                  open={popoverVisible && popoverVisible[index]} // Use the visibility state for this card
                  onOpenChange={(visible) =>
                    setPopoverVisible((prevState) => {
                      const updatedVisibility = [...prevState];
                      updatedVisibility[index] = visible;
                      return updatedVisibility;
                    })
                  }
                >
                  {task.agent &&
                  task.agent.first_name &&
                  task.agent.last_name ? (
                    <div
                      className="flex h-[30px] w-[30px] p-[5px] items-center justify-center rounded-full uppercase"
                      style={{ background: "rgb(234 179 8)" }}
                    >
                      {`${
                        task.agent &&
                        task.agent.first_name &&
                        task.agent.first_name[0]
                      }${
                        task.agent &&
                        task.agent.last_name &&
                        task.agent.last_name[0]
                      }`}
                    </div>
                  ) : (
                    <div className="w-[30px] text-center">
                      <UserOutlined
                        className="cursor-pointer text-current text-base"
                        // onClick={() => handleUserIconClick(index)} // Pass the card index
                        onClick={() => {
                          console.log("xyz clicked");
                        }}
                      />
                    </div>
                  )}
                </Popover>
              </div>
              <div className="mx-2">
                <Popconfirm
                  title="Delete the task"
                  description="Are you sure to delete this task?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => confirm(task.task_id)}
                  okButtonProps={{
                    loading: deletetaskLoading,
                  }}
                >
                  <DeleteOutlined
                    style={{ color: "red" }}
                    className="cursor-pointer text-current text-base"
                  />
                </Popconfirm>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  ));

  const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
  ));

  const SortableCardList = SortableContainer(({ tasks }) => (
    <div>
      {tasks &&
        tasks.map((task, index) => (
          <SortableCard
            key={`task-${task.task_id}`}
            index={index}
            task={task}
          />
        ))}
    </div>
  ));

  return (
    <Spin spinning={getTaskLoader}>
      <Row className="my-6" align="middle">
        <Col xxl={18} xl={18} lg={24} md={24} sm={24} xs={24}>
          <SortableCardList
            tasks={getTaskData}
            axis="y"
            lockAxis="y"
            onSortEnd={onSortEnd}
            useDragHandle
            // handleChangeTaskStatus={handleChangeTaskStatus}
            // handleChangeName={handleChangeName}
            // filterOption={filterOption}
            // handleEstimatedHoursSubmit={handleEstimatedHoursSubmit}
            // confirm={confirm}
            // handleUserIconClick={handleUserIconClick}
            // handleTImeShow={handleTImeShow}
            // handleDropdownChange={handleDropdownChange}
          />
          <Card
            className={`task-card`}
            style={{
              marginTop: 16,
            }}
            loading={false}
          >
            <Row align="middle">
              <Col span={1} className="color-greeen-parent">
                <CheckCircleOutlined
                  style={{ color: "grey" }}
                  className="color-greeen text-current text-base"
                />
              </Col>
              <Col span={18} style={{ marginLeft: 0 }} align="middle">
                <Form
                  form={form}
                  className="ant-form-item1"
                  name="taskForm"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    name="task_name"
                    rules={[
                      {
                        required: false,
                        message: "Please input your Task Name!",
                      },
                    ]}
                  >
                    <Input
                      onChange={handleChange}
                      bordered={false}
                      // Remove the autofocus attribute
                      placeholder="Enter Task Name"
                      autoFocus
                      autoComplete="off"
                      className="bg-transparent task-input"
                    />
                  </Form.Item>
                </Form>
              </Col>
              {/* <Col span={5} align="right">
                  <div className="flex justify-end items-center">
                    <div className="mx-2">
                      <CalendarOutlined className="text-current text-base" />
                    </div>
                    <div className="mx-2">
                      <Tooltip
                        className="cursor-pointer"
                        placement="top"
                        title={"Assign this Task"}
                      >
                        <UserOutlined className="cursor-pointer text-current text-base" />
                      </Tooltip>
                    </div>
                  </div>
                </Col> */}
            </Row>
          </Card>

          <Button
            type="button" // Ensure the type is "button" and not "submit"
            onClick={handleAddTask}
            disabled={!TaskValue}
            className="bg-transparent my-3 task-button"
            icon={<PlusOutlined />}
            loading={createTaskLoading}
          >
            Add Task
          </Button>
        </Col>
      </Row>
    </Spin>
  );
};

const mapStateToProps = (state) => {
  return {
    getTaskData: state.ticket.getTaskData,
    getTaskLoader: state.ticket.getTaskLoader,
    taskCount: state.ticket.taskCount,
    allUsersData: state.users.allUsersData,
    createTaskLoading: state.ticket.createTaskLoading,
    deletetaskLoading: state.ticket.deletetaskLoading,
    taskUserData: state.ticket.taskUserData,
    sortedTaskData: state.ticket.sortedTaskData,
    updateTasksStatusData: state.ticket.updateTasksStatusData,
    updateTasksStatusLoader: state.ticket.updateTasksStatusLoader,
  };
};

export default connect(mapStateToProps, {
  actionGetAllTask,
  actionGetUsersAllData,
  actionCreateTask,
  actionUpdateTaskUser,
  deleteTask,
  actionUpdateTaskName,
  actionUpdateTaskTime,
  actionSortTasks,
  actionTaskSorting,
  actionUpdateTaskStatus,
})(Task);
