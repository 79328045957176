import * as actionTypes from "../action";

const initialState = {
  addTimeLoader: false,
  timeData: [],
  timeLoader: false,
  timeCount: {},
  timeDetailData: {},

};

const timeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TIME_LOADING:
      return { ...state, timeLoader: action.payload };

    case actionTypes.GET_TIME_DATA:
      return { ...state, timeData: action.payload };

    case actionTypes.TOTAL_TIME_COUNT:
      return { ...state, timeCount: action.payload };

    case actionTypes.ADD_TIME_LOADING:
      return { ...state, addTimeLoader: action.payload };

    case actionTypes.ADD_TIME_DATA:
      return { ...state, timeData: action.payload };

    case actionTypes.DELETE_TIME_LOADING:
      return { ...state, deleteTimeLoading: action.payload };

    case actionTypes.DELETE_TIME_ID:
      return {
        ...state,
        timeData: [
          ...state.timeData.filter(
            (data) => data.id !== action.payload
          ),
        ],
      };
    case actionTypes.GET_TIME_DETAIL_LOADING:
      return { ...state, timeDetailLoader: action.payload };

    case actionTypes.GET_TIME_DETAIL_DATA:
      return {
        ...state,
        timeDetailData: action.payload,
      };
    case actionTypes.UPDATE_TIME_LOADING:
      return { ...state, timeUpdateLoader: action.payload };

    case actionTypes.UPDATE_TIMESINGLE_DATA:
      return { ...state, timeData: action.payload };


    default:
      return state;
  }
};

export default timeReducer;