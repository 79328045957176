import { Button, Card, Col, Descriptions, Row, Spin, Typography } from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  actionDeleteProject,
  actionGetProjectDetail,
} from "../../store/actions/projectAction";
const { Title, Text } = Typography;
const ViewProject = (props) => {
  const Navigate = useNavigate();
  const { projectDetailData, actionGetProjectDetail, projectDetailLoader } =
    props;
  const { projectId } = useParams();

  useEffect(() => {
    if (projectId) actionGetProjectDetail(projectId);
  }, [projectId]);

  const projectdata = [
    {
      title: "Project Title",
      description: <>{projectDetailData && projectDetailData.title}</>,
    },
    {
      title: "Description",
      description: <>{projectDetailData && projectDetailData.description}</>,
    },
    {
      title: "Manager Name",
      description: (
        <>
          {projectDetailData && projectDetailData.manager_name
            ? projectDetailData.manager_name
            : "-"}
        </>
      ),
    },
    {
      title: "Manager Email",
      description: (
        <>
          {projectDetailData && projectDetailData.manager_email
            ? projectDetailData.manager_email
            : "-"}
        </>
      ),
    },
    {
      title: "Project Agents",
      description: (
        <>
          {projectDetailData.projectagent &&
          projectDetailData.projectagent.length > 0
            ? projectDetailData.projectagent.map((data, index) => (
                <Typography.Text key={index}>
                  {data.first_name +
                    " " +
                    data.last_name +
                    " " +
                    (index < projectDetailData.projectagent.length - 1
                      ? ", "
                      : "")}
                </Typography.Text>
              ))
            : "-"}
        </>
      ),
    },
    {
      title: "Project Users",
      description: (
        <>
          {projectDetailData.projectuser &&
          projectDetailData.projectuser.length > 0
            ? projectDetailData.projectuser.map((data, index) => (
                <Typography.Text key={index}>
                  {data.first_name +
                    " " +
                    data.last_name +
                    " " +
                    (index < projectDetailData.projectuser.length - 1
                      ? ", "
                      : "")}
                </Typography.Text>
              ))
            : "-"}
        </>
      ),
    },
    {
      title: "Total Hours",
      description: (
        <>
          {projectDetailData && projectDetailData.hours
            ? projectDetailData.hours
            : "-"}
        </>
      ),
    },
    {
      title: "Business Confirmation Email Duration (In Days)",
      description: (
        <>
          {projectDetailData &&
          projectDetailData.awaiting_business_confirmation_email_duration
            ? projectDetailData.awaiting_business_confirmation_email_duration
            : "-"}
        </>
      ),
    },
  ];

  const confirm = (id) => {
    actionDeleteProject(id);
  };

  return (
    <>
      <Spin spinning={projectDetailLoader}>
        <div className="user-detail">
          <Row gutter={16}>
            <Col xl={17} md={24} sm={24} xxl={17}>
              <Card
                className="big-card mt-5"
                // title={
                //   <>
                //     <Typography.Title level={4}>
                //       <div>
                //         View Project<span className="text-line"></span>
                //       </div>
                //     </Typography.Title>
                //   </>
                // }
                bordered={false}
              >
                {/* <List
                  itemLayout="horizontal"
                  dataSource={data}
                  renderItem={(item, index) => (
                    <List.Item
                      style={{
                        backgroundColor: index % 2 === 0 ? "#fff" : "#F1F5F9",
                      }}
                    >
                      <List.Item.Meta
                        title={item.title}
                        description={item.description}
                      />
                    </List.Item>
                  )}
                /> */}
                <div className="user-detail">
                  <Row gutter={16}>
                    <Col span={24}>
                      <Descriptions
                        title=""
                        layout="horizontal"
                        colon={true}
                        bordered
                        column={1}
                      >
                        {projectdata &&
                          projectdata.length > 0 &&
                          projectdata.map((item, index) => (
                            <Descriptions.Item
                              key={index}
                              label={item.title}
                              style={{
                                background:
                                  index % 2 !== 0 ? "#FFFFFF" : "#E2EEFF",
                              }} // Set color dynamically or default to black
                            >
                              {item.description ? item.description : "-"}
                            </Descriptions.Item>
                          ))}
                      </Descriptions>
                    </Col>
                  </Row>
                </div>
                <br />
                <Link to={`/update-project/${projectDetailData.project_id}`}>
                  <Button
                    htmlType="button"
                    size="large"
                    style={{ marginRight: 10 }}
                  >
                    Update
                  </Button>
                </Link>
                {/* <br /> */}
                {/* <Col span={10}> */}
                {/* <Button  htmlType="button" size="large" onClick={() => deleteUser(ticketsDetailData._id)}> */}

                {/* <Popconfirm
                  title="Are you sure you want to delete this Ticket?"
                  onConfirm={() => confirm(ticketsDetailData.ticket_id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button htmlType="button" size="large">
                    Delete
                  </Button>
                </Popconfirm> */}
                {/* </Col> */}
              </Card>
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    projectsLoader: state.project.projectsLoader,
    projectDetailLoader: state.project.projectDetailLoader,
    projectDetailData: state.project.projectDetailData,
  };
};

export default connect(mapStateToProps, {
  actionGetProjectDetail,
})(ViewProject);
