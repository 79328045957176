import { message } from "antd";
import axios from "axios";
import { BASE_URL } from "../../components/config/web-config";
import * as actionTypes from "../action";

export const actionGetTimeAll = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_TIME_LOADING, payload: true });
  try {
    let result = await axios.get(
      `${BASE_URL}/tickets-resolution-timing/v1/${id}`
    );
    dispatch({ type: actionTypes.GET_TIME_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_TIME_DATA,
        payload: result.data.data,
      });
      dispatch({
        type: actionTypes.TOTAL_TIME_COUNT,
        payload: result.data.total_records,
      });
      
    } else if (parseInt(result.data.status) === 404) {
      dispatch({
        type: actionTypes.GET_TIME_DATA,
        payload: [],
      });
      dispatch({
        type: actionTypes.TOTAL_TIME_COUNT,
        payload: 0,
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    message.error(error.message, 5);
    dispatch({ type: actionTypes.GET_TIME_LOADING, payload: false });
  }
};

export const actionAddTime = (timeData) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.ADD_TIME_LOADING, payload: true });
  try {
    let result = await axios.post(
      `${BASE_URL}/tickets-resolution-timing/v1`,
      timeData
    );
    dispatch({ type: actionTypes.ADD_TIME_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.ADD_TIME_DATA,
        payload: result.data.data,
      });
      dispatch({
        type: actionTypes.TOTAL_TIME_COUNT,
        payload: result.data.total_records,
      });
      dispatch({
        type: actionTypes.CHANGE_TOTAL_TIME_SPEND_GET_ALL_TICKTES,
        payload: {
          ticket_id: timeData.ticket,
          data: result.data.total_records,
        },
      });
      message.success(result.data.message, 5);
      //navigate && setTimeout(() => navigate(`/projects`), 1000);
    } else {
      message.error(result.data.message, 5);
    }
  } catch (error) {
    message.error(error.message, 5);
    dispatch({ type: actionTypes.ADD_TIME_LOADING, payload: false });
  }
};

export const actionUpdateTime =
  (timeData, id) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_TIME_LOADING, payload: true });
    try {
      let result = await axios.put(
        `${BASE_URL}/tickets-resolution-timing/${id}`,
        timeData
      );
      dispatch({ type: actionTypes.UPDATE_TIME_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.UPDATE_TIMESINGLE_DATA,
          payload: result.data.data,
        });
        dispatch({
          type: actionTypes.TOTAL_TIME_COUNT,
          payload: result.data.total_records,
        });
        dispatch({
          type: actionTypes.CHANGE_TOTAL_TIME_SPEND_GET_ALL_TICKTES,
          payload: {
            ticket_id: timeData.ticket,
            data: result.data.total_records,
          },
        });
        message.success(result.data.message, 5);
        // navigate && setTimeout(() => navigate(`/projects`), 1000);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.UPDATE_TIME_LOADING, payload: false });
    }
  };

export const actionGetTimeDetail = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_TIME_DETAIL_LOADING, payload: true });
  try {
    let result = await axios.get(
      `${BASE_URL}/tickets-resolution-timing/find-one/${id}`
    );
    dispatch({ type: actionTypes.GET_TIME_DETAIL_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_TIME_DETAIL_DATA,
        payload: result.data.data,
      });

      dispatch({
        type: actionTypes.TOTAL_TIMEHRS_COUNT,
        payload: result.data.total_records,
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    dispatch({ type: actionTypes.GET_TIME_DETAIL_LOADING, payload: false });
  }
};

export const actionDeleteTime =
  (timeData, id) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.DELETE_TIME_LOADING,
      payload: true,
    });
    try {
      let result = await axios.delete(
        `${BASE_URL}/tickets-resolution-timing/${id}`
      );
      dispatch({
        type: actionTypes.DELETE_TIME_LOADING,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        dispatch({ type: actionTypes.DELETE_TIME_ID, payload: id });

        dispatch({
          type: actionTypes.TOTAL_TIME_COUNT,
          payload: result.data.total_records,
        });
        dispatch({
          type: actionTypes.CHANGE_TOTAL_TIME_SPEND_GET_ALL_TICKTES,
          payload: {
            ticket_id: timeData.ticket,
            data: result.data.total_records,
          },
        });
        message.success(result.data.message, 5);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({
        type: actionTypes.DELETE_TIME_LOADING,
        payload: false,
      });
    }
  };
