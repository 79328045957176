import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  actionAddAreaOfConcern,
  actionGetAreaOfConcern,
} from "../../store/actions/ticketAction";
import {
  actionGetUserDetail,
  actionUpdateUser,
} from "../../store/actions/userAction";
const { Text } = Typography;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const UpdateUser = (props) => {
  const {
    userUpdateLoader,
    userDetailLoader,
    addAreaConcernLoader,
    userDetailData,
    actionGetAreaOfConcern,
    areaConcernData,
    areaConcernLoader,
    actionGetUserDetail,
    actionUpdateUser,
    actionAddAreaOfConcern,
  } = props;

  const [disable, setDisable] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [areaConcernValue, setAreaConcernValue] = useState("");
  const [roles, setRoles] = useState();
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const inputRef = useRef(null);

  const { userId } = useParams();

  const [form] = Form.useForm();
  const Navigate = useNavigate();

  const handleRoleChange = (d) => {
    setRoles(d);
    setDisable(d && d === "ADMIN" ? true : false);
    d === "ADMIN" && setAreaConcernValue(undefined);
    setSelectedOption(undefined);
    setAreaConcernValue(undefined);
  };

  useEffect(() => {
    actionGetAreaOfConcern();
  }, []);

  const handleChange = (value, KeyValuePair) => {
    setSelectedOption(KeyValuePair);
    setAreaConcernValue(KeyValuePair);
  };

  useEffect(() => {
    actionGetUserDetail(userId);
  }, []);

  useEffect(() => {
    const areaConcern = areaConcernData.map((data, index) => ({
      key: index,
      label: data.name,
      value: data.id,
    }));
    setData(areaConcern);
  }, [areaConcernData]);

  const onNameChange = (event, index) => {
    setName(event.target.value);
  };
  const addItem = () => {
    // setData([...data, newItem]);
    actionAddAreaOfConcern({ name: name });
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  useEffect(() => {
    const newArray =
      userDetailData.user_area_of_concern &&
      userDetailData.user_area_of_concern.length > 0 &&
      userDetailData.user_area_of_concern.map((item, index) => ({
        key: index,
        value: item.area_of_concern_id,
        label: item.name,
      }));

    const area =
      userDetailData &&
      userDetailData.user_area_of_concern &&
      userDetailData.user_area_of_concern.length > 0 &&
      userDetailData.user_area_of_concern[0];
    setAreaConcernValue(newArray);

    area &&
      setSelectedOption({
        label: area.name,
        value: area.area_of_concern_id,
      });

    form.setFieldsValue({
      first_name: userDetailData.first_name,
      last_name: userDetailData.last_name,
      email: userDetailData.email,
      phone: userDetailData.phone,
      roles: userDetailData.roles,
      agent_type: userDetailData.agent_type,
      area_of_concern: newArray,
    });

    // setSelectedOption({ label: "", value })
    setRoles(userDetailData.roles);
    setDisable(userDetailData.roles === "ADMIN" ? true : false);
  }, [userDetailData]);

  const onFinish = (values) => {
    const AreaConcernFinal = [];
    selectedOption &&
      selectedOption.length > 0 &&
      selectedOption.map((data, index) => {
        AreaConcernFinal.push({
          key: index,
          name: data.label,
          area_of_concern_id: data.value,
        });
      });
    if (userDetailData.roles === "AGENT" && !areaConcernValue) {
      setError("Please Select Area of Concern!");
      return false;
    }

    const AgentSingleObject = [
      {
        name: selectedOption && selectedOption.label,
        area_of_concern_id: selectedOption && selectedOption.value,
      },
    ];

    const updatedValues = {
      ...values,
      roles: userDetailData.roles === "AGENT" ? "AGENT" : values.roles,
      area_of_concern: roles === "AGENT" ? AgentSingleObject : AreaConcernFinal,
    };
    actionUpdateUser(updatedValues, Navigate, userId);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Fragment>
      <Spin spinning={userDetailLoader}>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Card
              className="main-card "
              title={
                <>
                  <Row align="middle">
                    <Col span={2}>
                      <h2 className="text-white text-base">
                        {userDetailData.roles === "AGENT"
                          ? "Update Agent"
                          : "Update User"}
                      </h2>
                    </Col>
                  </Row>
                </>
              }
              style={{
                width: "100%",
                minHeight: "60vh",
              }}
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form
                    autoComplete="off"
                    requiredMark={true}
                    form={form}
                    layout="vertical"
                    size="large"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >
                    <Row gutter={[32, 0]}>
                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          label="First Name"
                          name="first_name"
                          normalize={(value) =>
                            value.replace(/[^a-zA-Z]/g, "").trim()
                          }
                          rules={[
                            {
                              required: true,
                              message: "Please input your first name!",
                            },
                            {
                              validator: (_, value) =>
                                value.length <= 15
                                  ? Promise.resolve()
                                  : Promise.reject(
                                      new Error(
                                        "Please enter maximum 15 characters for first Name"
                                      )
                                    ),
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="first name"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Last Name"
                          colon={false}
                          name="last_name"
                          normalize={(value) =>
                            value.replace(/[^a-zA-Z]/g, "").trim()
                          }
                          rules={[
                            {
                              required: true,
                              message: "Please enter last name!",
                            },
                            {
                              validator: (_, value) =>
                                value.length <= 15
                                  ? Promise.resolve()
                                  : Promise.reject(
                                      new Error(
                                        "Please enter maximum 15 characters for last Name"
                                      )
                                    ),
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="last name"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Email"
                          normalize={(value) => value.trim()}
                          colon={false}
                          name="email"
                          rules={[
                            {
                              type: "email",
                              required: true,
                              // message: "Please enter email!",
                              message: "The input is not valid E-mail!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="Email"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Mobile"
                          normalize={(value) => value.trim()}
                          colon={false}
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message: "Please enter mobile!",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (value && value.length == 10) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error("Invalid Mobile Number!")
                                );
                              },
                            }),
                          ]}
                        >
                          <Input
                            maxLength={2}
                            autoFocus="none"
                            type={"number"}
                            placeholder="Mobile"
                          />
                        </Form.Item>
                      </Col>

                      {userDetailData.roles !== "AGENT" && (
                        <Col span={12}>
                          <Form.Item
                            label="Roles"
                            colon={false}
                            value={roles}
                            name="roles"
                            rules={[
                              {
                                required: true,
                                message: "Please select Roles.!",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Select Role"
                              onChange={handleRoleChange}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={[
                                {
                                  value: "ADMIN",
                                  label: "Admin",
                                },

                                {
                                  value: "USER",
                                  label: "User",
                                },
                                {
                                  value: "BUSINESS USER",
                                  label: "Business User",
                                },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                      )}

                      <Col span={12}>
                        <Form.Item
                          label="Area Of Concern"
                          colon={false}
                          name="area_of_concern"
                          rules={[
                            {
                              required: true,
                              message: "Please select Area Of Concern!",
                            },
                          ]}
                        >
                          <>
                            {userDetailData.roles === "USER" ? (
                              <Select
                                mode="multiple"
                                loading={areaConcernLoader}
                                dropdownRender={(menu) => (
                                  <>
                                    {menu}
                                    <Divider
                                      style={{
                                        margin: "8px 0",
                                      }}
                                    />
                                    <Space
                                      style={{
                                        padding: "0 8px 4px",
                                      }}
                                    >
                                      <Input
                                        placeholder="Please enter item"
                                        ref={inputRef}
                                        value={name}
                                        onChange={onNameChange}
                                      />
                                      <Button
                                        type="text"
                                        loading={addAreaConcernLoader}
                                        icon={<PlusOutlined />}
                                        className="not-white flex items-center"
                                        disabled={!name}
                                        onClick={addItem}
                                      >
                                        Add item
                                      </Button>
                                    </Space>
                                  </>
                                )}
                                value={
                                  areaConcernValue
                                    ? areaConcernValue
                                    : undefined
                                }
                                disabled={disable ? true : false}
                                onChange={handleChange}
                                style={{
                                  width: "100%",
                                }}
                                options={data}
                              />
                            ) : (
                              <Select
                                loading={areaConcernLoader}
                                dropdownRender={(menu) => (
                                  <>
                                    {menu}
                                    <Divider
                                      style={{
                                        margin: "8px 0",
                                      }}
                                    />
                                    <Space
                                      style={{
                                        padding: "0 8px 4px",
                                      }}
                                    >
                                      <Input
                                        placeholder="Please enter item"
                                        ref={inputRef}
                                        value={name}
                                        onChange={onNameChange}
                                      />
                                      <Button
                                        type="text"
                                        loading={addAreaConcernLoader}
                                        icon={<PlusOutlined />}
                                        className="not-white flex  items-center"
                                        disabled={!name}
                                        onClick={addItem}
                                      >
                                        Add item
                                      </Button>
                                    </Space>
                                  </>
                                )}
                                value={
                                  areaConcernValue
                                    ? areaConcernValue
                                    : undefined
                                }
                                disabled={disable ? true : false}
                                onChange={handleChange}
                                style={{
                                  width: "100%",
                                }}
                                options={data}
                              />
                            )}
                            {error && (
                              <Text
                                style={{
                                  color: "#ff4d4f",
                                  fontWeight: "400",
                                  fontSize: "13px",
                                }}
                              >
                                {error}
                              </Text>
                            )}
                          </>
                        </Form.Item>
                      </Col>
                      {userDetailData.roles === "AGENT" && (
                        <Col span={12}>
                          <Form.Item
                            label="Agent Type"
                            colon={false}
                            name="agent_type"
                            rules={[
                              {
                                required: true,
                                message: "Please select agent type!",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              allowClear
                              // onChange={handleTypeChange}
                              placeholder="Select Agent Type"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={[
                                {
                                  value: "FUNCTIONAL",
                                  label: "Functional",
                                },
                                {
                                  value: "TECHNICAL",
                                  label: "Technical",
                                },
                                {
                                  value: "CSR",
                                  label: "CSR",
                                },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                      )}
                      <Col span={24}>
                        <Form.Item>
                          <Button
                            loading={userUpdateLoader}
                            align="center"
                            htmlType="submit"
                            className="primary"
                            size="large"
                            style={{ width: 200, marginTop: "30px" }}
                          >
                            Update
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Spin>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userUpdateLoader: state.users.userUpdateLoader,
    userDetailLoader: state.users.userDetailLoader,
    userDetailData: state.users.userDetailData,
    areaConcernLoader: state.ticket.areaConcernLoader,
    areaConcernData: state.ticket.areaConcernData,
    addAreaConcernLoader: state.ticket.addAreaConcernLoader,
  };
};

export default connect(mapStateToProps, {
  actionGetUserDetail,
  actionUpdateUser,
  actionGetAreaOfConcern,
  actionAddAreaOfConcern,
})(UpdateUser);
