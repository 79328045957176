import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <section class="bg-white dark:bg-gray-900">
            <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div class="mx-auto max-w-screen-sm text-center">
                    <h1 class="mb-4 text-4xl tracking-tight font-extrabold lg:text-4xl text-[#265ba6] ">404 Not Found</h1>
                    <p class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-3xl ">Something's missing.</p>
                    <p class="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Sorry, we can't find that page. You'll find lots to explore on the home page. </p>
                    <Link to="/" class="inline-flex text-white bg-[#265ba6] hover:bg-primary-800 ring-0 focus:ring-0 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-0 my-4">Go to Dashboard</Link>
                </div>
            </div>
        </section>
    )
}

export default NotFound
