import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
  SearchOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Dropdown,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
  Table,
  Typography,
} from "antd";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { actionGetProjectDetail } from "../../store/actions/projectAction";
import { hideReports } from "../../store/actions/userAction";
import {
  actionDeleteUser,
  actionGetUserDetail,
  actionGetUsersAll,
} from "../../store/actions/userAction";

const { Text } = Typography;
const { Search } = Input;

const User = (props) => {
  const {
    actionGetUsersAll,
    actionDeleteUser,
    usersLoader,
    hideReportsLoader,
    usersData,
    deleteUserLoading,
    usersCount,
    actionGetUserDetail,
    userDetailLoader,
    userDetailData,
    projectDetailData,
    userProfileData,
    hideReports,
    is_report_visible,
  } = props;
  const { userId } = useParams();
  const { projectId } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [current, setCurrent] = useState(1);

  const [search, setSearch] = useState();
  const [role, setRole] = useState();

  const showModal = (id) => {
    setIsModalOpen(true);
    actionGetUserDetail(id);
  };
  const handleOk = (e) => {
    setIsModalOpen(false);
  };
  const handleCancel = (e) => {
    setIsModalOpen(false);
  };

  const userdata = [
    {
      title: "Name",
      description: (
        <>{userDetailData.first_name + " " + userDetailData.last_name}</>
      ),
    },
    {
      title: "Email",
      description: <>{userDetailData && userDetailData.email}</>,
    },
    {
      title: "Mobile Number",
      description: <>{userDetailData && userDetailData.phone}</>,
    },

    {
      title: "Role",
      description: (
        <>
          <p style={{ textTransform: "lowercase" }}>
            {userDetailData && userDetailData.roles}
          </p>
        </>
      ),
    },
  ];

  const dataList = [];
  const [sortedInfo, setSortedInfo] = useState();

  const [data, setData] = useState([]);

  const confirm = (id) => {
    actionDeleteUser(id);
  };

  useEffect(() => {
    actionGetUsersAll(0, 10);
    actionGetProjectDetail(projectId);
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // ellipsis: true,
      width: 100,
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      // ellipsis: true,
      width: 100,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      // ellipsis: true,
      width: 100,
    },
    {
      title: "Mobile Number",
      dataIndex: "phone",
      key: "phone",
      // ellipsis: true,
      width: 100,
      // width: 80,
    },
    {
      title: "Role",
      dataIndex: "roles",
      key: "roles",
      // ellipsis: true,
      width: 100,
    },
    {
      title: "Report Visibility (Business User)",
      dataIndex: "is_report_visible",
      key: "is_report_visible",
      // ellipsis: true,
      width: 100,
    },

    {
      title: "Action",
      fixed: "right",
      align: "center",
      width: 80,
      key: "action",
      render: (item) => (
        <Dropdown
          placement="bottom"
          menu={{
            items: [
              {
                label: (
                  <div>
                    <Link
                      // onClick={(id) => showModal(item._id)}
                      to={`/view-user/${item._id}`}
                      style={{ color: "black" }}
                    >
                      <EyeOutlined
                        style={{
                          color: "#265BA6",
                          fontSize: "18px",
                          marginRight: 10,
                        }}
                      />
                      View
                    </Link>
                  </div>
                ),
              },
              {
                label: (
                  <div>
                    <Link
                      to={`/update-user/${item._id}`}
                      style={{ color: "black" }}
                    >
                      <EditOutlined
                        style={{
                          color: "#265BA6",
                          fontSize: "18px",
                          marginRight: 10,
                        }}
                      />
                      Edit
                    </Link>
                  </div>
                ),
              },
              // Change Password option (conditionally rendered based on user role)

              {
                label: userProfileData && userProfileData.roles === "ADMIN" && (
                  <div>
                    <Link
                      to={`/change-password/${item._id}`}
                      style={{ color: "black" }}
                    >
                      <KeyOutlined
                        style={{
                          color: "#265BA6",
                          fontSize: "18px",
                          marginRight: 10,
                        }}
                      />
                      Change Password
                    </Link>
                  </div>
                ),
              },

              {
                label: (
                  <Popconfirm
                    okButtonProps={{
                      loading: deleteUserLoading,
                    }}
                    placement="topLeft"
                    title="Are you sure you want to delete this User?"
                    onConfirm={() => confirm(item._id)}
                    
                    okText="Yes"
                    cancelText="No"
                  >
                    <div style={{ color: "black" }}>
                      <DeleteOutlined
                        style={{
                          color: "#265BA6",
                          fontSize: "18px",
                          marginRight: 10,
                        }}
                      />
                      Delete
                    </div>
                  </Popconfirm>
                ),
              },
            ],
          }}
        >
          <a>
            <EllipsisOutlined style={{ fontSize: "20px" }} />
          </a>
        </Dropdown>
      ),
    },
  ];

  const toggleHide = (id, item) => {
    const val = item == "YES" ? "NO" : "YES";
    hideReports(id, val);
  };

  useMemo(() => {
    usersData &&
      usersData.map((e, index) => {
        dataList.push({
          key: index,
          _id: e.id,
          username: <Text strong>{e.username}</Text>,
          name: (
            <Text strong>
              {e.first_name} {e.last_name}
            </Text>
          ),
          email: <Text strong>{e.email}</Text>,
          phone: <Text strong>{e.phone}</Text>,
          is_report_visible:
            e.roles === "BUSINESS USER" ? (
              <Popconfirm
                title={`Are you sure you want to ${
                  e.is_reports_visible == "YES" ? "Hide" : "Show"
                } tickets-reports for this user`}
                // description="Are you sure you want to show tickets reports to this user?"
                onConfirm={() => toggleHide(e.id, e.is_reports_visible)}
                okText="Yes"
                cancelText="No"
              >
                <Switch
                  checked={e.is_reports_visible == "YES" ? true : false}
                  disabled={e.is_loading}
                  loading={e.is_loading}
                  // onChange={() => toggleHide(e.id, e.is_reports_visible)}
                />
              </Popconfirm>
            ) : (
              <Switch checked={true} loading={false} disabled />
            ),
          roles: (
            <Text
              className="capitalize"
              style={{ textTransform: "capitalize" }}
              strong
            >
              {e.roles && e.roles.toLowerCase()}
            </Text>
          ),
        });
      });
    setData(dataList);
  }, [usersData]);

  const onClickPaginationNumber = (e, t, sorter) => {
    actionGetUsersAll(e.current - 1, e.pageSize, search, role);
    setCurrent(e.current);
  };

  const onFinishSearch = (value) => {
    setCurrent(1);
    setSearch(value.keyword ? value.keyword : "");
    setRole(value.role ? value.role : "");
    actionGetUsersAll(
      0,
      10,
      value.keyword ? value.keyword : "",
      value.role ? value.role : ""
    );
  };

  return (
    <Fragment>
      <Typography.Title level={3} className="ml-3">
        Users
      </Typography.Title>
      <Card
        className="main-card no-padding"
        title={
          <>
            <Row align="middle" justify="">
              <Col xs={20} sm={20} md={16} lg={16} xl={18} align="">
                <Form
                  className="custom-form flex md:pl-2 	"
                  name="basic"
                  layout="inline"
                  onFinish={onFinishSearch}
                  autoComplete="off"
                >
                  <Form.Item name="keyword" className="custom-slect ">
                    <Input
                      style={{ width: "100%" }}
                      autoFocus="none"
                      type={"text"}
                      //onChange={(e) => onchangeSearch(e)}
                      allowClear
                      prefix={
                        <SearchOutlined className="site-form-item-icon text-white" />
                      }
                      placeholder="Search Here"
                    />
                  </Form.Item>

                  <Form.Item
                    name="role"
                    className="custom-slect md:pt-1 lg:pt-0"
                  >
                    <Select
                      className="user"
                      showSearch
                      placeholder="Select Role"
                      optionFilterProp="children"
                      allowClear
                      onClear={() => setRole("")}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={[
                        {
                          value: "ADMIN",
                          label: "Admin",
                        },
                        {
                          value: "AGENT",
                          label: "Agent",
                        },
                        {
                          value: "USER",
                          label: "User",
                        },
                        {
                          value: "BUSINESS USER",
                          label: "Business User",
                        },
                      ]}
                    />
                  </Form.Item>

                  <Form.Item className="md:pt-1 lg:pt-0">
                    <Button
                      className="bg-white search-btn user"
                      type="primary"
                      htmlType="submit"
                    >
                      Search
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col xs={4} sm={4} md={8} lg={8} xl={6} align="right">
                <Link to="/create-user">
                  <Button
                    type="primary"
                    className="bg-white-btn"
                    size={"large"}
                  >
                    Create User
                  </Button>
                </Link>
                <Link to="/create-agent">
                  <Button
                    type="primary"
                    className="bg-white-btn ml-4"
                    size={"large"}
                  >
                    Create Agent
                  </Button>
                </Link>
              </Col>
            </Row>
          </>
        }
        bordered={false}
        style={{
          width: "100%",
        }}
      >
        <Row>
          <Col span={24}>
            <Table
              bordered
              className="user-table"
              sticky={true}
              size="small"
              pagination={{
                total: usersCount,
                current: current,
                hideOnSinglePage: true,
              }}
              columns={columns}
              dataSource={data}
              loading={usersLoader}
              onChange={(e) => onClickPaginationNumber(e)}
              scroll={{
                x: "max-content",
                //  y: window.innerWidth < 1500 ? "80vh" : "60vh",
              }}
            />
          </Col>
        </Row>
      </Card>
      <Modal
        title="View User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        // centered
        className="popUp"
      >
        <div className="user-detail">
          <Row gutter={16}>
            <Col span={24}>
              <Descriptions
                title=""
                layout="horizontal"
                colon={true}
                bordered
                column={1}
              >
                {userdata &&
                  userdata.length > 0 &&
                  userdata.map((item, index) => (
                    <Descriptions.Item
                      key={index}
                      label={item.title}
                      style={{
                        background: index % 2 !== 0 ? "#FFFFFF" : "#E2EEFF",
                      }} // Set color dynamically or default to black
                    >
                      {item.description ? item.description : "-"}
                    </Descriptions.Item>
                  ))}
              </Descriptions>
            </Col>
          </Row>
        </div>
      </Modal>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    usersLoader: state.users.usersLoader,
    usersData: state.users.usersData,
    deleteUserLoading: state.users.deleteUserLoading,
    usersCount: state.users.usersCount,
    userDetailLoader: state.users.userDetailLoader,
    userDetailData: state.users.userDetailData,
    projectDetailData: state.project.projectDetailData,
    userProfileData: state.auth.userProfileData,
    hideReportsLoader: state.users.hideReportsLoader,
    is_report_visible: state.users.is_report_visible,
  };
};

export default connect(mapStateToProps, {
  actionGetUsersAll,
  actionDeleteUser,
  actionGetUserDetail,
  actionGetProjectDetail,
  hideReports,
})(User);
