import React from 'react'
import {
    Card,
    Col,
    Row,
    Table,
    Typography,
    Image
} from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import timeSpent from "../../assets/img/icon/timeSpent.png";
import agentYearly from "../../assets/img/icon/agentYearly.png";



const BusinessReport = ({
    userProfileData
}) => {
    return (
        <>
            <Typography.Title level={3} className="ml-3">
                Ticket  Reports
            </Typography.Title>

            <Row className="mt-10"
                gutter={[8, 32]}
            >

                {/* {userProfileData && userProfileData.roles !== "BUSINESS USER" && */}
                <Col className="gutter-row" lg={12} md={12} sm={24} xxl={12}>
                    <Link to="/ticket-agent-yearly-report">
                        <Card
                            align="center"
                            hoverable
                            className="mx-3 estimating-card cursor-pointer"
                            bordered={false}
                        >
                            <Image preview={false} width={50} height={50} src={agentYearly} alt="User Productivity" />
                            <p className="text-[#265ba6] font-bold text-[20px] tracking-wide" >
                                <span className="mr-2">
                                    Monthly Agent Productivity Overview
                                </span>

                            </p>
                            <Typography.Text className="text-[1.145rem] text-[#808080]" >
                                Optimize Agent Hours for Efficiency with Monthly Ticket Analysis
                            </Typography.Text>
                        </Card>
                    </Link>
                </Col>
                {/* }
                {userProfileData && userProfileData.roles !== "BUSINESS USER" && */}
                <Col className="gutter-row" lg={12} md={12} sm={24} xxl={12}>
                    <Link to="/ticket-time-spent-report">
                        <Card
                            align="center"
                            hoverable
                            className="mx-3 estimating-card cursor-pointer"
                            bordered={false}
                        >
                            <Image preview={false} width={50} height={50} src={timeSpent} alt="User Productivity" />
                            <p className="text-[#265ba6] font-bold text-[20px] tracking-wide" >
                                <span className="mr-2">
                                    Ticket Time Tracking Report
                                </span>

                            </p>
                            <Typography.Text className="text-[1.145rem] text-[#808080]" >
                                Track time on specific tickets and assigned agents for improved project management and accountability
                            </Typography.Text>
                        </Card>
                    </Link>
                </Col>
                {/* } */}
            </Row>

        </>

    )
}
const mapStateToProps = (state) => {
    return {
        userProfileData: state.auth.userProfileData,
    };
};
export default connect(mapStateToProps, {
})(BusinessReport);
