import {
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
  SearchOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Table,
  Typography,
} from "antd";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import project from "../../assets/img/comman/Group (6).svg";
import {
  actionDeleteProject,
  actionGetProjectAll,
  actionGetProjectDetail,
  actionUpdateProjectTotalHours,
} from "../../store/actions/projectAction";

const { Text } = Typography;
const { Search } = Input;

const Project = (props) => {
  const {
    actionGetProjectAll,
    actionDeleteProject,
    projectsLoader,
    projectsData,
    actionUpdateProjectTotalHours,
    deleteProjectLoading,
    projectsCount,
    actionGetProjectDetail,
    userProfileData,
  } = props;

  const [search, setSearch] = useState();
  const [modalProjectId, setModalProjectId] = useState();
  const [modalProjectHours, setModalProjectHours] = useState();
  const [data, setData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [editHRSModalOpen, setEditHRSModalOpen] = useState(false);
  const [addHoursForm] = Form.useForm();

  const confirm = (id) => {
    actionDeleteProject(id);
  };

  useEffect(() => {
    actionGetProjectAll(0, 10);
  }, []);

  const columns = [
    {
      title: "Project Title",
      dataIndex: "title",
      key: "title",
      width: 100,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 100,
    },
    {
      title: "Manager Name",
      dataIndex: "manager_name",
      key: "manager_name",
      width: 100,
    },
    {
      title: "Manager Email",
      dataIndex: "manager_email",
      key: "manager_email",
      width: 100,
    },
    {
      title: "Total Hours",
      dataIndex: "hours",
      key: "hours",
      width: 100,
    },
    {
      title: "Business Confirmation Email Duration (In Days)",
      dataIndex: "email_duration",
      key: "email_duration",
      width: 100,
    },

    {
      title: "Action",
      fixed: "right",
      align: "center",
      width: 80,
      key: "action",
      render: (item) => (
        <Dropdown
          placement="bottom"
          menu={{
            items: [
              {
                label: (
                  <div>
                    <Link
                      to={`/view-project/${item.id}`}
                      // onClick={(id) => showModal(item.id)}
                      style={{ color: "black" }}
                    >
                      <EyeOutlined
                        style={{
                          color: "#265BA6",
                          fontSize: "18px",
                          marginRight: 10,
                        }}
                      />
                      View
                    </Link>
                  </div>
                ),
              },
              {
                label: (
                  <div>
                    <Link
                      to={`/update-project/${item.id}`}
                      style={{ color: "black" }}
                    >
                      <EditOutlined
                        style={{
                          color: "#265BA6",
                          fontSize: "18px",
                          marginRight: 10,
                        }}
                      />
                      Edit
                    </Link>
                  </div>
                ),
              },
              {
                label: (
                  <div>
                    <Link
                      // to={`/update-project/${item.id}`}
                      onClick={() => {
                        setEditHRSModalOpen(true);
                        setModalProjectId(item.id);
                        setModalProjectHours(
                          projectsData.length &&
                            projectsData.find((d) => d.project_id == item.id)
                              ?.hours
                        );
                      }}
                      style={{ color: "black" }}
                    >
                      <PlusOutlined
                        style={{
                          color: "#265BA6",
                          fontSize: "18px",
                          marginRight: 10,
                        }}
                      />
                      Add more hours
                    </Link>
                  </div>
                ),
                style: {
                  display:
                    userProfileData &&
                    (userProfileData.email === "chirag@vytechenterprise.com" ||
                      userProfileData.email === "chirag@vytech.co")
                      ? "block"
                      : "none",
                },
              },
              // {
              //   label: (
              //     <div>
              //       <Image
              //         src={project}
              //         style={{ marginRight: "10px", width: "20px" }}
              //         preview={false}
              //       />{" "}
              //       Archived
              //     </div>
              //   ),
              // },
              // {
              //   label: (
              //     <Popconfirm
              //       okButtonProps={{
              //         loading: deleteProjectLoading,
              //       }}
              //       placement="topLeft"
              //       title="Are you sure to delete this Project?"
              //       onConfirm={() => confirm(item.id)}
              //       okText="Yes"
              //       cancelText="No"
              //     >
              //       <span style={{ color: "black" }}>
              //         <DeleteOutlined
              //           style={{
              //             color: "#265BA6",
              //             fontSize: "18px",
              //             marginRight: 10,
              //           }}
              //         />
              //         Delete
              //       </span>
              //     </Popconfirm>
              //   ),
              // },
              // {
              //   label: (
              //     <div>
              //       <Image
              //         src={project}
              //         style={{ marginRight: "10px", width: "20px" }}
              //         preview={false}
              //       />{" "}
              //       Tickets
              //     </div>
              //   ),
              // },
            ],
          }}
        >
          <a>
            <EllipsisOutlined style={{ fontSize: "20px" }} />
          </a>
        </Dropdown>
      ),
    },
  ];
  const dataList = [];
  useMemo(() => {
    projectsData &&
      projectsData.map((e, index) => {
        dataList.push({
          key: index,
          id: e.project_id,
          title: (
            <Link to={`/view-project/${e.project_id}`} className="link">
              {e.title}
            </Link>
          ),
          description: (
            <div>
              {e.description && e.description.length > 100
                ? e.description.slice(0, 100) + "..."
                : e.description}
            </div>
          ),
          hours: <div>{e.hours ? parseFloat(e.hours).toFixed(2) : "-"}</div>,
          manager_name: <div>{e.manager_name ? e.manager_name : "-"}</div>,
          manager_email: <div>{e.manager_email ? e.manager_email : "-"}</div>,
          email_duration: (
            <div>
              {e.awaiting_business_confirmation_email_duration
                ? e.awaiting_business_confirmation_email_duration
                : "-"}
            </div>
          ),
          projectagent:
            e.projectagent && e.projectagent.length > 0 ? (
              <Text strong>
                {e.projectagent.map((data, index) => (
                  <p key={index}>{data.agent_id ? data.agent_id : "-"}</p>
                ))}
              </Text>
            ) : (
              <Text>-</Text>
            ),
          projectuser:
            e.projectuser && e.projectuser.length > 0 ? (
              <Text strong>
                {e.projectuser.map((data, index) => (
                  <p key={index}>
                    {data.project_agent_id ? data.project_agent_id : "-"}
                  </p>
                ))}
              </Text>
            ) : (
              <Text>-</Text>
            ),
        });
      });
    setData(dataList);
  }, [projectsData]);

  const onClickPaginationNumber = (e) => {
    actionGetProjectAll(e.current - 1, e.pageSize, search);
    setCurrent(e.current);
  };

  const onFinishSearch = (value) => {
    setCurrent(1);
    setSearch(value.keyword);
    actionGetProjectAll(0, 10, value.keyword);
  };

  const handleAddHRSSubmit = (values) => {
    const toFixedHRS = parseFloat(values.hours).toFixed(2);
    const req = {
      project_id: modalProjectId.toString(),
      hours: toFixedHRS,
    };
    actionUpdateProjectTotalHours(req);
    // setTimeout(() => {
    //   setEditHRSModalOpen(false);
    // }, 500);
    setEditHRSModalOpen(false);
  };
  const handleAddHRSCancel = () => {
    setEditHRSModalOpen(false);
  };

  return (
    <Fragment>
      <Typography.Title level={3} className="ml-3">
        Projects
      </Typography.Title>
      <Card
        className="main-card no-padding"
        title={
          <>
            <Row align="middle" justify="">
              <Col span={12}>
                <Form
                  className="custom-form md:pl-3 sm:pl-8"
                  name="basic"
                  layout="inline"
                  onFinish={onFinishSearch}
                  autoComplete="off"
                >
                  <Form.Item name="keyword" className="custom-slect">
                    <Input
                      style={{ width: "100%" }}
                      autoFocus="none"
                      type={"text"}
                      //onChange={(e) => onchangeSearch(e)}
                      allowClear
                      prefix={
                        <SearchOutlined className="site-form-item-icon text-white" />
                      }
                      placeholder="Search Here"
                    />
                  </Form.Item>

                  <Form.Item className="md:pt-1 lg:pt-0">
                    <Button
                      className="bg-white search-btn user"
                      type="primary"
                      htmlType="submit"
                    >
                      Search
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col span={12} align="right">
                <Link to="/create-project">
                  <Button
                    type="primary"
                    className="bg-white-btn create-project-btn"
                    size={"large"}
                  >
                    Create Project
                  </Button>
                </Link>
              </Col>
            </Row>
          </>
        }
        bordered={false}
        style={{
          width: "100%",
        }}
      >
        <Row>
          <Col span={24}>
            <Table
              bordered
              className="user-table"
              sticky={true}
              size="small"
              pagination={{
                current: current,
                total: projectsCount,
                hideOnSinglePage: true,
              }}
              columns={columns}
              dataSource={data}
              loading={projectsLoader}
              onChange={(e) => onClickPaginationNumber(e)}
              scroll={{
                x: "max-content",
                // y: window.innerWidth < 1500 ? "55vh" : "60vh",
              }}
            />
          </Col>
        </Row>
      </Card>
      {console.log(modalProjectHours, "modalProjectHours")}
      <Modal
        title={`Current Project Hours -  ${modalProjectHours}`}
        open={editHRSModalOpen}
        okText="Add"
        onOk={() => {
          addHoursForm
            .validateFields()
            .then((values) => {
              addHoursForm.resetFields();
              handleAddHRSSubmit(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        onCancel={handleAddHRSCancel}
      >
        <Form layout="vertical" form={addHoursForm}>
          <Form.Item
            name="hours"
            label="Hours"
            normalize={(value) => value.replace(/[^0-9.]/g, "").trim()}
            rules={[
              {
                required: true,
                message: "Please input Hours!",
              },
            ]}
          >
            <Input
              type="text"
              // autoFocus="none"
              autoComplete="off"
              placeholder="Add More Hours"
            />
          </Form.Item>
        </Form>
        {/* <p>Are you sure you want to edit total hours of this project?</p> */}
      </Modal>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    projectsLoader: state.project.projectsLoader,
    projectsData: state.project.projectsData,
    deleteProjectLoading: state.project.deleteProjectLoading,
    projectsCount: state.project.projectsCount,
    userProfileData: state.auth.userProfileData,
  };
};

export default connect(mapStateToProps, {
  actionGetProjectAll,
  actionDeleteProject,
  actionGetProjectDetail,
  actionUpdateProjectTotalHours,
})(Project);
