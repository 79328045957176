import { Card, Empty, Spin } from "antd";
import React, { useEffect } from "react";
import { actionGetProjectHRSLog } from "../../store/actions/projectAction";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { FieldTimeOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const ProjectHoursLogs = (props) => {
  const { actionGetProjectHRSLog, projectHRSlogData, projectHRSlogLoader } =
    props;
  const { projectId } = useParams();

  useEffect(() => {
    if (projectId) actionGetProjectHRSLog(projectId);
  }, [projectId]);

  return (
    <div className="mt-5">
      {projectHRSlogData && projectHRSlogData.length > 0 ? (
        projectHRSlogData.map((data, index) => {
          return (
            <Card
              className="w-full lg:w-[70%] xl:w-[65%] task-card mt-3"
              key={index}
            >
              <div className="flex justify-between">
                <div className="flex space-x-2 items-center">
                    <FieldTimeOutlined style={{ fontSize: "18px" , color:'grey'}} />
                  <p className="text-base font-medium">{data?.log}</p>
                </div>
                <p className="text-base font-normal">
                  {dayjs(data.created_at).format("DD/MM/YYYY h:mm:ss A")}
                </p>
              </div>
            </Card>
          );
        })
      ) : (
        <Card className="mt-5 w-full lg:w-[70%] xl:w-[65%] task-card">
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className="project-log-empty-card"
            description={"Data Not Found"}
          />
        </Card>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    projectHRSlogLoader: state.project.projectHRSlogLoader,
    projectHRSlogData: state.project.projectHRSlogData,
  };
};

export default connect(mapStateToProps, {
  actionGetProjectHRSLog,
})(ProjectHoursLogs);
