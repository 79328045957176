import React from "react";
import { Tabs } from "antd";
import ViewProject from "./ViewProject";
import ProjectHoursLogs from "./ProjectHoursLogs";

const ProjectTabs = () => {
  const items = [
    {
      key: "1",
      label: "Project Detail",
      children: <ViewProject />,
    },
    {
      key: "2",
      label: "Project Logs",
      children: <ProjectHoursLogs />,
    },
  ];
  return (
    <Tabs
      defaultActiveKey={"1"}
      items={items}
      // onTabClick={(key) => {
      //   key === '1' && handleGetTcketDetailsData();
      // }}
    />
  );
};

export default ProjectTabs;
