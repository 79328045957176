import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Spin,
  Typography,
  message
} from "antd";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actionUpdateUserProfile } from "../../store/actions/authAction";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const EditProfile = (props) => {
  const { updateProfileLoader, actionUpdateUserProfile, userProfileData } =
    props;

  const [loading, setLoading] = useState(false);
  const [profilePicUrl, setProfilePicUrl] = useState();

  const Navigate = useNavigate();

  const [form] = Form.useForm();

  useMemo(() => { }, [userProfileData]);

  useEffect(() => {
    userProfileData &&
      form.setFieldsValue({
        first_name: userProfileData.first_name,
        last_name: userProfileData.last_name,
        email: userProfileData.email,
        phone: userProfileData.phone,
        profile_pic: userProfileData.profile_pic,
      });
    setProfilePicUrl(userProfileData.profile_pic);
  }, [userProfileData]);

  const onFinish = (values) => {
    actionUpdateUserProfile(values, userProfileData._id);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };

  const handleChangeUserPic = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setProfilePicUrl(info.file.response.file.path);
        //actionUploadBannerPic(url, Navigate);
        message.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  return (
    <Fragment>
      <Spin spinning={updateProfileLoader}>
        <Row gutter={[0, 24]}>
          <Col span={18}>
            <Card
              style={{
                width: "100%",
                minHeight: "60vh",
              }}
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Typography.Title level={4}>
                    <span className="text-line">Personal Details</span>
                  </Typography.Title>
                </Col>
                <Col span={24}>
                  <Form
                    autoComplete="off"
                    requiredMark={"optional"}
                    form={form}
                    size="large"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >
                    <Row gutter={[32, 0]}>
                      <Col span={12}>
                        <span>First Name</span>
                        <Form.Item
                          colon={false}
                          name="first_name"
                          normalize={(value) =>
                            value.replace(/[^a-zA-Z]/g, "").trim()
                          }
                          rules={[
                            {
                              required: true,
                              message: "Please input your first name!",
                            },
                            {
                              validator: (_, value) =>
                                value.length <= 15
                                  ? Promise.resolve()
                                  : Promise.reject(
                                    new Error(
                                      "Please enter maximum 15 characters for first Name"
                                    )
                                  ),
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="first name"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <span>Last Name</span>
                        <Form.Item
                          colon={false}
                          name="last_name"
                          normalize={(value) =>
                            value.replace(/[^a-zA-Z]/g, "").trim()
                          }
                          rules={[
                            {
                              required: true,
                              message: "Please enter last name!",
                            },
                            {
                              validator: (_, value) =>
                                value.length <= 15
                                  ? Promise.resolve()
                                  : Promise.reject(
                                    new Error(
                                      "Please enter maximum 15 characters for last Name"
                                    )
                                  ),
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="last name"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <span>Email</span>
                        <Form.Item
                          normalize={(value) => value.trim()}
                          colon={false}
                          name="email"
                          rules={[
                            {
                              type: "email",
                              required: true,
                              // message: "Please enter email!",
                              message: "The input is not valid E-mail!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="Email"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <span>Mobile number</span>

                        <Form.Item
                          normalize={(value) => value.trim()}
                          colon={false}
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Mobile Number!',
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (value && (value.length !== 10)) {
                                  return Promise.reject("Please enter a valid 10-digit mobile number! ");
                                }

                                return Promise.resolve();
                              },
                            })
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            placeholder="Mobile"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item normalize={(value) => value.trim()}>
                          <Button
                            loading={updateProfileLoader}
                            align="center"
                            htmlType="submit"
                            className="primary"
                            size="large"
                            style={{ width: 200, marginTop: "30px" }}
                          >
                            Save
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Spin>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userProfileData: state.auth.userProfileData,
    updateProfileLoader: state.auth.updateProfileLoader,
  };
};

export default connect(mapStateToProps, { actionUpdateUserProfile })(
  EditProfile
);
