import {
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  actionDeleteKnowledge,
  actionGetKnowledgeAll,
  actionGetKnowledgeDetail,
} from "../../store/actions/knowlegdeAction";
import {
  actionAddAreaOfConcern,
  actionGetAreaOfConcern,
  actionGetTicketDetail,
} from "../../store/actions/ticketAction";

import dayjs from "dayjs";

const { Text } = Typography;
const { Search } = Input;
const { RangePicker } = DatePicker;

const Knowledge = (props) => {
  const {
    actionDeleteKnowledge,
    knowledgeLoader,
    knowledgeData,
    actionGetKnowledgeAll,
    deleteKnowledgeLoading,
    knowledgeCount,
    actionGetKnowledgeDetail,
    knowledgeDetailLoader,
    projectDetailData,
    ticketsDetailLoader,
    actionGetAllTickets,
    actionGetAreaOfConcern,
    areaConcernData,
  } = props;

  const { projectId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState();
  const [role, setRole] = useState();
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [areaData, setAreaData] = useState();
  const [status, setStatus] = useState();
  const [current, setCurrent] = useState();

  const handleOk = (e) => {
    setIsModalOpen(false);
  };
  const handleCancel = (e) => {
    setIsModalOpen(false);
  };

  const projectdata = [
    {
      title: "Title",
      description: <>{projectDetailData && projectDetailData.title}</>,
    },
    {
      title: "Description",
      description: <>{projectDetailData && projectDetailData.description}</>,
    },
    {
      title: "Module",
      areaofconcernName: (
        <>{projectDetailData && projectDetailData.areaofconcernName}</>
      ),
    },
  ];

  const confirm = (id) => {
    actionDeleteKnowledge(id);
  };

  useEffect(() => {
    actionGetKnowledgeAll(
      0,
      10,
      startDate ? startDate : "",
      endDate ? endDate : ""
    );
  }, []);
  useEffect(() => {
    actionGetAreaOfConcern();
  }, []);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
      width: 200,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 200,
    },
    {
      title: "Module",
      dataIndex: "areaofconcernName",
      key: "areaofconcernName",
      width: 200,
    },

    {
      title: (
        <div className="flex justify-center items-center m-auto">
          <CalendarOutlined />
          <div className=" ml-1 flex justify-center items-center ">
            Submission Date
          </div>
        </div>
      ),
      dataIndex: "created_at",
      key: "created_at",
      width: 200,
    },

    {
      title: "Action",
      fixed: "right",
      align: "center",
      width: 80,
      key: "action",
      render: (item) => (
        <>
          <Space size="middle">
            <Link
              to={`/view-knowlegde/${item.id}`}
              // onClick={(id) => showModal(item.id)}
              style={{ color: "black" }}
            >
              <Tooltip placement="top" title={"View Knowledge Base"}>
                <EyeOutlined
                  style={{
                    color: "#265BA6",
                    fontSize: "18px",
                    // marginRight: 5,
                  }}
                />
              </Tooltip>
            </Link>

            <Link
              to={`/update-knowlegde/${item.id}`}
              style={{ color: "black" }}
            >
              <Tooltip placement="top" title={"Update Knowledge Base"}>
                <EditOutlined
                  style={{
                    color: "#265BA6",
                    fontSize: "18px",
                    // marginRight: 5,
                  }}
                />
              </Tooltip>
            </Link>
            <Tooltip placement="top" title={"Delete Knowledge Base"}>
              <Popconfirm
                okButtonProps={{
                  loading: deleteKnowledgeLoading,
                }}
                placement="topLeft"
                title="Are you sure you want to delete this Knowledge Base?"
                onConfirm={() => confirm(item.id)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined
                  style={{
                    color: "#265BA6",
                    fontSize: "18px",
                    marginRight: 10,
                  }}
                />
              </Popconfirm>
            </Tooltip>
          </Space>
        </>
      ),
    },
  ];
  const dataList = [];
  useMemo(() => {
    knowledgeData &&
      knowledgeData.map((e, index) => {
        dataList.push({
          key: index,
          id: e.kb_id,

          title: <div className="">{e.title ? e.title : "-"} </div>,
          ticket_id: <Text strong>{e.ticket_id ? e.ticket_id : "-"} </Text>,
          created_at: (
            <Text>
              {e.created_at ? (
                <>
                  <div className="flex justify-center items-center">
                    <Text className="ml-1">
                      {dayjs(e.created_at).format("DD/MM/YYYY hh:mm:ss A")}
                    </Text>
                  </div>
                </>
              ) : (
                "-"
              )}{" "}
            </Text>
          ),
          description: (
            <div
              className=""
              dangerouslySetInnerHTML={{
                __html: e.description
                  ? e.description.length > 50
                    ? e.description.slice(0, 50) + "..."
                    : e.description
                  : "-",
              }}
            />
          ),
          areaofconcernName: (
            <div className="">
              {e.areaofconcernName ? e.areaofconcernName : "-"}
            </div>
          ),
        });
      });
    setData(dataList);
  }, [knowledgeData]);

  const onClickPaginationNumber = (e) => {
    actionGetKnowledgeAll(e.current - 1, e.pageSize, search);
    setCurrent(e.current);
  };

  const onSearch = (e) => {
    actionGetKnowledgeAll(
      0,
      10,
      e,
      startDate ? startDate : "",
      endDate ? endDate : ""
    );
  };

  const onFinishSearch = (value) => {
    setCurrent(1);
    actionGetKnowledgeAll(
      0,
      10,
      value.keyword,
      value.status,
      startDate ? startDate : "",
      endDate ? endDate : ""
    );
  };
  const onRangeChange = (data, dateString) => {
    if (data) {
      setStartDate(dateString[0]);
      setEndDate(dateString[1]);
    } else {
      setStartDate("");
      setEndDate("");
    }
  };
  const onChange = (e) => {
    setStatus(e);
  };

  const areaConcern = [];
  areaConcernData &&
    areaConcernData.length > 0 &&
    areaConcernData.map((data, index) => {
      areaConcern.push({
        key: index,
        label: data.name,
        value: data.id,
      });
    });

  return (
    <Fragment>
      <Typography.Title level={3} className="ml-3">
        Knowledge Base
      </Typography.Title>
      <Card
        className="main-card no-padding"
        title={
          <>
            <Row align="middle" justify="">
              <Col xs={20} sm={20} md={16} lg={16} xl={18} align="">
                <Form
                  className="custom-form"
                  name="basic"
                  layout="inline"
                  onFinish={onFinishSearch}
                  autoComplete="off"
                >
                  <Form.Item name="keyword" className="custom-slect">
                    <Input
                      style={{ width: "100%" }}
                      autoFocus="none"
                      type={"text"}
                      //onChange={(e) => onchangeSearch(e)}
                      prefix={
                        <SearchOutlined className="site-form-item-icon text-white" />
                      }
                      placeholder="Search Here"
                    />
                  </Form.Item>
                  <Form.Item
                    name="status"
                    className="custom-slect   md:pt-1 lg:pt-0"
                  >
                    <Select
                      showSearch
                      onChange={onChange}
                      className="user rate-select"
                      placeholder="Select Module"
                      optionFilterProp="children"
                      allowClear
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={areaConcern}
                    />
                  </Form.Item>
                  <Form.Item name="date" className="custom-slect">
                    <RangePicker
                      onChange={onRangeChange}
                      className="range-date"
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      className="bg-white search-btn user"
                      type="primary"
                      htmlType="submit"
                    >
                      Search
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col xs={4} sm={4} md={8} lg={8} xl={6} align="right">
                <Link to="/create-knowledge">
                  <Button
                    type="primary"
                    className="bg-white-btn"
                    size={"large"}
                  >
                    Create knowledge
                  </Button>
                </Link>
              </Col>
            </Row>
          </>
        }
        bordered={false}
        style={{
          width: "100%",
        }}
      >
        <Row>
          <Col span={24}>
            <Table
              bordered
              className="user-table"
              sticky={true}
              size="small"
              pagination={{ current: current, total: knowledgeCount, hideOnSinglePage: true }}
              columns={columns}
              dataSource={data}
              loading={knowledgeLoader}
              onChange={(e) => onClickPaginationNumber(e)}
              scroll={{
                // x: "max-content",
              }}
            />
          </Col>
        </Row>
      </Card>
      <Modal
        style={{ width: 400 }}
        title="View Project"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        // centered
        className="popUp"
      >
        <div className="user-detail">
          <Row gutter={16}>
            <Col span={24}>
              <Descriptions
                title=""
                layout="horizontal"
                colon={true}
                bordered
                column={1}
              >
                {projectdata &&
                  projectdata.length > 0 &&
                  projectdata.map((item, index) => (
                    <Descriptions.Item
                      key={index}
                      label={item.title}
                      style={{
                        background: index % 2 !== 0 ? "#FFFFFF" : "#E2EEFF",
                      }}
                    // Set color dynamically or default to black
                    >
                      {item.description ? item.description : "-"}
                    </Descriptions.Item>
                  ))}
              </Descriptions>
            </Col>
          </Row>
        </div>
      </Modal>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    knowledgeLoader: state.knowledge.knowledgeLoader,
    knowledgeData: state.knowledge.knowledgeData,
    deleteKnowledgeLoading: state.knowledge.deleteKnowledgeLoading,
    knowledgeDetailLoader: state.knowledge.knowledgeDetailLoader,
    knowledgeCount: state.knowledge.knowledgeCount,
    areaConcernLoader: state.ticket.areaConcernLoader,
    areaConcernData: state.ticket.areaConcernData,
    addAreaConcernLoader: state.ticket.addAreaConcernLoader,
    addAreaConcernData: state.ticket.addAreaConcernData,
  };
};

export default connect(mapStateToProps, {
  actionGetKnowledgeAll,
  actionDeleteKnowledge,
  actionGetKnowledgeDetail,
  actionGetTicketDetail,
  actionGetAreaOfConcern,
  actionAddAreaOfConcern,
})(Knowledge);
