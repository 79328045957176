import { combineReducers } from "redux";
import authReducer from "./authReducer";
import campaignReducer from "./campaignReducer";
import knowlegdgeReducer from "./knowlegdgeReducer";
import notificationReducer from "./notificationReducer";
import orderReducer from "./orderReducer";
import projectReducer from "./projectReducer";
import ticketReducer from "./ticketReducer";
import userReducer from "./userReducer";
import timeReducer from "./timeReducer";
import reportReducer from "./reportReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  project: projectReducer,
  users: userReducer,
  ticket: ticketReducer,
  campaign: campaignReducer,
  orders: orderReducer,
  notification: notificationReducer,
  knowledge: knowlegdgeReducer,
  time: timeReducer,
  report: reportReducer,
});

export default rootReducer;
