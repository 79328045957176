import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actionAddProject } from "../../store/actions/projectAction";
import { actionGetUsersAll } from "../../store/actions/userAction";
const { TextArea } = Input;

const CreateProject = (props) => {
  const {
    addProjectsLoader,
    actionAddProject,
    addProjectsData,
    usersData,
    usersLoader,
    actionGetUsersAll,
  } = props;

  const Navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    actionGetUsersAll(0, 500);
  }, []);

  const users = [];
  usersData.map((user, index) => {
    if (user.roles === "USER") {
      users.push({
        key: index,
        label: user.first_name + " " + user.last_name,
        value: user.id,
      });
    }
  });

  const agents = [];

  usersData.map((user, index) => {
    if (user.roles === "AGENT") {
      agents.push({
        key: index,

        label: user.first_name + " " + user.last_name,
        value: user.id,
      });
    }
  });

  // const onFinish = (values) => {
  //   actionAddProject(values, Navigate);
  // };
  const onFinish = (values) => {
    const floatValueOfHours = parseFloat(values.hours);
    const intValueofEmailDuration = parseInt(values.awaiting_business_confirmation_email_duration);

    // Call the actionAddProject function with the float value
    actionAddProject({ ...values, hours: floatValueOfHours, awaiting_business_confirmation_email_duration: intValueofEmailDuration }, Navigate);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <Fragment>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Card
            className="main-card "
            title={
              <>
                <Row align="middle" justify="">
                  <Col span={2}>
                    <h2 className="text-white text-base">Create Project</h2>
                  </Col>
                </Row>
              </>
            }
            style={{
              width: "100%",
              minHeight: "60vh",
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form
                  autoComplete="off"
                  requiredMark={true}
                  form={form}
                  layout="vertical"
                  size="large"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Row gutter={[32, 0]}>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label="Project Title"
                        name="title"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Project Title!",
                          },
                          {
                            validator: (_, value) =>
                              value.length <= 35
                                ? Promise.resolve()
                                : Promise.reject(
                                  new Error(
                                    "Please enter maximum 35 characters for Project Title"
                                  )
                                ),
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Project Title"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Project Agents"
                        colon={false}
                        name="projectagent"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Project Agents!",
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          allowClear
                          style={{
                            width: "100%",
                          }}
                          placeholder="Project Agents"
                          className="project-select"
                          onChange={handleChange}
                          options={agents}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Users"
                        colon={false}
                        name="projectuser"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Users!",
                          },
                        ]}
                      >
                        <Select
                          className="project-select"
                          mode="multiple"
                          allowClear
                          style={{
                            width: "100%",
                          }}
                          placeholder="Users"
                          onChange={handleChange}
                          options={users}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="manager_name"
                        label="Manager Name"
                        rules={[
                          {
                            // type: "text",
                            required: true,
                            message: "Please input Manager Name!",
                          },
                        ]}
                      >
                        <Input
                          autoComplete="off"
                          placeholder="Enter Manager Name"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        name="manager_email"
                        label="Manager Email"
                        rules={[
                          {
                            type: "email",
                            required: true,
                            message: "Please input Manager Email!",
                          },
                        ]}
                      >
                        <Input
                          autoComplete="off"
                          placeholder="Enter Manager Email"
                        />
                      </Form.Item>
                    </Col>


                    <Col span={12}>
                        <Form.Item
                          // colon={false}
                          name="hours"
                          label="Total Hours"
                          rules={[
                            {
                              validator: (_, value) => {
                                const isNumber = /^[0-9]+$/.test(value);
                                if (!isNumber) {
                                  return Promise.reject(new Error('Please enter a valid number for Total Hours!'));
                                }
                                return Promise.resolve();
                              },
                            },
                            {
                              required: true,
                              message: 'Please input Total Hours!',
                            },
                          ]}
                        >
                          <Input
                            type="text"
                            // autoFocus="none"
                            autoComplete="off"
                            placeholder="Enter Total Hours"
                          />
                        </Form.Item>
                      </Col>
                    <Col span={12}>
                        <Form.Item
                          name="awaiting_business_confirmation_email_duration"
                          label="Business Confirmation Email Duration (In Days)"
                          rules={[
                            {
                              required: true,
                              message: "Please input Business Confirmation Email Duration!",
                            },
                            {
                              validator: (_, value) =>
                                /^[1-9]\d*$/.test(value)
                                  ? Promise.resolve()
                                  : Promise.reject(
                                    new Error('Business confirmation email duration must be a positive integer greater than or equal to 1!')
                                  ),
                            },
                          ]}
                        >
                          <Input
                            type="text"  // Using text type to allow both numeric and non-numeric input
                            autoComplete="off"
                            placeholder="Enter Business Confirmation Email Duration (In Days)"
                          />
                        </Form.Item>

                      </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Description"
                        colon={false}
                        name="description"
                        rules={[
                          {
                            required: true,
                            // message: "Please enter email!",
                            message: "Please Enter Description!",
                          },
                          {
                            validator: (_, value) =>
                              value.length <= 255
                                ? Promise.resolve()
                                : Promise.reject(
                                  new Error(
                                    "Please enter maximum 255 characters for Description"
                                  )
                                ),
                          },
                        ]}
                      >
                        <TextArea placeholder="Enter Description" rows={3} />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item normalize={(value) => value.trim()}>
                        <Button
                          loading={addProjectsLoader}
                          align="center"
                          htmlType="submit"
                          className="primary"
                          size="large"
                          style={{ width: 200, marginTop: "30px" }}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    addProjectsLoader: state.project.addProjectsLoader,
    addProjectsData: state.project.addProjectsData,
    usersData: state.users.usersData,
    usersLoader: state.users.usersLoader,
  };
};

export default connect(mapStateToProps, {
  actionAddProject,
  actionGetUsersAll,
})(CreateProject);
