import { HomeOutlined, LeftCircleFilled, ArrowLeftOutlined } from "@ant-design/icons";
import { Breadcrumb, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import Task1 from "../Task/Task1";
import ViewTicket from "./ViewTicket";
import Task2 from "../Task/Task2";
import Task from "../Task/Task";
import { useNavigate, useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { actionGetTicketDetail } from "../../store/actions/ticketAction";

const { TabPane } = Tabs;

const Accordion = (props) => {
  const [IsSticky, setIsSticky] = useState(false);

  const ticketId = useParams()
  const urlParams = new URLSearchParams(window.location.search);
  const keyParam = urlParams.get('key');
  const Navigate = useNavigate();


  const {
    actionGetTicketDetail
  } = props;

  const items = [
    {
      key: "1",
      label: "View Ticket",
      children: <ViewTicket />,
    },
    {
      key: "2",
      label: "Task List",
      children: <Task2 />,
    },
  ];

  const onChange = (e) => {
    //actionGetTicketDetail(ticketId.ticketId);
  };

  const handleGetTcketDetailsData = () => {
    actionGetTicketDetail(ticketId.ticketId);
  };

  const breadcrumbItems = [
    {
      key: 'back',
      title: (
        <>
          <ArrowLeftOutlined style={{ cursor: 'pointer' }} />
          <span style={{ cursor: 'pointer', color: 'rgba(0, 0, 0, 0.88)', fontWeight: '600', fontSize: 18 }}>Go Back</span>
        </>
      ),
      onClick: () => Navigate(-1),
    },
  ];


  return (
    <>

      <Breadcrumb items={breadcrumbItems} />



      <Tabs
        defaultActiveKey={`${keyParam ? 2 : 1}`}
        items={items}
        onChange={onChange}
        onTabClick={(key) => {
          key === '1' && handleGetTcketDetailsData();
        }}
      />;</>
  )
};

const mapStateToProps = (state) => {
  return {
    ticketsDetailData: state.ticket.ticketsDetailData,
  };
};
export default connect(mapStateToProps, {
  actionGetTicketDetail
})(Accordion);