import { ArrowLeftOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  Col,
  Descriptions,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Tag,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  actionGetTicketDetail,
  actionSendMail,
  actionUpdateLevelStatus,
  actionUpdatePriorityStatus,
  actionUpdateTicketSeverity,
  actionUpdateTicketStatus,
} from "../../store/actions/ticketAction";
import GoBackButton from "../comman/GoBackButton";

const { TextArea } = Input;
const { Option } = Select;

const ViewTicket = (props) => {
  const Navigate = useNavigate();
  const {
    actionGetTicketDetail,
    ticketsDetailLoader,
    actionUpdateTicketStatus,
    priorityStatusUpdateLoader,
    severityUpdateLoader,
    actionUpdatePriorityStatus,
    userProfileData,
    ticketLevelUpdateLoader,
    actionUpdateLevelStatus,
    ticketStatusUpdateLoader,
    ticketsDetailData,
    actionUpdateTicketSeverity,
    actionSendMail,
    sendEmailLoader,
  } = props;
  const { ticketId } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emails, setEmails] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [validationError, setValidationError] = useState("");

  useEffect(() => {
    actionGetTicketDetail(ticketId);
  }, []);

  const onsend = () => {
    actionSendMail(ticketId, emails, setIsModalOpen, setEmails);
  };

  const onChange = (id, data) => {
    actionUpdateTicketStatus(id, data);
  };

  const onChangeTab = (key) => {
    console.log(key, "key");
  };

  const handlePriorityChange = (id, data) => {
    actionUpdatePriorityStatus(id, data);
  };

  const handleLevelChange = (id, data) => {
    actionUpdateLevelStatus(id, data);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setValidationError("");
  };
  const handleInputConfirm = () => {
    if (inputValue) {
      if (isValidEmail(inputValue)) {
        if (emails.includes(inputValue)) {
          setValidationError("Email already added");
        } else {
          setEmails([...emails, inputValue]);
          setInputValue("");
          setValidationError("");
        }
      } else {
        setValidationError("Invalid email!");
      }
    }
  };
  const handleRemoveEmail = (removedEmail) => {
    const newEmails =
      emails && emails.filter((email) => email !== removedEmail);
    setEmails(newEmails);
    setValidationError("");
  };
  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const imageAttachments =
    ticketsDetailData.attachemnets &&
    ticketsDetailData.attachemnets.filter((item) => {
      const lowercaseItem = item.toLowerCase();
      const fileExtension = lowercaseItem.split(".").pop();
      return ["jpg", "jpeg", "png"].includes(fileExtension);
    });

  // const items1 = [
  //   {
  //     key: "1",
  //     label: "Tab 1",
  //     children: <Ticket />,
  //   },
  //   {
  //     key: "2",
  //     label: "Tab 2",
  //     children: "Content of Tab Pane 2",
  //   },
  //   {
  //     key: "3",
  //     label: "Tab 3",
  //     children: "Content of Tab Pane 3",
  //   },
  // ];

  const items = [
    {
      key: "1",
      label: "Normal",
      value: "Normal",
    },
    {
      key: "2",
      label: "Medium",
      value: "Medium",
    },
    {
      key: "3",
      label: "High",
      value: "High",
    },
    {
      key: "4",
      label: "Very High",
      value: "Very High",
    },
    {
      key: "6",
      label: "Urgent",
      value: "Urgent",
    },
    {
      key: "7",
      label: "Registered",
      value: "REGISTERED",
    },

    {
      key: "8",
      label: "In Process",
      value: "IN_PROCESS",
    },

    {
      key: "9",
      label: "Await Business Confirmation",
      value: "AWAITING_BUSINESS_CONFIRMATION",
    },
    {
      key: "10",
      label: "Closed",
      value: "CLOSED",
    },
    {
      key: "11",
      label: "On Hold By Business",
      value: "ON_HOLD_BY_BUSINESS",
    },
    {
      key: "12",
      label: "On Hold By Dev Team",
      value: "ON_HOLD_BY_ADMIN",
    },
    {
      key: "13",
      label: "Normal",
      value: "Normal",
    },
    {
      key: "14",
      label: "Medium",
      value: "Medium",
    },
    {
      key: "15",
      label: "Complex",
      value: "Complex",
    },
  ];

  const onClick = (key, id) => {
    const selected = items.find((item) => item.key === key.key);
    actionUpdatePriorityStatus(id, selected.value);
  };

  const handleClickStatus = (key, id) => {
    const selected = items.find((item) => item.key === key.key);

    actionUpdateTicketStatus(id, selected.value);
  };

  const handleClickSeverity = (key, id) => {
    const selected = items.find((item) => item.key === key.key);
    actionUpdateTicketSeverity(id, selected.value);
  };

  const ticketdata = [
    {
      title: "Ticket ID",
      description: (
        <>{ticketsDetailData && ticketsDetailData.service_request_number}</>
      ),
    },
    {
      title: "Title",
      description: (
        <>
          {ticketsDetailData && ticketsDetailData.title
            ? ticketsDetailData.title
            : "-"}
        </>
      ),
    },
    {
      title: "On Behalf Name",
      description: (
        <>
          {ticketsDetailData && ticketsDetailData.on_behalf_of_name
            ? ticketsDetailData.on_behalf_of_name
            : "-"}
        </>
      ),
    },
    {
      title: "On Behalf Email",
      description: (
        <>
          {ticketsDetailData &&
            ticketsDetailData.on_behalf_of_email &&
            ticketsDetailData.on_behalf_of_email.toLowerCase()}
          {!ticketsDetailData.on_behalf_of_email && "-"}
        </>
      ),
    },
    {
      title: "Description",
      description: (
        <div
          className="custom-desccription"
          style={{ height: "auto", maxHeight: 400, overflow: "auto" }}
          dangerouslySetInnerHTML={{
            __html: ticketsDetailData.description
              ? ticketsDetailData && ticketsDetailData.description
              : "-",
          }}
        ></div>
      ),
    },

    {
      title: "Priority",
      description: (
        <>
          {userProfileData && userProfileData.roles == "BUSINESS USER" ? (
            <Tag
              color={
                ticketsDetailData.rate_issuue == "Normal"
                  ? "blue"
                  : ticketsDetailData.rate_issuue == "Medium"
                  ? "warning"
                  : ticketsDetailData.rate_issuue == "High"
                  ? "orange"
                  : ticketsDetailData.rate_issuue == "Very High"
                  ? "error"
                  : "purple"
              }
            >
              {ticketsDetailData.rate_issuue}
            </Tag>
          ) : (
            <Dropdown
              menu={{
                items: items.slice(0, 5),
                onClick: (key) => onClick(key, ticketsDetailData.ticket_id),
              }}
              placement="bottom"
              arrow={{
                pointAtCenter: true,
              }}
            >
              {/* <Tag>bottom</Tag> */}
              <a onClick={(e) => e.preventDefault()}>
                <Tag
                  className="cursor-pointer"
                  color={
                    ticketsDetailData.rate_issuue == "Normal"
                      ? "blue"
                      : ticketsDetailData.rate_issuue == "Medium"
                      ? "warning"
                      : ticketsDetailData.rate_issuue == "High"
                      ? "orange"
                      : ticketsDetailData.rate_issuue == "Very High"
                      ? "error"
                      : "purple"
                  }
                >
                  {ticketsDetailData.rate_issuue}
                </Tag>
              </a>
            </Dropdown>
          )}
        </>
      ),
    },
    {
      title: "Category",
      description: (
        <>{ticketsDetailData && ticketsDetailData.areaofconcernName}</>
      ),
    },
    {
      title: "Type of Issue",
      description: <>{ticketsDetailData && ticketsDetailData.typesofissue}</>,
    },

    {
      title: "Working Hours / Total  Estimated Hours",
      // description: <>{ticketsDetailData && ticketsDetailData?.total_working_time + " / " + ticketsDetailData.total_estimated_hours && ticketsDetailData.total_estimated_hours + " hrs"}</>,
      description: (
        <>
          {ticketsDetailData &&
            ticketsDetailData.total_working_time !== null &&
            ticketsDetailData.total_working_time !== undefined && (
              <>
                {ticketsDetailData.total_working_time} /
                {ticketsDetailData.total_estimated_hours !== null &&
                ticketsDetailData.total_estimated_hours !== undefined
                  ? ticketsDetailData.total_estimated_hours + "  hrs"
                  : " 0:0"}
              </>
            )}
        </>
      ),
    },
    {
      title: "Requester Name",
      description: (
        <>
          {ticketsDetailData.user
            ? ticketsDetailData.user.first_name +
              " " +
              ticketsDetailData.user.last_name
            : "-"}
        </>
      ),
    },
    {
      title: "Requester Email",
      description: (
        <>
          {ticketsDetailData &&
            ticketsDetailData.email &&
            ticketsDetailData.email.toLowerCase()}
        </>
      ),
    },
    {
      title: "Troubleshooting done",
      description: (
        <>{ticketsDetailData && ticketsDetailData.primary_troubleshooting}</>
      ),
    },
    {
      title: "Steps for  Primary Troubleshooting",
      description: (
        <>
          <p style={{ textTransform: "lowercase" }}>
            {ticketsDetailData &&
              ticketsDetailData.primary_troubleshooting_description}
          </p>
        </>
      ),
    },
    {
      title: "SR Severity",
      description: (
        <>
          {userProfileData && userProfileData.roles == "BUSINESS USER" ? (
            <Tag
              color={
                ticketsDetailData.sr_severity === "Normal"
                  ? "green"
                  : ticketsDetailData.sr_severity === "Medium"
                  ? "orange"
                  : "red"
              }
            >
              {ticketsDetailData &&
              ticketsDetailData.sr_severity &&
              ticketsDetailData.sr_severity.length > 10
                ? ticketsDetailData.sr_severity.slice(0, 15) + "..."
                : ticketsDetailData.sr_severity}
            </Tag>
          ) : (
            <Dropdown
              menu={{
                items: items.slice(-3),
                onClick: (key) =>
                  handleClickSeverity(key, ticketsDetailData.ticket_id),
              }}
              placement="bottom"
              arrow={{
                pointAtCenter: true,
              }}
            >
              {/* <Tag>bottom</Tag> */}
              <a onClick={(e) => e.preventDefault()}>
                <Tag
                  className="cursor-pointer"
                  color={
                    ticketsDetailData.sr_severity === "Normal"
                      ? "green"
                      : ticketsDetailData.sr_severity === "Medium"
                      ? "orange"
                      : "red"
                  }
                >
                  {ticketsDetailData &&
                  ticketsDetailData.sr_severity &&
                  ticketsDetailData.sr_severity.length > 10
                    ? ticketsDetailData.sr_severity.slice(0, 15) + "..."
                    : ticketsDetailData.sr_severity}
                </Tag>
              </a>
            </Dropdown>
          )}
        </>
      ),
    },
    {
      title: "Level",
      description: (
        <>
          <div>
            <div id="dropw">
              {userProfileData && userProfileData.roles !== "BUSINESS USER" ? (
                <Select
                  // defaultValue={"Level 1"}
                  loading={ticketLevelUpdateLoader}
                  disabled={ticketLevelUpdateLoader}
                  size="small"
                  value={ticketsDetailData.level}
                  name="level"
                  onChange={(data) =>
                    handleLevelChange(ticketsDetailData.ticket_id, data)
                  }
                  placeholder="Level Of Ticket"
                  className="custom-select"
                >
                  <Option value="Level 1">Level 1</Option>
                  <Option value="Level 2">Level 2</Option>
                  <Option value="Level 3">Level 3</Option>
                  <Option value="Level 4">Level 4</Option>
                  <Option value="CR">CR</Option>
                </Select>
              ) : (
                ticketsDetailData.level
              )}
            </div>
          </div>
        </>
      ),
    },

    {
      title: "Submission Date and Time",
      description: (
        <>
          <p style={{ textTransform: "lowercase" }}>
            {ticketsDetailData && ticketsDetailData.created_at
              ? dayjs(ticketsDetailData.created_at).format(
                  "DD/MM/YYYY h:mm:ss A"
                )
              : "-"}
          </p>
        </>
      ),
    },
    {
      title: "Updated Date",
      description: (
        <>
          <p style={{ textTransform: "lowercase" }}>
            {ticketsDetailData && ticketsDetailData.updated_at
              ? dayjs(ticketsDetailData.updated_at).format(
                  "DD/MM/YYYY h:mm:ss A"
                )
              : "-"}
          </p>
        </>
      ),
    },
    {
      title: "Status",
      description: (
        <>
          {userProfileData && userProfileData.roles == "BUSINESS USER" ? (
            <Tag
              color={
                ticketsDetailData.status == "REGISTERED"
                  ? "blue"
                  : ticketsDetailData.status == "IN_PROCESS"
                  ? "orange"
                  : ticketsDetailData.status == "AWAITING_BUSINESS_CONFIRMATION"
                  ? "warning"
                  : ticketsDetailData.status == "ON_HOLD_BY_BUSINESS"
                  ? "purple"
                  : ticketsDetailData.status == "ON_HOLD_BY_ADMIN"
                  ? "magenta"
                  : "green"
              }
            >
              {ticketsDetailData.status && ticketsDetailData.status}
            </Tag>
          ) : (
            <Dropdown
              menu={{
                items: items.slice(5, 11),
                onClick: (key) =>
                  handleClickStatus(key, ticketsDetailData.ticket_id),
              }}
              placement="bottom"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Tag
                  className="cursor-pointer"
                  color={
                    ticketsDetailData.status == "REGISTERED"
                      ? "blue"
                      : ticketsDetailData.status == "IN_PROCESS"
                      ? "orange"
                      : ticketsDetailData.status ==
                        "AWAITING_BUSINESS_CONFIRMATION"
                      ? "warning"
                      : ticketsDetailData.status == "ON_HOLD_BY_BUSINESS"
                      ? "purple"
                      : ticketsDetailData.status == "ON_HOLD_BY_ADMIN"
                      ? "magenta"
                      : "green"
                  }
                >
                  {ticketsDetailData.status && ticketsDetailData.status}
                </Tag>
              </a>
            </Dropdown>
          )}
        </>
      ),
    },
    {
      title: "Resolution",
      description: (
        <div
          className="custom-resolution"
          style={{ height: "auto", maxHeight: 400, overflow: "auto" }}
          dangerouslySetInnerHTML={{
            __html: ticketsDetailData.resolve_note
              ? ticketsDetailData && ticketsDetailData.resolve_note
              : "-",
          }}
        ></div>
      ),
      width: 200,
      height: 100,
    },
    {
      title: "Attachment",
      description: (
        <>
          {ticketsDetailData.attachemnets &&
          ticketsDetailData.attachemnets.length > 0 ? (
            <div>
              {/* Map PDF attachments */}
              {ticketsDetailData.attachemnets
                .filter((item) => item.toLowerCase().endsWith(".pdf"))
                .map((item, index) => (
                  <div className="mr-5 mt-2" key={index}>
                    <a
                      style={{ fontSize: 13 }}
                      href={item}
                      target="_blank"
                      className="link-tag hover:underline text-sm hover:text-black-600"
                      rel="noopener noreferrer"
                    >
                      {item.split("/").pop()}
                    </a>
                  </div>
                ))}

              {/* Map Excel, Word, and other attachments */}
              {ticketsDetailData.attachemnets
                .filter((item) => {
                  const lowercaseItem = item.toLowerCase();
                  const fileExtension = lowercaseItem.split(".").pop();
                  return ["xlsx", "xls", "csv", "doc", "docx"].includes(
                    fileExtension
                  );
                })
                .map((item, index) => (
                  <div className="mr-5 mt-2" key={index}>
                    <a
                      style={{ fontSize: 13 }}
                      href={item}
                      target="_blank"
                      className="link-tag hover:underline text-sm hover:text-black-600"
                      rel="noopener noreferrer"
                    >
                      {item.split("/").pop()}
                    </a>
                  </div>
                ))}

              {/* Display single image attachment */}
              {ticketsDetailData.attachemnets.filter((item) => {
                const lowercaseItem = item.toLowerCase();
                const fileExtension = lowercaseItem.split(".").pop();
                return ["jpg", "jpeg", "png"].includes(fileExtension);
              }).length > 0 && (
                <div className="mr-5 mt-2">
                  <Image.PreviewGroup
                    items={
                      ticketsDetailData.attachemnets &&
                      ticketsDetailData.attachemnets.filter((item) => {
                        const lowercaseItem = item.toLowerCase();
                        const fileExtension = lowercaseItem.split(".").pop();
                        return ["jpg", "jpeg", "png"].includes(fileExtension);
                      })
                    }
                  >
                    <Badge
                      style={{ cursor: "pointer" }}
                      offset={[-0, 5]}
                      count={
                        ticketsDetailData.attachemnets &&
                        ticketsDetailData.attachemnets.filter((item) => {
                          const lowercaseItem = item.toLowerCase();
                          const fileExtension = lowercaseItem.split(".").pop();
                          return ["jpg", "jpeg", "png"].includes(fileExtension);
                        }).length
                      }
                      color="grey"
                      size="medium"
                      title="Images"
                    >
                      <Image
                        preview={{ mask: <EyeOutlined /> }}
                        width={150}
                        height={150}
                        style={{
                          maxHeight: "100%",
                          maxWidth: "100%",
                          objectFit: "contain",
                        }}
                        src={
                          ticketsDetailData.attachemnets.filter((item) => {
                            const lowercaseItem = item.toLowerCase();
                            const fileExtension = lowercaseItem
                              .split(".")
                              .pop();
                            return ["jpg", "jpeg", "png"].includes(
                              fileExtension
                            );
                          })[0]
                        }
                      />
                    </Badge>
                  </Image.PreviewGroup>
                </div>
              )}
            </div>
          ) : (
            // Display "No attachments available" when no attachments are available
            <p>No attachments available</p>
          )}
        </>
      ),
    },
  ];

  const breadcrumbItems = [
    {
      key: "back",
      title: <GoBackButton />,
      onClick: () => Navigate(-1),
    },
  ];

  return (
    <>
      <Spin
        spinning={
          ticketsDetailLoader ||
          priorityStatusUpdateLoader ||
          ticketStatusUpdateLoader ||
          severityUpdateLoader
        }
      >
        {userProfileData && userProfileData.roles === "BUSINESS USER" && (
          <Breadcrumb items={breadcrumbItems} />
        )}

        <div className="user-detail my-10">
          <Row gutter={16}>
            <Col xl={12} md={24} sm={24} xxl={12}>
              <Card
                className="big-card"
                title={
                  <>
                    <Typography.Title level={4}>
                      <div className="flex justify-between items-center">
                        View Ticket<span className="text-line"></span>
                        <div className="">
                          {/* {userProfileData &&
                            userProfileData.roles !== "BUSINESS USER" &&
                            ticketsDetailData.resolve_note !== null && (
                              <Button
                                type="primary"
                                onClick={showModal}
                                className="mr-3"
                              >
                                Send Resolution
                              </Button>
                            )}

                          <Modal
                            className="custom-modal"
                            title="Send Resolution"
                            open={isModalOpen}
                            footer={null}
                            onCancel={handleCancel}
                          >
                            <Form>
                              <div>
                                <div style={{ marginBottom: 8 }}>
                                  {emails.map((email) => (
                                    <Tag
                                      key={email}
                                      closable
                                      onClose={() => handleRemoveEmail(email)}
                                      style={{ marginRight: 8 }}
                                    >
                                      {email}
                                    </Tag>
                                  ))}
                                </div>
                                <Input
                                  type="text"
                                  value={inputValue}
                                  onChange={handleInputChange}
                                  onPressEnter={handleInputConfirm}
                                  onBlur={handleInputConfirm}
                                  placeholder="Insert recepients email and press enter!"
                                  style={{ marginBottom: 8 }}
                                />
                                {validationError && (
                                  <p style={{ color: "red", marginBottom: 8 }}>
                                    {validationError}
                                  </p>
                                )}
                              </div>
                              <div className="flex justify-end mt-2">
                                <Button onClick={handleCancel} className="mr-3">
                                  Cancel
                                </Button>
                                <Button
                                  onClick={onsend}
                                  loading={sendEmailLoader}
                                  disabled={emails && emails.length <= 0}
                                >
                                  send
                                </Button>
                              </div>
                            </Form>
                          </Modal> */}

                          {userProfileData &&
                            userProfileData.roles !== "BUSINESS USER" && (
                              <Link
                                to={`/edit-ticket/${ticketsDetailData.ticket_id}`}
                              >
                                <Button
                                  className="mr-3 bg-transparent text-black"
                                  type="link"
                                >
                                  Edit Ticket
                                </Button>
                              </Link>
                            )}

                          {/* {userProfileData &&
                            userProfileData.roles !== "BUSINESS USER" && (
                              <Link
                                to={`/edit-resolve/${ticketsDetailData.ticket_id}`}
                              >
                                <Button
                                  className="bg-transparent text-black"
                                  type="link"
                                >
                                  {ticketsDetailData.resolve_note === null
                                    ? "Add Resolution"
                                    : "Edit Resolution"}
                                </Button>
                              </Link>
                            )} */}
                        </div>
                      </div>
                    </Typography.Title>
                  </>
                }
                bordered={false}
              >
                <div className="user-detail">
                  <Row gutter={16}>
                    <Col span={24}>
                      {/* <Descriptions
                        title=""
                        layout="horizontal"
                        colon={true}
                        bordered
                        column={1}
                      >
                        {ticketdata &&
                          ticketdata.length > 0 &&
                          ticketdata.map((item, index) => (
                            <Descriptions.Item
                              key={index}
                              label={item.title}
                              style={{
                                background:
                                  index % 2 !== 0 ? "#FFFFFF" : "rgba(226, 238, 255,0.5)",
                              }} // Set color dynamically or default to black
                            >
                              {item.description ? item.description : "-"}
                            </Descriptions.Item>
                          ))}
                      </Descriptions> */}
                      <Descriptions
                        title=""
                        layout="horizontal"
                        colon={true}
                        bordered
                        column={1}
                      >
                        {/* {ticketdata &&
                          ticketdata.length > 0 &&
                          ticketdata.map((item, index) => (
                            // Check if the user is not an admin or if the item is not "Type of Issue"
                            (!(userProfileData && userProfileData.roles === "ADMIN" && item.title === "Working Hours / Total  Estimated Hours")) && (
                              <Descriptions.Item
                                key={index}
                                label={item.title}
                                style={{
                                  background:
                                    index % 2 !== 0 ? "#FFFFFF" : "rgba(226, 238, 255,0.5)",
                                }} // Set color dynamically or default to black
                              >
                                {item.description ? item.description : "-"}
                              </Descriptions.Item>
                            )
                          ))} */}
                        {ticketdata &&
                          ticketdata.length > 0 &&
                          ticketdata.map((item, index) => {
                            // Exclude specific items and conditions from the Descriptions
                            const isExcludedItem = [
                              "Description",
                              "Resolution",
                              "Attachment",
                            ].includes(item.title);
                            const isAdminWorkingHours =
                              userProfileData?.roles !== "ADMIN" &&
                              userProfileData?.roles !== "AGENT" &&
                              item.title ==
                                "Working Hours / Total  Estimated Hours";

                            if (!isExcludedItem && !isAdminWorkingHours) {
                              return (
                                <Descriptions.Item
                                  key={index}
                                  label={item.title}
                                  style={{
                                    background:
                                      index % 2 !== 0
                                        ? "#FFFFFF"
                                        : "rgba(226, 238, 255,0.5)",
                                  }}
                                >
                                  {item.description ? item.description : "-"}
                                </Descriptions.Item>
                              );
                            }

                            return null;
                          })}
                      </Descriptions>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>

            <Col xl={12} md={24} sm={24} xxl={12}>
              <Row gutter={[16, 16]}>
                {userProfileData &&
                  userProfileData.roles !== "BUSINESS USER" && (
                    <Col span={24}>
                      <Card
                        className="estimating-card"
                        title={
                          <>
                            <div className="flex items-center justify-between">
                              <Typography.Title level={4}>
                                Task Time Breakdown
                              </Typography.Title>
                              <Typography.Title
                                style={{
                                  color: "#265ba6",
                                  fontWeight: "bold",
                                }}
                                level={4}
                                className="tracking-wide"
                              >
                                <span className="mr-2">
                                  {ticketsDetailData?.total_tasks_count}
                                </span>
                                Tasks
                              </Typography.Title>
                            </div>
                          </>
                        }
                      >
                        <Row>
                          <Col span={12}>
                            <div className="flex items-center">
                              <Typography.Title
                                level={3}
                                className="mr-1"
                                style={{
                                  marginBottom: 7,
                                  color: "#265ba6",
                                  fontWeight: "bold",
                                }}
                              >
                                {ticketsDetailData.total_estimated_hours
                                  ? ticketsDetailData.total_estimated_hours
                                  : "0:0"}
                              </Typography.Title>
                              <span
                                style={{
                                  color: "rgb(100 116 139)",
                                  fontWeight: 600,
                                }}
                                className="text-slate-400"
                              >
                                Hrs
                              </span>
                            </div>
                            <Typography.Text className="tracking-wider">
                              Total Estimated Time
                            </Typography.Text>
                          </Col>
                          <Col span={12}>
                            <div className="flex items-center">
                              <Typography.Title
                                level={3}
                                style={{ color: "#265ba6", fontWeight: "bold" }}
                                className="mr-2"
                              >
                                {ticketsDetailData?.total_working_time}
                              </Typography.Title>
                              <span
                                style={{
                                  color: "rgb(100 116 139)",
                                  fontWeight: 600,
                                }}
                                className="text-slate-400"
                              >
                                Hrs
                              </span>
                            </div>
                            <Typography.Text className="tracking-wider">
                              Total Time Spend
                            </Typography.Text>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  )}
                <Col span={24}>
                  <Card
                    className="big-card"
                    title={
                      userProfileData &&
                      userProfileData.roles !== "BUSINESS USER" ? (
                        <>
                          <Typography.Title level={4}>
                            <div className="flex justify-between items-center">
                              <span className="text-line"></span>
                              <div className="">
                                {userProfileData &&
                                  userProfileData.roles !== "BUSINESS USER" &&
                                  ticketsDetailData.resolve_note !== null && (
                                    <Button
                                      type="primary"
                                      onClick={showModal}
                                      className="mr-3"
                                    >
                                      Send Resolution
                                    </Button>
                                  )}

                                <Modal
                                  className="custom-modal"
                                  title="Send Resolution"
                                  open={isModalOpen}
                                  footer={null}
                                  onCancel={handleCancel}
                                >
                                  <Form>
                                    {/* <TextArea type='email' rows={4} /> */}
                                    <div>
                                      <div style={{ marginBottom: 8 }}>
                                        {emails.map((email) => (
                                          <Tag
                                            key={email}
                                            closable
                                            onClose={() =>
                                              handleRemoveEmail(email)
                                            }
                                            style={{ marginRight: 8 }}
                                          >
                                            {email}
                                          </Tag>
                                        ))}
                                      </div>
                                      <Input
                                        type="text"
                                        value={inputValue}
                                        onChange={handleInputChange}
                                        onPressEnter={handleInputConfirm}
                                        onBlur={handleInputConfirm}
                                        placeholder="Insert recepients email and press enter!"
                                        style={{ marginBottom: 8 }}
                                      />
                                      {validationError && (
                                        <p
                                          style={{
                                            color: "red",
                                            marginBottom: 8,
                                          }}
                                        >
                                          {validationError}
                                        </p>
                                      )}
                                    </div>
                                    <div className="flex justify-end mt-2">
                                      <Button
                                        onClick={handleCancel}
                                        className="mr-3"
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        onClick={onsend}
                                        loading={sendEmailLoader}
                                        disabled={emails && emails.length <= 0}
                                      >
                                        send
                                      </Button>
                                    </div>
                                  </Form>
                                </Modal>

                                {userProfileData &&
                                  userProfileData.roles !== "BUSINESS USER" && (
                                    <Link
                                      to={`/edit-resolve/${ticketsDetailData.ticket_id}`}
                                    >
                                      <Button
                                        className="bg-transparent text-black"
                                        type="link"
                                      >
                                        {ticketsDetailData.resolve_note === null
                                          ? "Add Resolution"
                                          : "Edit Resolution"}
                                      </Button>
                                    </Link>
                                  )}
                              </div>
                            </div>
                          </Typography.Title>
                        </>
                      ) : (
                        false
                      )
                    }
                    bordered={false}
                  >
                    <div className="user-detail">
                      <Row gutter={16}>
                        <Col span={24}>
                          <Descriptions
                            title=""
                            layout="horizontal"
                            colon={true}
                            bordered
                            column={1}
                          >
                            {ticketdata &&
                              ticketdata.length > 0 &&
                              ticketdata.map((item, index) => {
                                if (
                                  [
                                    "Description",
                                    "Resolution",
                                    "Attachment",
                                  ].includes(item.title)
                                ) {
                                  return (
                                    <Descriptions.Item
                                      key={index}
                                      label={item.title}
                                      style={{
                                        background:
                                          item.title === "Description"
                                            ? "rgba(226, 238, 255,0.5)"
                                            : item.title === "Attachment"
                                            ? "rgba(226, 238, 255,0.5)"
                                            : item.title === "Resolution"
                                            ? "#f6ffed"
                                            : index % 2 === 0
                                            ? "#FFFFFF"
                                            : "rgba(226, 238, 255,0.5)",
                                      }}
                                    >
                                      {item.description
                                        ? item.description
                                        : "-"}
                                    </Descriptions.Item>
                                  );
                                }
                                return null;
                              })}
                          </Descriptions>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ticketsDetailLoader: state.ticket.ticketsDetailLoader,
    ticketsDetailData: state.ticket.ticketsDetailData,
    ticketStatusUpdateLoader: state.ticket.ticketStatusUpdateLoader,
    priorityStatusUpdateLoader: state.ticket.priorityStatusUpdateLoader,
    ticketLevelUpdateLoader: state.ticket.ticketLevelUpdateLoader,
    severityUpdateLoader: state.ticket.severityUpdateLoader,
    userProfileData: state.auth.userProfileData,
    sendEmailLoader: state.ticket.sendEmailLoader,
    sendEmailData: state.ticket.sendEmailData,
  };
};

export default connect(mapStateToProps, {
  actionGetTicketDetail,
  actionUpdateLevelStatus,
  actionUpdateTicketStatus,
  actionUpdateTicketSeverity,
  actionUpdatePriorityStatus,
  actionSendMail,
})(ViewTicket);
