import * as actionTypes from "../action";

const initialState = {
  usersLoader: false,
  allUsersLoader: false,
  allUsersData: [],
  usersData: [],
  addUserLoading: false,
  deleteUserLoading: false,
  addUserData: [],
  userUpdateLoader: false,
  userDetailLoader: false,
  userDetailData: {},
  usersCount: {},
  is_reports_visible: false,
  hideReportsLoader: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOTAL_USERS_COUNT:
      return { ...state, usersCount: action.payload };

    case actionTypes.GET_USERS_LOADING:
      return { ...state, usersLoader: action.payload };

    case actionTypes.GET_USERS_DATA:
      return { ...state, usersData: action.payload };

    case actionTypes.GET_ALL_USERS_LOADING:
      return { ...state, allUsersLoader: action.payload };

    case actionTypes.GET_ALL_USERS_DATA:
      return { ...state, allUsersData: action.payload };

    case actionTypes.ADD_USER_LOADING:
      return { ...state, addUserLoading: action.payload };

    case actionTypes.ADD_USER_DATA:
      return { ...state, addUserData: action.payload };

    case actionTypes.DELETE_USER_LOADING:
      return { ...state, deleteUserLoading: action.payload };

    case actionTypes.DELETE_USER_ID:
      return {
        ...state,
        usersData: [
          ...state.usersData.filter((data) => data.id !== action.payload),
        ],
      };

    case actionTypes.UPDATE_USER_LOADING:
      return { ...state, userUpdateLoader: action.payload };

    case actionTypes.GET_USER_DETAIL_LOADING:
      return { ...state, userDetailLoader: action.payload };

    case actionTypes.GET_USER_DETAIL_DATA:
      return {
        ...state,
        userDetailData: action.payload,
      };

    case actionTypes.HIDE_REPORTS:
      return {
        ...state,
        usersData: state.usersData.map((user) => {
          if (user.id === action.payload.id) {
            return {
              ...user,
              is_reports_visible: action.payload.is_reports_visible,
            };
          }
          return user;
        }),
      };

    case actionTypes.HIDE_REPORTS_LOADER:
      return {
        ...state,
        usersData: state.usersData.map((data) =>
          data.id === action.payload.id
            ? {
                ...data,
                is_loading: action.payload.isLoading,
              }
            : data
        ),
      };

    default:
      return state;
  }
};

export default userReducer;
