import {
  CalendarOutlined,
  ClockCircleOutlined,
  EyeOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Spin,
  Steps,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import status1 from "../../assets/img/icon/status1.svg";
import {
  actionDeleteTicket,
  actionGetAllTicketLog,
  actionGetAllTickets,
  actionGetTicketDetail,
  actionGetTicketLog,
  actionGetTickets,
  actionUpdateLevelStatus,
  actionUpdatePriorityStatus,
  actionUpdateTicketStatus,
} from "../../store/actions/ticketAction";
const { Option } = Select;
const { RangePicker } = DatePicker;

const Ticket = (props) => {
  const {
    actionGetTickets,
    ticketsLoader,
    ticketsData,
    ticketsCount,
    getAllticketsData,
    ticketLevelUpdateLoader,
    actionUpdateLevelStatus,
    getAllticketsLoader,
    actionUpdatePriorityStatus,
    getAllTicketLogLoader,
    getAllTicketLogData,
    priorityStatusUpdateLoader,
    actionGetAllTickets,
    ticketLogLoader,
    actionUpdateTicketStatus,
    ticketStatusUpdateLoader,
    actionGetTicketLog,
    actionGetAllTicketLog,
    ticketLogData,
  } = props;

  const [search, setSearch] = useState();
  const [status, setStatus] = useState();
  const [sortedInfo, setSortedInfo] = useState({});
  const [rateIssue, setRateIssue] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [dataList, setDataList] = useState();
  const [modal2Open, setModal2Open] = useState(false);

  const onFinishSearch = (value) => {
    setSearch(value.keyword ? value.keyword : "");
    setStatus(value.status ? value.status : "");
    actionGetTickets(
      0,
      10,
      value.keyword ? value.keyword : "",
      value.status ? value.status : "",
      value.rate_issue ? value.rate_issue : "",
      startDate ? startDate : "",
      endDate ? endDate : ""
    );
    actionGetAllTicketLog(
      value.keyword ? value.keyword : "",
      value.status ? value.status : "",
      value.rate_issue ? value.rate_issue : "",
      startDate ? startDate : "",
      endDate ? endDate : ""
    );

    actionGetAllTickets(
      0,
      10,
      value.keyword ? value.keyword : "",
      value.status ? value.status : "",
      value.rate_issue ? value.rate_issue : "",
      startDate ? startDate : "",
      endDate ? endDate : ""
    );
  };

  useEffect(() => {
    actionGetTickets(0, 10);
    actionGetAllTicketLog(
      search ? search : "",
      status ? status : "",
      rateIssue ? rateIssue : "",
      startDate ? startDate : "",
      endDate ? endDate : ""
    );
    actionGetAllTickets(
      0,
      10,
      search ? search : "",
      status ? status : "",
      rateIssue ? rateIssue : "",
      startDate ? startDate : "",
      endDate ? endDate : "",
      "YES"
    );
  }, []);

  const modalOpen = (id) => {
    setModal2Open(true);
    actionGetTicketLog(id);
  };

  const onRangeChange = (data, dateString) => {
    if (data) {
      setStartDate(dateString[0]);
      setEndDate(dateString[1]);
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  const logData = [];
  ticketLogData &&
    ticketLogData.length > 0 &&
    ticketLogData.map((data, index) => {
      logData.push({
        key: index,
        title:
          data.created_at && dayjs(data.created_at).format("YYYY/MM/DD h:mm A"),
        description: data.log,
      });
    });

  const onChange = (id, data) => {
    actionUpdateTicketStatus(id, data);
  };

  const handlePriorityChange = (id, data) => {
    actionUpdatePriorityStatus(id, data);
  };

  const handleLevelChange = (id, data) => {
    actionUpdateLevelStatus(id, data);
  };

  const data = [];
  const items = [
    {
      key: "1",
      label: "Normal",
      value: "Normal",
    },
    {
      key: "2",
      label: "Medium",
      value: "Medium",
    },
    {
      key: "3",
      label: "High",
      value: "High",
    },
    {
      key: "4",
      label: "Very High",
      value: "Very High",
    },
    {
      key: "6",
      label: "Urgent",
      value: "Urgent",
    },
    {
      key: "7",
      label: "Registered",
      value: "REGISTERED",
    },

    {
      key: "8",
      label: "In Process",
      value: "IN_PROCESS",
    },

    {
      key: "9",
      label: "Await Business Confirmation",
      value: "AWAITING_BUSINESS_CONFIRMATION",
    },
    {
      key: "10",
      label: "Closed",
      value: "CLOSED",
    },
  ];

  const onClick = (key, id) => {
    const selected = items.find((item) => item.key === key.key);
    actionUpdatePriorityStatus(id, selected.value);
  };

  const handleClickStatus = (key, id) => {
    const selected = items.find((item) => item.key === key.key);
    actionUpdateTicketStatus(id, selected.value);
  };

  // useMemo(() => {
  ticketsData &&
    ticketsData.length > 0 &&
    ticketsData.map((e, index) =>
      data.push({
        key: index,
        timestamp: dayjs(e.created_at).format("MM/DD/YYYY h:mm:ss A"),
        id: e.ticket_id,
        title: e.title,
        service_request_number: e.service_request_number,
        requester_name: e.user.first_name
          ? e.user.first_name + " " + e.user.last_name
          : "-",
        description: (
          <div
            dangerouslySetInnerHTML={{
              __html: e.description
                ? e.description.length > 50
                  ? e.description.slice(0, 50) + "..."
                  : e.description
                : "-",
            }}
          />
        ),

        email: e.email && e.email.toLowerCase(),
        rate_issuue: (
          <>
            <div id="dropw">
              <Dropdown
                menu={{
                  items: items.slice(0, 5),
                  onClick: (key) => onClick(key, e.ticket_id),
                }}
                placement="bottom"
                arrow={{
                  pointAtCenter: true,
                }}
              >
                {/* <Tag>bottom</Tag> */}
                <a onClick={(e) => e.preventDefault()}>
                  <Tag
                    className="cursor-pointer"
                    color={
                      e.rate_issuue == "Normal"
                        ? "blue"
                        : e.rate_issuue == "Medium"
                        ? "warning"
                        : e.rate_issuue == "High"
                        ? "orange"
                        : e.rate_issuue == "Very High"
                        ? "error"
                        : "purple"
                    }
                  >
                    {e.rate_issuue}
                  </Tag>
                </a>
              </Dropdown>
            </div>
          </>
        ),
        level: (
          <>
            <div id="dropw">
              <Select
                loading={ticketLevelUpdateLoader}
                disabled={ticketLevelUpdateLoader}
                size="small"
                value={e.level}
                name="level"
                onChange={(data) => handleLevelChange(e.ticket_id, data)}
                placeholder="Level Of Ticket"
                className="custom-select"
              >
                <Option value="Level 1">Level 1</Option>
                <Option value="Level 2">Level 2</Option>
                <Option value="Level 3">Level 3</Option>
                <Option value="Level 4">Level 4</Option>
              </Select>
            </div>
          </>
        ),
        areaofconcernName: e.areaofconcernName,
        typesofissue: e.typesofissue,
        primary_troubleshooting_description:
          e.primary_troubleshooting_description,
        submission_date: dayjs(e.created_at).format("DD/MM/YYYY"),
        submission_time: dayjs(e.created_at).format("h:mm:ss A"),
        updated_at: dayjs(e.updated_at).format("DD/MM/YYYY h:mm:ss A"),
        status: (
          <>
            <Dropdown
              menu={{
                items: items.slice(-4),
                onClick: (key) => handleClickStatus(key, e.ticket_id),
              }}
              placement="bottom"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Tag
                  className="cursor-pointer"
                  color={
                    e.status == "REGISTERED"
                      ? "blue"
                      : e.status == "IN_PROCESS"
                      ? "orange"
                      : e.status == "AWAITING_BUSINESS_CONFIRMATION"
                      ? "warning"
                      : "green"
                  }
                >
                  {e.status.length > 10
                    ? e.status.slice(0, 15) + "..."
                    : e.status}
                </Tag>
              </a>
            </Dropdown>
          </>
        ),
        severitiy: (
          <>
            <Dropdown
              menu={{
                items: items.slice(-4),
                onClick: (key) => handleClickStatus(key, e.ticket_id),
              }}
              placement="bottom"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Tag
                  className="cursor-pointer"
                  color={
                    e.status == "REGISTERED"
                      ? "blue"
                      : e.status == "IN_PROCESS"
                      ? "orange"
                      : e.status == "AWAITING_BUSINESS_CONFIRMATION"
                      ? "warning"
                      : "green"
                  }
                >
                  {e.status.length > 10
                    ? e.status.slice(0, 15) + "..."
                    : e.status}
                </Tag>
              </a>
            </Dropdown>
          </>
        ),
        primary_troubleshooting:
          e.primary_troubleshooting === "YES" ? (
            <div className="flex justify-center items-center m-auto">
              <p>{e.primary_troubleshooting}</p>
              <p className="mb-1.5 ml-1">
                <Popover
                  className="popover-ticket"
                  content={<div>{e.primary_troubleshooting_description}</div>}
                  trigger="click"
                >
                  <InfoCircleOutlined />
                </Popover>
              </p>
            </div>
          ) : (
            e.primary_troubleshooting
          ),
        attachemnets:
          e.attachemnets && e.attachemnets.length > 0 ? (
            e.attachemnets.map((item, index) => {
              const fileName = item.split("/").pop(); // Extract the file name from the URL
              const fileExtension = fileName.split(".").pop(); // Extract the file extension

              return (
                <div className="mr-5 mt-2" key={index}>
                  {/* Display the attachment item */}
                  {fileExtension === "pdf" ? (
                    <a
                      href={item}
                      target="_blank"
                      className="link-tag hover:underline text-sm hover:text-black-600"
                      rel="noopener noreferrer"
                    >
                      {fileName}
                    </a>
                  ) : // : fileExtension === "jpg" ||
                  //   fileExtension === "jpeg" ||
                  //   fileExtension === "png" ? (
                  //   <Image
                  //     preview={{ mask: <EyeOutlined /> }}
                  //     width={100}
                  //     src={item}
                  //   />
                  // )
                  fileExtension === "xlsx" ||
                    fileExtension === "xls" ||
                    fileExtension === "csv" ||
                    fileExtension === "doc" ||
                    fileExtension === "docx" ? (
                    <a
                      href={item}
                      target="_blank"
                      className="link-tag  hover:underline text-sm hover:text-black-600"
                      rel="noopener noreferrer"
                    >
                      {fileName}
                    </a>
                  ) : (
                    <p>Unsupported file type: {fileExtension}</p>
                  )}
                </div>
              );
            })
          ) : (
            // Display "No attachments available" when no attachments are available
            <p>No attachments available</p>
          ),
      })
    );

  useMemo(() => setDataList(data), [ticketsData]);

  const onClickPaginationNumber = (e, filter, sorter) => {
    setSortedInfo(sorter);
    actionGetTickets(
      e.current - 1,
      e.pageSize,
      search,
      status,
      rateIssue,
      startDate,
      endDate
    );
  };

  const columns = [
    {
      title: "Ticket Number",
      dataIndex: "service_request_number",
      key: "service_request_number",
      width: 150,
    },
    {
      title: (
        <div className="flex justify-center items-center m-auto">
          <CalendarOutlined />
          <div className=" ml-1 flex justify-center items-center ">
            <Tooltip
              className="popover-ticket flex justify-center items-center "
              title={<div className="text-white">Date Of Submission</div>}
              trigger="hover"
            >
              <button>DOS</button>
            </Tooltip>
          </div>
        </div>
      ),
      dataIndex: "submission_date",
      key: "submission_date",
      width: 180,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 150,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 200,
    },

    {
      title: "Priority",
      dataIndex: "rate_issuue",
      key: "rate_issuue",
      width: 120,
    },
    {
      title: "Category",
      dataIndex: "areaofconcernName",
      key: "areaofconcernName",
      width: 120,
    },
    {
      title: "Requester Name",
      dataIndex: "requester_name",
      key: "requester_name",
      width: 150,
    },

    {
      title: "SR Severity",
      dataIndex: "severitiy",
      key: "severitiy",
      width: 120,
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      width: 120,
    },
    {
      title: "Requester Email",
      dataIndex: "email",
      key: "email",
      width: 200,
    },

    {
      title: "Troubleshooting",
      dataIndex: "primary_troubleshooting",
      key: "primary_troubleshooting",
      width: 150,
    },
    {
      title: "Type of Issue",
      dataIndex: "typesofissue",
      key: "typesofissue",
      width: 150,
    },

    {
      title: (
        <div className="flex justify-center items-center m-auto">
          <div className=" mr-1 flex justify-center items-center">
            <ClockCircleOutlined style={{ fontSize: 15 }} />
            <p className="ml-1">Submission Time</p>
          </div>
        </div>
      ),
      dataIndex: "submission_time",
      key: "submission_time",
      width: 180,
    },
    {
      title: (
        <div className="flex justify-center items-center m-auto">
          <p className="mb-1.5 mr-1">
            <CalendarOutlined />
          </p>
          <p>Updated Date</p>
        </div>
      ),
      dataIndex: "updated_at",
      key: "updated_at",
      width: 180,
    },
    {
      title: "Attachment",
      dataIndex: "attachemnets",
      key: "attachemnets",
      width: 150,
    },
    {
      title: "Status",
      fixed: "right",
      dataIndex: "status",
      key: "status",
      width: 150,
    },

    {
      title: "Action",
      fixed: "right",
      align: "center",
      width: "80px",
      key: "action",
      render: (item) => (
        <>
          <Space size="middle">
            <Link
              to={`/view-ticket/${item.id}`}
              // onClick={(id) => showModal(item.id)}
              style={{ color: "black" }}
            >
              <Tooltip placement="top" title={"View Ticket"}>
                <EyeOutlined
                  style={{
                    color: "#265BA6",
                    fontSize: "18px",
                    // marginRight: 5,
                  }}
                />
              </Tooltip>
            </Link>

            <a
              // to={`/view-ticket/${item.id}`}
              onClick={(id) => modalOpen(item.id)}
              style={{ color: "black" }}
            >
              <Tooltip placement="top" title={"View Logs"}>
                <HistoryOutlined
                  style={{
                    color: "#265BA6",
                    fontSize: "18px",
                    // marginRight: 5,
                  }}
                />
              </Tooltip>
            </a>
          </Space>
        </>
      ),
    },
  ];

  const htmlToPlainText = (html) => {
    // Create a temporary element to parse the HTML
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;

    // Get the plain text content from the temporary element
    let plainText = tempElement.textContent || tempElement.innerText;

    // Clean up special HTML entities
    const entities = {
      "&lt;": "<",
      "&gt;": ">",
      "&amp;": "&",
      "&quot;": '"',
      "&apos;": "'",
      "&nbsp;": " ",
      // Add more entities as needed
    };

    for (const entity in entities) {
      plainText = plainText.replace(new RegExp(entity, "g"), entities[entity]);
    }

    // Remove any extra leading/trailing white spaces
    plainText = plainText.trim();

    return plainText;
  };
  // Function to export data to Excel
  const exportToExcel = () => {
    const modifiedData =
      getAllticketsData &&
      getAllticketsData.length > 0 &&
      getAllticketsData.map((item) => ({
        "Ticket No": item.service_request_number,
        Title: item.title,
        Email: item.email,
        "Submission Date": dayjs(item.created_at).format("DD/MM/YYYY"),
        "Submission Time": dayjs(item.created_at).format("hh:mm:ss A"),
        Description: htmlToPlainText(item.description),
        "Updated At": dayjs(item.updated_at).format("DD/MM/YYYY hh:mm:ss A"),
        "Requester Name":
          item.user && item.user.first_name
            ? item.user.first_name + " " + item.user.last_name
            : "-",
        "Primary Troubleshooting": item.primary_troubleshooting,
        "Primary Troubleshooting Description":
          item.primary_troubleshooting_description,
        "Area of concern Name": item.areaofconcernName,
        Status: item.status,
        "Level Of Ticket": item.level,
        "Rate Issuue": item.rate_issuue,
        "Type Of Issue": item.typesofissue,
      }));
    const worksheet = XLSX.utils.json_to_sheet(modifiedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "SAP_Ticket_list.xlsx");
  };

  //const dataSourceLog = useMemo(() => getDataLogSource(logData), [logData]);

  // Function to export data to Excel

  const exportLogToExcel = () => {
    const data =
      getAllTicketLogData &&
      getAllTicketLogData.map((data) => ({
        "Ticket No":
          data.ticket && data.ticket.service_request_number
            ? data.ticket.service_request_number
            : "-",
        Email:
          data.ticket && data.ticket.email
            ? data.ticket && data.ticket.email
            : "-",
        "Submission Date": dayjs(data.created_at).format("DD/MM/YYYY"),
        "Submission Time": dayjs(data.created_at).format("hh:mm:ss A"),
        Log: data.log ? data.log : "-",
        "Project Name": data.project ? data.project : "-",
        // "Requester Name": data.log ? data.log : "-",
        Category:
          data && data.areaofconcernName ? data && data.areaofconcernName : "-",
      }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet2");

    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "SAP_Ticket_log.xlsx");
  };

  return (
    <Fragment>
      <Typography.Title level={3} className="ml-3">
        Tickets
      </Typography.Title>
      <Card
        className="main-card no-padding"
        title={
          <>
            <Row align="middle" justify="">
              <Col xs={14} sm={14} md={14} lg={18} xl={20} xxl={17} align="">
                {" "}
                <Form
                  className="custom-form md:pl-3 sm:pl-8"
                  name="basic"
                  layout="inline"
                  onFinish={onFinishSearch}
                  autoComplete="off"
                >
                  <Form.Item name="keyword" className="custom-slect">
                    <Input
                      style={{ width: "100%" }}
                      autoFocus="none"
                      type={"text"}
                      allowClear
                      //onChange={(e) => onchangeSearch(e)}
                      prefix={
                        <SearchOutlined className="site-form-item-icon text-white" />
                      }
                      placeholder="Search Here"
                    />
                  </Form.Item>

                  <Form.Item
                    name="status"
                    className="custom-slect   md:pt-1 lg:pt-0"
                  >
                    <Select
                      showSearch
                      className="user rate-select"
                      placeholder="Select Status"
                      optionFilterProp="children"
                      allowClear
                      onClear={() => setStatus("")}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={[
                        {
                          value: "REGISTERED",
                          label: "Registered",
                        },
                        {
                          value: "IN_PROCESS",
                          label: "In Process",
                        },
                        {
                          value: "AWAITING_BUSINESS_CONFIRMATION",
                          label: "Await Business Confirmation",
                        },
                        {
                          value: "CLOSED",
                          label: "Closed",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    name="rate_issue"
                    className="custom-slect  md:pt-1 lg:pt-0"
                  >
                    <Select
                      showSearch
                      className="user rate-select"
                      placeholder="Select Priority"
                      optionFilterProp="children"
                      allowClear
                      onClear={() => setRateIssue("")}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={[
                        {
                          value: "Normal",
                          label: "Normal",
                        },
                        {
                          value: "Medium",
                          label: "Medium",
                        },
                        {
                          value: "High",
                          label: "High",
                        },
                        {
                          value: "Very High",
                          label: "Very High",
                        },
                        {
                          value: "Urgent",
                          label: "Urgent",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item name="date" className="custom-slect">
                    <RangePicker
                      onChange={onRangeChange}
                      className="range-date"
                    />
                  </Form.Item>

                  <Form.Item className="md:pt-1 lg:pt-0">
                    <Button
                      className="bg-white search-btn user"
                      type="primary"
                      htmlType="submit"
                    >
                      Search
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col
                xs={10}
                sm={10}
                md={10}
                lg={5}
                xl={4}
                xxl={7}
                align="right"
                className="pr-3 custom-dropdown-render"
              >
                <Tooltip placement="top" title={"Create Ticket"}>
                  <Link to="/create-ticket">
                    <Button
                      // onClick={"/create-ticket"}
                      type="primary"
                      className="bg-white-btn plus-btn inline-flex items-center justify-center"
                      size={"large"}
                    >
                      {/* Create Ticket */}
                      <PlusOutlined />
                    </Button>
                  </Link>
                </Tooltip>

                {/* <Tooltip placement="top" title={"Export"}> */}
                <Dropdown.Button
                  className="bg-white-drop inline-flex items-center "
                  menu={{
                    items: [
                      {
                        key: 1,
                        label: (
                          <Typography.Text
                            onClick={exportToExcel}
                            type="primary"
                            className=" ml-2"
                            size={"large"}
                            style={{
                              borderRadius: 5,
                              margin: 0,
                              fontWeight: 700,
                              color: "black",
                            }}
                          >
                            Export tickets
                          </Typography.Text>
                        ),
                      },
                      {
                        key: 2,
                        label: (
                          <Typography.Text
                            //onClick={exportToExcel}
                            type="primary"
                            // loading={getAllTicketLogLoader}
                            disabled={getAllTicketLogLoader}
                            className=" ml-2"
                            size={"large"}
                            style={{
                              borderRadius: 5,
                              margin: 0,
                              fontWeight: 700,
                              color: "black",
                            }}
                            onClick={!getAllTicketLogLoader && exportLogToExcel}
                          >
                            Export Logs
                          </Typography.Text>
                        ),
                      },
                    ],
                  }}
                >
                  Actions
                </Dropdown.Button>
                {/* </Tooltip> */}
                {/* <Button
                  onClick={exportToExcel}
                  type="primary"
                  className="bg-white-btn ml-2"
                  size={"large"}
                >
                  Export tickets
                </Button>

                <Button
                  //onClick={exportToExcel}
                  type="primary"
                  // loading={getAllTicketLogLoader}
                  disabled={getAllTicketLogLoader}
                  className="bg-white-btn ml-2"
                  onClick={exportLogToExcel}
                  size={"large"}
                >
                  Export Logs
                </Button> */}
              </Col>
            </Row>
          </>
        }
        bordered={false}
        style={{
          width: "100%",
        }}
      >
        <Row>
          <Col span={24}>
            <Table
              bordered
              className="ticket-table"
              sticky={true}
              size="small"
              pagination={{
                total: ticketsCount,
                hideOnSinglePage: true,
              }}
              columns={columns}
              dataSource={dataList}
              loading={
                ticketsLoader ||
                ticketStatusUpdateLoader ||
                priorityStatusUpdateLoader ||
                ticketStatusUpdateLoader
              }
              onChange={(e, filters, sorter) =>
                onClickPaginationNumber(e, filters, sorter)
              }
              scroll={{
                x: "max-content",
                //y: window.innerWidth < 1500 ? "55vh" : "60vh",
              }}
            />
          </Col>
        </Row>
        <Modal
          className="log-modal"
          title={
            // <Space align="middle">
            <Row align="middle">
              <Col span={12} className="flex items-center">
                <Image preview={false} src={status1} className="mr-3" />
                <Typography.Title level={4} className="pl-3">
                  Status
                </Typography.Title>
              </Col>
            </Row>
          }
          footer={null}
          centered
          open={modal2Open}
          onOk={() => setModal2Open(false)}
          onCancel={() => setModal2Open(false)}
        >
          <Spin spinning={ticketLogLoader}>
            <Steps
              progressDot
              current={ticketLogData.length}
              direction="vertical"
              items={logData}
            />
          </Spin>
        </Modal>
      </Card>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    ticketsLoader: state.ticket.ticketsLoader,
    ticketsData: state.ticket.ticketsData,
    ticketLogLoader: state.ticket.ticketLogLoader,
    ticketLogData: state.ticket.ticketLogData,
    ticketsDetailLoader: state.ticket.ticketsDetailLoader,
    ticketsDetailData: state.ticket.ticketsDetailData,
    ticketStatusUpdateLoader: state.ticket.ticketStatusUpdateLoader,
    ticketStatusUpdateData: state.ticket.ticketStatusUpdateData,
    ticketsCount: state.ticket.ticketsCount,
    deleteticketsLoading: state.ticket.deleteticketsLoading,
    serviceNumber: state.ticket.serviceNumber,
    getAllticketsData: state.ticket.getAllticketsData,
    getAllticketsLoader: state.ticket.getAllticketsLoader,
    getAllTicketLogLoader: state.ticket.getAllTicketLogLoader,
    getAllTicketLogData: state.ticket.getAllTicketLogData,
    priorityStatusUpdateLoader: state.ticket.priorityStatusUpdateLoader,
    ticketLevelUpdateLoader: state.ticket.ticketLevelUpdateLoader,
  };
};

export default connect(mapStateToProps, {
  actionGetTicketLog,
  actionGetTickets,
  actionGetTicketDetail,
  actionUpdateTicketStatus,
  actionUpdateLevelStatus,
  actionGetAllTicketLog,
  actionDeleteTicket,
  actionGetAllTickets,
  actionUpdatePriorityStatus,
})(Ticket);
