import React from "react";
import { ArrowLeftOutlined, EyeOutlined } from "@ant-design/icons";

const GoBackButton = () => {
  return (
    <>
      <ArrowLeftOutlined style={{ cursor: "pointer" }} />
      <span
        style={{
          cursor: "pointer",
          color: "rgba(0, 0, 0, 0.88)",
          fontWeight: "600",
          fontSize: 18,
        }}
      >
        Go Back
      </span>
    </>
  );
};

export default GoBackButton;
